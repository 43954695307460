import React, { useEffect } from 'react';
import { connect } from 'react-redux';

/*----- Components -----*/
import TransferResultContainer from './TransferResultContainer';
import SearchFormTransfers from '../searchForm';
import GenericSearch from '../../../../components/search';

/*----- Selectors -----*/
import {
	transfersData,
	transfersIsLoading
} from '../../selectors/transfersSelector';

/*----- Utils -----*/
import { parse } from '../../../../utilities/queryString';
import { activitiesDestinations } from '../../../../utilities/constants';

/*----- Actions -----*/
import { getTransfers } from '../../actions/searchFormActions';
import { buildFetchQuery } from '../../../../utilities/buildSearchQuery';

const SearchTransfers = ({ isLoading, transfers, getTransfers, history }) => {
	useEffect(() => {
		let params = parse(history.location.search, activitiesDestinations);

		if (!params) {
			history.push('/transfers');
			return;
		}

		getTransfers(buildFetchQuery(params));
	}, [getTransfers, history]);

	return (
		<GenericSearch
			isLoading={isLoading}
			data={transfers}
			service={'transfers'}
			ResultContainer={<TransferResultContainer />}
			SearchForm={SearchFormTransfers}
		/>
	);
};

const mapStateToProps = state => {
	return {
		transfers: transfersData(state),
		isLoading: transfersIsLoading(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getTransfers: payload => getTransfers(dispatch, payload)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchTransfers);
