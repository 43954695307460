import React, { useEffect } from 'react';
import { connect } from 'react-redux';

/*----- Components -----*/
import SuccessResponse from './section/SuccessResponse';
import ReservationContent from '../../../commons/reservation/ReservationContent';

/*----- Actions -----*/
import { reserveHotel } from '../../actions/reserveActions';

/*----- Selectors -----*/
import {
	reserveHotelIsLoading,
	reserveHotelError,
	reserveHotelSuccess
} from '../../selectors/reserveHotelSelector';

/*----- Utils -----*/
import { getCheckoutHotel } from '../../../../services/HotelCheckoutService';
import { getId } from '../../../commons/checkout/CheckoutUtils';

const ReserveHotel = ({ history, error, success, isLoading, reserveHotel }) => {
	useEffect(() => {
		let id = getId(history);
		let checkoutData = getCheckoutHotel(id);

		if (!checkoutData) {
			history.push('/hotels');
			return;
		}

		let item_id = checkoutData.item_id;
		delete checkoutData.item_id;

		let params = {
			id: item_id,
			checkout: checkoutData
		};

		reserveHotel(params);
	}, [history, history.location.pathname, reserveHotel]);

	return (
		<ReservationContent
			error={error}
			success={success}
			isLoading={isLoading}
			SuccessResponse={SuccessResponse}
		/>
	);
};

const mapStateToProps = state => {
	return {
		isLoading: reserveHotelIsLoading(state),
		error: reserveHotelError(state),
		success: reserveHotelSuccess(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		reserveHotel: payload => reserveHotel(dispatch, payload)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReserveHotel);
