import React from 'react';
import { Form } from 'antd';
import { StyledCheckboxGroup } from '../styles';
import { isMobile } from '../../../utilities/utils';

const { Item } = Form;

const FilterFormCategories = ({
	form,
	options = [],
	fieldName,
	name,
	fetchData,
	defaultValues = ['all'],
	disabled
}) => {
	const { getFieldDecorator } = form;
	return (
		<Item>
			{getFieldDecorator(fieldName, {
				initialValue: defaultValues
			})(
				<StyledCheckboxGroup
					name={name}
					options={options}
					disabled={disabled}
					afterChange={values => {
						const includeAll = () => {
							return values.includes('all');
						};

						if (
							!values.length ||
							values[values.length - 1] === 'all' ||
							(!includeAll() && values.length === options.length - 1)
						) {
							form.setFieldsValue({ [fieldName]: ['all'] });
						} else if (values.length > 1 && includeAll()) {
							/* Si esta en la ultima posicion significa que recien se seleccinó */
							if (values[values.length - 1] === 'all') {
								form.setFieldsValue({ [fieldName]: ['all'] });
							} else {
								let tmpValues = [...values];
								tmpValues.splice(tmpValues.indexOf('all'), 1);
								form.setFieldsValue({ [fieldName]: tmpValues });
							}
						}
						if (!isMobile()) {
							fetchData();
						}
					}}
				/>
			)}
		</Item>
	);
};

export default FilterFormCategories;
