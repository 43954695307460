/*----- Core -----*/
import React from 'react'
import { useTranslation } from 'react-i18next'

/*----- Assets -----*/

/*----- Ant design -----*/
import { Row, Col } from 'antd'

/*----- Components -----*/ 
import HertoText     from '../../components/hero/HeroText'
import AttributeList from '../../components/attributes_list/AttributeList'

/*----- Styles -----*/
import Styles from './AboutUsStyles'

/*----- Sections -----*/

/*----- Export -----*/
const AboutUs = (props) => {
	const { t } = useTranslation()

  	return (
		<Styles>

			<Col xs={24} sm={{span: 24}} md={{span: 20, offset: 2}} lg={{span: 22, offset: 1}} xl={{span: 22, offset: 1}}>
					<Col>
						<Row type="flex" justify="start" align="top">
								<HertoText 
									title    = {t('aboutUs.title')} 
									subtitle = {t('aboutUs.subtitle')}
								/>
						</Row>
					</Col>
					<Col xs={24} sm={24} md={24} lg={12} xl={12}>
						<Row type="flex" justify="start" align="top">
							<Col xs={24} sm={12} md={12} lg={0} xl={0} className="">
							</Col>
						</Row>
					</Col>
					<Col span={24}>
						<Row type="flex" justify="start" align="top">
								<AttributeList />
						</Row>
					</Col>
			</Col>

    </Styles>
  )
}
export default AboutUs