import styled, {css} from 'styled-components';
import Color from '../../commons/Color';
import OpenSans from '../../commons/mixins/fonts/OpenSans';
import OpenSansBold from '../../commons/mixins/fonts/OpenSansBold';

const StyledItemService = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: 25px;
		margin-right:3px;
	}

	span {
		line-height: 28px;
		${OpenSans(12,400, Color.text)};
		margin:0;

		${props => props.bold && css`
			${OpenSansBold(11, 400, Color.footer)};
		`}
	}
`

export default StyledItemService;