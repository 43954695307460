import React, { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getColumnSearchProps } from './utils';
import SearchBar from './searchBar';
import { reservationsColumns } from '../../../../../utilities/constants';
import ErrorImage from '../../../../../assets/images/NoResults.svg';
import { ConfigProvider, Table } from 'antd';
import { StyledTableContainer, StyledTableWrapper } from './styles';
import ResponseWithImage from '../../../../commons/ResponseWithImage';

const ReservationsTable = ({ loading, dataSource, history }) => {
	const { t } = useTranslation();
	const columnsArr = reservationsColumns(t);
	const [columnRefs, setColumnRefs] = useState([]);
	useEffect(() => {
		setColumnRefs(columnRefs =>
			Array(columnsArr.length)
				.fill()
				.map((_, i) => columnRefs[i] || createRef())
		);
	}, [columnsArr.length]);

	const columns = columnsArr.map((column, index) => {
		return Object.assign(column, {
			...getColumnSearchProps({
				dataIndex: column.dataIndex,
				inputRefs: columnRefs,
				refIndex: index,
				customRender: column.render
			})
		});
	});

	return (
		<StyledTableContainer>
			<SearchBar history={history} />
			<StyledTableWrapper>
				<ConfigProvider
					renderEmpty={() => (
						<ResponseWithImage
							image={ErrorImage}
							title={t('myReservations.noResults.title')}
							subtitle={t('general.noResults.subtitle')}
						/>
					)}
				>
					<Table
						dataSource={dataSource}
						columns={columns}
						loading={loading}
						pagination={false}
					/>
				</ConfigProvider>
			</StyledTableWrapper>
		</StyledTableContainer>
	);
};

export default ReservationsTable;
