import React from 'react';
import { useTranslation } from 'react-i18next';

import ResponseContent from '../../../../components/responseContent';
import SpinnerModal from '../../../../components/modal/SpinnerModal';
import ErrorResponseReservation from '../ErrorResponseReservation';
import { StyledContainerLoading } from '../../checkout/styles';

const ReservationContent = ({
	success,
	isLoading,
	error,
	SuccessResponse,
	errorResponse
}) => {
	const { t } = useTranslation();

	const Spinner = () => (
		<SpinnerModal visible={isLoading} spinnerText={t('reservation.inProcess')} />
	);

	return (
		<StyledContainerLoading isLoading={isLoading} error={error}>
			<ResponseContent
				isLoading={isLoading}
				Loading={Spinner}
				successResponse={success}
				Success={SuccessResponse}
				Error={errorResponse || <ErrorResponseReservation />}
			/>
		</StyledContainerLoading>
	);
};

export default ReservationContent;
