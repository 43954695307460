import React from 'react';
import { useTranslation } from 'react-i18next';

/*----- Form items -----*/
import { requiredRule } from '../../../../components/formItems/InputRules';

import {
	MainDiv,
	StyledTitle,
	StyledText,
	StyledTextMail,
	StyledTextSelect,
	StyledSearchSelect
} from './styles';
import { Form } from 'antd';

const { Item } = Form;

const PickUpLocationForm = ({ form, initialValue, locations }) => {
	const { t } = useTranslation();
	const { getFieldDecorator } = form;

	return (
		<MainDiv>
			<StyledTitle bold content={t('checkout.activities.meetingPoint.title')} />
			<StyledText content={t('checkout.activities.meetingPoint.description.1')} />
			<StyledText content={t('checkout.activities.meetingPoint.description.2')} />
			<StyledTextMail bold content={t('checkout.activities.meetingPoint.mail')} />
			<StyledTextSelect
				content={t('checkout.activities.meetingPoint.selectHotel')}
			/>

			<Item>
				{getFieldDecorator('pickUpLocation', {
					rules: requiredRule(t('checkout.activities.meetingPoint.field.error')),
					validateTrigger: 'onSubmit',
					initialValue: initialValue
				})(
					<StyledSearchSelect
						placeholder={t('checkout.activities.meetingPoint.field.title')}
						options={locations}
					/>
				)}
			</Item>
		</MainDiv>
	);
};

export default PickUpLocationForm;
