import Raven from 'raven-js';

const raven = Raven.config(
	'https://b67a0612f74b48298ef50c9110eb51d8@sentry.snappler-app.com/90',
	{
		environment: process.env.REACT_APP_SENTRY_ENV
	}
);
if (process.env.REACT_APP_SENTRY_ENV === 'staging') raven.install();

export default raven;
