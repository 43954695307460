/** * Core ***/
import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

/** * Components ***/
import AttributeItem from './AttributeItem'
/** * Assets ***/
import DownArrow from '../../assets/icons/arrow-down.svg'
import Device from '../../commons/Device'

const StyledAttributeList = styled.div`
  display: flex;
	justify-content: center;
	flex-direction: column;
	border: 1px solid lightgrey;
  border-radius: 8px;
	padding: 8px;
	width: 100%;
  max-width: 100%;
	@media ${Device.md} {
		flex-direction: row;
		max-width: 1000px;
		padding: 40px;
	}
`
const AttributeList = () => {
  const { t } = useTranslation()
  return (
    <StyledAttributeList className="attributes-list">
      <AttributeItem
        urlIcon = { DownArrow }
        title = {t('aboutUs.data.segments.title')}
        description = {t('aboutUs.data.segments.description')}
      />
      <AttributeItem
        urlIcon = { DownArrow }
        title = {t('aboutUs.data.ourStrength.title')}
        description = {t('aboutUs.data.ourStrength.description')}
      />
      <AttributeItem
        urlIcon = { DownArrow }
        title = {t('aboutUs.data.offer.title')}
        description = {t('aboutUs.data.offer.description')}
      />
    </StyledAttributeList>
  )
}
export default AttributeList
