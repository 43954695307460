import { RESET_CART, ADD_TO_CART, REMOVE_FROM_CART } from '../actions/cartActions';

const initialState = {
	data: JSON.parse(window.localStorage.getItem('cart')) || []
};

const cart = (state = initialState, action) => {
	switch (action.type) {
		case RESET_CART:
			return {
				...state,
				data: []
			};
		case ADD_TO_CART:
			const current = [...state.data, action.payload];
			window.localStorage.setItem('cart', JSON.stringify(current));
			return {
				...state,
				data: current
			};
		case REMOVE_FROM_CART:
			const removeId = state.data.findIndex(i => i.uuid === action.payload);
			const cartItems = [...state.data];
			if (removeId !== -1) cartItems.splice(removeId, 1);
			window.localStorage.setItem('cart', JSON.stringify(cartItems));
			return {
				...state,
				data: cartItems
			};
		default:
			return state;
	}
};

export default cart;
