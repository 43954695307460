export const GET_ACTIVITIES = 'GET_ACTIVITIES';
export const GET_ACTIVITIES_SUCCESS = 'GET_ACTIVITIES_SUCCESS';
export const GET_ACTIVITIES_FAILED = 'GET_ACTIVITIES_FAILED';
export const RESET_ACTIVITY_TRANSFER = 'RESET_ACTIVITY_TRANSFER';

export const getActivities = (dispatch, param) =>
	dispatch({ type: GET_ACTIVITIES, payload: param });

export const resetActivityFilter = (dispatch, params) =>
	dispatch({ type: RESET_ACTIVITY_TRANSFER, payload: params });
