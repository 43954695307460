import {
	CANCEL_RESERVATION,
	CANCEL_RESERVATION_SUCCESS,
	CANCEL_RESERVATION_FAILED
} from '../actions/myReservationsActions';

const initialState = {
	isLoading: false,
	error: '',
	success: false
};

export default function cancelReservation(state = initialState, action) {
	switch (action.type) {
		case CANCEL_RESERVATION:
			return {
				...state,
				isLoading: true,
				error: '',
				success: false
			};
		case CANCEL_RESERVATION_SUCCESS:
			return {
				...state,
				isLoading: false,
				success: true
			};
		case CANCEL_RESERVATION_FAILED:
			return {
				...state,
				isLoading: false,
				error: action.payload,
				success: false
			};
		default:
			return state;
	}
}
