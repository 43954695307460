export const driver = window.localStorage;

export const addCheckoutPackages = (id, data) => {
	let packages = JSON.parse(driver.getItem('checkout_packages')) || {};

	if (packages[id]) {
		packages[id] = { ...packages[id], ...data };
	} else {
		packages[id] = data;
	}
	driver.setItem('checkout_packages', JSON.stringify(packages));
};

export const removeCheckoutPackages = id => {
	const packages = JSON.parse(driver.getItem('checkout_packages')) || {};
	delete packages[id];
	driver.setItem('checkout_packages', JSON.stringify(packages));
};

export const getCheckoutPackages = id => {
	const packages = JSON.parse(driver.getItem('checkout_packages'));
	return packages ? packages[id] : null;
};

export const addPackageRates = rates =>
	driver.setItem('package_rates', JSON.stringify(rates));

export const getPackageRates = () => JSON.parse(driver.getItem('package_rates'));

export const addPackageHotel = rates =>
	driver.setItem('package_hotel', JSON.stringify(rates));

export const getPackageHotel = () => JSON.parse(driver.getItem('package_hotel'));
