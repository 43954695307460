/*----- Core -----*/
import React from 'react'
import styled from 'styled-components'

/*----- Assets -----*/
import underConstructionImage from '../assets/images/under_construction.svg'

/*----- Commons -----*/
import Montserrat from '../commons/mixins/fonts/Montserrat'

/*----- Styles-----*/
const StyledContactItem = styled.article`
	height: 100vh;
	width: 100%;
	background: #f1f1f1;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	.under-construction-page__image {
		width: 250px;
		background: white;
    border-radius: 50%;
	}
	.under-construction-page__message {
		${ Montserrat(20, 600, 'Black' ) }
		padding: 20px;
	}
`
/*----- Component -----*/
const PageUnderConstruction = ({ message }) => (
  <StyledContactItem className="under-construction-page">
	<img src={underConstructionImage} alt="Constructor" className="under-construction-page__image"/>
		<div className="under-construction-page__message">Pagina en construcción</div>
  </StyledContactItem>
)
export default PageUnderConstruction
