import React from 'react';
import { connect } from 'react-redux';

/*----- Components -----*/
import ActivitySaleCard from '../../../../components/card/ActivitySaleCard';

/*----- Selectors -----*/
import { activitiesData } from '../../selectors/activitiesSelector';

/*----- Assets -----*/
import guideIcon from '../../../../assets/icons/guide.svg';
import busIcon from '../../../../assets/icons/bus.svg';

const iconServices = [
	{ icon: busIcon, label: 'Traslados incluídos' },
	{ icon: guideIcon, label: 'Guías disponibles en español, inglés y portugués' }
];

const ResultList = ({ activitiesData }) => {
	return activitiesData.map((data, i) => {
		return data.rates.map((rate, i) => (
			<ActivitySaleCard
				key={i}
				title={data.name}
				imageUrl={data.images[0]}
				destination={`/activities/${rate.item_id}`}
				price={
					rate.amount_details.occupancy.passenger.sale +
					rate.amount_details.occupancy.passenger.iva
				}
				currency={rate.currency}
				iconServices={iconServices}
			/>
		));
	});
};

const mapStateToProps = state => {
	return {
		activitiesData: activitiesData(state)
	};
};

export default connect(mapStateToProps, null)(ResultList);
