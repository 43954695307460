import { createGlobalStyle } from 'styled-components';
import CardListContainer from './commons/styles/CardListContainer';

/*** Open Sans Fonts ***/
import OpenSansRegularFont from './fonts/OpenSans/OpenSans-Regular.ttf';
import OpenSansBoldFont from './fonts/OpenSans/OpenSans-Bold.ttf';
import Color from './commons/Color';
import Device from './commons/Device';

import OpenSansBold from './commons/mixins/fonts/OpenSansBold';

export default createGlobalStyle`

	${CardListContainer()}

	@font-face {
		font-family: 'OpenSans';
		src:
			url(${OpenSansRegularFont}) format('truetype');
	}

    @font-face {
			font-family: 'OpenSansBold';
			src:
				url(${OpenSansBoldFont}) format('truetype');
    }


	body {
		margin: 0;
		padding: 0;
	}

	#root {
		height:100%;
	}

	h1, h2, h3, h4, p, a, div, button, li {
    font-family: 'OpenSans', sans-serif;
  }

	.height-100 {
		height: 100%;
	}

	/*** Overwrite Ant Design input styles ***/
	/*** Modal ***/
	.generic-modal {
		max-width: 900px;
		width: 100% !important;
		.ant-modal-body {
			padding: 0;
		}
	}
	.ant-modal-mask {
		background-color: rgba(0, 0, 0, 0.8) !important;
	}
	/*** Form ***/
	.ant-form-item {
		margin-bottom: 0 !important;
	}

	/* spinner-modal */
	.spinner-modal{
		top:25% !important;
		.ant-modal-content{
			width:50%;
			margin-left:25%;
			margin-right:25%;
			padding: 5% 10% 5% 8%;

			@media ${Device.xs} {
				width: 80%;
				margin-left:10%;
				margin-right:10%;
			}
		}

	}

	.custom-select-group > div {
		overflow:initial !important;
	}

	.custom-select-group {
		width:350px !important;

		.ant-select-dropdown-menu-item-group-title{
			${OpenSansBold(14, 400, Color.footer)}
			background-color: #F5F5F5;
		}
	}

	.modal-map{
		.ant-modal-body .custom-title{
			padding-left:20px;
		}
	}

	.select-language > div {
		overflow:initial !important;
	}

	.select-language {
		width:120px !important;

		.ant-select-dropdown-menu-item-group-title{
			background-color: #F5F5F5;
		}

		img{
			margin-bottom:2px;
			margin-right:5px;
			width:10px;
			height:10px;
		}
	}

	@media ${Device.xs} {
		.ant-calendar-picker-container {
			.ant-calendar {
				.ant-calendar-input{
					font-size: 16px;
				}
				.ant-calendar-header {
					height: 60px;
					display: flex;
					justify-content: center;
					align-items: center;
					width:100%;
					.ant-calendar-my-select {
						font-size: 16px;
					}
					.ant-calendar-prev-month-btn, .ant-calendar-next-month-btn{
						margin: 0px 12px;
					}
					.ant-calendar-prev-month-btn, .ant-calendar-next-month-btn, .ant-calendar-prev-year-btn, .ant-calendar-next-year-btn {
						position: relative ;
						left: 0px;
						::before, ::after {
							width: 15px;
							height: 15px;
						}
					}
				}
			}
		}
	}
`;
