/*----- Core -----*/
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

/*----- Components -----*/
import { Form } from 'antd';
import FilterForm from '../../../../components/form/FilterForms';

/*----- Constants -----*/

/*----- Actions -----*/
import { getActivities } from '../../actions/searchFormActions';

/*----- Selectors -----*/
import {
	activitiesQuery,
	activityFilterReseted
} from '../../selectors/activitiesSelector';

/*----- utilities -----*/
import { activitiesDestinations } from '../../../../utilities/constants';
import { parse } from '../../../../utilities/queryString';
import {
	buildFetchQuery,
	addFilterToQuery
} from '../../../../utilities/buildSearchQuery';
import { replaceSearch } from '../../../../components/pagination/SearchUtils';

const FilterActivity = ({
	history,
	form,
	getActivities,
	query,
	filterReseted,
	onSubmit
}) => {
	const [initialMaxPrice, setInitialMaxPrice] = useState(null);
	const [initialMinPrice, setInitialMinPrice] = useState(null);

	useEffect(() => {
		let params = parse(history.location.search, activitiesDestinations);
		if (!params) {
			history.push('/activities');
			return;
		}

		setInitialMaxPrice(params.max_price);
		setInitialMinPrice(params.min_price);
	}, [history]);

	useEffect(() => {
		if (filterReseted) {
			form.resetFields();
		}
	}, [filterReseted, form]);

	const fetchData = () => {
		let newQuery = addFilterToQuery(query, form.getFieldsValue());
		getActivities(buildFetchQuery(newQuery));
		replaceSearch(history, 'activities', newQuery);

		if (onSubmit) onSubmit();
	};

	return (
		<FilterForm
			form={form}
			fetchData={fetchData}
			disabled={filterReseted}
			defaultValues={{
				min_price: initialMinPrice,
				max_price: initialMaxPrice
			}}
		/>
	);
};

const mapStateToProps = state => {
	return {
		query: activitiesQuery(state),
		filterReseted: activityFilterReseted(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getActivities: payload => getActivities(dispatch, payload)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Form.create({ name: 'activity_filter' })(withRouter(FilterActivity)));
