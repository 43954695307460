import styled from 'styled-components';
import { Row, Col, Modal } from 'antd';
import DefaultButton from '../../../../components/button/DefaultButton';
import Device from '../../../../commons/Device';
import { Carousel } from 'react-responsive-carousel';

export const StyledRow = styled(Row)`
	min-height: 400px;
`;

export const BigImage = styled(Col)`
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	border-right: 3px solid white;
	background-image: url(${props => props.image});
	height: 400px;
	@media ${Device.xs} {
		height: 200px;
	}

	&:hover {
		cursor: ${props => (props.showCursor ? 'pointer' : 'initial')};
	}
`;

export const TwoImage = styled(Row)``;

export const SmallImage = styled(Col)`
	&.ant-col {
		min-height: 200px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		background-image: url(${props => props.image});
		border-bottom: ${props => (props.borderbottom ? '2px solid white' : 'none')};
		border-right: ${props => (props.borderright ? '2px solid white' : 'none')};
		position: relative;
	}
`;

export const Button = styled(DefaultButton)`
	& {
		position: absolute;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		width: 100px;
		bottom: 0;
		.default-button {
			max-width: initial;
		}
	}
`;

export const StyledDetailHeader = styled.div`
	@media ${Device.xs} {
		margin-top: 30px;
	}
`;

export const StyledCarousel = styled(Carousel)`
	height: ${props => props.heigth};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	li.slide {
		background: #00000000 !important;
		div {
			padding: 1px;
			height: 400px;
			@media ${Device.xs} {
				padding: 0px;
				height: auto;
			}
		}
		img {
			height: 400px;
			@media ${Device.xs} {
				height: auto;
			}
		}
	}

	.carousel .thumbs-wrapper {
		margin: 20px 35px;
	}
	.carousel-slider {
		width: ${props => props.width} !important;
		padding: ${props => props.sliderPadding};
	}

	@media ${Device.xs} {
		.carousel-slider {
			width: 100% !important;
			padding: 0px;
		}
	}

	.carousel .dot {
		width: 12px !important;
		height: 12px !important;
	}

	.carousel .control-next.control-arrow,
	.carousel .control-next.control-arrow:hover {
		background-color: transparent;
		right: 10px;
	}

	.carousel .control-prev.control-arrow,
	.carousel .control-prev.control-arrow:hover {
		background-color: transparent;
		left: 10px;
	}

	.carousel .control-arrow,
	.carousel.carousel-slider .control-arrow {
		opacity: 1;
	}

	.carousel.carousel-slider .control-next.control-arrow:before {
		content: '';
		border: solid white;
		border-width: 0 5px 5px 0;
		display: inline-block;
		padding: 14px;
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		@media ${Device.xs} {
			padding: 8px;
		}
	}

	.carousel.carousel-slider .control-prev.control-arrow:before {
		content: '';
		border: solid white;
		border-width: 0 5px 5px 0;
		display: inline-block;
		padding: 14px;
		transform: rotate(135deg);
		-webkit-transform: rotate(135deg);
		@media ${Device.xs} {
			padding: 8px;
		}
	}
`;

export const StyledModal = styled(Modal)`
	&& {
		max-width: 100%;
		min-height: 100%;
		margin: 0;
		padding: 0;
		height: auto;
		top: 0;
	}
	height: 100vh;
	position: relative;
	display: flex;

	&.ant-modal {
		width: 100% !important;
		height: 10px;
	}

	.ant-modal-content {
		width: 100% !important;
		border-radius: unset;
		padding: 12px;
		background-color: #000000d6;
	}
	.ant-modal-body {
		height: 100%;
		padding: 24px 0px;
	}

	.ant-modal-close {
		& {
			outline: 0px;
		}
		.ant-modal-close-x {
			font-size: 32px;
		}
		.anticon svg {
			fill: white !important;
		}
	}
`;

export const ImageContainer = styled.div`
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	border-right: 3px solid white;
	background-image: url(${props => props.image});
	height: 400px;
`;

export const NextButton = styled;
