import React from 'react';
import { StyledCarousel } from './styles';
import { useTranslation } from 'react-i18next';
import { ImageContainer } from './styles';

const Gallery = ({
	images,
	showThumbs = true,
	showIndicators = false,
	showArrows = true,
	width = '100%',
	sliderPadding = '0px',
	heigth = '100%',
	useImage = false
}) => {
	const { t } = useTranslation();

	const getImages = () => {
		return images.map((image, idx) => {
			if (useImage) {
				return <ImageContainer image={image} key={idx} />;
			} else {
				return <img key={idx} src={image} alt="hotel" />;
			}
		});
	};

	return (
		<StyledCarousel
			showThumbs={showThumbs}
			showIndicators={showIndicators}
			useKeyboardArrows={true}
			showArrows={showArrows}
			width={width}
			sliderPadding={sliderPadding}
			heigth={heigth}
			statusFormatter={(current, total) => `${current} ${t('general.of')} ${total}`}
		>
			{getImages()}
		</StyledCarousel>
	);
};

export default Gallery;
