import React from 'react';
import { TitlesContainer, StyledText } from './styles';
import PropTypes from 'prop-types';

const CheckoutTitle = ({ text, children }) => {
	return (
		<TitlesContainer>
			<StyledText content={text} />
			{children}
		</TitlesContainer>
	);
};

export default CheckoutTitle;

CheckoutTitle.defaultProps = {
	text: ''
};

CheckoutTitle.propTypes = {
	text: PropTypes.string.isRequired,
	children: PropTypes.node
};
