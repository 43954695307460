import styled from 'styled-components';
import Text from '../../../components/text/Text';

export const StyledItem = styled.div`
	width: 100%;
	padding-top: 10px;
`;

export const StyledText = styled(Text)`
	font-size: 20px;
	margin-bottom: 5px;
`;
