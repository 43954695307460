export const driver = window.localStorage;

export const addCheckoutHotel = (id, data) => {
	let hotels = JSON.parse(driver.getItem('checkout_hotel')) || {};

	if (hotels[id]) {
		hotels[id] = { ...hotels[id], ...data };
	} else {
		hotels[id] = data;
	}
	driver.setItem('checkout_hotel', JSON.stringify(hotels));
};

export const removeCheckoutHotel = id => {
	const hotels = JSON.parse(driver.getItem('checkout_hotel')) || {};
	delete hotels[id];
	driver.setItem('checkout_hotel', JSON.stringify(hotels));
};

export const getCheckoutHotel = id => {
	const hotels = JSON.parse(driver.getItem('checkout_hotel'));
	return hotels ? hotels[id] : null;
};
