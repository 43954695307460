import React from 'react';

import { useTranslation } from 'react-i18next';
import { StyledCheckoutContainer } from '../../../../components/containers/CheckoutContainer/styles';
import StepsContainer from '../../../../components/steps/StepsContainer';
import { ItemCheckoutContainer } from '../../../../components/containers/CheckoutContainer';
import CheckoutTitle from '../../../../components/titles/CheckoutTitle';
import { StyledTitle } from './styles';

const GenericConfirm = ({ steps, id, active, title, ConfirmCard }) => {
	const { t } = useTranslation();

	return (
		<StyledCheckoutContainer>
			<StepsContainer active={active} rate_id={id} dataSteps={steps(id)} />
			<ItemCheckoutContainer>
				<CheckoutTitle text={t('checkout.detail')}>
					<StyledTitle bold content={title} />
				</CheckoutTitle>
			</ItemCheckoutContainer>

			{ConfirmCard}
		</StyledCheckoutContainer>
	);
};

export default GenericConfirm;
