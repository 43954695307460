import React from 'react';
import CustomRadio from '../../../../components/form/Radio';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

const IsAirportRadio = ({
	form,
	setIsAirport,
	isAirport,
	transferKind,
	isMix,
	fromPackage = false
}) => {
	const { getFieldDecorator } = form;
	const { t } = useTranslation();
	const options = [
		{ value: true, label: t('general.airport') },
		{ value: false, label: t('general.busTerminal') }
	];

	return (
		<Row
			style={{ marginTop: '15px', paddingBottom: '15px' }}
			className={'row-radio'}
		>
			{getFieldDecorator(`${isMix ? transferKind + '_' : ''}is_airport`, {
				rules: [{ required: true, message: t('checkout.business.error') }],
				initialValue: isAirport
			})(
				<CustomRadio
					options={options}
					afterChange={value => {
						setIsAirport(prevState => {
							let prev = { ...prevState };
							if (fromPackage) {
								prev = value.target.value;
							} else {
								prev[transferKind] = value.target.value;
							}
							return prev;
						});
					}}
				/>
			)}
		</Row>
	);
};

export default IsAirportRadio;
