/*----- Imports -----*/
import React     from 'react'
import PropTypes from 'prop-types'
import styled    from 'styled-components'
import { useTranslation } from 'react-i18next'

/*----- Commons-----*/
import Montserrat from '../commons/mixins/fonts/Montserrat'

/*----- Styles-----*/
const StyledServiceDetailItem  = styled.div`
	${ Montserrat(12, 400, 'black') }
	display: flex;
	margin: 4px;
	.service-detail-item__icon {
		width: 20px;
		margin-right: 8px;
	}

	svg { 
		width: 30px;
		fill: #000000;
		transition: all 0.5s;
		transform: scale(0.7);
	}
	.service-detail-item__content{
		padding-top:3px;
	}
`

/*----- Component -----*/
const ServiceDetailItem = ({ icon, content, svgIcon}) => {
	const { t } = useTranslation()
  	return(
    	<StyledServiceDetailItem className="service-detail-item d-flex">
			{svgIcon ? svgIcon : null}
			{icon ? <img src={icon} alt="" className="service-detail-item__icon"/> : null}
			<div className="service-detail-item__content">
				{ t(content) }
			</div>
		</StyledServiceDetailItem>
	)
}
export default ServiceDetailItem

/*----- PropTypes -----*/
ServiceDetailItem.propTypes = {
	icon: 	 PropTypes.string,
	content: PropTypes.string.isRequired
}
