import styled from 'styled-components';
import Device from '../../../../commons/Device';
import Color from '../../../../commons/Color';
import OpenSans from '../../../../commons/mixins/fonts/OpenSans';
import OpenSansBold from '../../../../commons/mixins/fonts/OpenSansBold';
import SearchFormTransfers from '../searchForm';

import Text from '../../../../components/text/Text';

export default styled.main`
	margin-bottom: 50px;

	.card__list-container {
		padding-top: 20px;
	}

	.card-container {
		padding: 10px;
	}

	.title-service-section {
		padding-top: 50px;

		h1 {
			${OpenSans(34, 400, Color.footer)}
			span {
				${OpenSansBold(34, 600, Color.footer)}
			}
		}
	}

	.card-container {
		height: 100%;
	}

	@media ${Device.xs} {
		.card__list-container {
			padding-top: 10px;
		}

		.card-container {
			padding: 0;
		}
	}
`;

export const StyledText = styled(Text)`
	font-size: 34px;
	font-weight: bold;
	@media ${Device.xs} {
		font-size: 25px;
		text-align: center;
		padding: 0px 19%;
	}
`;

export const FilterContainer = styled.article`
	display: flex;
	justify-content: center;
`;

export const StyledSearchFormTransfer = styled(SearchFormTransfers)`
	z-index: 1;
	width: 900px;
	border-radius: 8px;
	box-shadow: 0px 10px 60px -10px rgba(0, 0, 0, 0.5);
	background-color: white;
	margin-top: -64px;

	@media ${Device.xs} {
		width: 90%;
		padding: 30px 15px;
		margin-top: ${props => props.marginTopOffset};

		.custom-title {
			text-align: center;
			font-size: 18px;
			font-weight: bold;
			margin: 0px;
		}
	}
`;
