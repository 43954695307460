/*----- Core -----*/
import React from 'react'

/*----- Components -----*/
import { Form, InputNumber } from 'antd'

const { Item } = Form

const InputNumberItem = ({label, fieldName, placeholder, getFieldDecorator, rules}) => {

    const parseFormatter = (value) => {
        if( value.length === 1 && isNaN(value) ){
            return ''
        }
        if( value.length > 1 ){
            let lastChar = value.charAt(value.length-1);
            if( isNaN(lastChar) ){
                return value.substring(0, value.length - 1);
            }
            return value
        }
        return value
    }
    
    
    return (
        <Item label={label}>
            {
                getFieldDecorator(
                    fieldName,
                    {
                        rules: rules,
                        validateTrigger: 'onSubmit'
                    }
                )(
                    <InputNumber
                        style={{ width: '100%' }} 
                        min={0}
                        formatter={value => parseFormatter(value)}
                        placeholder={placeholder}/>
                )
            }
        </Item>
    )
}

export default InputNumberItem