import styled from 'styled-components';
import Color from '../../commons/Color';

export const StyledPagination = styled.div`
	margin: 30px 0px 0px 0px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.ant-pagination-item {
		border-radius: 50px;
		margin-right: 8px;
	}

	.ant-pagination-item-active {
		background: ${Color.main};
		color: #fff;
		font-weight: 700;
		border-color: ${Color.main};
	}

	.ant-pagination-next {
		svg {
			fill: ${Color.main};
		}
	}

	.ant-pagination-prev .ant-pagination-item-link,
	.ant-pagination-next .ant-pagination-item-link {
		font-size: 17px;
	}

	.ant-pagination.mini .ant-pagination-prev,
	.ant-pagination.mini .ant-pagination-next {
		min-width: 30px;
		height: 30px;
	}

	.ant-pagination.mini .ant-pagination-item {
		min-width: 30px;
		height: 30px;
		line-height: 27px;
	}
`;
