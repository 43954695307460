import React from 'react';
import { useTranslation } from 'react-i18next';
import FilterFormFronCategories from './FilterFormCategories';

const FilterFormTransferKind = ({
	form,
	fetchData,
	defaultValues = [],
	disabled
}) => {
	const { t } = useTranslation();

	const kinds = [
		{ value: 'all', label: t('transfers.all') },
		{ value: 'in', label: t('transfers.kinds.in') },
		{ value: 'out', label: t('transfers.kinds.out') },
		{ value: 'mix', label: t('transfers.kinds.mix') }
	];

	return (
		<FilterFormFronCategories
			form={form}
			options={kinds}
			fieldName={'kind_codes'}
			fetchData={fetchData}
			defaultValues={defaultValues}
			disabled={disabled}
		/>
	);
};

export default FilterFormTransferKind;
