import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ReservationCode from '../ReservationCode';
import {
	SuccessTitle,
	SuccessSubTitle,
	StyledReservationData,
	ContainerReservationCode,
	Span
} from './styles';

const ReservationData = ({ code }) => {
	const { t } = useTranslation();

	return (
		<StyledReservationData>
			<SuccessTitle
				content={t('reservation.success')}
				size={1}
				color={'secondary'}
			/>
			<SuccessSubTitle content={t('reservation.mail')} color={'secondary'} />
			{code && (
				<ContainerReservationCode>
					<ReservationCode code={code} />
					<Span>{t('reservation.saveCode')}</Span>
				</ContainerReservationCode>
			)}
		</StyledReservationData>
	);
};

export default ReservationData;

ReservationData.propTypes = {
	hotel_name: PropTypes.string.isRequired,
	code: PropTypes.string.isRequired
};
