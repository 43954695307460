import BASE_URL from '../BaseUrl';

import { getRefreshToken } from '../../sessions/loginSession';
import requestDecorator from '../../decorators/RequestDecorator';

const USERS = 'users';
const AUTHENTICATION = 'auth';

export function* login(params, cbResponse) {
	yield requestDecorator(
		function* (params) {
			return yield fetch(`${BASE_URL}/${AUTHENTICATION}/agency`, {
				method: 'POST',
				body: JSON.stringify(params),
				headers: {
					'Content-Type': 'application/json'
				}
			});
		},
		params,
		cbResponse
	);
}

export function* refreshToken(params, cbResponse) {
	yield requestDecorator(
		function* () {
			return yield fetch(`${BASE_URL}/${AUTHENTICATION}/refresh`, {
				method: 'POST',
				body: JSON.stringify({ refresh_token: getRefreshToken() }),
				headers: {
					'Content-Type': 'application/json'
				}
			});
		},
		params,
		cbResponse
	);
}

export function* register(params, cbResponse) {
	yield requestDecorator(
		function* (params) {
			return yield fetch(`${BASE_URL}/${USERS}/signup`, {
				method: 'POST',
				body: JSON.stringify(params),
				headers: {
					'Content-Type': 'application/json'
				}
			});
		},
		params,
		cbResponse
	);
}

export function* recovery(params, cbResponse) {
	yield requestDecorator(
		function* (params) {
			return yield fetch(`${BASE_URL}/${USERS}/access_recovery`, {
				method: 'POST',
				body: JSON.stringify(params),
				headers: {
					'Content-Type': 'application/json'
				}
			});
		},
		params,
		cbResponse
	);
}

export function* registerConfirm(params, cbResponse) {
	yield requestDecorator(
		function* (params) {
			return yield fetch(`${BASE_URL}/${USERS}/validation?token=${params}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json'
				}
			});
		},
		params,
		cbResponse
	);
}
