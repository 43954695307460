import { call, put, takeLatest } from 'redux-saga/effects';
import {
	GET_HOTELS,
	GET_HOTELS_SUCCESS,
	GET_HOTELS_FAILED
} from '../actions/searchFormActions';
import { getHotels } from '../../../api/endpoints/Hotels';

function* shotFetchHotels(action) {
	function* onSuccess(body) {}

	function* onOk(body) {
		yield put({ type: GET_HOTELS_SUCCESS, payload: body });
	}

	function* onError(error) {
		yield put({ type: GET_HOTELS_FAILED, payload: error });
	}

	try {
		yield call(getHotels, action.payload, {
			onSuccess: onSuccess,
			onError: onError,
			onOk: onOk
		});
	} catch (error) {
		yield onError(error.message);
	}
}

export default function* HotelsSagas() {
	yield takeLatest(GET_HOTELS, shotFetchHotels);
}
