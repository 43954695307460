import Regular from '../../../../assets/images/tranfers/transferRegular.jpeg';
import Premium from '../../../../assets/images/tranfers/transferPremium.jpeg';
import Privado from '../../../../assets/images/tranfers/transferPrivado.jpeg';

export const transferData = [
	{
		name: 'transfers.data.regular.name',
		image: Regular,
		description: 'transfers.data.regular.description',
		small_description:
			'Opera desde los Aeropuertos y Terminales de Ómnibus de Puerto Iguazú y Foz do Iguaçu hacia los hoteles dentro del perímetro de ambas ciudades.',
		label: ''
	},
	{
		name: 'transfers.data.private.name',
		image: Privado,
		description: 'transfers.data.private.description',
		small_description:
			'Opera desde los Aeropuertos y terminales de ómnibus de Puerto Iguazú, Foz do Iguaçu y Ciudad del Este, hacia los hoteles dentro del perímetro de las tres ciudades.',
		label: 'Más rápido y sin esperas'
	},
	{
		name: 'transfers.data.premium.name',
		image: Premium,
		description: 'transfers.data.premium.description',
		small_description:
			'Opera desde los Aeropuertos y terminales de ómnibus de Puerto Iguazú, Foz do Iguaçu y Ciudad del Este, hacia los hoteles dentro del perímetro de las tres ciudades.',
		label: 'Vehículos de lujo'
	}
];
