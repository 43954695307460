import React from 'react';

/*----- Components -----*/
import GenericCheckout from '../../../commons/checkout/GenericCheckout';

/*----- Actions -----*/
import { showTransfer } from '../../actions/showTransferActions';

/*----- Selectors -----*/
import {
	transferIsLoading,
	transferSuccess
} from '../../selectors/showTransferSelector';
import { connect } from 'react-redux';

const CheckoutTransfer = ({ showTransfer, success, isLoading, SuccessResponse }) => (
	<GenericCheckout
		showData={showTransfer}
		success={success}
		isLoading={isLoading}
		service="transfers"
		SuccessResponse={SuccessResponse}
	/>
);

const mapStateToProps = state => {
	return {
		isLoading: transferIsLoading(state),
		success: transferSuccess(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		showTransfer: payload => showTransfer(dispatch, payload)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutTransfer);
