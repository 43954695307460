import styled from 'styled-components';
import Device from '../../../commons/Device';
import Text from '../../text/Text';
import GenericModal from '../../modal/GenericModal';

export const StyledCard = styled.div`
	width: 100%;
`;

export default StyledCard;

export const TitleContainer = styled.div`
	padding: 0px;
	@media ${Device.xs} {
		.card__title-container {
			padding: 10px 0px;
		}
	}
`;

export const StyledTitle = styled(Text)`
	font-size: 20px;

	@media ${Device.xs} {
		margin-bottom: ${props => props.marginBottom};
		text-align: center;
	}
`;

export const StyledGenericModal = styled(GenericModal)`
	width: 90% !important;

	.ant-modal-header {
		display: none !important;
	}
	.ant-modal-close {
		padding: 0px;
		&:focus {
			outline: none;
		}
	}

	.ant-modal-close-x {
		background-color: transparent;
		top: -69px;
		position: relative;
		width: auto;
		height: auto;

		.ant-modal-close-icon {
			color: white;
		}
	}

	.ant-modal-body {
		padding: 0px;
	}

	@media ${Device.xs} {
		width: 100% !important;
		.ant-modal-close {
			padding: 8px;
		}
		.ant-modal-body {
			padding: 8px;
		}
	}
`;
