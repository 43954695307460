import React, { useEffect } from 'react';
import { connect } from 'react-redux';

/*----- Components -----*/
import SuccessResponse from './section/SuccessResponse';
import ReservationContent from '../../../commons/reservation/ReservationContent';

/*----- Actions -----*/
import { reserveTransfer } from '../../actions/reserveActions';

/*----- Selectors -----*/
import {
	reserveTransferIsLoading,
	reserveTransferError,
	reserveTransferSuccess
} from '../../selectors/reserveTransferSelector';

/*----- Utils -----*/
import { getCheckoutTransfer } from '../../../../services/TransferCheckoutService';
import { getId } from '../../../commons/checkout/CheckoutUtils';

const ReserveTransfer = ({
	history,
	error,
	success,
	isLoading,
	reserveTransfer
}) => {
	useEffect(() => {
		let id = getId(history);
		let checkoutData = getCheckoutTransfer(id);

		if (!checkoutData) {
			history.push('/transfers');
			return;
		}

		let params = {
			id: id,
			date: checkoutData.reservation_date,
			checkout: checkoutData
		};
		reserveTransfer(params);
	}, [history, history.location.pathname, reserveTransfer]);

	return (
		<ReservationContent
			error={error}
			success={success}
			isLoading={isLoading}
			SuccessResponse={SuccessResponse}
		/>
	);
};

const mapStateToProps = state => {
	return {
		isLoading: reserveTransferIsLoading(state),
		error: reserveTransferError(state),
		success: reserveTransferSuccess(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		reserveTransfer: payload => reserveTransfer(dispatch, payload)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReserveTransfer);
