export const nameRules = [
    {
        required: true,
        message: 'Debe completar el nombre',
        whitespace: true
    }
]

export const emailRules = [
    {
        type:    'email',
        message: 'El email es inválido',
    },
    {
        required: true,
        message:  'Debe completar el email',
    }
]

export const phoneRules = [
    {
        type:    'number',
        message: 'El teléfono es inválido',
    }
]

export const radioGroupRules = [
    {
        required: true,
        message: 'Debe seleccionar una opción'
    }
]

export const commentRules = [
    {
        required: true,
        message: 'Debe ingresar un comentario',
        whitespace: true
    }
]

export const dateRules = [
    { 
        type: 'object', 
        required: true, 
        message: 'Debe seleccionar una fecha válida' 
    }
]

export const requiredRule = ( text ) => {
    return [{
        required: true,
        message: text,
    }]
}