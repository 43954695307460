import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { cancelReservation } from '../../../actions/myReservationsActions';
import { cancelReservationIsLoading } from '../../../selectors/cancelReservationsSelector';
import { ReactComponent as TrashIcon } from '../../../../../assets/icons/trash.svg';
import {
	StyledConfirmTooltipMessage,
	StyledConfirmTooltipClose,
	StyledConfirmTooltipButton,
	StyledCancelReservationContainer,
	StyledConfirmTooltipTitle,
	StyledCancelReservationIconWrapper,
	StyledConfirmTooltipInner,
	StyledCancelReservationTooltipTxt,
	StyledConfirmTooltip
} from './styles';
import { isCancelable } from './utils';

const Tooltip = ({ message }) => (
	<StyledCancelReservationTooltipTxt>{message}</StyledCancelReservationTooltipTxt>
);

const Confirm = ({ id, close, clickHandler }) => {
	const { t } = useTranslation();
	const ref = useRef(null);

	useEffect(() => {
		const handleClickOutside = event => {
			if (ref.current && !ref.current.contains(event.target)) {
				close && close();
			}
		};
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, [close]);
	return (
		<StyledConfirmTooltip ref={ref}>
			<StyledConfirmTooltipInner>
				<StyledConfirmTooltipClose onClick={() => close()} />
				<StyledConfirmTooltipTitle>
					{t('reservation.cancelationMessages.title')}
				</StyledConfirmTooltipTitle>
				<StyledConfirmTooltipMessage>
					{t('reservation.cancelationMessages.subtitle')}
				</StyledConfirmTooltipMessage>
				<StyledConfirmTooltipButton
					content={t('reservation.cancelationMessages.button')}
					onClick={() => clickHandler({ id: id })}
				/>
			</StyledConfirmTooltipInner>
		</StyledConfirmTooltip>
	);
};

const CancelReservation = ({ status, id, cancelReservation }) => {
	const { t } = useTranslation();

	const [showTooltip, setShowTooltip] = useState(false);
	const [showConfirm, setShowConfirm] = useState(false);
	const cancelable = isCancelable(status);
	const requested = status === 'PEC';
	const handleCancelation = payload => cancelReservation(payload);

	return (
		<StyledCancelReservationContainer>
			{showTooltip && (cancelable || requested) && !showConfirm && (
				<Tooltip
					message={t(
						`reservation.cancelationMessages.${requested ? 'requested' : 'request'}`
					)}
				/>
			)}
			<StyledCancelReservationIconWrapper
				cancelable={cancelable}
				active={showConfirm}
			>
				<TrashIcon
					onMouseEnter={() => setShowTooltip(true)}
					onMouseLeave={() => setShowTooltip(false)}
					onClick={() => cancelable && setShowConfirm(!showConfirm)}
				/>
			</StyledCancelReservationIconWrapper>
			{showConfirm && (
				<Confirm
					id={id}
					clickHandler={payload => handleCancelation(payload)}
					close={() => setShowConfirm(false)}
				/>
			)}
		</StyledCancelReservationContainer>
	);
};

const mapStateToProps = state => {
	return {
		isLoading: cancelReservationIsLoading(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		cancelReservation: payload => cancelReservation(dispatch, payload)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CancelReservation);
