import { call, put, takeLatest } from 'redux-saga/effects';
import {
	RECOVERY_USER,
	RECOVERY_SUCCESS,
	RECOVERY_FAILED
} from '../actions/recoveryActions';
import { recovery } from '../../api/endpoints/Sessions';

function* recoveryUser(action) {
	function* onSuccess(body) {
		yield put({ type: RECOVERY_SUCCESS, authData: body.data });
	}

	function* onError(error) {
		yield put({ type: RECOVERY_FAILED, error: error });
	}

	try {
		yield call(recovery, action.payload, { onSuccess: onSuccess, onError: onError });
	} catch (error) {
		yield onError(error.message);
	}
}

function* RecoverySaga() {
	yield takeLatest(RECOVERY_USER, recoveryUser);
}

export default RecoverySaga;
