/* eslint-disable no-undef */
/*----- Core -----*/
import React from 'react';

/*----- Components -----*/
import ObservationForm from '../ObservationForm';
import Styles from '../styles';

import GetPassengerForm from './GetPassengerForm';

const PassengerForm = ({
	form,
	countPassengers,
	dataCheckout,
	passengersRequired
}) => {
	return (
		<Styles>
			<GetPassengerForm
				form={form}
				dataCheckout={dataCheckout}
				countPassengers={countPassengers}
				passengersRequired={passengersRequired}
			/>
			<ObservationForm
				form={form}
				value={dataCheckout ? dataCheckout.observation : ''}
			/>
		</Styles>
	);
};

export default PassengerForm;

PassengerForm.defaultProps = {
	countPassengers: [],
	dataCheckout: {},
	passengersRequired: {}
};
