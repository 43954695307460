import React from 'react';
import { StyledDiv, StyledSpan, FooterText, IconRow } from './styles';
import RecoveryForm from '../forms/recoveryForm';
import { useTranslation } from 'react-i18next';
import { Icon } from 'antd';
import { connect } from 'react-redux';
import { deleteRecovery } from '../../../authentication/actions/recoveryActions';

const RecoveryPassword = ({ deleteRecovery, closeModalMobile }) => {
	const { t } = useTranslation();

	return (
		<StyledDiv>
			<IconRow>
				<Icon
					type="close"
					onClick={e => {
						e.preventDefault();
						deleteRecovery();
						if (closeModalMobile) closeModalMobile();
					}}
					style={{ color: '#8A9EAD', fontSize: '16px' }}
				/>
			</IconRow>
			<StyledSpan>
				<strong>{t('authentication.recovery.title.1')}</strong>
				{t('authentication.recovery.title.2')}
			</StyledSpan>
			<RecoveryForm />
			<FooterText
				center
				size="12px"
				padding="30% 10% 0% 10%"
				content={t('authentication.recovery.feedback.footer')}
			/>
		</StyledDiv>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		deleteRecovery: payload => deleteRecovery(dispatch, payload)
	};
};

export default connect(null, mapDispatchToProps)(RecoveryPassword);
