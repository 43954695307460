import styled from 'styled-components';
import Color from '../../../../commons/Color';
import Device from '../../../../commons/Device';
import Text from '../../../../components/text/Text';
import Title from '../../../../components/text/Title';
import { Icon } from 'antd';

export const DescriptionContainer = styled.div`
	padding: 5px 0px;
	margin-bottom: 1rem;
`;

export const StyledText = styled(Text)`
	color: ${Color.text};
	font-size: 14px;
	width: 90%;

	@media ${Device.xs} {
		font-size: 12px !important;
		width: 100%;
	}
`;

export const StyledTitle = styled(Title)`
	color: ${Color.footer};
	margin: 32px 0px 12px 0px;
	font-weight: 700;

	@media ${Device.xs} {
		font-size: 17px;
	}
`;

export const StyledIconArrow = styled(Icon)`
	width: 7px;
	height: 4px;
	align-self: baseline;
	margin-top: 6px;
	color: ${Color.main} !important;
`;

export const StyledArrowText = styled(Text)`
	font-size: 17px;
	margin-bottom: 0px;
	margin-right: 12px;
`;

export const ArrowDiv = styled.div`
	display: flex;
	align-items: center;
`;
