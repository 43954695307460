import styled from 'styled-components';
export default styled.main`
	display: flex;
	flex-direction: column;
	/* padding-top: 64px; */
	.contact-form .handler-button {
		height: 50px;
		border-radius: 4px !important;
	}
	.contact-items-container {
		margin-top: 32px;
		padding: 0 16px;
		.phone_icon,
		.email_icon {
			width: 64px;
		}
	}
	.contact-items-icon {
		width: 64px;
		margin-bottom: 16px;
	}
	.contact-item {
		display: flex;
		align-items: center;
		margin: 5px 0px;
		img {
			width: 7%;
			height: 7%;
		}
		p {
			padding: 0px;
			margin: 0px 5px;
			font-family: 'Montserrat';
			color: grey;
			font-size: 14px;
		}
	}
`;
