/*----- Core -----*/
import React from 'react';
import { connect } from 'react-redux';

/*----- Components -----*/
import {
	StyledRow,
	StyledDetailCol,
	StyledReservationCol
} from '../../../../commons/detail/styles';
import DetailHeader from '../../../../commons/detail/DetailHeader';
import ReservationPanelPackage from '../../../../../components/panel/ReservationPanelPackage';
import { StyledReservationPanel } from '../../../../../components/panel/styles';
import DetailTitle from '../../../../commons/detail/detailTitle';
import StepsContainer from '../../../../../components/steps/StepsContainer';
import DetailDescription from '../../../../commons/detail/DetailDescription';

/*----- Selectors -----*/
import { showPackageData } from '../../../selectors/showPackageSelector';

/*----- Utils -----*/
import { getSearchQuery } from '../../../../../services/SearchService';
import {
	isMobile,
	packagesWithHotelSteps,
	packagesSteps
} from '../../../../../utilities/utils';

const SuccessResponse = ({ showPackage }) => {
	const withHotel = getSearchQuery().with_hotel;
	const showStepper = () => isMobile() || !withHotel;
	return (
		<>
			{showStepper() && (
				<StepsContainer
					active={1}
					rate_id={1}
					dataSteps={withHotel ? packagesWithHotelSteps() : packagesSteps()}
				/>
			)}
			<DetailHeader images={[showPackage.rate.imagen]} />

			<StyledRow type="flex">
				<StyledDetailCol
					xs={{ span: 24, order: withHotel ? 2 : 1 }}
					md={{ span: 16, order: 1 }}
					lg={18}
				>
					<DetailTitle title={showPackage.name} />
					{showPackage.info.observations[0].text ? (
						<DetailDescription
							title={'packages.detailTitle'}
							description={showPackage.info.observations[0].text}
							fromPackage
						/>
					) : null}
				</StyledDetailCol>
				<StyledReservationCol
					xs={{ span: 24, order: withHotel ? 1 : 2 }}
					md={{ span: 8, order: 2 }}
					lg={6}
				>
					<StyledReservationPanel marginTop={withHotel ? '-64px' : '0px'}>
						<ReservationPanelPackage withHotel={withHotel} />
					</StyledReservationPanel>
				</StyledReservationCol>
			</StyledRow>
		</>
	);
};

const mapStateToProps = state => {
	return {
		showPackage: showPackageData(state)
	};
};

export default connect(mapStateToProps, null)(SuccessResponse);
