export default () => {
	const maxChildrenAge = 17;
	const ages = [];

	for (let age = 0; age <= maxChildrenAge; age++) {
		ages.push({ label: age, value: age });
	}

	return ages;
};
