import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Col } from 'antd';
import {
	StyledInput,
	StyledDatePicker
} from '../../../../../components/form/styles';
import { SearchBarContainer, StyledSearchSelect } from './styles';
import { getMyReservations } from '../../../actions/myReservationsActions';
import { parseMyReservationsParams } from '../../../../../utilities/queryString';
import { replaceSearch } from '../../../../../components/pagination/SearchUtils';

import { statusOptions, getStatusCode } from './utils';
import SearchSelect from '../../../../../components/form/SearchSelect';

const SearchBar = ({ getMyReservations, history }) => {
	const [searchValues, setSearchValues] = useState({
		id: null,
		from: null,
		to: null,
		pax_name: null,
		emission_date: null,
		status_code: null
	});

	const handleInputChange = (field, value) =>
		setSearchValues(prev => ({ ...prev, [field]: value }));

	useEffect(() => {
		let filters = { ...searchValues };
		let parsedFilters = {};
		Object.keys(filters).forEach(key => {
			let value = filters[key];
			if (value !== null) {
				if (typeof value === 'string' && value.length === 0) return;
				if (key === 'status_code') {
					parsedFilters[key] = getStatusCode(value);
				} else {
					parsedFilters[key] = value;
				}
			}
		});
		if (!parsedFilters) return;
		replaceSearch(history, 'my_reservations', parsedFilters);
	}, [searchValues, history]);

	useEffect(() => {
		let params = parseMyReservationsParams(history.location.search);
		getMyReservations(params);
		console.log(params);
		console.log(getMyReservations);
	}, [getMyReservations, history.location.search]);

	return (
		<SearchBarContainer>
			<Col xs={3}>
				<StyledInput
					name="file"
					type="text"
					placeholder=""
					onChange={e => handleInputChange('id', e.target.value.toLowerCase())}
					value={searchValues.id}
					label="Número de file"
					labelColor="#030F33"
					weight={400}
					fontSize="16px"
				/>
			</Col>
			<Col xs={3}>
				<StyledDatePicker
					label="Fecha de Creación"
					labelColor="#030F33"
					weight={400}
					fontSize="16px"
					value={
						searchValues.emission_date
							? moment(searchValues.emission_date)
							: undefined
					}
					onChange={(date, dateString) =>
						handleInputChange(
							'emission_date',
							date ? moment(date).format('YYYY-MM-DD') : undefined
						)
					}
				/>
			</Col>
			<Col xs={3}>
				<StyledDatePicker
					label="Reserva Desde"
					labelColor="#030F33"
					weight={400}
					fontSize="16px"
					value={
						searchValues.from
							? moment(searchValues.from)
							: undefined
					}
					onChange={(date, dateString) =>
						handleInputChange(
							'from',
							date ? moment(date).format('YYYY-MM-DD') : undefined
						)
					}
				/>
			</Col>
			<Col xs={3}>
				<StyledDatePicker
					label="Reserva Hasta"
					labelColor="#030F33"
					weight={400}
					fontSize="16px"
					value={
						searchValues.to
							? moment(searchValues.to)
							: undefined
					}
					onChange={(date, dateString) =>
						handleInputChange(
							'to',
							date ? moment(date).format('YYYY-MM-DD') : undefined
						)
					}
				/>
			</Col>
			<Col xs={3}>
				<StyledInput
					name="pax_name"
					type="text"
					placeholder=""
					label="Pasajero"
					labelColor="#030F33"
					weight={400}
					fontSize="16px"
					value={searchValues.pax_name}
					onChange={e => handleInputChange('pax_name', e.target.value.toLowerCase())}
				/>
			</Col>
			<Col xs={3}>
				<StyledSearchSelect>
					<SearchSelect
						name="status_code"
						label="Estado"
						options={statusOptions}
						value={searchValues.status_code}
						onChange={value =>
							handleInputChange('status_code', value ? value.toLowerCase() : null)
						}
						className="styled-status-select"
						allowClear
					/>
				</StyledSearchSelect>
			</Col>
		</SearchBarContainer>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		getMyReservations: payload => getMyReservations(dispatch, payload)
	};
};

export default connect(null, mapDispatchToProps)(SearchBar);
