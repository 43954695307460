/*----- Core -----*/
import React from 'react'
import { connect } from "react-redux"
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

/*----- Component -----*/
import { Form, Button, Col } from 'antd';
import Color from '../../../commons/Color'

/*----- Form items-----*/
import { nameRules, emailRules, phoneRules, commentRules } from '../../../components/formItems/InputRules'
import InputItem from '../../../components/formItems/InputItem'
import TextAreaItem from '../../../components/formItems/TextAreaItem'
import InputNumberItem from '../../../components/formItems/InputNumberItem'

const hasErrors = (fieldsError) => {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

const handleFormSubmit = (event, form, sendEmail) => {
    form.validateFields()
    event.preventDefault();
    if (hasErrors(form.getFieldsError())) {
        console.log('Error de validación')
    }
    else {
        sendEmail(form.getFieldsValue())
        form.resetFields()
    }
};

const { Item } = Form

const StyledFormContainer = styled.div`
    .ant-col .ant-form-item-control-wrapper{
        padding-right: 10px;
    }
    
    .ant-form label {
        font-size:14px !important;
    }

    .alert{
        color: #721c24;
        background-color: #f8d7da;
        width: 100%;
        border-radius: 5px;
        padding: 10px;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
        font-weight:600;
    }
`

const ContactForm = ({ form, sendEmail, requestError }) => {
    const { t } = useTranslation()
    const { getFieldDecorator } = form

    return (
        <StyledFormContainer>

            <Form
                onSubmit={(event) => { handleFormSubmit(event, form, sendEmail) }}
                className="login-form">
                <InputItem
                    label={t('contact.form.name')}
                    fieldName={'name'}
                    placeholder={'Franco Gomez'}
                    getFieldDecorator={getFieldDecorator}
                    rules={nameRules}
                />
                <InputItem
                    label={t('contact.form.email')}
                    fieldName={'email'}
                    placeholder={'fgomez@gmail.com'}
                    getFieldDecorator={getFieldDecorator}
                    rules={emailRules}
                />
                <InputNumberItem
                    label={t('contact.form.telephone')}
                    fieldName={'phone'}
                    placeholder={'+55 21 2134-1425'}
                    getFieldDecorator={getFieldDecorator}
                    rules={phoneRules}
                />
                {/* <InputItem 
                        label={'Asunto'}
                        fieldName={'subject'}
                        placeholder={'Asunto'}
                        getFieldDecorator={getFieldDecorator}
                        rules={requireRules('asunto')}
                    /> */}
                <TextAreaItem
                    label={t('contact.form.comment')}
                    fieldName={'message'}
                    getFieldDecorator={getFieldDecorator}
                    rules={commentRules}
                    rows={3}
                />
                <Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        style={{ width: '100%', backgroundColor: Color.main, fontWeight: 600, border: 'none' }}
                    // disabled  = { isLoading }
                    >
                        {'Enviar'}
                    </Button>
                </Item>
                {requestError ?
                    <Col span={24}>
                        < div className="alert">
                            {requestError}
                        </div>
                    </Col>
                    : ''
                }
            </Form>
        </StyledFormContainer>
    )
}

function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create({ name: 'contact_form' })(ContactForm))