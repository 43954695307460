import React from 'react';

/*----- Components -----*/
import { StyledCol, StyledRow } from './styles';
import StepsContainer from '../../../components/steps/StepsContainer';
import ReservationSuccess from './ReservationSuccess';

const Reservation = ({ code, dataSteps, imageReservation, active = 4 }) => (
	<section>
		<StepsContainer active={active} rate_id={1} dataSteps={dataSteps} />
		<StyledRow type="flex" justify="center">
			<StyledCol xs={24} md={20}>
				<ReservationSuccess code={code} imageReservation={imageReservation} />
			</StyledCol>
		</StyledRow>
	</section>
);

export default Reservation;
