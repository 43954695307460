import React from 'react';
import styled from 'styled-components';
import Color from '../../commons/Color';

import { Form, Input } from 'antd';

const { Item } = Form;

const StyledItem = styled(Item)`
	input {
		border-radius: 10px;
		font-size: 12px;
		padding: 6px 11px;
	}

	input:focus {
		border-color: ${Color.main} !important;
	}

	input:hover {
		border-color: ${Color.main};
	}
`;

const InputItem = ({
	label,
	fieldName,
	placeholder,
	getFieldDecorator,
	rules,
	initialValue = ''
}) => {
	return (
		<StyledItem label={label}>
			{getFieldDecorator(fieldName, {
				rules: rules,
				validateTrigger: 'onSubmit',
				initialValue: initialValue
			})(<Input placeholder={placeholder} />)}
		</StyledItem>
	);
};

export default InputItem;
