import React from 'react';

/*----- Components -----*/
import {
	Main,
	StyledSearchFormContainer,
	FilterFormContainer,
	FilterDataContainer
} from './styles';

import MoreInformationCard from '../card/MoreInformationCard';
import SearchFormMobile from '../form/SearchFormMobile';

/*----- Utils -----*/
import { isMobile } from '../../utilities/utils';

const GenericSearch = ({
	isLoading,
	data,
	SearchMobile,
	ResultContainer,
	SearchForm,
	service
}) => {
	return (
		<Main showWaterMark={data.length} isLoading={isLoading}>
			<FilterFormContainer>
				<FilterDataContainer>
					<SearchFormMobile service={service} SearchForm={SearchForm} />
					{data.length > 0 && <MoreInformationCard margin="12px 0px 0px" mobile />}
				</FilterDataContainer>
				{!isMobile() && (
					<StyledSearchFormContainer>
						<SearchForm />
					</StyledSearchFormContainer>
				)}
			</FilterFormContainer>
			{ResultContainer}
		</Main>
	);
};

export default GenericSearch;
