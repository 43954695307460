import React from 'react';
import { connect } from 'react-redux';
import {
	isLoadingRegisterConfirm,
	successRegisterConfirm,
	invalidToken
} from '../../../authentication/selectors/registerConfirmSelector';

const Content = ({
	Loading,
	Success,
	Failed,
	Error,
	isLoading,
	successResponse,
	invalidToken
}) => {
	if (isLoading) {
		return <Loading />;
	} else if (successResponse) {
		return <Success />;
	} else if (invalidToken) {
		return <Error />;
	} else {
		return <Failed />;
	}
};

function mapStateToProps(state) {
	return {
		isLoading: isLoadingRegisterConfirm(state),
		successResponse: successRegisterConfirm(state),
		invalidToken: invalidToken(state)
	};
}

export default connect(mapStateToProps, null)(Content);
