/*----- Core -----*/
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

/*----- Ant design -----*/
import { Row, Col } from 'antd';

/*----- Components -----*/
import HertoText from '../../components/hero/HeroText';

/*----- Styles -----*/
import Styles from './ContactStyles';

/*----- Sections -----*/
import ContactForm from './section/ContactForm';

import { toggleFooter } from '../../modules/app/AppActions';
import { ContactList } from '../../components/commons/nav/Footer/Contact';
import { StyledContactWrapper } from './styles';

/*----- Export -----*/
const Contact = props => {
	const { t } = useTranslation();

	useEffect(() => {
		props.toggleFooter();
		return function cleanup() {
			props.toggleFooter();
		};
	});
	return (
		<Styles>
			<Col
				xs={24}
				sm={{ span: 24 }}
				md={{ span: 20, offset: 2 }}
				lg={{ span: 22, offset: 1 }}
				xl={{ span: 22, offset: 1 }}
			>
				<Col xs={24} sm={24} md={24} lg={12} xl={12}>
					<Row type="flex" justify="start" align="top">
						<HertoText title={t('contact.title')} subtitle={t('contact.subtitle')} />
					</Row>
					<Row type="flex" justify="start" align="top">
						<Col xs={0} lg={10}>
							<StyledContactWrapper>
								<ContactList secondary />
							</StyledContactWrapper>
						</Col>
					</Row>
				</Col>
				<Col xs={24} sm={24} md={24} lg={12} xl={12}>
					<Row type="flex" justify="center" align="middle">
						<ContactForm />
					</Row>
				</Col>
				<Col xs={24} sm={24} md={24} lg={0} xl={0}>
					<StyledContactWrapper>
						<ContactList secondary />
					</StyledContactWrapper>
				</Col>
			</Col>
		</Styles>
	);
};
function mapStateToProps(state) {
	return {};
}

function mapDispatchToProps(dispatch) {
	return {
		toggleFooter: () => toggleFooter(dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
