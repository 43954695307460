import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import StyledSteps from './styles';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Steps, Icon, Row, Col } from 'antd';
const { Step } = Steps;

const StepsContainer = ({ active = 0, history, rate_id, dataSteps }) => {
	const { t } = useTranslation();
	const [current, setCurrent] = useState(active);

	const customDot = (dot, { status, index }) => {
		return <Icon component={dataSteps[index].icon} />;
	};

	const handleClick = new_current => {
		history.push(dataSteps[new_current].destination);
		setCurrent(new_current);
		new_current === 0 && window.location.reload();
	};

	const getSteps = () => {
		return dataSteps.map((step, idx) => {
			return (
				<Step
					key={idx}
					title={t(step.title)}
					disabled={
						idx > active || (active === dataSteps.length - 1 && idx < active)
					}
				/>
			);
		});
	};

	return (
		<StyledSteps steps={dataSteps.length}>
			<Row type="flex" justify="center">
				<Col xs={24} lg={20}>
					<Steps
						progressDot={customDot}
						current={current}
						onChange={new_current => {
							handleClick(new_current);
						}}
					>
						{getSteps()}
					</Steps>
				</Col>
			</Row>
		</StyledSteps>
	);
};

export default withRouter(StepsContainer);

StepsContainer.propTypes = {
	active: PropTypes.number.isRequired,
	rate_id: PropTypes.number.isRequired
};

StepsContainer.defaultProps = {
	active: 0
};
