/*----- Core -----*/
import React from 'react';
import PropTypes from 'prop-types';

/*----- Components -----*/
import ReservationPanelItem from './ReservationPanelItem';
import ReservationDates from './ReservationDates';

const ReservationPanelDates = ({ dateLabel, dateFrom, dateTo }) => (
	<ReservationPanelItem label={dateLabel}>
		<ReservationDates dateFrom={dateFrom} dateTo={dateTo} />
	</ReservationPanelItem>
);

export default ReservationPanelDates;

/*----- PropTypes -----*/
ReservationPanelDates.propTypes = {
	dateFrom: PropTypes.string.isRequired,
	dateTo: PropTypes.string
};
