import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Form } from 'antd';
import { isMobile } from '../../../utilities/utils';
import { Button, FilterContainer } from './styles';
import { Row, Col } from 'antd';
import { StyledSlider } from '../styles';
import { getUserCurrency } from '../../../utilities/utils';

const { Item } = Form;

const FilterForm = ({
	form,
	children,
	fetchData,
	searchName,
	defaultValues,
	disabled
}) => {
	const { t } = useTranslation();
	const userCurrency = getUserCurrency();
	defaultValues.min_price = defaultValues.min_price || userCurrency.min;
	defaultValues.max_price = defaultValues.max_price || userCurrency.max;

	return (
		<FilterContainer>
			<Form>
				<Row type="flex">
					<Col span={24} xs={{ order: 3 }} sm={{ order: 1 }}>
						{searchName}
					</Col>

					<Col span={24} xs={{ order: 1 }} sm={{ ordeR: 2 }}>
						{children}
					</Col>

					<Col span={24} xs={{ order: 2 }} sm={{ order: 3 }}>
						<Item>
							{form.getFieldDecorator('prices', {
								initialValue: [defaultValues.min_price, defaultValues.max_price]
							})(
								<StyledSlider
									name={t('general.price')}
									min={userCurrency.min}
									max={userCurrency.max}
									content={userCurrency.name}
									disabled={disabled}
									onAfterChange={() => {
										if (!isMobile()) {
											fetchData();
										}
									}}
								/>
							)}
						</Item>
					</Col>
					{isMobile() && (
						<Col order={4} span={24}>
							<Button
								content={t('general.apply')}
								onClick={() => {
									fetchData();
								}}
							/>
						</Col>
					)}
				</Row>
			</Form>
		</FilterContainer>
	);
};

export default withRouter(FilterForm);
