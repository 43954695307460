import styled from 'styled-components';
import Text from '../../../../components/text/Text';
import Color from '../../../../commons/Color';
import Device from '../../../../commons/Device';

export const StyledMapText = styled(Text)`
	font-size: 16px;
	color: ${Color.main};
	margin: 0px 0px 0px 5px;
`;

export const StyledMapContainer = styled.div`
	padding-left: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover {
		cursor: pointer;
	}

	@media ${Device.xs} {
		padding-left: 0px;
	}
`;
