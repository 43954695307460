/*----- Core -----*/
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

/*----- Components -----*/
import Title from '../text/Title';
import { Row, Col } from 'antd';

import DefaultButton from '../button/DefaultButton';

/*----- Commons -----*/
import CardCommonStyles from '../../commons/styles/Card';
import Device from '../../commons/Device';
import getPackagesServices from '../commons/getPackagesServices';

const SM_HEIGHT = 'min-height: 340px; height: 100%';
const MD_HEIGHT = 'min-height: 210px; height: 100%';

/*----- Styles-----*/

const StyledPackageCard = styled.article`
	${CardCommonStyles}
	position: relative;

	.content-button {
		position: absolute;
		bottom: 0;
	}

	.default-button {
		margin: 0 auto;
		min-width: 160px;
	}

	.package-card__service-details {
		padding: 5px 0;
		display: flex;
		flex-wrap: wrap;
		margin: 0px 15px;
		margin-bottom: 54px;
		justify-content: center;
	}

	@media ${Device.sm} {
		${SM_HEIGHT}
	}

	@media ${Device.md} {
		${MD_HEIGHT}
	}

	@media ${Device.xs} {
		.content-button {
			position: static;
		}

		.package-card__service-details {
			margin-bottom: 5px;
		}
	}
`;

/*----- Export -----*/
const PackageCard = ({ title, imageUrl, onClick, services }) => (
	<StyledPackageCard>
		<Row type="flex">
			<Col
				span={24}
				className="product-card__image-container"
				style={{ backgroundImage: `url(${imageUrl})` }}
			></Col>

			<Col span={24}>
				<div className="product-card__title-container">
					<Title content={title} size={3} />
				</div>
				<div className="package-card__service-details">
					{getPackagesServices(services)}
				</div>
			</Col>

			<Col span={24} className="content-button">
				<DefaultButton content="consultar" onClick={onClick} />
			</Col>
		</Row>
	</StyledPackageCard>
);
export default PackageCard;

/*----- PropTypes -----*/
PackageCard.propTypes = {
	imageUrl: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired
};
