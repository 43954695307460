import styled from 'styled-components';
import OpenSansBold from '../../../commons/mixins/fonts/OpenSansBold';
import Color from '../../../commons/Color';
import Device from '../../../commons/Device';

export const Img = styled.img`
	width: 100%;
	margin-bottom: 15px;
`;

export const Text = styled.p`
	${OpenSansBold(24, 700, Color.footer)};
	text-align: center;
	line-height: 28px;

	@media ${Device.xs} {
		font-size: 22px;
		line-height: 26px;
	}
`;
