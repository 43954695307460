import React from 'react';

/*----- Components -----*/
import Filter from '../filter';
import ResultList from './ResultList';

import { connect } from 'react-redux';
import {
	hotelsPagination,
	hotelsQuery,
	hotelsData,
	hotelsIsLoading,
	hotelsSuccess
} from '../../selectors/hotelsSelector';

/*----- Actions -----*/
import { getHotels } from '../../actions/searchFormActions';
import SearchResultContainer from '../../../../components/search/SearchResultContainer';

const HotelResultContainer = ({
	hotelsData,
	pagination,
	query,
	getHotels,
	isLoading,
	success
}) => {
	return (
		<SearchResultContainer
			resultData={hotelsData}
			pagination={pagination}
			query={query}
			getData={getHotels}
			Filter={Filter}
			service="hotels"
			ResultList={ResultList}
			isLoading={isLoading}
			success={success}
			moreRooms={true}
		/>
	);
};

const mapStateToProps = state => {
	return {
		hotelsData: hotelsData(state),
		pagination: hotelsPagination(state),
		query: hotelsQuery(state),
		isLoading: hotelsIsLoading(state),
		success: hotelsSuccess(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getHotels: payload => getHotels(dispatch, payload)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(HotelResultContainer);
