import React from 'react';
import {
	StyledImg,
	StyledTitle,
	StyledSubtitle,
	SyledColImage,
	SyledColMessage
} from './styles';
import { Row } from 'antd';

const ResponseWithImage = ({ image, title, subtitle }) => {
	return (
		<Row type={'flex'} justify="center" align="middle">
			<SyledColImage xs={{ order: 1, span: 24 }} sm={{ order: 2 }} >
				<StyledImg src={image} />
			</SyledColImage>
			<SyledColMessage xs={{ order: 2, span: 24 }} sm={{ order: 1 }}>
				{title && <StyledTitle content={title} color={'secondary'} size={3} />}
				{subtitle && (
					<StyledSubtitle content={subtitle} color={'secondary'} size={5} />
				)}
			</SyledColMessage>
		</Row>
	);
};

export default ResponseWithImage;
