import React from 'react';

/*----- Components -----*/
import { TitleContainer, StyledTitle } from './styles';

const DetailTitle = ({ title, subtitle }) => (
	<TitleContainer>
		<StyledTitle content={title} size={2} color="secondary" />
		{subtitle}
	</TitleContainer>
);

export default DetailTitle;
