import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
	SHOW_HOTEL,
	SHOW_HOTEL_SUCCESS,
	SHOW_HOTEL_FAILED
} from '../actions/showHotelActions';
import { showHotels } from '../../../api/endpoints/Hotels';

import { removeCheckoutHotel } from '../../../services/HotelCheckoutService';

function* shotFetchHotels(action) {
	function* onSuccess(body) {}

	function* onOk(body) {
		body.data.rates = body.data.rates.filter(rates => rates.available);
		yield put({ type: SHOW_HOTEL_SUCCESS, payload: body.data });
	}

	function* onError(error) {
		removeCheckoutHotel(action.payload);
		yield put({ type: SHOW_HOTEL_FAILED, payload: error });
		yield put(push('/hotels/search'));
	}

	try {
		yield call(showHotels, action.payload, {
			onSuccess: onSuccess,
			onError: onError,
			onOk: onOk
		});
	} catch (error) {
		yield onError(error.message);
	}
}

export default function* HotelsSagas() {
	yield takeLatest(SHOW_HOTEL, shotFetchHotels);
}
