import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'antd';
import { cartData } from '../../selectors/cartSelector';
import { removeFromCart } from '../../actions/cartActions';
import HeroText from '../../../../components/hero/HeroText';
import CartItems from './items';
import { ReactComponent as EmptyIcon } from '../../../../assets/icons/warning.svg';
import {
	countPassengers,
	passengerSelector
} from '../../../commons/checkout/CheckoutUtils';

import { StyledCartCheckout } from '../styles';
import { StyledSection, StyledEmpty, StyledEmptyLogo } from './styles';
import StepsContainer from '../../../../components/steps/StepsContainer';
import { cartSteps } from '../../../../utilities/utils';

const CartCheckout = ({ cartData, removeFromCart, form }) => {
	const { t } = useTranslation();
	const passengersRequired = passengerSelector([...cartData].pop());
	const passengers = countPassengers(passengersRequired);
	return (
		<StyledCartCheckout>
			<StepsContainer active={0} dataSteps={cartSteps()} />
			<Col
				xs={24}
				sm={{ span: 24 }}
				md={{ span: 20, offset: 2 }}
				lg={{ span: 22, offset: 1 }}
				xl={{ span: 22, offset: 1 }}
			>
				<Col>
					<Row type="flex" justify="start" align="top">
						<HeroText
							title={t('cart.checkout.subtitle')}
							subtitle={t('cart.checkout.title')}
							reverse
						/>
					</Row>
				</Col>
				{cartData.length ? (
					<CartItems
						cartData={cartData}
						removeFromCart={removeFromCart}
						passengers={passengers}
						passengersRequired={passengersRequired}
						form={form}
					/>
				) : (
					<StyledSection>
						<StyledEmptyLogo>
							<EmptyIcon />
						</StyledEmptyLogo>
						<StyledEmpty>{t('cart.dropdown.empty')}</StyledEmpty>
					</StyledSection>
				)}
			</Col>
		</StyledCartCheckout>
	);
};

const mapStateToProps = state => {
	return {
		cartData: cartData(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		removeFromCart: payload => removeFromCart(dispatch, payload)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Form.create({ name: 'cart_form' })(CartCheckout));
