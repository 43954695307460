import React from 'react';
import { StyledCard, TitleContainer, StyledTitle } from './styles';
import SaleCard from '../SaleCard';

const ActivitySaleCard = ({ title, ...others }) => (
	<SaleCard
		{...others}
		cardInformation={
			<StyledCard>
				<TitleContainer>
					<StyledTitle bold content={title} />
				</TitleContainer>
			</StyledCard>
		}
	/>
);

export default ActivitySaleCard;
