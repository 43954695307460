import styled from 'styled-components';
import Color from '../../../../commons/Color';
import Device from '../../../../commons/Device';

export const Container = styled.div`
	border: 2px solid ${Color.main};
	border-radius: 5px;
	padding: 5px 30px;
	font-size: 22px;
	font-weight: 700;
	color: ${Color.main};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	@media ${Device.xs} {
		padding: 5px 20px;
	}
`;

export const Span = styled.span`
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 600;
	@media ${Device.xs} {
		font-size: 10px;
	}
`;

export const NumberCode = styled.p`
	margin: 0;
	line-height: 45px;
	font-size: 50px;

	@media ${Device.xs} {
		font-size: 35px;
	}
`;
