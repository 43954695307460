export const getColumnSearchProps = ({
	dataIndex,
	searchText,
	searchedColumn,
	customRender
}) => {
	if (customRender) {
		return;
	} else {
		return {
			render: text => text
		};
	}
};

const statusCodes = ['CX', 'OK', 'PC', 'PE', 'RQ', 'SS', 'UC', 'WL', 'PEC'];

const statusLabels = [
	'Cancelado',
	'Confirmado',
	'En proceso de cancelación',
	'Pendiente, no solicitar servicios',
	'Requerido',
	'Solicitado',
	'Cerrado',
	'Lista de Espera',
	'Pendiente de Cancelacion'
];

export const statusOptions = statusLabels.map((label, index) => ({
	value: label,
	label: label
}));

export const getStatusCode = status =>
	statusCodes[
		statusLabels.findIndex(i => i.toLowerCase() === status.toLowerCase())
	].toLowerCase();

export const isCancelable = statusCode =>
	statusCode !== 'CX' && statusCode !== 'PC' && statusCode !== 'PEC';
