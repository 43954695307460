import BASE_URL from '../BaseUrl';

import { getAuthToken } from '../../sessions/loginSession';
import requestDecorator from '../../decorators/RequestDecorator';
import { stringify } from '../../utilities/queryString';
import i18n from 'i18next';

const HOTELS = 'hotels';

export function* getHotels(params, cbResponse) {
	yield requestDecorator(
		function* (params) {
			const result = yield fetch(
				`${BASE_URL}/${HOTELS}/search?${stringify(params)}`,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${getAuthToken()}`,
						'Accept-Language': `${i18n.language}`
					}
				}
			);
			return result;
		},
		params,
		cbResponse
	);
}

export function* showHotels(params, cbResponse) {
	yield requestDecorator(
		function* (params) {
			const result = yield fetch(`${BASE_URL}/${HOTELS}/${params}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getAuthToken()}`,
					'Accept-Language': `${i18n.language}`
				}
			});
			return result;
		},
		params,
		cbResponse
	);
}

export function* reserveHotels(params, cbResponse) {
	yield requestDecorator(
		function* (params) {
			return yield fetch(`${BASE_URL}/${HOTELS}`, {
				method: 'POST',
				body: JSON.stringify(params),
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getAuthToken()}`,
					'Accept-Language': `${i18n.language}`
				}
			});
		},
		params,
		cbResponse
	);
}
