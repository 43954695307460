import BASE_URL from '../BaseUrl';

import { getAuthToken } from '../../sessions/loginSession';
import requestDecorator from '../../decorators/RequestDecorator';
import { stringify } from '../../utilities/queryString';

const ACTIVITIES = 'activities';

export function* getActivities(params, cbResponse) {
	yield requestDecorator(
		function* (params) {
			return yield fetch(`${BASE_URL}/${ACTIVITIES}/search?${stringify(params)}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getAuthToken()}`,
					'Accept-Language': 'en'
				}
			});
		},
		params,
		cbResponse
	);
}

export function* showActivities(params, cbResponse) {
	yield requestDecorator(
		function* (params) {
			return yield fetch(`${BASE_URL}/${ACTIVITIES}/${params}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getAuthToken()}`,
					'Accept-Language': 'en'
				}
			});
		},
		params,
		cbResponse
	);
}

export function* reserveActivity(params, cbResponse) {
	yield requestDecorator(
		function* (params) {
			return yield fetch(`${BASE_URL}/${ACTIVITIES}`, {
				method: 'POST',
				body: JSON.stringify(params),
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getAuthToken()}`,
					'Accept-Language': 'en'
				}
			});
		},
		params,
		cbResponse
	);
}
