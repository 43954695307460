import React from 'react';
import styled from 'styled-components';
import { Divider } from 'antd';
import Device from '../../commons/Device';

const StyledDvider = styled(Divider)`
	margin: 0px !important;
	@media ${Device.xs} {
		margin: 8px 0px !important;
	}
`;

const DefaultDivider = () => <StyledDvider />;

export default DefaultDivider;
