import React from 'react';
import FailedRegisterConfirmImage from '../../../assets/images/FailedRegisterConfirmImage.svg';
import FailedRegisterConfirmImageMobile from '../../../assets/images/FailedRegisterConfirmImageMobile.svg';
import { useTranslation } from 'react-i18next';
import GenericResponse from './GenericResponse';
import { isMobile } from '../../../utilities/utils';

const FailedResponse = () => {
	const { t } = useTranslation();
	return (
		<GenericResponse
			image={
				isMobile() ? FailedRegisterConfirmImageMobile : FailedRegisterConfirmImage
			}
			title={t('authentication.register.feedback.confirm.failed.title')}
			firstContent={t('authentication.register.feedback.confirm.failed.content.1')}
			secondContent={t('authentication.register.feedback.confirm.failed.content.2')}
			thirdContent={t('authentication.register.feedback.confirm.failed.content.3')}
		/>
	);
};

export default FailedResponse;
