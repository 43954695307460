import { call, takeLeading, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
	REFRESH_TOKEN,
	REFRESH_FAILED,
	REFRESH_SUCCESS
} from '../actions/refreshActions';
import { refreshToken } from '../../api/endpoints/Sessions';

export function* shootRefreshToken(action) {
	function* onSuccess(body) {
		yield put({ type: REFRESH_SUCCESS, payload: body.data });
	}

	function* onError(error) {
		yield put(push('/'));
		yield put({ type: REFRESH_FAILED, payload: error });
	}

	try {
		yield call(refreshToken, action.payload, {
			onSuccess: onSuccess,
			onError: onError
		});
	} catch (error) {
		yield onError(error.message);
	}
}

export default function* createRouteWatcher() {
	yield takeLeading(REFRESH_TOKEN, shootRefreshToken);
}
