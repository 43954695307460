/*----- Core -----*/
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/*----- Components -----*/
import { Form } from 'antd';

import { getActivities, resetActivityFilter } from '../../actions/searchFormActions';
import SearchForms from '../../../../components/form/SearchForms';
import SearchFormDestinations from '../../../../components/form/SearchForms/SearchFormDestinations';

const SearchForm = ({
	form,
	header,
	className,
	getActivities,
	onSubmit,
	resetActivityFilter
}) => {
	const fetchData = fields => {
		resetActivityFilter();
		getActivities({
			city_code: fields.city_code,
			date_in: fields.date_in,
			date_out: fields.date_out,
			adults_number: fields.rooms[0].adults_number,
			children_ages: fields.rooms[0].children_ages
		});
	};

	return (
		<div className={className}>
			<SearchForms
				form={form}
				header={header}
				destinations={<SearchFormDestinations form={form} md={9} />}
				fetchData={fetchData}
				moreRooms={false}
				onSubmit={onSubmit}
				service="activities"
			/>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		getActivities: payload => getActivities(dispatch, payload),
		resetActivityFilter: payload => resetActivityFilter(dispatch, payload)
	};
};

export default connect(
	null,
	mapDispatchToProps
)(Form.create({ name: 'activities_filter' })(withRouter(SearchForm)));
