import styled from 'styled-components';
import Device from '../../../../commons/Device';

export const Container = styled.div`
	display: flex;
	align-items: center;
	.custom-title {
		margin: 16px 2px;
		margin-right: 10px;
		@media ${Device.xs} {
			font-size: 18px;
		}
	}
`;
