/*----- Core -----*/
import React from 'react';
import styled from 'styled-components';
import Moment from 'moment';

/*----- Assets -----*/
import Text from '../text/Text';

const StyledText = styled(Text)`
	font-size: 16px;
	color: #707070;
`;

const ReservationDates = ({ dateFrom, dateTo }) => {
	return (
		<>
			{dateFrom && dateTo ? (
				<StyledText
					content={`
						${Moment(dateFrom).format('DD/MM/YYYY')}
						-
						${Moment(dateTo).format('DD/MM/YYYY')}
					`}
				/>
			) : (
				<StyledText content={Moment(dateFrom || dateTo).format('DD/MM/YYYY')} />
			)}
		</>
	);
};

export default ReservationDates;
