import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckoutPassengersContainer from '../CheckoutPassengersContainer';
import { ItemCheckoutConfirmContainer } from '../../../../components/containers/CheckoutContainer';
import CheckoutData from '../CheckoutData';
import CheckoutConfirmButton from '../CheckoutConfirmButton';
import { withRouter } from 'react-router';
import { getId } from '../../checkout/CheckoutUtils';
import { StyledBoxContainerConfirm } from '../../../../components/containers/CheckoutContainer/styles';
import Divider from '../../../../components/divider';
import { MainDiv, ObservationDiv } from './styles';
import TotalAmountRow from '../totalAmountRow';

const GenericConfirmCard = ({
	history,
	header,
	children,
	dataCheckout,
	currency,
	sale,
	taxes,
	service,
	fromCart = false
}) => {
	const { t } = useTranslation();
	const id = getId(history);

	return (
		<MainDiv>
			<StyledBoxContainerConfirm fromCart={fromCart}>
				{header}

				{children ? <Divider /> : <></>}

				{children}

				<Divider />

				<ItemCheckoutConfirmContainer>
					<CheckoutPassengersContainer
						dataCheckout={dataCheckout}
						passengerLabel={t('checkout.passengers.passenger')}
					/>
					{dataCheckout.observation && dataCheckout.observation.length && (
						<ObservationDiv>
							<CheckoutData
								label={t('checkout.comment.observation')}
								value={dataCheckout.observation}
								lastItemData={true}
							/>
						</ObservationDiv>
					)}
				</ItemCheckoutConfirmContainer>
				<TotalAmountRow currency={currency} price={sale + taxes} />
			</StyledBoxContainerConfirm>

			<CheckoutConfirmButton
				destination={
					fromCart ? '/cart/reservation' : `/${service}/${id}/reservation`
				}
			/>
		</MainDiv>
	);
};

export default withRouter(GenericConfirmCard);
