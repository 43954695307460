import {OPEN_MODAL, CLOSE_MODAL} from '../actions/loginModalActions'

/*----- Reducer -----*/
const initialState = {
  visible: false
}

export function loginModalVisible(state = initialState, action) {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        visible: true,
      }
    case CLOSE_MODAL:
      return {
        ...state,
        visible: false
      }
    default:
      return state
  }
}

export default loginModalVisible

