import React from 'react';
import { StyledLink, StyledLogo } from './styles';
import logo from '../../../../assets/logo.svg';

const Logo = ({ absolute = false }) => (
	<StyledLink to="/home" absolute={absolute}>
		<StyledLogo src={logo} alt="logo" />
	</StyledLink>
);

export default Logo;
