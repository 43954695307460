import React, { useEffect } from 'react';
import { connect } from 'react-redux';

/*----- Components -----*/
import SearchFormHotels from '../searchForm';
import HotelResultContainer from './HotelResultContainer';
import GenericSearch from '../../../../components/search';

/*----- Selectors -----*/
import { hotelsData, hotelsIsLoading } from '../../selectors/hotelsSelector';

/*----- Utils -----*/
import { parse } from '../../../../utilities/queryString';
import { activitiesDestinations } from '../../../../utilities/constants';

/*----- Actions -----*/
import { getHotels } from '../../actions/searchFormActions';
import { buildFetchQuery } from '../../../../utilities/buildSearchQuery';
import { cleanHotelRoom } from '../../actions/hotelRoomActions';

const SearchHotels = ({ isLoading, hotels, getHotels, history, cleanHotelRoom }) => {
	useEffect(() => {
		let params = parse(history.location.search, activitiesDestinations);

		if (!params) {
			history.push('/hotels');
			return;
		}

		cleanHotelRoom();
		getHotels(buildFetchQuery(params, true));
	}, [cleanHotelRoom, getHotels, history]);

	return (
		<GenericSearch
			isLoading={isLoading}
			data={hotels}
			service="hotels"
			ResultContainer={<HotelResultContainer />}
			SearchForm={SearchFormHotels}
		/>
	);
};

const mapStateToProps = state => {
	return {
		hotels: hotelsData(state),
		isLoading: hotelsIsLoading(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getHotels: payload => getHotels(dispatch, payload),
		cleanHotelRoom: payload => cleanHotelRoom(dispatch, payload)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchHotels);
