/*----- Actions -----*/
import {
	SET_HOTEL_ROOM,
	SELECT_ROOM,
	CLEAN_HOTEL_ROOM
} from '../actions/hotelRoomActions';

const initialState = {
	data: {},
	selectedRoom: false
};

export default function hotelRoom(state = initialState, action) {
	switch (action.type) {
		case SET_HOTEL_ROOM:
			return {
				...state,
				data: action.payload
			};
		case SELECT_ROOM:
			return {
				...state,
				selectedRoom: action.payload
			};
		case CLEAN_HOTEL_ROOM:
			return {
				...state,
				...initialState
			};
		default:
			return state;
	}
}
