import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { cartData } from '../../selectors/cartSelector';
//import HeroText from '../../../../components/hero/HeroText';
/* import {
	countPassengers,
	passengerSelector
} from '../../../commons/checkout/CheckoutUtils';
 */
import { StyledCartCheckout, StyledConfirmContainer } from '../styles';
import StepsContainer from '../../../../components/steps/StepsContainer';
import {
	cartSteps,
	getTotalAmount,
	getUserCurrency
} from '../../../../utilities/utils';
import CartDetailItem from './item';
import GenericConfirmCard from '../../../commons/confirm/GenericConfirm/GenericConfirmCard';
import { getCheckoutCart } from '../../../../services/CartCheckoutService';
import { Col, Row } from 'antd';
import HeroText from '../../../../components/hero/HeroText';

const CartConfirm = ({ cartData }) => {
	const dataCheckout = getCheckoutCart();
	const { t } = useTranslation();

	return (
		<StyledCartCheckout>
			<StepsContainer active={1} dataSteps={cartSteps()} />
			<Col
				xs={24}
				sm={{ span: 24 }}
				md={{ span: 20, offset: 2 }}
				lg={{ span: 22, offset: 1 }}
				xl={{ span: 22, offset: 1 }}
			>
				<Col>
					<Row type="flex" justify="start" align="top">
						<HeroText
							title={t('cart.checkout.confirm')}
							subtitle={t('cart.checkout.title')}
							reverse
						/>
					</Row>
				</Col>
				<StyledConfirmContainer>
					<GenericConfirmCard
						header={
							<>
								{cartData.map((item, index) => (
									<CartDetailItem item={item} key={index} />
								))}
							</>
						}
						dataCheckout={dataCheckout}
						currency={getUserCurrency().name}
						sale={parseInt(getTotalAmount(cartData).toFixed(2), 10)}
						taxes={0}
						fromCart
					/>
				</StyledConfirmContainer>
			</Col>
		</StyledCartCheckout>
	);
};

const mapStateToProps = state => {
	return {
		cartData: cartData(state)
	};
};

export default connect(mapStateToProps, null)(CartConfirm);
