import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledRow, BigImage, SmallImage, Button } from './styles';
import { Col, Row } from 'antd';

const Mosaic = ({ images, showGallery }) => {
	const { t } = useTranslation();

	return (
		<StyledRow type="flex">
			<BigImage span={14} image={images[0]} />
			<Col span={10}>
				<Row>
					<SmallImage span={24} image={images[1]} borderbottom={1} />
				</Row>
				<Row>
					<SmallImage xs={0} md={12} image={images[2]} borderright={1} />
					<SmallImage xs={24} md={12} image={images[3]}>
						<Button
							content={t('general.seePictures')}
							secondary
							onClick={showGallery}
						/>
					</SmallImage>
				</Row>
			</Col>
		</StyledRow>
	);
};

export default Mosaic;
