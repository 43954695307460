import React from 'react';
import { useTranslation } from 'react-i18next';
import { getTotalAmount, getUserCurrency } from '../../../../utilities/utils';
import { addCheckoutCart } from '../../../../services/CartCheckoutService';
import { ButtonCheckoutContainer } from '../../../../components/containers/CheckoutContainer';
import DefaultButton from '../../../../components/button/DefaultButton';
import CheckoutPassengers from '../../../commons/checkout/CheckoutPassengers';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/trash-icon.svg';
import CartCheckoutItem from './item';
import { Col, Form } from 'antd';
import {
	StyledCheckoutRemoveButton,
	StyledRow,
	StyledCheckoutRemoveButtonCol
} from '../styles';
import {
	cartHandleSubmit,
	loadPassengers
} from '../../../commons/checkout/CheckoutUtils';
import { withRouter } from 'react-router';
import TotalAmountRow from '../../../commons/confirm/totalAmountRow';

const CartItems = ({
	cartData,
	removeFromCart,
	passengers,
	passengersRequired,
	form,
	history
}) => {
	const { t } = useTranslation();
	const setPassengersParsed = data => {
		let checkoutInfo = {
			passengers: [],
			observation: data.observation || ''
		};
		checkoutInfo.passengers = loadPassengers(data, passengers);
		addCheckoutCart(checkoutInfo);
	};

	return (
		<>
			{cartData.map((item, index) => (
				<StyledRow type="flex" justify="center" align="center" key={index}>
					<Col md={23}>
						<CartCheckoutItem item={item} />
					</Col>
					<StyledCheckoutRemoveButtonCol
						md={1}
						onClick={() => removeFromCart(item.uuid)}
					>
						<StyledCheckoutRemoveButton>
							<TrashIcon />
						</StyledCheckoutRemoveButton>
					</StyledCheckoutRemoveButtonCol>
				</StyledRow>
			))}
			<TotalAmountRow
				currency={getUserCurrency().name}
				price={getTotalAmount(cartData)}
				roundedTop
			/>
			<Form
				onSubmit={event =>
					cartHandleSubmit(event, form, history, setPassengersParsed)
				}
			>
				<CheckoutPassengers
					form={form}
					passengersRequired={passengersRequired}
					countPassengers={passengers}
					size={24}
				/>
				<ButtonCheckoutContainer>
					<DefaultButton
						htmlType="submit"
						content={t('checkout.confirmReservation')}
					/>
				</ButtonCheckoutContainer>
			</Form>
		</>
	);
};

export default withRouter(CartItems);
