/*----- Featured Hotels -----*/
import hotelBourbon from '../../../assets/images/hotels/hotelBourbon.jpg';
import hotelGrandCrucero from '../../../assets/images/hotels/hotelGrandCrucero.jpg';
import hotelFallsIguazu from '../../../assets/images/hotels/hotelFallsIguazu.jpg';
import hotelSuica from '../../../assets/images/hotels/suicaHotelResortIguazu.jpg';

/*----- Featured Activities -----*/
import cataratas_argentina from '../../../assets/images/activities/cataratas_argentinas.jpg';
import cataratas_brasileras from '../../../assets/images/activities/cataratas_brasileras.jpg';
import ruinas_de_san_ignacio from '../../../assets/images/activities/ruinas_de_san_ignacio.jpg';
import parque_de_aves from '../../../assets/images/activities/parque_de_aves.jpg';

/*----- Featured Packages -----*/
import packageAventura from '../../../assets/images/packages/aventura.jpg';
import packageJesuitico from '../../../assets/images/packages/jesuitico.jpg';
import packagePromocional from '../../../assets/images/packages/promocional.jpg';
import packageMasCataratas from '../../../assets/images/packages/mas_catarats.jpg';

export const hotelsData = [
	{
		name: 'Suíça Hotel & Resort',
		image: hotelSuica,
		star: 4,
		description: 'hotels.data.hotelSuica.description'
	},
	{
		name: 'Bourbon Cataratas do Iguaçu Resort',
		image: hotelBourbon,
		star: 5,
		description: 'hotels.data.hotelBourbon.description'
	},
	{
		name: 'Falls Iguazú',
		image: hotelFallsIguazu,
		star: 5,
		description: 'hotels.data.hotelFallsIguazu.description'
	},
	{
		name: 'Grand Crucero Iguazú Hotel',
		image: hotelGrandCrucero,
		star: 4,
		description: 'hotels.data.hotelGrandCrucero.description'
	}
];
export const packagesData = [
	{
		name: 'packages.data.aventura.name',
		image: packageAventura,
		services: ['transfer', 'assistance', 'in', 'lunch'],
		description: 'packages.data.aventura.description'
	},
	{
		name: 'packages.data.jesuitico.name',
		image: packageJesuitico,
		services: ['transfer', 'assistance'],
		description: 'packages.data.jesuitico.description'
	},
	{
		name: 'packages.data.promocional.name',
		image: packagePromocional,
		services: ['transfer', 'assistance'],
		description: 'packages.data.promocional.description'
	},
	{
		name: 'packages.data.masCataratas.name',
		image: packageMasCataratas,
		services: ['transfer', 'assistance'],
		description: 'packages.data.masCataratas.description'
	}
];
export const activitiesData = [
	{
		name: 'activities.data.cataratasArgentinas.name',
		image: cataratas_argentina,
		description: 'activities.data.cataratasArgentinas.description'
	},
	{
		name: 'activities.data.paqueteDeAves.name',
		image: parque_de_aves,
		description: 'activities.data.paqueteDeAves.description'
	},
	{
		name: 'activities.data.cataratasBrasileras.name',
		image: cataratas_brasileras,
		description: 'activities.data.cataratasBrasileras.description'
	},
	{
		name: 'activities.data.ruinasDeSanIgnacio.name',
		image: ruinas_de_san_ignacio,
		description: 'activities.data.ruinasDeSanIgnacio.description'
	}
];
