/*----- Imports -----*/
import React from 'react';
import { connect } from 'react-redux';
import {
	StyledFooter,
	StyledRow,
	StyledCol,
	StyledRightCol,
	StyledLink,
	StyledLogo,
	StyledReportLink,
	StyledEnterpriseItem
} from './styles';

/*----- Assets -----*/
import logo from '../../../../assets/logo_footer.svg';

/*----- Selectors -----*/
import { footerVisibleSelector } from '../../../../modules/app/AppSelectors';

/*----- Component -----*/
import SocialNetworks from './SocialNetworks';
import Contact from './Contact';

const Footer = ({ footerVisible }) => {
	return (
		footerVisible && (
			<StyledFooter>
				<StyledRow type="flex" align="middle" justify="space-around">
					<StyledCol sm={{ order: 1 }} xs={{ order: 3 }}>
						<Contact />
					</StyledCol>
					<StyledCol sm={{ order: 2 }} xs={{ order: 1 }}>
						<StyledLink to="/home">
							<StyledLogo src={logo} alt="logo" />
						</StyledLink>
					</StyledCol>
					<StyledRightCol sm={{ order: 3 }} xs={{ order: 2 }}>
						<SocialNetworks />
						<StyledEnterpriseItem>EB Group SRL</StyledEnterpriseItem>
						<StyledEnterpriseItem>CUIT 30-70714333-5</StyledEnterpriseItem>
						<StyledEnterpriseItem>Legajo 4615</StyledEnterpriseItem>
						<StyledReportLink href="https://www.argentina.gob.ar/servicio/presentar-una-denuncia-contra-una-agencia-de-viajes">
							Denunciar Agencia
						</StyledReportLink>
					</StyledRightCol>
				</StyledRow>
			</StyledFooter>
		)
	);
};

const mapStateToProps = state => {
	return {
		footerVisible: footerVisibleSelector(state)
	};
};

export default connect(mapStateToProps, null)(Footer);
