/*----- Core -----*/
import React from 'react';

/*----- Components -----*/
import { Form, Select } from 'antd';

const { Item } = Form;
const { Option } = Select;

const SelectItem = ({
	label,
	fieldName,
	placeholder,
	getFieldDecorator,
	rules,
	options
}) => {
	const getOptions = () => {
		return options.map((opt, idx) => (
			<Option value={opt.value} key={`${idx}-${opt.value}`}>
				{opt.label}
			</Option>
		));
	};

	return (
		<Item label={label}>
			{getFieldDecorator(fieldName, {
				rules: rules,
				validateTrigger: 'onSubmit'
			})(<Select placeholder={placeholder}>{getOptions()}</Select>)}
		</Item>
	);
};

export default SelectItem;
