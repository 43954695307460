import React from 'react';
import { StyledTabs, StyledTabPane } from './styles';

const DefaultTab = ({ onChange, tabs, width, display }) => (
	<StyledTabs
		display={display}
		defaultActiveKey="0"
		onChange={onChange}
		animated={false}
		width={width}
	>
		{tabs.map((tab, index) => (
			<StyledTabPane display={display} tab={tab.name} key={index} width={width}>
				{tab.content}
			</StyledTabPane>
		))}
	</StyledTabs>
);

DefaultTab.defaultProps = {
	display: true
};

export default DefaultTab;
