import {
	SET_LOCATION,
	SET_ENTRY_DATE,
	SET_EXIT_DATE,
	ADD_ROOM,
	REMOVE_ROOM,
	ADD_ADULT,
	REMOVE_ADULT,
	ADD_YOUNGER,
	REMOVE_YOUNGER,
	CHANGE_YOUNGER_AGE,
	CLEAR_NOTIFICATION,
	SET_NOTIFICATION
} from './HomeConstants';

const setLocationCreator = param => ({
	type: SET_LOCATION,
	payload: param
});

const setEntryDateCreator = param => ({
	type: SET_ENTRY_DATE,
	payload: param
});

const setExitDateCreator = param => ({
	type: SET_EXIT_DATE,
	payload: param
});

const addRoomCreator = () => ({
	type: ADD_ROOM
});

const removeRoomCreator = param => ({
	type: REMOVE_ROOM,
	payload: param
});

const addAdultCreator = param => ({
	type: ADD_ADULT,
	payload: param
});

const removeAdultCreator = param => ({
	type: REMOVE_ADULT,
	payload: param
});

const addYoungerCreator = param => ({
	type: ADD_YOUNGER,
	payload: param
});

const removeYoungerCreator = param => ({
	type: REMOVE_YOUNGER,
	payload: param
});

const changeYoungerAgeCreator = param => ({
	type: CHANGE_YOUNGER_AGE,
	payload: param
});

const clearNotificationCreator = param => ({
	type: CLEAR_NOTIFICATION,
	payload: param
});
const setNotificationCreator = param => ({
	type: SET_NOTIFICATION,
	payload: param
});

export const setLocation = (dispatch, param) => dispatch(setLocationCreator(param));

export const setEntryDate = (dispatch, param) =>
	dispatch(setEntryDateCreator(param));

export const setExitDate = (dispatch, param) => dispatch(setExitDateCreator(param));

export const addRoom = dispatch => dispatch(addRoomCreator());

export const removeRoom = (dispatch, param) => dispatch(removeRoomCreator(param));

export const addAdult = (dispatch, param) => dispatch(addAdultCreator(param));

export const removeAdult = (dispatch, param) => dispatch(removeAdultCreator(param));

export const addYouger = (dispatch, param) => dispatch(addYoungerCreator(param));

export const removeYounger = (dispatch, param) =>
	dispatch(removeYoungerCreator(param));

export const changeYoungerAge = (dispatch, param) =>
	dispatch(changeYoungerAgeCreator(param));

export const clearNotification = (dispatch, param) =>
	dispatch(clearNotificationCreator(param));

export const setNotification = (dispatch, param) =>
	dispatch(setNotificationCreator(param));
