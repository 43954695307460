import JwtDecode from 'jwt-decode';
import { getAuthToken, isAuth, destroySession } from '../sessions/loginSession';

export const getCurrentUser = () => {
	const payload = getAuthTokenPayload();
	return payload.user;
};

export const isLogged = () => {
	if (!isAuth()) return false;

	return getAuthTokenPayload() ? true : false;
};

const getAuthTokenPayload = () => {
	try {
		return JwtDecode(getAuthToken());
	} catch (e) {
		destroySession();
		return false;
	}
};
