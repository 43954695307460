import React, { useState } from 'react';
import Map from './Map';
import { Modal } from 'antd';
import Color from '../../../../commons/Color';
import { StyledMapText, StyledMapContainer } from './styles';

/*----- Assets -----*/
import LocationIcon from '../../../../components/icons/LocationIcon';

const MapText = ({ title, location, address }) => {
	const [visible, setVisible] = useState(false);

	return (
		<>
			<StyledMapContainer onClick={() => setVisible(true)}>
				<LocationIcon width={'15px'} height={'17px'} fill={Color.main} />
				<StyledMapText content={'Ver mapa'} bold />
			</StyledMapContainer>

			<Modal
				width={1000}
				title={
					<h3
						style={{
							color: `${Color.text}`,
							fontWeight: 'bold',
							fontFamily: 'OpenSansBold'
						}}
					>{`Hotel ${title}`}</h3>
				}
				visible={visible}
				onCancel={() => setVisible(false)}
				footer={null}
				className="modal-map"
			>
				<Map location={location} address={address} />
			</Modal>
		</>
	);
};

export default MapText;
