import styled from 'styled-components';
import Text from '../../../components/text/Text';
import { Row } from 'antd';

export const StyledDiv = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	height: 461px;
	padding: 32px;
`;

export const TitleText = styled(Text)`
	color: #b01a24;
	font-size: 24px;
	text-align: center;
	font-weight: bold;
	align-items: center;
`;

export const SubTitleText = styled(TitleText)`
	font-size: 12px;
	margin-top: 12px;
`;

export const ContentText = styled(Text)`
	color: #1d2226;
	font-size: 12px;
	text-align: center;
	margin-top: 12px;
	font-weight: bold;
`;

export const FooterText = styled(Text)`
	color: #8a9ead;
	font-size: 12px;
	text-align: center;
`;

export const IconRow = styled(Row)`
	display: flex !important;
	justify-content: flex-end;
	width: 100%;
`;

export const ContentRow = styled(Row)`
	width: 100%;
	height: 100% !important;
	display: flex !important;
	align-items: center;
	flex-direction: column;
	justify-content: center;
`;

export const FooterRow = styled(Row)`
	display: flex !important;
	justify-content: center;
	align-items: flex-end;
	width: 100%;
`;

export const StyledImage = styled.img`
	width: 356px;
	height: 293px;
`;

export const StyledSpan = styled.span`
	margin-top: 20%;
	text-align: center;
`;
