import styled from 'styled-components';
import { Drawer, Icon } from 'antd';
import { Link } from 'react-router-dom';
import Text from '../text/Text';
import LogoutButton from '../button/LogoutButton';
import Color from '../../commons/Color';
import Device from '../../commons/Device';

export const StyledBurgerMenu = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

export const StyledOptionsItems = styled.div`
	box-shadow: ${props => (props.showProfile ? '0px -2px 8px #000029' : '')};
	padding: 16px 0px;
	width: 100%;
	display: flex;
	align-items: center;
`;

export const StyledDrawer = styled(Drawer)`
	.ant-drawer-content {
		padding: 10px;
	}
	.ant-drawer-header {
		padding: 20px;
	}
	.ant-drawer-body {
		padding: 0px;
	}
`;

export const MenuDrawerItems = styled.div`
	border-bottom: 1px solid #e8e8e8;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 20px;
`;

export const StyledLink = styled(Link)`
	text-align: left;
	font-size: 16px;
	color: #707070;
	letter-spacing: 0px;
	margin-bottom: 16px;
	&:hover {
		text-decoration: none;
	}
`;

export const StyledTitle = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;

export const StyledLogo = styled.img`
	width: 106px;
	height: 23px;
`;

export const StyledIcon = styled.img`
	width: 16px;
	height: 18px;
`;

export const StyledIconProfile = styled.img`
	width: 20px;
	height: 20px;
`;

export const StyledLinkProfile = styled(Link)`
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
`;

export const StyledLogout = styled.div`
	position: absolute;
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;
	padding: 20px;
	bottom: 0;
`;

export const StyledLogoutButton = styled(LogoutButton)`
	&& {
		border: none;
		padding: 0px;
	}
`;

export const StyledLogoutContent = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;

export const StyledTextLogout = styled(Text)`
	font-size: 16px;
	color: ${Color.main};
	margin: 0px 12px 0px 0px;
`;

export const StyledTextUserProfile = styled(Text)`
	font-size: 10px;
	color: #707070;
	margin: 0px 8px;
`;

export const StyledHeaderItems = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	justify-content: ${props => (props.showProfile ? 'flex-start' : 'center')};
	margin-left: ${props => (props.showProfile ? '54px' : '0px')};
`;

export const StyledColLogo = styled.div`
	display: flex;
	align-items: center;
`;

export const StyledColOptions = styled.div`
	position: absolute;
	z-index: 3;
	right: 0;
	margin-right: ${props => (props.showProfile ? '20px' : '14px')};
	display: flex !important;
	justify-content: flex-end;
	align-items: center;
	@media ${Device.xs} {
		right: 38px;
	}
`;

export const StyledIconMenu = styled(Icon)`
	font-size: 20px;
	color: ${Color.main} !important;
	margin-left: 24px;
	margin-right: 12px;
	position: absolute;
	z-index: 3;
	left: 0;
`;
