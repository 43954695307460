import React from 'react';

const HotelIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 17.823 17.823"
			className="hotel-icon"
		>
			<g transform="translate(0)">
				<path
					className="a"
					d="M-56.639,196.276h-2.089a.522.522,0,0,0-.522.522v2.089a.522.522,0,0,0,.522.522h2.089a.522.522,0,0,0,.522-.522V196.8a.522.522,0,0,0-.522-.522Zm-.522,2.089h-1.044V197.32h1.044Zm0,0"
					transform="translate(64.437 -192.099)"
				/>
				<path
					className="a"
					d="M-56.639,286.276h-2.089a.522.522,0,0,0-.522.522v2.089a.522.522,0,0,0,.522.522h2.089a.522.522,0,0,0,.522-.522V286.8a.522.522,0,0,0-.522-.522Zm-.522,2.089h-1.044V287.32h1.044Zm0,0"
					transform="translate(64.437 -277.921)"
				/>
				<path
					className="a"
					d="M34.861,196.276H32.772a.522.522,0,0,0-.522.522v2.089a.522.522,0,0,0,.522.522h2.089a.522.522,0,0,0,.522-.522V196.8a.522.522,0,0,0-.522-.522Zm-.522,2.089H33.294V197.32h1.044Zm0,0"
					transform="translate(-22.816 -192.099)"
				/>
				<path
					className="a"
					d="M34.861,286.276H32.772a.522.522,0,0,0-.522.522v2.089a.522.522,0,0,0,.522.522h2.089a.522.522,0,0,0,.522-.522V286.8a.522.522,0,0,0-.522-.522Zm-.522,2.089H33.294V287.32h1.044Zm0,0"
					transform="translate(-22.816 -277.921)"
				/>
				<path
					className="a"
					d="M-153.7,113.586a.522.522,0,0,0,.522-.522v-2.089a.522.522,0,0,0-.522-.522h-2.611v-1.044h.522a.522.522,0,0,0,.522-.522V106.8a.522.522,0,0,0-.522-.522h-12.6a.522.522,0,0,0-.522.522v2.089a.522.522,0,0,0,.522.522h.522v1.044h-2.611a.522.522,0,0,0-.522.522v2.089a.522.522,0,0,0,.522.522h.522v9.469h-.522a.522.522,0,0,0-.522.522.522.522,0,0,0,.522.522H-153.7a.522.522,0,0,0,.522-.522.522.522,0,0,0-.522-.522h-.522v-9.469Zm-14.168,9.469h-1.044v-9.469h1.044Zm0-10.513h-2.089V111.5h2.089Zm0-5.222h11.557v1.044h-11.557Zm3.133,15.734v-3.2h2.16l0,3.2Zm5.291,0h-2.082l0-3.2h2.086Zm2.089,0H-158.4V119.33a.522.522,0,0,0-.522-.522h-6.336a.522.522,0,0,0-.522.522v3.725h-1.044V109.409h9.469v13.646Zm2.089,0h-1.044v-9.469h1.044Zm-1.044-10.513V111.5h2.089v1.044Zm0,0"
					transform="translate(171 -106.276)"
				/>
			</g>
		</svg>
	);
};

export default HotelIcon;
