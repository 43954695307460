import React from 'react';
import { connect } from 'react-redux';

/*----- Components -----*/
import PackageSaleCard from '../../../../components/card/PackageSaleCard';

/*----- Selectors -----*/
import { packagesData } from '../../selectors/packagesSelector';

/*----- Assets -----*/
import guideIcon from '../../../../assets/icons/guide.svg';
import busIcon from '../../../../assets/icons/bus.svg';
import ActivityIcon from '../../../../assets/icons/services/map.svg';

/*----- Utils -----*/
import { getSearchQuery } from '../../../../services/SearchService';

const iconServices = [
	{ icon: busIcon, label: 'Traslados incluídos' },
	{ icon: ActivityIcon, label: 'Actividades incluídas' },
	{
		icon: guideIcon,
		label: 'Guías disponibles en español, inglés y portugués'
	}
];

const ResultList = ({ packagesData }) => {
	const withHotel = getSearchQuery().with_hotel;
	return packagesData.map((data, i) => (
		<PackageSaleCard
			key={data.uuid}
			title={data.name}
			imageUrl={data.rates[0].imagen}
			price={
				data.rates[0].amount_details.passenger.sale +
				data.rates[0].amount_details.passenger.iva
			}
			currency={data.rates[0].currency}
			iconServices={iconServices}
			itemId={data.rates[0].item_id}
			rates={data.rates}
			hotelId={withHotel ? data.rates[0].hotel[0].id : undefined}
			withHotel={withHotel}
		/>
	));
};

const mapStateToProps = state => {
	return {
		packagesData: packagesData(state)
	};
};

export default connect(mapStateToProps, null)(ResultList);
