import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Form } from 'antd';

import { ItemCheckoutContainer } from '../../../../../components/containers/CheckoutContainer';
import Text from '../../../../../components/text/Text';
import ReservationCheckout from '../../../../commons/checkout/ReservationCheckout';
import PickUpLocationForm from '../../../../commons/checkout/PickUpLocationForm';
import { setNotification } from '../../../../home/HomeActions';
import { showActivityData } from '../../../selectors/showActivitySelector';
import { addToCart } from '../../../../cart/actions/cartActions';
import { addToCartHandler } from '../../../../../utilities/utils';
import { getSearchQuery } from '../../../../../services/SearchService';
import {
	addCheckoutActivity,
	getCheckoutActivity
} from '../../../../../services/ActivityCheckoutService';
import {
	loadPassengers,
	countPassengers,
	getId,
	checkoutHandleSubmit
} from '../../../../commons/checkout/CheckoutUtils';

import {
	pickUpLocationFoz,
	pickUpLocationPuerto
} from '../../../../../utilities/constants';

const ActivityForm = ({
	history,
	form,
	showActivity,
	addToCart,
	setNotification
}) => {
	const [continueReservation, setContinueReservation] = useState(false);
	const { t } = useTranslation();
	const cityCode = getSearchQuery().city_code;
	const locationData = cityCode === 'IGU' ? pickUpLocationFoz : pickUpLocationPuerto;
	let id = getId(history);
	const dataCheckout = getCheckoutActivity(id);
	let passengersRequired = showActivity.rate.amount_details.occupancy.required;

	const passengers = countPassengers(passengersRequired);

	const getHotel = locationId => {
		let hotel = locationData.find(hotel => hotel.value === locationId);
		return hotel.label;
	};
	const parseCheckoutInfo = data => ({
		passengers: [],
		observation: data.observation || '',
		pickUpLocation: data.pickUpLocation,
		hotel: getHotel(data.pickUpLocation),
		reservation_date_in: dataCheckout.reservation_date_in,
		preset: dataCheckout.preset
	});

	const setPassengersParsed = data => {
		let checkoutInfo = parseCheckoutInfo(data);
		checkoutInfo.passengers = loadPassengers(data, passengers);
		addCheckoutActivity(id, checkoutInfo);
	};

	const activityCartHandler = data => {
		const parsedData = parseCheckoutInfo(data);
		addToCartHandler(
			parsedData,
			showActivity,
			addToCart,
			history,
			() => addCheckoutActivity(id, parsedData),
			() =>
				setNotification({
					msg: 'Actividad Agregada al Carrito!',
					type: 'excursion'
				})
		);
	};

	return (
		<Form>
			<ItemCheckoutContainer>
				<Text content={t('checkout.activities.additional')} />
				<PickUpLocationForm
					form={form}
					initialValue={dataCheckout.pickUpLocation}
					locations={locationData}
				/>
			</ItemCheckoutContainer>
			<ReservationCheckout
				continueReservation={continueReservation}
				form={form}
				id={id}
				checkoutHandleSubmit={checkoutHandleSubmit}
				addToCartHandler={formData => activityCartHandler(formData)}
				setContinueReservation={setContinueReservation}
				passengersRequired={passengersRequired}
				dataCheckout={dataCheckout}
				setPassengersParsed={setPassengersParsed}
				passengers={passengers}
				service="activities"
			/>
		</Form>
	);
};

const mapStateToProps = state => {
	return {
		showActivity: showActivityData(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		addToCart: payload => addToCart(dispatch, payload),
		setNotification: payload => setNotification(dispatch, payload)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Form.create({ name: 'checkout_activity_form' })(withRouter(ActivityForm)));
