import React, { useEffect, useState } from 'react';
import { FormRow, StyledCol, Button, StyledTitle } from './styles';
import { Form } from 'antd';
import RoomInput, { initialOccupancy } from '../room';
import CustomRangePicker from '../../rangePicker/CustomRangePicker';
import { withRouter } from 'react-router';
import moment from 'moment';
import { activitiesDestinations } from '../../../utilities/constants';
import { parse } from '../../../utilities/queryString';

import { replaceSearch } from '../../pagination/SearchUtils';
import {
	addDateSeachQuery,
	getDateSeachQuery
} from '../../../services/SearchService';

const { Item } = Form;

const SearchForm = ({
	history,
	form,
	onSubmit,
	className,
	header = '',
	moreRooms = false,
	destinations,
	fetchData,
	service,
	nights,
	options
}) => {
	const { getFieldDecorator } = form;
	const previousDate = getDateSeachQuery();
	const previousDateIn = previousDate?.date_in;
	const previousDateOut = previousDate?.date_out
		? moment(previousDate?.date_out)
		: moment().add(1, 'days');
	const [initialDateIn, setInitialDateIn] = useState(moment(previousDateIn));
	const [initialDateOut, setInitialDateOut] = useState(
		previousDateOut || moment().add(1, 'days')
	);
	const [initialRoom, setInitialRoom] = useState({ 0: initialOccupancy });

	useEffect(() => {
		let params = parse(history.location.search, activitiesDestinations);

		if (!params) {
			return;
		}

		setInitialDateIn(moment(params.date_in));
		setInitialDateOut(moment(params.date_out));
		setInitialRoom(params.rooms);
	}, [history, history.location.search]);

	const submitHandler = event => {
		event.preventDefault();
		form.validateFields(err => {
			if (err) return;

			const fields = form.getFieldsValue();
			fields.date_in = moment(fields.date_in).format('YYYY-MM-DD');
			fields.date_out = moment(fields.date_out).format('YYYY-MM-DD');
			fetchData(fields);

			if (onSubmit) onSubmit();
			replaceSearch(history, service, fields);
			addDateSeachQuery({ date_in: fields.date_in, date_out: fields.date_out });
		});
	};

	return (
		<div className={className}>
			<StyledTitle bold content={`${header}`} />

			{options}
			<Form onSubmit={submitHandler} method="GET">
				<FormRow type="flex" align="bottom">
					{destinations}

					<StyledCol xs={24} md={8}>
						<CustomRangePicker
							form={form}
							initialDateIn={initialDateIn}
							initialDateOut={initialDateOut}
						/>
					</StyledCol>

					{nights}

					<StyledCol xs={nights ? 12 : 24} md={3}>
						<Item>
							{getFieldDecorator('rooms', { initialValue: initialRoom })(
								<RoomInput moreRooms={moreRooms} />
							)}
						</Item>
					</StyledCol>

					<StyledCol xs={24} md={3} style={{ textAlign: 'center' }}>
						<Button content="Buscar" htmlType="Submit" />
					</StyledCol>
				</FormRow>
			</Form>
		</div>
	);
};

export default withRouter(SearchForm);
