import React, { useEffect } from 'react';
import { connect } from 'react-redux';

/*----- Components -----*/
import ActivityResultContainer from './ActivityResultContainer';
import SearchFormActivities from '../searchForm';
import GenericSearch from '../../../../components/search';

/*----- Selectors -----*/
import {
	activitiesData,
	activitiesIsLoading
} from '../../selectors/activitiesSelector';

/*----- Utils -----*/
import { parse } from '../../../../utilities/queryString';
import { activitiesDestinations } from '../../../../utilities/constants';

/*----- Actions -----*/
import { getActivities } from '../../actions/searchFormActions';
import { buildFetchQuery } from '../../../../utilities/buildSearchQuery';

const SearchActivities = ({ isLoading, activities, getActivities, history }) => {
	useEffect(() => {
		let params = parse(history.location.search, activitiesDestinations);

		if (!params) {
			history.push('/activities');
			return;
		}

		getActivities(buildFetchQuery(params));
	}, [getActivities, history]);

	return (
		<GenericSearch
			isLoading={isLoading}
			data={activities}
			service="activities"
			ResultContainer={<ActivityResultContainer />}
			SearchForm={SearchFormActivities}
		/>
	);
};

const mapStateToProps = state => {
	return {
		activities: activitiesData(state),
		isLoading: activitiesIsLoading(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getActivities: payload => getActivities(dispatch, payload)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchActivities);
