/*----- Core -----*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Device from '../../commons/Device';

/*----- Antd -----*/
import { Modal } from 'antd';

/*----- Actions -----*/
import { toggleModal } from '../../modules/app/AppActions';

/*----- Selectors -----*/
import { modalVisibleSelector } from '../../modules/app/AppSelectors';

const StyledModal = styled(Modal)`
	@media ${Device.xs} {
		&& {
			max-width: calc(100vw);
			margin: 0;
			top: 0;
		}
	}
`;

const CustomModal = ({ modalVisible, toggleModal, children, width }) => {
	return (
		<StyledModal
			footer={null}
			width={width}
			visible={modalVisible}
			onCancel={toggleModal}
			className="generic-modal"
		>
			{children}
		</StyledModal>
	);
};
function mapStateToProps(state) {
	return {
		modalVisible: modalVisibleSelector(state)
	};
}
function mapDispatchToProps(dispatch) {
	return {
		toggleModal: () => toggleModal(dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomModal);

/*----- PropTypes -----*/
CustomModal.propTypes = {
	toggleModal: PropTypes.func.isRequired,
	modalVisible: PropTypes.bool.isRequired
};
