import styled from 'styled-components';
import { Tabs } from 'antd';
import Color from '../../../commons/Color';

const { TabPane } = Tabs;

export const StyledTabs = styled(Tabs)`
	width: ${props => props.width};
	display: ${props => (props.display ? 'flex' : 'none')};
	flex-direction: column;
	align-items: center;
	.ant-tabs-tab:hover {
		color: ${Color.main} !important;
	}
`;
export const StyledTabPane = styled(TabPane)`
	display: ${props => (props.display ? 'flex' : 'none')};
	justify-content: center;

	.ant-tabs-tab:hover {
		color: ${Color.main} !important;
	}
`;
