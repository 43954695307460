import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
	SHOW_ACTIVITY,
	SHOW_ACTIVITY_SUCCESS,
	SHOW_ACTIVITY_FAILED
} from '../actions/showActivityActions';
import { showActivities } from '../../../api/endpoints/Activities';

import { removeCheckoutActivity } from '../../../services/ActivityCheckoutService';

function* shotFetchActivity(action) {
	function* onSuccess(body) {}

	function* onOk(body) {
		yield put({ type: SHOW_ACTIVITY_SUCCESS, payload: body.data });
	}

	function* onError(error) {
		removeCheckoutActivity(action.payload);
		yield put({ type: SHOW_ACTIVITY_FAILED, payload: error });
		yield put(push('/activities/search'));
	}

	try {
		yield call(showActivities, action.payload, {
			onSuccess: onSuccess,
			onError: onError,
			onOk: onOk
		});
	} catch (error) {
		yield onError(error.message);
	}
}

export default function* ActivitySagas() {
	yield takeLatest(SHOW_ACTIVITY, shotFetchActivity);
}
