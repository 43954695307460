import styled from 'styled-components';
import Text from '../../../components/text/Text';
import { Row } from 'antd';
import Device from '../../../commons/Device';
import Color from '../../../commons/Color';

export const StyledMain = styled.main`
	padding: 32px 20%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 95vh;

	@media ${Device.xs} {
		padding: 10px;
	}
`;

export const StyledDiv = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	height: 100%;
`;

export const TitleText = styled(Text)`
	color: ${Color.main};
	font-size: 24px;
	text-align: center;
	font-weight: bold;
	margin: 24px 0px;
	align-items: center;

	@media ${Device.xs} {
		font-size: 18px;
		margin: 12px 0px;
	}
`;

export const ContentText = styled(Text)`
	color: #1d2226;
	font-size: 14px;
	text-align: center;
	font-weight: bold;

	@media ${Device.xs} {
		font-size: 12px;
		margin: 0px;
		padding: 0 12px;
	}
`;

export const SubContentText = styled(ContentText)`
	margin-top: 24px;
	color: ${Color.main};

	@media ${Device.xs} {
		font-size: 12px;
		margin-top: 24px;
	}
`;

export const FooterText = styled(Text)`
	color: #8a9ead;
	font-size: 14px;
	text-align: center;
	margin-top: 24px;

	@media ${Device.xs} {
		font-size: 10px;
		margin-bottom: 40px;
	}
`;

export const StyledRow = styled(Row)`
	display: flex !important;
	width: 100%;
`;

export const ImageRow = styled(StyledRow)``;

export const IconRow = styled(StyledRow)`
	justify-content: flex-end;
`;

export const ContentRow = styled(StyledRow)`
	align-items: center;
	flex-direction: column;
	justify-content: center;
`;

export const FooterRow = styled(StyledRow)`
	justify-content: center;
	align-items: flex-end;
`;

export const StyledImage = styled.img`
	width: auto;
	height: 100%;
	@media ${Device.xs} {
		height: auto;
		width: 100%;
		margin-top: 24px;
	}
`;
