import { call, put, takeLatest } from 'redux-saga/effects';
import {
	RESERVE_HOTEL,
	RESERVE_HOTEL_SUCCESS,
	RESERVE_HOTEL_FAILED
} from '../actions/reserveActions';
import { reserveHotels } from '../../../api/endpoints/Hotels';

import { removeCheckoutHotel } from '../../../services/HotelCheckoutService';

function* shotReserveHotel(action) {
	function* onSuccess(body) {}

	function* onOk(body) {
		yield put({ type: RESERVE_HOTEL_SUCCESS, payload: body.data });
	}

	function* onError(error) {
		removeCheckoutHotel(action.payload);
		yield put({ type: RESERVE_HOTEL_FAILED, payload: error });
	}

	try {
		yield call(reserveHotels, action.payload, {
			onSuccess: onSuccess,
			onError: onError,
			onOk: onOk
		});
	} catch (error) {
		yield onError(error.message);
	}
}

export default function* ReserveHotelSagas() {
	yield takeLatest(RESERVE_HOTEL, shotReserveHotel);
}
