/*----- Imports -----*/
import React from 'react';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { StyledHeader, StyledContent, StyledMenu, Pipe } from './styles';

/*----- Components -----*/
import MinNavButton from '../../../button/MinNavButton';

/*----- Commons -----*/
import Profile from '../../../profile';
import { isLogged } from '../../../../authentication/currentUser';
import ServicesNavBar from './ServicesNavBar';
import SelectLanguage from './SelectLanguage';
import Logo from './Logo';

/*----- Component -----*/
const Header = () => {
	const { t } = useTranslation();
	const path = window.location.pathname;
	return (
		<StyledHeader>
			<StyledContent logged={isLogged()}>
				{!isLogged() && <Logo />}
				<StyledMenu>
					<MinNavButton
						content={t('general.menu.init')}
						destination="/home"
						active={path.includes('home')}
					/>
					<MinNavButton
						content={t('general.menu.myReservations')}
						destination="/my_reservations/search"
						active={path.includes('my_reservations')}
					/>
					<MinNavButton
						content={t('general.menu.contactUs')}
						destination="/contact"
						active={path.includes('contact')}
					/>
					<MinNavButton
						content={t('general.menu.aboutUs')}
						destination="/about_us"
						active={path.includes('about_us')}
					/>
					<SelectLanguage logged={isLogged()} />
					<Pipe />
					<Profile />
				</StyledMenu>
			</StyledContent>
			{isLogged() && <ServicesNavBar />}
		</StyledHeader>
	);
};

export default withRouter(Header);
