import React from 'react';
import StyledItemService from './styles';
import PropTypes from 'prop-types';

const ItemService = ({ icon, label, bold }) => (
	<StyledItemService className="item-service" bold={bold}>
		<img src={icon} alt={label} /> 
		<span>{ label }</span>
	</StyledItemService>
);

export default ItemService

ItemService.propTypes = {
	icon: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	bold: PropTypes.bool
};