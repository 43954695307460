import React from 'react';
import { Form, Icon } from 'antd';
import {
	StyledItem,
	StyledForm,
	StyledDefaultButton,
	StyledText,
	StyledLink
} from './styles';
import { useTranslation } from 'react-i18next';
import { StyledInput } from '../../../components/form/styles';

const LoginForm = ({ form, onSubmit, isLoading, error, openRecovery, display }) => {
	const { getFieldDecorator, validateFields, getFieldsError, getFieldsValue } = form;
	const { t } = useTranslation();

	const handleSubmit = e => {
		e.preventDefault();
		validateFields();
		const validationErrors = Object.values(getFieldsError());
		if (validationErrors.some(e => e !== undefined)) return;
		onSubmit(getFieldsValue());
	};

	return (
		<StyledForm onSubmit={handleSubmit}>
			<StyledItem>
				{getFieldDecorator('username', {
					rules: [{ required: true, message: t('authentication.emptyField') }]
				})(
					<StyledInput
						type="text"
						name="username"
						placeholder={t('authentication.login.form.email')}
						prefix={<Icon type="user" style={{ color: 'grey' }} />}
						withoutLabel
					/>
				)}
			</StyledItem>
			<StyledItem>
				{getFieldDecorator('password', {
					rules: [{ required: true, message: t('authentication.emptyField') }]
				})(
					<StyledInput
						type="password"
						name="password"
						placeholder={t('authentication.login.form.password')}
						prefix={<Icon type="lock" style={{ color: 'grey' }} />}
						withoutLabel
					/>
				)}
			</StyledItem>
			<StyledText
				error
				content={error ? t('authentication.wrongCredentials') : ''}
			/>
			<StyledDefaultButton
				loading={isLoading}
				type="primary"
				htmlType="submit"
				fontSize="11"
				content={t('authentication.login.button')}
				margin="80px 0px 10px 0px"
			/>
			<StyledText
				center
				padding="0% 15%"
				content={t('authentication.login.forgetPassword')}
			/>
			<StyledLink
				onClick={e => {
					e.preventDefault();
					openRecovery();
				}}
			>
				{t('authentication.login.clickHere')}
			</StyledLink>
		</StyledForm>
	);
};

export default Form.create({ name: 'login_form' })(LoginForm);
