export default `
	animation-duration: 0.5s;
	animation-name: scale;
	transition: all 0.5s ease-in-out;
	@keyframes scale {
	from {
		transform: scale(0.8);
	}

	to {
		transform: scale(1);
	}
`;
