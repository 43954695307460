import React from 'react';
import { Form } from 'antd';
import { StyledItem, StyledFormRecovery, StyledDefaultButton } from './styles';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { isLoadingRecovery } from '../../../authentication/selectors/recoverySelector';
import { recoveryUser } from '../../../authentication/actions/recoveryActions';
import { StyledInput } from '../../../components/form/styles';

const RecoveryForm = ({ form, recoveryUser, isLoading }) => {
	const { getFieldDecorator, validateFields, getFieldsError, getFieldsValue } = form;
	const { t } = useTranslation();

	const handleSubmit = e => {
		e.preventDefault();
		validateFields();
		const validationErrors = Object.values(getFieldsError());
		if (validationErrors.some(e => e !== undefined)) return;
		recoveryUser(getFieldsValue());
	};

	return (
		<StyledFormRecovery onSubmit={handleSubmit}>
			<StyledItem>
				{getFieldDecorator('email', {
					rules: [
						{
							required: true,
							message: t('authentication.wrongEmail'),
							pattern: '^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.com)$'
						}
					]
				})(
					<StyledInput
						type="email"
						name="email"
						placeholder={t('authentication.recovery.form.email')}
						fontSizePlaceHolder="12px"
						withoutLabel
						margin="40px 0px 0px 0px"
						padding="0px"
						borderRadius="10px"
					/>
				)}
			</StyledItem>
			<StyledDefaultButton
				loading={isLoading}
				type="primary"
				htmlType="submit"
				fontSize="11"
				content={t('authentication.recovery.button')}
				margin="80px 0px 10px 0px"
			/>
		</StyledFormRecovery>
	);
};

function mapStateToProps(state) {
	return {
		isLoading: isLoadingRecovery(state)
	};
}

function mapDispatchToProps(dispatch) {
	return {
		recoveryUser: payload => recoveryUser(dispatch, payload)
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Form.create({ name: 'recovery_form' })(RecoveryForm));
