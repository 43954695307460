import { call, put, takeLatest } from 'redux-saga/effects';
import { RESET_CART } from '../actions/cartActions';
import {
	RESERVE_CART_SUCCESS,
	RESERVE_CART_FAILED,
	RESERVE_CART_CONTINUE,
	RESERVE_CART
} from '../actions/reserveActions';

import { reserveCart } from '../../../api/endpoints/Cart';
import {
	removeCart,
	removeCheckoutCart
} from '../../../services/CartCheckoutService';

function* clearCart() {
	yield put({ type: RESET_CART });
	removeCheckoutCart();
	removeCart();
}

function* shotReserveCart(action) {
	function* onOk(error) {
		yield put({ type: RESERVE_CART_FAILED, payload: error });
		yield clearCart();
	}
	function* onCreated(body) {
		yield put({ type: RESERVE_CART_SUCCESS, payload: body.data });
		yield clearCart();
	}
	function* onPartialContent() {
		yield put({ type: RESERVE_CART_CONTINUE });
	}
	function* onInternalServerError() {
		yield put({ type: RESERVE_CART_FAILED, payload: null });
		yield clearCart();
	}

	try {
		yield call(reserveCart, action.payload, {
			onCreated: onCreated,
			onOk: onOk,
			onPartialContent: onPartialContent,
			onInternalServerError: onInternalServerError
		});
	} catch (error) {
		yield onInternalServerError();
	}
}

export default function* reserveCartSagas() {
	yield takeLatest(RESERVE_CART, shotReserveCart);
}
