/*----- Core -----*/
import React from 'react';

/*----- Components -----*/
import { Form, Radio } from 'antd';

const { Item } = Form;

const RadioGroupItem = ({
	label,
	fieldName,
	initialValue,
	getFieldDecorator,
	rules,
	options
}) => {
	const getOptions = () => {
		return options.map((opt, idx) => (
			<Radio value={opt.value} key={idx}>
				{opt.label}
			</Radio>
		));
	};

	return (
		<Item label={label}>
			{getFieldDecorator(fieldName, {
				initialValue: initialValue,
				rules: rules,
				validateTrigger: 'onSubmit'
			})(<Radio.Group>{getOptions()}</Radio.Group>)}
		</Item>
	);
};

export default RadioGroupItem;
