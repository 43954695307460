import TransfersIcon from '../../../../../assets/icons/checkout/transfer.svg';
import ActivityIcon from '../../../../../assets/icons/services/map.svg';
import PersonIcon from '../../../../../assets/icons/custom_icons/user.svg';
import { getPassengerInformation } from '../../../../hotels/views/checkout/section/detailsItemsHotel';

const detailsItemsPackages = (withHotel = false, search) => {
	return [
		{ icon: TransfersIcon, text: 'Traslados' },
		{ icon: ActivityIcon, text: 'Actividades' },
		{
			icon: PersonIcon,
			text: withHotel
				? getPassengerInformation(search).passengers
				: getPassengerInformation(search).passengersText
		}
	];
};

export default detailsItemsPackages;
