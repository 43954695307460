import React from 'react';
import { StyledSocialNetworks, StyledSocialItem, StyledSocialIcon } from './styles';
import InstagramIcon from '../../../../assets/icons/social_network/instagram.svg';
import FacebookIcon from '../../../../assets/icons/social_network/facebook.svg';
import WhatsAppIcon from '../../../../assets/icons/social_network/whatsapp.svg';
import YoutubeIcon from '../../../../assets/icons/social_network/youtube.svg';

const Item = ({ href, icon, alt }) => (
	<StyledSocialItem href={href} target="blank">
		<StyledSocialIcon src={icon} alt={alt} />
	</StyledSocialItem>
);
const SocialNetworks = () => (
	<StyledSocialNetworks>
		<Item
			href="https://api.whatsapp.com/send?phone=554598470099"
			icon={WhatsAppIcon}
			alt="whatsapp"
		/>
		<Item
			href="https://www.instagram.com/ebinternationaltravelgroup/"
			icon={InstagramIcon}
			alt="instagram"
		/>
		<Item
			href="https://www.facebook.com/ebinternationaltravelgroup"
			icon={FacebookIcon}
			alt="facebook"
		/>
		<Item
			href="https://www.youtube.com/channel/UCP9APeo0ejLSRCGl5n_yalg"
			icon={YoutubeIcon}
			alt="youtube"
		/>
	</StyledSocialNetworks>
);

export default SocialNetworks;
