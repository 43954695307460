/*----- Actions -----*/
import {
	GET_MY_RESERVATIONS,
	GET_MY_RESERVATIONS_SUCCESS,
	GET_MY_RESERVATIONS_FAILED,
	FILTER_RESERVATIONS
} from '../actions/myReservationsActions';
import { addSearchQuery } from '../../../services/SearchService';
import { parseMetadata } from '../../../utilities/queryString';

const initialState = {
	data: [],
	isLoading: false,
	error: '',
	metadata: {},
	success: false
};

export default function myReservations(state = initialState, action) {
	switch (action.type) {
		case GET_MY_RESERVATIONS:
			return {
				...state,
				data: [],
				isLoading: true,
				error: '',
				success: false
			};
		case GET_MY_RESERVATIONS_SUCCESS:
			let metadata = parseMetadata(action.payload.metadata);

			const emptyQuery = q => {
				for (let _ in q) {
					return false;
				}
				return true;
			};

			if (!emptyQuery) addSearchQuery(metadata.query);

			return {
				...state,
				data: action.payload.data,
				metadata: metadata,
				isLoading: false,
				success: true
			};
		case GET_MY_RESERVATIONS_FAILED:
			return {
				...state,
				isLoading: false,
				error: action.payload,
				success: false
			};
		case FILTER_RESERVATIONS:
			return {
				...state,
				data: action.payload,
				isLoading: false
			};
		default:
			return state;
	}
}
