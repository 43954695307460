import React, { useEffect } from 'react';
import { StyledMain } from './styles';
import { connect } from 'react-redux';
import { registerConfirm } from '../../../authentication/actions/registerConfirmActions';
import SuccessResponse from './SuccessResponse';
import FailedResponse from './FailedResponse';
import ErrorResponse from './ErrorResponse';
import Loading from './Loading';
import Content from './Content';

const ConfirmRegister = ({ registerConfirm, history }) => {
	useEffect(() => {
		const token = new URLSearchParams(history.location.search).get('token');
		registerConfirm(token);
	}, [history.location.search, registerConfirm]);

	return (
		<StyledMain>
			<Content
				Loading={Loading}
				Success={SuccessResponse}
				Failed={FailedResponse}
				Error={ErrorResponse}
			/>
		</StyledMain>
	);
};

function mapDispatchToProps(dispatch) {
	return {
		registerConfirm: payload => registerConfirm(dispatch, payload)
	};
}

export default connect(null, mapDispatchToProps)(ConfirmRegister);
