import { Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonCheckoutContainer } from '../../../components/containers/CheckoutContainer';
import Button from '../../../components/button/DefaultButton';
const AddToCart = ({
	checkoutHandleSubmit,
	service,
	history,
	form,
	id,
	addToCartHandler,
	continueReservation
}) => {
	const { t } = useTranslation();
	return (
		<Row type="flex" justify="center">
			<ButtonCheckoutContainer>
				<Button
					htmlType="button"
					content={t('checkout.continueReservation')}
					onClick={() => continueReservation()}
				/>
			</ButtonCheckoutContainer>
			<ButtonCheckoutContainer>
				<Button
					content={t('checkout.addToCart')}
					onClick={event => {
						checkoutHandleSubmit(
							event,
							form,
							history,
							service,
							id,
							undefined,
							addToCartHandler
						);
					}}
					secondary
				/>
			</ButtonCheckoutContainer>
		</Row>
	);
};

export default AddToCart;
