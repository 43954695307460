export const groupByRoomType = rates => {
	let roomType = {};

	rates.forEach(room => {
		if (!roomType[room.room_type.name]) {
			roomType[room.room_type.name] = [];
		}
		roomType[room.room_type.name].push(room);
	});

	return roomType;
};

export const getMinorRoom = rates => {
	let minorRate = rates[0];
	rates.forEach(rate => {
		if (rate.amount < minorRate.amount) {
			minorRate = rate;
		}
	});
	return minorRate;
};
