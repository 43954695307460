import { createSelector } from 'reselect';

export const filterPayload = state => state.home.filter;
export const location = state => state.home.filter.location;
export const entryDate = state => state.home.filter.entryDate;
export const exitDate = state => state.home.filter.exitDate;
export const rooms = state => state.home.filter.rooms;
export const notificationData = state => state.home.filter.notification;

export const locationSelector = createSelector(location, location => location);

export const entryDateSelector = createSelector(entryDate, entryDate => entryDate);

export const exitDateSelector = createSelector(exitDate, exitDate => exitDate);

export const filterPayloadSelector = createSelector(
	filterPayload,
	filterPayload => filterPayload
);

export const roomsSelector = createSelector(rooms, rooms => rooms);
