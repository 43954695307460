import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

/*----- Components -----*/
import ReservationPanelItem from './ReservationPanelItem';
import ReservationPanelItemIcon from './ReservationPanalItemIcon';

const ReservationPanelDetail = ({ items, withTitle = true }) => {
	const { t } = useTranslation();
	const getContent = () => {
		return items.map((item, idx) => (
			<ReservationPanelItemIcon key={idx} icon={item.icon} description={item.text} />
		));
	};

	return (
		<ReservationPanelItem label={t('checkout.detail')} withTitle={withTitle}>
			{getContent()}
		</ReservationPanelItem>
	);
};
export default ReservationPanelDetail;

/*----- PropTypes -----*/
ReservationPanelDetail.propTypes = {
	items: PropTypes.array.isRequired
};

ReservationPanelDetail.defaultProps = {
	items: []
};
