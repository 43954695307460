/*----- Core -----*/
import React from 'react';
import Moment from 'moment';

/*----- Exports -----*/
export const handleEnableAllDates = current => false;

export const handleDisableDate = current => {
	return current.isBefore(Moment().format('YYYY-MM-DD'));
};

export const handleDisableBeforeDate = (current, otherDate) => {
	return current.isBefore(otherDate);
};

export const handleDisableBeforeToday = current =>
	current.isAfter(Moment().format('YYYY-MM-DD'));

export const handleDisabledAfterDate = (current, otherDate) => {
	return current.isAfter(otherDate);
};

export const handlerBetweenDates = (current, initDate, endDate) => {
	return current.isBetween(initDate, endDate);
};

export const handleDateRender = (current, Component) => {
	const style = {};
	const today = current.format('YYYY-MM-DD') === Moment().format('YYYY-MM-DD');
	return (
		<Component>
			<div className="ant-calendar-date" style={style}>
				{current.date()}
				{today ? <div className="today">HOY</div> : null}
			</div>
		</Component>
	);
};
