import React, { forwardRef } from 'react';
import Label from '../form/Label';
import { InputContainer } from './styles';

/*----- Components -----*/
import { Input } from 'antd';
const { TextArea } = Input;

const CustomTextArea = (
	{ placeholder, name, onChange, withoutLabel, className, value, rows, margin },
	ref
) => {
	return (
		<InputContainer className={className} margin={margin}>
			{withoutLabel ? '' : <Label htmlFor={name} name={name} />}
			<TextArea
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				rows={rows}
				ref={ref}
			/>
		</InputContainer>
	);
};

export default forwardRef(CustomTextArea);
