import React from 'react';
import { useTranslation } from 'react-i18next';
import GenericFeedback from './GenericFeedback';
import { connect } from 'react-redux';
import { closeModal } from '../../../authentication/actions/loginModalActions';
import { deleteRecovery } from '../../../authentication/actions/recoveryActions';
import { email } from '../../../authentication/selectors/registerSelector';

const SuccessResponseRegister = ({
	closeModal,
	deleteRecovery,
	email,
	closeModalMobile
}) => {
	const { t } = useTranslation();

	const closeFunction = () => {
		closeModal();
		deleteRecovery();
		if (closeModalMobile) closeModalMobile();
	};

	return (
		<GenericFeedback
			closeModal={closeFunction}
			title={t('authentication.register.feedback.success.title')}
			content={`${t('authentication.register.feedback.success.content')}${email}`}
		/>
	);
};

const mapStateToProps = state => {
	return {
		email: email(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		closeModal: payload => closeModal(dispatch, payload),
		deleteRecovery: payload => deleteRecovery(dispatch, payload)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SuccessResponseRegister);
