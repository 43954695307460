import React from 'react';
import { StyledDiv } from './styles';
import { Icon } from 'antd';

const Loading = () => {
	return (
		<StyledDiv>
			<Icon type="loading" style={{ fontSize: '50px' }} />
		</StyledDiv>
	);
};

export default Loading;
