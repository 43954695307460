import styled from 'styled-components';

import Device from '../../../../commons/Device';
import Color from '../../../../commons/Color';
import Title from '../../../../components/text/Title';

export const TitleContainer = styled.div`
	border-bottom: 1px solid #66666669;
	padding-bottom: 17px;
`;

export const StyledTitle = styled(Title)`
	margin: 0px;
	font-size: 28px;

	@media ${Device.xs} {
		font-size: 20px;
	}
`;

export const StyledSubtitle = styled(Title)`
	font-size: 16px;
	color: ${Color.text};
	margin: 0px;

	@media ${Device.xs} {
		padding: 5px 0px;
		font-size: 12px;
	}
`;

export const SubtitleContainer = styled.div`
	display: flex;
	align-items: center;
	padding-top: 5px;

	@media ${Device.xs} {
		flex-direction: column;
		align-items: start;
	}
`;
