import React from 'react';
import { Form } from 'antd';
import {
	StyledItem,
	StyledForm,
	StyledDefaultButton,
	TwoFields,
	StyledItemTwo,
	StyledCol
} from './styles';
import { useTranslation } from 'react-i18next';
import { StyledInput, StyledInputTwo } from '../../../components/form/styles';

const RegisterForm = ({ form, onSubmit, isLoading }) => {
	const { t } = useTranslation();
	const {
		getFieldDecorator,
		validateFields,
		getFieldsError,
		getFieldsValue,
		getFieldValue
	} = form;

	const handleSubmit = e => {
		e.preventDefault();
		validateFields();
		const validationErrors = Object.values(getFieldsError());
		if (validationErrors.some(e => e !== undefined)) return;
		onSubmit(getFieldsValue());
	};

	const validatePassword = (rule, value, callback) => {
		if (value && value !== getFieldValue('password')) {
			callback(t('authentication.wrongPassword'));
		} else {
			callback();
		}
	};

	return (
		<StyledForm onSubmit={handleSubmit}>
			<StyledItem>
				{getFieldDecorator('name', {
					rules: [{ required: true, message: t('authentication.emptyField') }]
				})(
					<StyledInput
						type="text"
						name="name"
						placeholder={t('authentication.register.form.nameAndSurname')}
						withoutLabel
					/>
				)}
			</StyledItem>
			<StyledItem>
				{getFieldDecorator('email', {
					rules: [
						{
							required: true,
							message: t('authentication.wrongEmail'),
							pattern: '^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.com)$'
						}
					]
				})(
					<StyledInput
						type="email"
						name="email"
						placeholder={t('authentication.register.form.email')}
						withoutLabel
					/>
				)}
			</StyledItem>
			<TwoFields>
				<StyledCol lg={12} xs={24}>
					<StyledItemTwo>
						{getFieldDecorator('password', {
							rules: [{ required: true, message: t('authentication.emptyField') }]
						})(
							<StyledInputTwo
								type="password"
								name="password"
								placeholder={t('authentication.register.form.password')}
								withoutLabel
								left
							/>
						)}
					</StyledItemTwo>
				</StyledCol>
				<StyledCol lg={12} xs={24}>
					<StyledItemTwo>
						{getFieldDecorator('repet_password', {
							rules: [
								{ required: true, message: t('authentication.emptyField') },
								{ validator: validatePassword }
							]
						})(
							<StyledInputTwo
								type="password"
								name="repet_password"
								placeholder={t('authentication.register.form.repeatPassword')}
								withoutLabel
							/>
						)}
					</StyledItemTwo>
				</StyledCol>
			</TwoFields>
			<StyledItem>
				{getFieldDecorator('trade_name', {
					rules: [{ required: true, message: t('authentication.emptyField') }]
				})(
					<StyledInput
						type="string"
						name="trade_name"
						placeholder={t('authentication.register.form.trade_name')}
						withoutLabel
					/>
				)}
			</StyledItem>
			<StyledItem>
				{getFieldDecorator('legal_name', {
					rules: [{ required: true, message: t('authentication.emptyField') }]
				})(
					<StyledInput
						type="string"
						name="legal_name"
						placeholder={t('authentication.register.form.legal_name')}
						withoutLabel
					/>
				)}
			</StyledItem>

			<StyledItem>
				{getFieldDecorator('file', {
					rules: [{ required: true, message: t('authentication.emptyField') }]
				})(
					<StyledInput
						type="string"
						name="file"
						placeholder={t('authentication.register.form.file')}
						withoutLabel
					/>
				)}
			</StyledItem>

			<StyledItem>
				{getFieldDecorator(
					'cuit',
					{}
				)(
					<StyledInput
						type="string"
						name="cuit"
						placeholder={t('authentication.register.form.cuit')}
						withoutLabel
					/>
				)}
			</StyledItem>
			<TwoFields>
				<StyledCol lg={12} xs={24}>
					<StyledItem>
						{getFieldDecorator('address', {
							rules: [{ required: true, message: t('authentication.emptyField') }]
						})(
							<StyledInputTwo
								type="string"
								name="address"
								placeholder={t('authentication.register.form.address')}
								withoutLabel
								left
							/>
						)}
					</StyledItem>
				</StyledCol>
				<StyledCol lg={12} xs={24}>
					<StyledItem>
						{getFieldDecorator('city', {
							rules: [{ required: true, message: t('authentication.emptyField') }]
						})(
							<StyledInputTwo
								type="string"
								name="city"
								placeholder={t('authentication.register.form.city')}
								withoutLabel
							/>
						)}
					</StyledItem>
				</StyledCol>
			</TwoFields>
			<StyledDefaultButton
				type="primary"
				loading={isLoading}
				htmlType="submit"
				fontSize="11"
				margin="40px 0px 0px 0px"
				content={t('authentication.register.button')}
			/>
		</StyledForm>
	);
};

export default Form.create({ name: 'register_form' })(RegisterForm);
