import styled from 'styled-components';
import Color from '../../../../commons/Color';

export const StyledSection = styled.section`
	width: 100%;
	padding: 0 1rem;
`;

export const StyledEmpty = styled.h4`
	font-family: 'OpenSans';
	font-size: 25px;
	color: ${Color.text};
	padding: 2rem 3rem 2rem 3rem;
	text-align: center;
`;

export const StyledEmptyLogo = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 1rem;
	& > svg:first-child {
		width: 100px;
		height: 100px;
		path {
			fill: lightgray;
		}
	}
`;
