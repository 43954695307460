export const getConfirmData = (dataCheckout, t, type = '') => {
	let info = type ? dataCheckout[type] : dataCheckout;
	let data = [
		{
			label: t('checkout.flight.time.arrival'),
			value: info.arrival_time
		}
	];

	if (info.is_airport === 'true' || info.is_airport === true) {
		data.unshift({
			label: t('checkout.flight.time.departure'),
			value: info.departure_time
		});
		data.push(
			...[
				{
					label: t('checkout.flight.airline.title'),
					value: info.airline
				},
				{
					label: t('checkout.flight.flycode.title'),
					value: info.fly_code
				}
			]
		);
	} else {
		data.push({
			label: t('checkout.business.title'),
			value: info.company
		});
	}

	return data;
};
