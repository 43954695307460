import BASE_URL from '../BaseUrl';

import { getAuthToken } from '../../sessions/loginSession';
import requestDecorator from '../../decorators/RequestDecorator';
import i18n from 'i18next';

export function* reserveCart(params, cbResponse) {
	yield requestDecorator(
		function* (params) {
			return yield fetch(`${BASE_URL}/cart`, {
				method: 'POST',
				body: JSON.stringify(params),
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getAuthToken()}`,
					'Accept-Language': `${i18n.language}`
				}
			});
		},
		params,
		cbResponse
	);
}
