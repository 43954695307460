import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	StyledResultContainer,
	FilterResultContainer,
	StyledCardContainer,
	TitleSection
} from './styles';

const ResultContainer = ({
	breadcrumbs,
	filter,
	resultTitle,
	hideTitleContent,
	content
}) => {
	const { t } = useTranslation();
	return (
		<>
			<StyledResultContainer type="flex" justify="center">
				<FilterResultContainer xs={0} md={10} lg={6} xl={5} xxl={4}>
					{breadcrumbs}
					<TitleSection content={`${t('general.filters')}:`} />
					{filter}
				</FilterResultContainer>
				<StyledCardContainer xs={24} md={14}>
					{resultTitle ? (
						resultTitle
					) : !hideTitleContent ? (
						<TitleSection content={`${t('general.results')}:`} />
					) : (
						''
					)}
					{content}
				</StyledCardContainer>
			</StyledResultContainer>
		</>
	);
};

export default ResultContainer;
