export const driver = window.localStorage;

export const addCheckoutActivity = (id, data) => {
	let activities = JSON.parse(driver.getItem('checkout_activity')) || {};

	if (activities[id]) {
		activities[id] = { ...activities[id], ...data };
	} else {
		activities[id] = data;
	}

	driver.setItem('checkout_activity', JSON.stringify(activities));
};

export const removeCheckoutActivity = id => {
	const activities = JSON.parse(driver.getItem('checkout_activity')) || {};
	delete activities[id];
	driver.setItem('checkout_activity', JSON.stringify(activities));
};

export const getCheckoutActivity = id => {
	const activities = JSON.parse(driver.getItem('checkout_activity'));
	return activities ? activities[id] : null;
};
