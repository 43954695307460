/*----- Core -----*/
import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

/*----- Components -----*/
import PackageCard from '../../../components/card/PackageCard';
import { packagesData } from './FeatureData';

/*----- Ant design -----*/
import { Row, Col } from 'antd';
import Title from '../../../components/text/Title';

/*----- Styles -----*/
import Styles from '../HomeStyles';

/*----- Actions -----*/
import { toggleModal } from '../../app/AppActions';

function FeaturedPackages({ toggleModal }) {
	const { t } = useTranslation();

	const renderPackages = () => {
		return packagesData.map((packageCard, i) => {
			return (
				<Col xs={24} sm={12} lg={6} className="card-container" key={i}>
					<PackageCard
						title={t(packageCard.name)}
						imageUrl={packageCard.image}
						services={packageCard.services}
						onClick={() =>
							toggleModal('Paquete', t(packageCard.name), t(packageCard.description))
						}
					/>
				</Col>
			);
		});
	};
	return (
		<Styles>
			<Row className="featured-card__title">
				<Title content={t('packages.mainPackages')} size={2} color={'secondary'} />
			</Row>
			<Row type="flex" justify="center" className="card__list-container">
				{renderPackages()}
			</Row>
		</Styles>
	);
}
function mapStateToProps(state) {
	return {};
}

function mapDispatchToProps(dispatch) {
	return {
		toggleModal: (type, name, description) =>
			toggleModal(dispatch, type, name, description)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedPackages);
