export const SET_HOTEL_ROOM = 'SET_HOTEL_ROOM';
export const SELECT_ROOM = 'SELECT_ROOM';
export const CLEAN_HOTEL_ROOM = 'CLEAN_HOTEL_ROOM';

export const setHotelRoom = (dispatch, params) =>
	dispatch({ type: SET_HOTEL_ROOM, payload: params });

export const selectRoom = (dispatch, params) => {
	dispatch({ type: SELECT_ROOM, payload: true });
	setTimeout(() => {
		dispatch({ type: SELECT_ROOM, payload: false });
	}, 1000);
};

export const cleanHotelRoom = (dispatch, params) =>
	dispatch({ type: CLEAN_HOTEL_ROOM, payload: params });
