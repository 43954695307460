import hotelIguazu from '../../../../assets/images/hotels/hotelIguazu.jpg';
import hotelPanoramic from '../../../../assets/images/hotels/hotelPanoramic.jpg';
import hotelMercuri from '../../../../assets/images/hotels/hotelMercuri.jpg';
import hotelPirayu from '../../../../assets/images/hotels/hotelPirayu.jpg';
import hotelTourbillon from '../../../../assets/images/hotels/hotelTourbillon.jpg';
import hotelComplejo from '../../../../assets/images/hotels/hotelComplejo.jpg';
import hotelAlexander from '../../../../assets/images/hotels/hotelAlexander.jpg';
import hotelSol from '../../../../assets/images/hotels/hotelSol.jpg';
import hotelMerit from '../../../../assets/images/hotels/hotelMerit.jpg';
import hotelLaCantera from '../../../../assets/images/hotels/hotelLaCantera.jpg';
import hotelYvy from '../../../../assets/images/hotels/hotelYvy.jpg';
import hotelGolden from '../../../../assets/images/hotels/hotelGolden.png';
import hotelBella from '../../../../assets/images/hotels/hotelBella.jpg';
import hotelContinetal from '../../../../assets/images/hotels/hotelContinetal.jpg';
import hotelNadai from '../../../../assets/images/hotels/hotelNadai.jpg';
import hotelMabu from '../../../../assets/images/hotels/hotelMabu.jpg';
import hotelWish from '../../../../assets/images/hotels/hotelWish.jpg';
import hotelWyndham from '../../../../assets/images/hotels/hotelWyndham.jpg';
import hotelAguas from '../../../../assets/images/hotels/hotelAguas.jpg';
import hotelCanzi from '../../../../assets/images/hotels/hotelCanzi.jpg';
import hotelCataratas from '../../../../assets/images/hotels/hotelCataratas.jpg';
import hotelDelRey from '../../../../assets/images/hotels/hotelDelRey.jpg';
import hotelManaca from '../../../../assets/images/hotels/hotelManaca.jpg';
import hotelPietro from '../../../../assets/images/hotels/hotelPietro.jpg';
import hotelFoz from '../../../../assets/images/hotels/hotelFoz.jpg';
import hotelLuz from '../../../../assets/images/hotels/hotelLuz.jpg';
import hotelCassino from '../../../../assets/images/hotels/hotelCassino.png';
import hotelMirante from '../../../../assets/images/hotels/hotelMirante.jpg';
import hotelDanInn from '../../../../assets/images/hotels/hotelDanInn.jpg';
import hotelRaicesEsturion from '../../../../assets/images/hotels/hotelRaicesEsturion.jpg';
import hotelIguazuExpress from '../../../../assets/images/hotels/hotelIguazuExpress.jpg';
import hotelBelmond from '../../../../assets/images/hotels/hotelBelmond.jpg';
import hotelFallsGali from '../../../../assets/images/hotels/hotelFallsGali.jpg';
import hotelLoiSuites from '../../../../assets/images/hotels/hotelLoiSuites.jpg';
import hotelMarcopolo from '../../../../assets/images/hotels/hotelMarcopolo.jpg';
import hotelOrquideas from '../../../../assets/images/hotels/hotelOrquideas.jpg';
import hotelAmerianPortalDelIguazu from '../../../../assets/images/hotels/hotelAmerianPortalDelIguazu.jpg';

import hotelBourbon from '../../../../assets/images/hotels/hotelBourbon.jpg';
import hotelNacional from '../../../../assets/images/hotels/hotelNacional.jpg';
import hotelGrandCrucero from '../../../../assets/images/hotels/hotelGrandCrucero.jpg';
import hotelFallsIguazu from '../../../../assets/images/hotels/hotelFallsIguazu.jpg';
import hotelSuica from '../../../../assets/images/hotels/suicaHotelResortIguazu.jpg';
import hotelMelia from '../../../../assets/images/hotels/hotelMelia.jpeg';

import hotelRoom1 from '../../../../assets/images/hotels/detail/hotel1.png';
import hotelRoom2 from '../../../../assets/images/hotels/detail/hotel3.png';

import { destinations } from '../../../../utilities/constants';

export const hotelsData = [
	{
		name: 'Suíça Hotel & Resort',
		image: hotelSuica,
		star: 4,
		description: 'hotels.data.hotelSuica.description'
	},
	{
		name: 'Bourbon Cataratas do Iguaçu Resort',
		image: hotelBourbon,
		star: 5,
		description: 'hotels.data.hotelBourbon.description'
	},
	{
		name: 'Nacional Inn',
		image: hotelNacional,
		star: 3,
		description: 'hotels.data.hotelNacional.description'
	},
	{
		name: 'Falls Iguazú',
		image: hotelFallsIguazu,
		star: 5,
		description: 'hotels.data.hotelFallsIguazu.description'
	},
	{
		name: 'Grand Crucero Iguazú Hotel',
		image: hotelGrandCrucero,
		star: 4,
		description: 'hotels.data.hotelGrandCrucero.description'
	},
	{
		name: 'Grand Meliá Iguazú',
		image: hotelMelia,
		star: 4,
		description: 'hotels.data.hotelMelia.description'
	},
	{
		name: 'Iguazu Grand Resort Spa & Casino',
		image: hotelIguazu,
		star: 5,
		description: 'hotels.data.hotelIguazuCasino.description'
	},
	{
		name: 'Panoramic Hotel Iguazu',
		image: hotelPanoramic,
		star: 5,
		description: 'hotels.data.hotelPanoramic.description'
	},
	{
		name: 'Mercure Iguazu Hotel Iru',
		image: hotelMercuri,
		star: 4,
		description: 'hotels.data.hotelMercuri.description'
	},
	{
		name: 'Pirayú Hotel & Lodge',
		image: hotelPirayu,
		star: 3,
		description: 'hotels.data.hotelPirayu.description'
	},
	{
		name: 'Gran Hotel Tourbillon',
		image: hotelTourbillon,
		star: 4,
		description: 'hotels.data.hotelTourbillon.description'
	},
	{
		name: 'Americano Complejo Turístico',
		image: hotelComplejo,
		star: 3,
		description: 'hotels.data.hotelComplejo.description'
	},
	{
		name: 'Alexander Hotel',
		image: hotelAlexander,
		star: 3,
		description: 'hotels.data.hotelAlexander.description'
	},
	{
		name: 'Hotel Sol Cataratas',
		image: hotelSol,
		star: 3,
		description: 'hotels.data.hotelSol.description'
	},
	{
		name: 'Merit Iguazú Hotel',
		image: hotelMerit,
		star: 3,
		description: 'hotels.data.hotelMerit.description'
	},
	{
		name: 'La Cantera Lodge de Selva',
		image: hotelLaCantera,
		star: 4,
		description: 'hotels.data.hotelLaCantera.description'
	},
	{
		name: 'Yvy Hotel de la Selva',
		image: hotelYvy,
		star: 4,
		description: 'hotels.data.hotelYvy.description'
	},
	{
		name: 'Golden Park International Foz',
		image: hotelGolden,
		star: 5,
		description: 'hotels.data.hotelGolden.description'
	},
	{
		name: 'Bella Italia  Hotel & Eventos',
		image: hotelBella,
		star: 4,
		description: 'hotels.data.hotelBella.description'
	},
	{
		name: 'Hotel Continental 4Soul',
		image: hotelContinetal,
		star: 4,
		description: 'hotels.data.hotelContinetal.description'
	},
	{
		name: 'Nadai Confort Hotel e Spa',
		image: hotelNadai,
		star: 4,
		description: 'hotels.data.hotelNadai.description'
	},
	{
		name: 'Mabu Thermas Grand Resort',
		image: hotelMabu,
		star: 5,
		description: 'hotels.data.hotelMabu.description'
	},
	{
		name: 'Wish Foz do Iguaçu',
		image: hotelWish,
		star: 5,
		description: 'hotels.data.hotelWish.description'
	},
	{
		name: 'Wyndham Golden Foz Suítes',
		image: hotelWyndham,
		star: 5,
		description: 'hotels.data.hotelWyndham.description'
	},
	{
		name: 'Águas do Iguaçu Hotel Centro',
		image: hotelAguas,
		star: 3,
		description: 'hotels.data.hotelAguas.description'
	},
	{
		name: 'Canzi Cataratas Hotel',
		image: hotelCanzi,
		star: 3,
		description: 'hotels.data.hotelCanzi.description'
	},
	{
		name: 'Mirante Hotel',
		image: hotelMirante,
		star: 3,
		description: 'hotels.data.hotelMirante.description'
	},
	{
		name: 'Cataratas Park Hotel',
		image: hotelCataratas,
		star: 3,
		description: 'hotels.data.hotelCataratas.description'
	},
	{
		name: 'Del Rey Quality Hotel',
		image: hotelDelRey,
		star: 3,
		description: 'hotels.data.hotelDelRey.description'
	},
	{
		name: 'Hotel Manacá 4Soul',
		image: hotelManaca,
		star: 3,
		description: 'hotels.data.hotelManaca.description'
	},
	{
		name: 'Hotel Cassino',
		image: hotelCassino,
		star: 3,
		description: 'hotels.data.hotelCassino.description'
	},
	{
		name: 'Pietro Angelo Hotel',
		image: hotelPietro,
		star: 3,
		description: 'hotels.data.hotelPietro.description'
	},
	{
		name: 'Foz Plaza Hotel',
		image: hotelFoz,
		star: 3,
		description: 'hotels.data.hotelFoz.description'
	},
	{
		name: 'Luz Hotel',
		image: hotelLuz,
		star: 3,
		description: 'hotels.data.hotelLuz.description'
	},
	{
		name: 'Amerian Portal del Iguazú Hotel ',
		image: hotelAmerianPortalDelIguazu,
		star: 5,
		description: 'hotels.data.hotelAmerianPortalDelIguazu.description'
	},
	{
		name: 'Loi Suites Iguazú Hotel',
		image: hotelLoiSuites,
		star: 4,
		description: 'hotels.data.hotelLoiSuites.description'
	},
	{
		name: 'Raíces Esturión Iguazú',
		image: hotelRaicesEsturion,
		star: 4,
		description: 'hotels.data.hotelRaicesEsturion.description'
	},
	{
		name: 'Orquideas Hotel & Cabañas',
		image: hotelOrquideas,
		star: 4,
		description: 'hotels.data.hotelOrquideas.description'
	},
	{
		name: 'Marcopolo Suites',
		image: hotelMarcopolo,
		star: 3,
		description: 'hotels.data.hotelMarcopolo.description'
	},
	{
		name: 'Belmond Hotel das Cataratas',
		image: hotelBelmond,
		star: 5,
		description: 'hotels.data.hotelBelmond.description'
	},
	{
		name: 'Falls Galli Hotel',
		image: hotelFallsGali,
		star: 4,
		description: 'hotels.data.hotelFallsGali.description'
	},
	{
		name: 'Hotel Dan Inn Foz do Iguaçu',
		image: hotelDanInn,
		star: 3,
		description: 'hotels.data.hotelDanInn.description'
	},
	{
		name: 'Iguassu Express Hotel',
		image: hotelIguazuExpress,
		star: 3,
		description: 'hotels.data.hotelIguazuExpress.description'
	}
];

export const hotelDataSelect = () => {
	let data = hotelsData.map((hotel, idx) => ({
		value: idx,
		label: hotel.name
	}));

	return {
		Ciudades: destinations,
		Hoteles: data
	};
};

export const hotelSearchFormSelect = () => {
	let data = hotelsData.map((hotel, idx) => ({
		value: idx,
		label: hotel.name
	}));

	return destinations.concat(data);
};

export const detailData = {
	id: 'HT|000002-971056',
	name: 'VIK Arena Blanca',
	stars: 4,
	category: '4 *',
	location: {
		code: 'PUJ',
		name: 'Bàvaro',
		zone: 'Villa Yolanda, Foz do Iguaçu'
	},
	address: 'Avenida das Cataratas 2345',
	phone: '809-221-6640',
	url: 'http://www.vikhotels.com',
	position: {
		latitude: 18.709667,
		longitude: -68.44255
	},
	descriptions:
		'A sólo 12,5 km de las Cataratas del Iguazú, elegida como una de las 7 Nuevas Maravillas de la Naturaleza – y a 10 km del Aeropuerto Internacional de Foz do Iguaçu, el Bourbon Cataratas do Iguaçu ocupa un área total de 245 mil m2. Ofrece completa estructura para recreación y negocios en perfecta armonía con la naturaleza exuberante de la región.',
	images: [
		'\\\\Servidor\\logos\\00000000076VIK76_ARENA_BLANCABB-DELUXE_(RENOVATED)_GARDEN_VIEW_ROOM_.JPG',
		'\\\\Servidor\\logos\\00000000075VIK61_ARENA_D-POOL_&_THE_CORAL_REEF_VIEW.JPG',
		'\\\\Servidor\\logos\\00000000074VIK40_ARENA_BLANCA_DOUBLE_SEA_VIEW_ROOM_STANDARD_ROOM-NEW.JPG',
		'\\\\Servidor\\logos\\00000000073VIK4.JPG'
	],
	rates: [
		{
			id: '478771e9e9018ea75215e5d22969d19c',
			date_from: '10-11-2019',
			date_to: '20-11-2019',
			total_price: 466.45,
			total_taxes: 5.55,
			currency: 'USD',
			url: hotelRoom1,
			type: 'Standard',
			rooms: [
				{
					id: 'HT|000002-971056|00000024683|SGL.CHD|USD',
					rph: '1',
					type: 'SGL.CHD',
					availability: 3,
					currency: 'USD',
					description:
						'Habitación Individual con Un Menor Superior Garden View/all Inclusive',
					base_price: 466.45,
					taxes: [
						{
							type: 'GRAVADO',
							value: 5.55
						}
					],
					per_night: [],
					cancellation_policy: [],
					requested: {
						adults_number: 1,
						children_ages: [5]
					}
				}
			]
		},
		{
			id: '144cad9479f6904fd357d1538a785f79',
			date_from: '2019-11-10',
			date_to: '2019-11-20',
			total_price: 553.06,
			total_taxes: 6.94,
			currency: 'USD',
			url: hotelRoom2,
			type: 'Superior vista jardin',
			rooms: [
				{
					id: 'HT|000002-971056|00000024685|SGL.CHD|USD',
					rph: '1',
					type: 'SGL.CHD',
					availability: 3,
					currency: 'USD',
					description: 'Habitación Individual con Un Menor Superior Ocean View',
					base_price: 553.06,
					taxes: [
						{
							type: 'GRAVADO',
							value: 6.94
						}
					],
					per_night: [],
					cancellation_policy: [],
					requested: {
						adults_number: 1,
						children_ages: [5]
					}
				}
			]
		}
	]
};
