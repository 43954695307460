/*----- Imports -----*/
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
/*----- Commons -----*/
import OpenSans from '../../commons/mixins/fonts/OpenSans';

/*----- Styles -----*/
const StyledLabel = styled.label`
	${OpenSans(10, 400, 'black')}
	color: ${props => props.color};
	font-weight: ${props => props.weight};
	margin: 0;
	margin-bottom: 10px;
	display: block;
	text-transform: capitalize;
	font-size: ${props => props.fontSize};
	position: sticky;
    width: max-content;
`;

/*----- Component -----*/
const Label = ({ name, fontSize, color, weight, minHeight }) => (
	<StyledLabel
		fontSize={fontSize}
		color={color}
		weight={weight}
		className="custom-label"
	>
		{' '}
		{name}{' '}
	</StyledLabel>
);
export default Label;

/*----- PropTypes -----*/
Label.defaultProptypes = {
	fontSize: '10px',
	color: 'black',
	weight: 600
};

Label.propTypes = {
	name: PropTypes.string,
	fontSize: PropTypes.string,
	color: PropTypes.string,
	weight: PropTypes.number
};
