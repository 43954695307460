import styled from 'styled-components';
import Device from '../../../../commons/Device';
import SearchForms from '../../../../components/form/SearchForms';

export const StyledSearchForm = styled(SearchForms)`
	.ant-form .ant-row-flex .ant-col {
		.ant-form-item-children > div {
			margin-top: 5px !important;
			@media ${Device.xs} {
				margin-top: 16px !important;
			}
		}
	}

	@media ${Device.xs} {
		.ant-form .ant-row-flex > .ant-col:first-child {
			.ant-form-item-children > div {
				margin-top: 5px !important;
			}
		}
	}
`;

export const StyledOption = styled.span`
	margin: 18px 0px 0px 18px;
	font-family: OpenSansBold;
	color: #000033;
`;
