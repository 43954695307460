import styled from 'styled-components';

/*----- Components -----*/
import DefaultButton from '../../button/DefaultButton';
import { Row, Col } from 'antd';
import Device from '../../../commons/Device';
import Text from '../../text/Text';

export const FormRow = styled(Row)`
	padding: 5px 10px;

	// Hack para no mostrar mensaje de error en los inputs de Ant.
	.ant-form-explain {
		display: none;
	}
`;

export const Button = styled(DefaultButton)`
	padding-top: 25px;
	@media ${Device.xs} {
		margin-top: 24px;
		padding: 0px;
		align-items: center;
	}
`;

export const StyledCol = styled(Col)`
	@media ${Device.xs} {
		margin: 0px;
		justify-content: center;
		max-width: unset;
		.custom-label {
			margin: 0px;
		}
	}
`;

export const StyledTitle = styled(Text)`
	font-size: 20px;
	text-align: left;
	margin: 18px 0px 0px 18px;

	@media ${Device.xs} {
		text-align: center;
		font-size: 18px;
		margin: 0px;
	}
`;

export const FormRowSwitch = styled(Row)`
	padding: 10px 10px 0px 10px;
	display: ${props => (props.display ? 'flex' : 'none')} !important;
	.ant-form-explain {
		display: none;
	}

	@media ${Device.xs} {
		&.ant-row-flex {
			justify-content: center;
		}
	}
`;
