/*----- Core -----*/
import React from 'react';
import PropTypes from 'prop-types';

/*----- Assets -----*/
import UserIcon from '../../../../assets/icons/user.svg';

/*----- Components -----*/
import Label from '../../Label';

/*----- Styles -----*/
import { RoomResults, Container, PeopleNumberContainer, Img } from './styles';

const Results = ({ people, onClick, label, children }) => (
	<RoomResults onClick={onClick}>
		<Label name={label} />
		<Container>
			{children}

			<Img src={UserIcon} alt="Person icon" />

			<PeopleNumberContainer>{people}</PeopleNumberContainer>
		</Container>
	</RoomResults>
);

export default Results;

/*----- PropTypes -----*/
Results.propTypes = {
	people: PropTypes.number.isRequired,
	onClick: PropTypes.func.isRequired,
	label: PropTypes.string.isRequired,
	children: PropTypes.node
};
