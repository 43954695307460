/*----- Core -----*/
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
/*----- Assets -----*/
import FallsVideo from '../../assets/videos/falls.mp4';

/*----- Components -----*/
import HeroWithVideo from '../../components/hero/HeroWithVideo';
import ConsultForm from '../../components/form/ConsultForm';

/*----- Styles -----*/
import Styles, { CarouselContainer, StyledNotificationIcon } from './HomeStyles';

/*----- Sections -----*/
import FeaturedHotels from './section/FeaturedHotels';
import FeaturedActivities from './section/FeaturedActivities';
import FeaturedPackages from './section/FeaturedPackages';
import FeaturedTransfers from './section/FeaturedTransfers';

import { isLogged } from '../../authentication/currentUser';
import { isMobile, openNotification } from '../../utilities/utils';
import ServicesCarousel from '../../components/carousel/ServicesCarousel';
import { hotelsData, activitiesData, packagesData } from './section/FeatureData';
import { transferData } from '../tranfers/views/index/data';
import SearchFormTransfers from '../tranfers/views/searchForm';
import SearchFormCard from '../../components/card/SearchFormCard';
import { clearNotification } from './HomeActions';
import { notificationData } from './HomeSelector';

const Home = ({ notificationData, clearNotification }) => {
	const { t } = useTranslation();

	useEffect(() => {
		notificationData &&
			openNotification({
				notificationData: notificationData,
				clearNotification: clearNotification,
				iconWrapper: StyledNotificationIcon
			});
	}, [notificationData, clearNotification]);

	return (
		<Styles>
			<HeroWithVideo
				title={t('general.title')}
				subtitle={t('general.subtitle')}
				videoUrl={FallsVideo}
			/>

			{isLogged() && (
				<SearchFormCard
					content={<SearchFormTransfers header={t('transfers.titleForm')} />}
					marginTopOffset="-300px"
				/>
			)}

			<ConsultForm />

			{isMobile() ? (
				<CarouselContainer>
					<ServicesCarousel
						data={hotelsData}
						title={t('hotels.mainHotels')}
						type="Hotel"
					/>
					<ServicesCarousel
						data={activitiesData}
						title={t('activities.mainActivities')}
						type="Actividades"
					/>
					<ServicesCarousel
						data={packagesData}
						title={t('packages.mainPackages')}
						type="Paquetes"
					/>
					<ServicesCarousel
						data={transferData}
						title={t('transfers.title')}
						type="Traslados"
					/>
				</CarouselContainer>
			) : (
				<div className="featured-container">
					<FeaturedHotels />
					<FeaturedActivities />
					<FeaturedPackages />
					<FeaturedTransfers />
				</div>
			)}
		</Styles>
	);
};

const mapStateToProps = state => {
	return {
		notificationData: notificationData(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		clearNotification: payload => clearNotification(dispatch, payload)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
