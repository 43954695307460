export const validateEmail = email => {
	const re = /^\w+([\.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/; //eslint-disable-line
	return re.test(email);
};
export const validateName = name => {
	const re = /^[a-zA-Z ]{2,30}$/;
	return re.test(name);
};
export const sendEmailSimple = (_subject, _header, _message, _email) => {
	return fetch(process.env.REACT_APP_EMAIL_URL, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		mode: 'cors',
		body: JSON.stringify({
			subject: _subject,
			template_params: {
				header: _header,
				body: _message
			},
			reply_to: [_email],
			sender_email: process.env.REACT_APP_SENDER_EMAIL
		})
	});
};
