import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';

/*** Reducers ***/
import home from '../modules/home/HomeReducer';
import hotels from '../modules/hotels/reducers/hotelsReducer';
import app from '../modules/app/AppReducer';
import showHotel from '../modules/hotels/reducers/showHotelReducer';
import activities from '../modules/activities/reducers/activitiesReducer';
import detailsActivity from '../modules/activities/reducers/detailsReducer';
import showActivity from '../modules/activities/reducers/showActivityReducer';
import packages from '../modules/packages/reducers/packagesReducer';
import login from '../authentication/reducers/loginReducer';
import register from '../authentication/reducers/registerReducer';
import loginModalVisible from '../authentication/reducers/loginModalReducer';
import refreshToken from '../authentication/reducers/refreshReducer';
import transfers from '../modules/tranfers/reducers/transfersReducer';
import recovery from '../authentication/reducers/recoveryReducer';
import registerConfirm from '../authentication/reducers/registerConfirmReducer';
import transfer from '../modules/tranfers/reducers/showTransferReducer';
import reserveTransfer from '../modules/tranfers/reducers/reserveTransferReducer';
import reserveActivity from '../modules/activities/reducers/reserveActivityReducer';
import reserveHotel from '../modules/hotels/reducers/reserveHotelReducer';
import reservePackages from '../modules/packages/reducers/reservePackagesReducer';
import hotelRoom from '../modules/hotels/reducers/hotelRoomReducer';
import showPackage from '../modules/packages/reducers/showPackageReducer';
import cart from '../modules/cart/reducers/cartReducer';
import reserveCart from '../modules/cart/reducers/reserveCartReducer';
import myReservations from '../modules/my_reservations/reducers/myReservationsReducer';
import cancelReservation from '../modules/my_reservations/reducers/cancelReservationReducer';

/*** Sagas ***/
import loginSaga from '../authentication/sagas/loginSaga';
import registerSaga from '../authentication/sagas/registerSaga';
import refreshTokenSagas from '../authentication/sagas/refreshTokenSagas';
import recoverySaga from '../authentication/sagas/recoverySaga';
import registerConfirmSagas from '../authentication/sagas/registerConfirmSaga';
import transfersSagas from '../modules/tranfers/sagas/FetchTransfers';
import showTransferSagas from '../modules/tranfers/sagas/ShowTransfer';
import reserveTransferSagas from '../modules/tranfers/sagas/ReserveTransfer';
import packagesSagas from '../modules/packages/sagas/FetchPackages';
import activitiesSagas from '../modules/activities/sagas/FetchActivities';
import hotelsSagas from '../modules/hotels/sagas/FetchHotels';
import showHotelSagas from '../modules/hotels/sagas/ShowHotel';
import showActivitySagas from '../modules/activities/sagas/ShowActivity';
import reserveActivitySagas from '../modules/activities/sagas/ReserveActivity';
import reserveHotelSagas from '../modules/hotels/sagas/ReserveHotel';
import showPackageSagas from '../modules/packages/sagas/ShowPackage';
import reservePackagesSagas from '../modules/packages/sagas/ReservePackages';
import reserveCartSagas from '../modules/cart/sagas/reserveCart';
import myReservationsSagas from '../modules/my_reservations/sagas/fetchMyReservations';
import cancelResevationSagas from '../modules/my_reservations/sagas/cancelReservation';

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
	home,
	app,
	showHotel,
	hotels,
	activities,
	showActivity,
	packages,
	showPackage,
	reservePackages,
	login,
	loginModalVisible,
	register,
	refreshToken,
	transfers,
	recovery,
	registerConfirm,
	transfer,
	reserveTransfer,
	reserveActivity,
	reserveHotel,
	hotelRoom,
	detailsActivity,
	cart,
	reserveCart,
	myReservations,
	cancelReservation,
	router: connectRouter(history)
});

const middlewares = [routerMiddleware(history), sagaMiddleware];

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line

export const store = createStore(
	rootReducer,
	composeEnhancer(applyMiddleware(...middlewares))
);

export function* rootSagas() {
	yield all([
		loginSaga(),
		registerSaga(),
		refreshTokenSagas(),
		transfersSagas(),
		recoverySaga(),
		registerConfirmSagas(),
		showTransferSagas(),
		reserveTransferSagas(),
		packagesSagas(),
		activitiesSagas(),
		hotelsSagas(),
		showHotelSagas(),
		showActivitySagas(),
		reserveActivitySagas(),
		reserveHotelSagas(),
		showPackageSagas(),
		reservePackagesSagas(),
		reserveCartSagas(),
		myReservationsSagas(),
		cancelResevationSagas()
	]);
}

sagaMiddleware.run(rootSagas);
