import React from 'react';
import {
	StyledContact,
	StyledContactList,
	StyledContactItem,
	StyledContactIcon,
	StyledContactTitle,
	StyledContactText,
	StyledIcon,
	StyledDiv
} from './styles';
import { useTranslation } from 'react-i18next';
import BrazilIcon from '../../../../assets/icons/brazilian-flag.svg';
import ArgentinaIcon from '../../../../assets/icons/argentinian-flag.svg';

import PhoneIcon from '../../../../assets/icons/phone-white.svg';
import WppIcon from '../../../../assets/icons/whatsapp-white.svg';
import ClockIcon from '../../../../assets/icons/clock-white.svg';
import EmailIcon from '../../../../assets/icons/email-white.svg';

import SecondaryPhoneIcon from '../../../../assets/icons/phone-blue.svg';
import SecondaryWppIcon from '../../../../assets/icons/whatsapp-blue.svg';
import SecondaryClockIcon from '../../../../assets/icons/clock-blue.svg';
import SecondaryEmailIcon from '../../../../assets/icons/email-blue.svg';

const Item = ({ secondary, icon, number, alt, transparent = false }) => (
	<StyledContactItem>
		<StyledContactText content={number} secondary={secondary} />
		<StyledContactIcon
			src={icon}
			alt={alt}
			secondary={secondary}
			transparent={transparent}
		/>
	</StyledContactItem>
);

export const ContactList = ({ secondary = false }) => (
	<StyledContactList>
		<StyledDiv>
			<StyledIcon src={secondary ? SecondaryWppIcon : WppIcon} alt="Whatsapp" />
			<Item
				icon={BrazilIcon}
				alt=""
				number="+55 45 9 9847-0099"
				transparent
				secondary={secondary}
			/>
		</StyledDiv>
		<StyledDiv>
			<StyledIcon src={secondary ? SecondaryPhoneIcon : PhoneIcon} alt="Phone" />
			<Item
				icon={ArgentinaIcon}
				alt="Argentina Icon"
				number="+54 9 11 39847573"
				secondary={secondary}
			/>
		</StyledDiv>
		<StyledDiv>
			<StyledIcon src={secondary ? SecondaryPhoneIcon : PhoneIcon} alt="Phone" />
			<Item
				icon={BrazilIcon}
				alt="Brazil Icon"
				number="+55 45 3027-7722"
				secondary={secondary}
			/>
		</StyledDiv>
		<StyledDiv>
			<StyledIcon src={secondary ? SecondaryEmailIcon : EmailIcon} alt="Email" />
			<StyledContactText
				content="consultas@privateservice.tur.ar"
				secondary={secondary}
			/>
		</StyledDiv>
		<StyledDiv>
			<StyledIcon src={secondary ? SecondaryClockIcon : ClockIcon} alt="Horario" />
			<StyledContactText
				content="Atención de 08:00h a 19:00h"
				secondary={secondary}
			/>
		</StyledDiv>
	</StyledContactList>
);

const Contact = () => {
	const { t } = useTranslation();

	return (
		<StyledContact>
			<StyledContactTitle content={t('general.footer.contactUs')} />
			<ContactList />
		</StyledContact>
	);
};

export default Contact;
