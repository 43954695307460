import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Row, Col } from 'antd';
import DefaultButton from '../../../components/button/DefaultButton';
import OpenSansBold from '../../../commons/mixins/fonts/OpenSansBold';
import Color from '../../../commons/Color';

const StyledText = styled.p`
	text-align: center;
	${OpenSansBold(14, 700, Color.footer)}
	padding-left: 30px;
	padding-right: 30px;
`;

const StyledButtonCol = styled(Col)`
	padding-bottom: 24px;
`;

const CheckoutConfirmButton = ({ destination }) => {
	const { t } = useTranslation();

	return (
		<Row type="flex" justify="center">
			<Col span={24}>
				<StyledText>{t('checkout.verification')}</StyledText>
			</Col>
			<StyledButtonCol>
				<DefaultButton
					content={t('checkout.endReservation')}
					destination={destination}
				/>
			</StyledButtonCol>
		</Row>
	);
};

export default CheckoutConfirmButton;

CheckoutConfirmButton.defaultProps = {
	destination: ''
};

CheckoutConfirmButton.propTypes = {
	destination: PropTypes.string.isRequired
};
