import { call, put, takeLatest } from 'redux-saga/effects';
import {
	RESERVE_PACKAGES,
	RESERVE_PACKAGES_SUCCESS,
	RESERVE_PACKAGES_FAILED
} from '../actions/reserveActions';
import { reservePackages } from '../../../api/endpoints/Packages';

import { removeCheckoutPackages } from '../../../services/PackagesCheckoutService';
import { getWithHotel } from '../../../services/SearchService';

function* shotReservePackages(action) {
	function* onSuccess(body) {}

	function* onOk(body) {
		yield put({ type: RESERVE_PACKAGES_SUCCESS, payload: body.data });
	}

	function* onError(error) {
		removeCheckoutPackages(action.payload);
		yield put({ type: RESERVE_PACKAGES_FAILED, payload: error });
	}

	try {
		if (getWithHotel()) yield call(onOk, {});
		else {
			yield call(reservePackages, action.payload, {
				onSuccess: onSuccess,
				onError: onError,
				onOk: onOk
			});
		}
	} catch (error) {
		yield onError(error.message);
	}
}

export default function* ReservePackagesSagas() {
	yield takeLatest(RESERVE_PACKAGES, shotReservePackages);
}
