import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

/*----- Components -----*/
import ReservationPanel from './ReservationPanel';
import ReservationPanelDetail from './ReservationPanelDetail';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { showActivityData } from '../../modules/activities/selectors/showActivitySelector';
import { getSearchQuery } from '../../services/SearchService';
import { getCheckoutActivity } from '../../services/ActivityCheckoutService';
import { getId } from '../../modules/commons/checkout/CheckoutUtils';
import { isMobile } from '../../utilities/utils';
import { setSelectDateActivity } from '../../modules/activities/actions/detailsActions';

const ReservationPanelActivity = ({
	history,
	currency,
	amount,
	panelTitle,
	itemsDetails,
	goCheckout,
	setSelectDateActivity,
	disabled
}) => {
	const { t } = useTranslation();
	const searchQuery = getSearchQuery();

	const [textButton, setTextButton] = useState(t('general.selectDate'));
	const [textDateLabel, setTextDateLabel] = useState(t('general.date'));
	const [dateIn, setDateIn] = useState(searchQuery.date_in);
	const [dateOut, setDateOut] = useState(searchQuery.date_out);

	let activityCheckout = getCheckoutActivity(getId(history));

	useEffect(() => {
		if (activityCheckout && activityCheckout.reservation_date_in) {
			setTextButton(t('general.continue'));
			setTextDateLabel(t('general.date'));
			setDateIn(activityCheckout.reservation_date_in);
			setDateOut(null);
		} else {
			isMobile()
				? setTextButton(t('general.continue'))
				: setTextButton(t('general.selectDate'));
			setTextDateLabel(t('general.search'));
			setDateIn(searchQuery.date_in);
			setDateOut(searchQuery.date_out);
		}
	}, [activityCheckout, t, searchQuery, setSelectDateActivity]);

	return (
		<ReservationPanel
			currency={currency}
			amount={amount}
			title={panelTitle}
			dateLabel={textDateLabel}
			textTotal={t('checkout.totalPrice')}
			dateFrom={dateIn}
			dateTo={dateOut}
			disabled={disabled}
			buttonLegend={t('general.buttonLegend')}
			onClick={() => {
				if (activityCheckout && activityCheckout.reservation_date_in) {
					goCheckout();
				} else {
					setSelectDateActivity();
				}
			}}
			textButton={textButton}
		>
			<ReservationPanelDetail items={itemsDetails} />
		</ReservationPanel>
	);
};

const mapStateToProps = state => {
	return {
		showActivity: showActivityData(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setSelectDateActivity: payload => setSelectDateActivity(dispatch, payload)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(ReservationPanelActivity));
