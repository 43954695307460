import styled from 'styled-components';
import Color from '../../../commons/Color';
import { Modal } from 'antd';
import Text from '../../text/Text';
import Device from '../../../commons/Device';

export const Div = styled.div``;

export const StyledModal = styled(Modal)`
	.ant-modal-content {
		border-radius: 10px;
		padding: 12px;
	}
	.ant-modal-header {
		padding: 8px 24px;
	}
	.ant-modal-body {
		padding: 10px 24px;
	}
`;

export const StyledText = styled.a`
	color: ${Color.main} !important;
	font-size: 12px;
	font-weight: bold;
`;

export const TitleContainer = styled.div`
	@media ${Device.xs} {
		margin-bottom: 12px;
	}
`;

export const StyledTitle = styled(Text)`
	font-size: 20px;
	font-weight: bold;
	color: #000033;

	@media ${Device.xs} {
		font-size: 16px;
	}
`;

export const StyleKind = styled(Text)`
	font-size: 20px;
	font-weight: bold;
	color: ${Color.main};
	margin-bottom: 12px;

	@media ${Device.xs} {
		margin-bottom: 0px;
		font-size: 16px;
	}
`;

export const InfoContainer = styled.div`
	margin-bottom: 12px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

export const StyledInfoContent = styled(Text)`
	font-size: 14px;
	margin: 0px;
	@media ${Device.xs} {
		font-size: 12px;
	}
`;

export const StyledInfoTitle = styled(StyledInfoContent)`
	font-weight: bold;
`;
