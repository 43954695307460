import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	StyledTable,
	StyledText,
	StyledRadio,
	SeeMoreRooms,
	StyledList,
	StyledTdPrice,
	StyledIconArrow,
	StyledSeeRooms,
	StyledContainerSeeRooms
} from './styles';
import {
	TextPrice,
	StyledInformation
} from '../../../../../components/panel/ReservationPanelHeader/styles';
import RegimeIcon from '../../../../../assets/icons/checkout/reception-bell.svg';
import ReservationPanalItemIcon from '../../../../../components/panel/ReservationPanalItemIcon';
import Loading from '../../../../login/confirmRegister/Loading';
/*----- Selectors -----*/
import { hotelRoom } from '../../../../../modules/hotels/selectors/hotelRoomSelector';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

const RoomList = ({
	roomType,
	rooms = [],
	roomSelected,
	hotelRoom,
	showHotel,
	getInformation,
	getDetailCheckout
}) => {
	const { t } = useTranslation();
	const [roomsShowed, setRoomsShowed] = useState(rooms.slice(0, 1));
	const [hotelInformation, setHotelInformation] = useState(null);

	useEffect(() => {
		setHotelInformation(getInformation(showHotel, hotelRoom.item_id));
	}, [showHotel, hotelRoom, getInformation]);

	const isRoomTypeSelected = () => {
		return rooms.filter(room => roomSelected.item_id === room.item_id).length;
	};

	const isRoomSelected = record => {
		return roomSelected.item_id === record.item_id;
	};

	const columns = [
		{
			title: `${roomType}`,
			dataIndex: 'roomType',
			width: '30%',
			render: (text, record, index) => {
				const getIcons = () => {
					let icons = getDetailCheckout(hotelInformation, ['nights', 'passengers']);
					return icons.map((icon, idx) => (
						<ReservationPanalItemIcon
							key={idx}
							icon={icon.icon}
							description={icon.text}
						/>
					));
				};

				return {
					children: <>{getIcons()}</>,
					props: {
						rowSpan: index === 0 ? roomsShowed.length : 0
					}
				};
			}
		},
		{
			title: t('hotels.regime'),
			dataIndex: 'regime',
			width: '25%',
			render: (text, record) => {
				return (
					<StyledTdPrice>
						<ReservationPanalItemIcon
							icon={RegimeIcon}
							description={record.regime.name}
						/>
					</StyledTdPrice>
				);
			}
		},
		{
			title: t('checkout.totalPrice'),
			dataIndex: 'amount',
			width: '25%',
			render: (text, record) => (
				<StyledTdPrice>
					<TextPrice
						content={`${record.currency} ${record.amount_with_taxes.toFixed(2)}`}
						bold={isRoomSelected(record)}
					/>
					<StyledInformation content={t('checkout.taxes')} />
				</StyledTdPrice>
			)
		},
		{
			title: '',
			dataIndex: 'action',
			width: '20%',
			render: (text, record) => (
				<StyledRadio value={record.item_id}>
					<StyledText
						content={
							isRoomSelected(record) ? t('general.selected') : t('general.select')
						}
					/>
				</StyledRadio>
			)
		}
	];

	const seeRooms = () => {
		let iconType = '';
		let text = '';
		if (rooms.length > roomsShowed.length) {
			text = t('general.seeMoreRooms');
			iconType = 'down';
		} else if (rooms.length === roomsShowed.length && rooms.length > 3) {
			text = t('general.seeLessRooms');
			iconType = 'up';
		}

		if (iconType) {
			return (
				<StyledSeeRooms>
					<StyledContainerSeeRooms
						onClick={() => {
							iconType === 'up'
								? setRoomsShowed(rooms.slice(0, 1))
								: setRoomsShowed(rooms);
						}}
					>
						<SeeMoreRooms content={text} bold />
						<StyledIconArrow type={iconType} />
					</StyledContainerSeeRooms>
				</StyledSeeRooms>
			);
		}

		return null;
	};

	if (!hotelInformation) {
		return <Loading />;
	}
	return (
		<StyledList>
			<StyledTable
				isSelected={isRoomTypeSelected()}
				rowKey={room => room.item_id}
				columns={columns}
				dataSource={roomsShowed}
				pagination={false}
				bordered
			/>
			{seeRooms()}
		</StyledList>
	);
};

const mapStateToProps = state => {
	return {
		hotelRoom: hotelRoom(state)
	};
};

export default connect(mapStateToProps)(withRouter(RoomList));
