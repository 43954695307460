import styled, { css } from 'styled-components';
import Color from '../../../../commons/Color';
import DefaultButton from '../../../../components/button/DefaultButton';

import { hoverCart } from '../styles';

export const StyledDropdownSection = styled.section`
	width: 100%;
	padding: 0 1rem;
`;
export const StyledDropdownItem = styled.article`
	margin: 0.5rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0 1.45rem;
	margin-right: 20px;
`;
export const StyledDropdownItemTitle = styled.span`
	font-family: 'Montserrat', sans-serif;
	font-size: 12px;
	font-weight: 500;
	color: gray;
`;

export const StyledDropdownItemPrice = styled.span`
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	font-weight: bold;
	color: ${Color.footer};
	margin: 0.4rem 0.75rem;
`;

export const StyledDropdownTotalTitle = styled.span`
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	font-weight: bold;
	color: ${Color.footer};
`;

export const StyledButton = styled(DefaultButton)`
	align-items: center;
`;

export const StyledTitleWrapper = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 50%;
	flex: 1;
`;

export const StyledDropdownItemIcon = styled.span`
	svg {
		width: 19px;
		height: 19px;
		fill: ${Color.footer};
	}
	margin-right: 1rem;
	${props =>
		props.absolute &&
		css`
			position: absolute;
			right: 1rem;
			margin-bottom: 4px;
			&:hover {
				${hoverCart}
				cursor:pointer;
			}
		`}
`;
