import { call, put, takeLatest } from 'redux-saga/effects';
import {
	REGISTER_CONFIRM,
	REGISTER_CONFIRM_SUCCESS,
	REGISTER_CONFIRM_FAILED,
	REGISTER_CONFIRM_ERROR
} from '../actions/registerConfirmActions';

import { registerConfirm } from '../../api/endpoints/Sessions';

function* registerConfirmFunction(action) {
	function* onSuccess(body) {
		yield put({ type: REGISTER_CONFIRM_SUCCESS, payload: body.data });
	}

	function* onFailedDependency(error) {
		yield put({ type: REGISTER_CONFIRM_FAILED, error: error });
	}

	function* onError(error) {
		yield put({ type: REGISTER_CONFIRM_ERROR, error: error });
	}

	try {
		yield call(registerConfirm, action.payload, {
			onSuccess: onSuccess,
			onFailedDependency: onFailedDependency,
			onError: onError
		});
	} catch (error) {
		yield onError(error.message);
	}
}

function* RegisterConfirmSaga() {
	yield takeLatest(REGISTER_CONFIRM, registerConfirmFunction);
}

export default RegisterConfirmSaga;
