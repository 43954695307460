import React from 'react';
import { connect } from 'react-redux';

/*----- Components -----*/
import GenericCheckout from '../../../commons/checkout/GenericCheckout';

/*----- Actions -----*/
import { showPackage } from '../../actions/showPackageActions';

import {
	showPackageIsLoading,
	showPackageSuccess
} from '../../selectors/showPackageSelector';

const PackageFinder = ({
	showPackage,
	SuccessResponse,
	packageIsLoading,
	packageSuccess
}) => {
	return (
		<GenericCheckout
			showData={showPackage}
			success={packageSuccess}
			isLoading={packageIsLoading}
			service="packages"
			SuccessResponse={SuccessResponse}
		/>
	);
};

const mapStateToProps = state => {
	return {
		packageIsLoading: showPackageIsLoading(state),
		packageSuccess: showPackageSuccess(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		showPackage: payload => showPackage(dispatch, payload)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PackageFinder);
