import React from 'react';
import { connect } from 'react-redux';
import {
	registerResponse,
	successRegister
} from '../../authentication/selectors/registerSelector';
import {
	recovery,
	successRecovery
} from '../../authentication/selectors/recoverySelector';

const Content = ({
	Tabs,
	SuccessRegister,
	FailedRegister,
	RecoveryPasswordForm,
	SuccessRecoveryPassword,
	recoveryPassword,
	registerResponse,
	successRegister,
	successRecovery
}) => {
	if (recoveryPassword) {
		if (successRecovery) {
			return <SuccessRecoveryPassword />;
		} else {
			return <RecoveryPasswordForm />;
		}
	} else if (registerResponse) {
		if (successRegister) {
			return <SuccessRegister />;
		} else {
			return <FailedRegister />;
		}
	} else {
		return <Tabs />;
	}
};

const mapStateToProps = state => {
	return {
		successRegister: successRegister(state),
		successRecovery: successRecovery(state),
		recoveryPassword: recovery(state),
		registerResponse: registerResponse(state)
	};
};

export default connect(mapStateToProps, null)(Content);
