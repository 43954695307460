import { Form, Row, Col } from 'antd';
import styled from 'styled-components';
import DefaultButton from '../../../components/button/DefaultButton';
import Text from '../../../components/text/Text';
import Device from '../../../commons/Device';

const { Item } = Form;

export const StyledForm = styled(Form)`
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 0px 24px !important;
	margin-bottom: 32px !important;
	display: flex;
`;

export const StyledFormRecovery = styled(StyledForm)`
	padding: 0px !important;
`;

export const StyledItem = styled(Item)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	.ant-form-explain {
		font-size: 11px;
		margin: 0px;
		min-height: 0px;
	}
	@media ${Device.xs} {
		margin: 16px 0px 0px !important;
	}
`;

export const StyledItemTwo = styled(Item)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.ant-form-explain {
		font-size: 11px;
		margin: 0px;
		min-height: 0px;
	}
	@media ${Device.xs} {
		margin: 16px 0px 0px !important;
	}
`;

export const StyledDefaultButton = styled(DefaultButton)`
	justify-content: center;
	align-items: center;
	margin: ${props => props.margin};
`;

export const StyledText = styled(Text)`
	text-align: ${props => (props.center ? 'center' : 'left')};
	font-size: ${props => props.size || '10px'};
	color: ${props => (props.error ? '#B01A24' : '#8A9EAD')};
	padding: ${props => props.padding};
`;

export const TwoFields = styled(Row)`
	display: flex !important;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;

	@media ${Device.xs} {
		flex-direction: column;
	}
`;

export const StyledCol = styled(Col)`
	min-width: 0px;
`;

export const StyledLink = styled.a`
	color: #b01a24 !important;
	text-decoration: underline !important;
`;
