/** * Core ***/
import React from 'react'
import styled from 'styled-components'
import Montserrat from '../../commons/mixins/fonts/Montserrat'
import Device from '../../commons/Device'


const StyledAttributeItem = styled.div`
  border-radius: 10px;
  padding: 12px;
  width: 100%;
  .title-cont {
    .icon {
      width: 16px;
    }
    .title {
      ${ Montserrat(16, 600, 'dimgrey')}
      margin: 0;
    }
  }
  .description {
    ${Montserrat(12, 400, 'grey')}
  }
	@media ${Device.md} {
		width: 33%;
	}
`
const AttributeItem = ({ title, description, urlIcon }) => (
  <StyledAttributeItem className="attribute-item d-flex flex-column mx-1 my-2">
    <div className="title-cont d-flex flex-row justify-content-start align-items-center">
      <img src={ urlIcon } alt={`${title} icon`} className="icon"/>
      <h4 className="title ml-3">{ title }</h4>
    </div>
    <p className="description py-2 mb-0">{ description }</p>
  </StyledAttributeItem>
)
export default AttributeItem
