/*----- Core -----*/
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ItemService from '../../components/itemService';

const StyledDetailServices = styled.div`
	padding: ${props => props.padding};

	.detail-service__container {
		display: flex;
		flex-wrap: wrap;

		span {
			margin-right: 15px;
		}
		img {
			width: 20px;
			height: 20px;
			margin-right: 3px;
		}
	}
`;

const DetailServices = ({ iconServices, padding }) => {
	const getIconServices = () => {
		return iconServices.map((service, idx) => (
			<ItemService
				key={idx}
				icon={service.icon}
				label={service.label}
				bold={service.bold}
			/>
		));
	};

	return (
		<StyledDetailServices className="detail-services" padding={padding}>
			<div className="detail-service__container">{getIconServices()}</div>
		</StyledDetailServices>
	);
};

export default DetailServices;

/*----- PropTypes -----*/
DetailServices.propTypes = {
	iconServices: PropTypes.array.isRequired,
	padding: PropTypes.string
};

DetailServices.defaultProps = {
	iconServices: [],
	padding: '8px 0px'
};
