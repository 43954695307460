/*----- Core -----*/
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

/*----- Commons -----*/
import Montserrat from '../../commons/mixins/fonts/Montserrat';
import Device from '../../commons/Device';
import Color from '../../commons/Color';

/*----- Styles-----*/
const StyledHeroText = styled.article`
	padding: 16px;
	max-width: 400px;
	@media ${Device.sm} {
		max-width: 500px;
	}
	@media ${Device.md} {
		max-width: 100%;
	}
	.hero-text__text-container {
		position: relative;
		z-index: 1;
	}
	.hero-text__title {
		${Montserrat(40, 900, Color.main)};
		line-height: 38px;
		margin: 0;
	}
	.hero-text__subtitle {
		${Montserrat(18, 400, 'grey')};
		line-height: 24px;
		margin-top: 20px;
	}
	@media ${Device.md} {
		.hero-text__title {
			font-size: 60px;
			line-height: 60px;
		}
		.hero-text__subtitle {
			font-size: 26px;
			line-height: 30px;
		}
	}
	@media ${Device.lg} {
		.hero-text__title {
			font-size: 70px;
			line-height: 70px;
		}
		.hero-text__subtitle {
			font-size: 32px;
			line-height: 36px;
		}
	}
`; /*----- Component -----*/
const HeroText = ({ title, subtitle, reverse = false }) => (
	<StyledHeroText className="hero-text">
		<div className="hero-text__text-container">
			{reverse && <h2 className="hero-text__subtitle">{subtitle}</h2>}
			<h1 className="hero-text__title">{title}</h1>
			{!reverse && <h2 className="hero-text__subtitle">{subtitle}</h2>}
		</div>
	</StyledHeroText>
);
export default HeroText;

/*----- PropTypes -----*/
HeroText.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string.isRequired,
	reverse: PropTypes.bool
};
