import React, { useState } from 'react';
import { Col, Form, Row } from 'antd';
import {
	handleDisableDate,
	handleDisableBeforeDate
} from '../../components/date-picker/Handlers';
import DatePicker from '../../components/date-picker/DatePicker';

const { Item } = Form;
const CustomRangePicker = ({ form, initialDateIn, initialDateOut }) => {
	const { getFieldDecorator } = form;
	const [dateOutOpen, setDateOutOpen] = useState(false);
	const [dateInOpen, setDateInOpen] = useState(false);

	const handleDateInOpenChange = open => {
		setDateInOpen(open);
		if (!open) {
			setDateOutOpen(true);
		}
	};

	const handleDateOutOpenChange = open => {
		setDateOutOpen(open);
		let nights = 0;
		if (form.getFieldValue('date_out') && form.getFieldValue('date_in')) {
			nights = form
				.getFieldValue('date_out')
				.diff(form.getFieldValue('date_in'), 'days');
		}
		form.setFieldsValue({ nights: nights });
	};

	const disabledEndDate = endValue => {
		return handleDisableBeforeDate(endValue, form.getFieldValue('date_in'));
	};

	return (
		<Row>
			<Col xs={12} md={12}>
				<Item>
					{getFieldDecorator('date_in', {
						rules: [{ required: true }],
						validateTrigger: ['onSubmit'],
						initialValue: initialDateIn
					})(
						<DatePicker
							name="Entrada"
							open={dateInOpen}
							funcDisabledDate={handleDisableDate}
							onOpenChange={handleDateInOpenChange}
							afterChange={date => {
								form.setFieldsValue({ date_out: date });
							}}
						/>
					)}
				</Item>
			</Col>

			<Col xs={12} md={12}>
				<Item>
					{getFieldDecorator('date_out', {
						rules: [{ required: true }],
						validateTrigger: ['onSubmit'],
						initialValue: initialDateOut
					})(
						<DatePicker
							name="Salida"
							open={dateOutOpen}
							onOpenChange={handleDateOutOpenChange}
							funcDisabledDate={disabledEndDate}
							afterChange={() => {
								handleDateOutOpenChange(false);
							}}
						/>
					)}
				</Item>
			</Col>
		</Row>
	);
};

export default CustomRangePicker;
