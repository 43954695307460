import React from 'react';
import {
	StyledLogo,
	StyledIconProfile,
	StyledLinkProfile,
	StyledHeaderItems,
	StyledColOptions
} from './styles';
import logo from '../../assets/logo.svg';
import userIcon from '../../assets/icons/header-user.svg';
import SelectLanguage from '../commons/nav/Header/SelectLanguage';
import { Link } from 'react-router-dom';

const HeaderItems = ({ showProfile }) => {
	return (
		<StyledHeaderItems showProfile={showProfile}>
			<Link to="/home">
				<StyledLogo src={logo} alt="logo" />
			</Link>
			<StyledColOptions showProfile={showProfile}>
				<SelectLanguage withoutText />
				{showProfile && (
					<StyledLinkProfile to={'/login'}>
						<StyledIconProfile src={userIcon} alt="user-icon" />
					</StyledLinkProfile>
				)}
			</StyledColOptions>
		</StyledHeaderItems>
	);
};

export default HeaderItems;
