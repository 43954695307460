import {
	RECOVERY_USER,
	RECOVERY_SUCCESS,
	RECOVERY_FAILED,
	DELETE_RECOVERY,
	OPEN_RECOVERY
} from '../actions/recoveryActions';

/*----- Reducer -----*/
const initialState = {
	isLoadingRecovery: false,
	successRecovery: false,
	errorRecovery: false,
	recovery: false
};

export function recovery(state = initialState, action) {
	switch (action.type) {
		case OPEN_RECOVERY:
			return {
				...state,
				recovery: true
			};
		case RECOVERY_USER:
			return {
				...state,
				recovery: true,
				isLoadingRecovery: true
			};
		case DELETE_RECOVERY:
			return {
				...state,
				recovery: false
			};
		case RECOVERY_SUCCESS:
			return {
				...state,
				isLoadingRecovery: false,
				successRecovery: true
			};
		case RECOVERY_FAILED:
			return {
				...state,
				isLoadingRecovery: false,
				successRecovery: true,
				errorRecovery: true
			};
		default:
			return state;
	}
}

export default recovery;
