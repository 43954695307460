import React from 'react';
import { useTranslation } from 'react-i18next';
import ErrorRegisterConfirmImage from '../../../../../assets/images/ErrorRegisterConfirmImage.svg';
import Title from '../../../../../components/text/Title';
import {
	StyledTitleWrapper,
	StyledServiceErrorWrapper,
	StyledServiceError,
	StyledServiceErrorRow
} from '../../styles';
import { connect } from 'react-redux';
import { reserveCartError } from '../../../selectors/reserveCartSelector';
import {
	StyledTitle,
	SyledColImage,
	SyledColMessage,
	StyledImg
} from '../../../../commons/ResponseWithImage/styles';
import { Row } from 'antd';
import { getIconByService } from '../../../../../utilities/utils';

const ErrorResponseReservation = ({ error }) => {
	const { t } = useTranslation();

	return (
		<>
			<Row type="flex" justify="center" align="middle">
				<SyledColImage>
					<StyledImg src={ErrorRegisterConfirmImage} />
				</SyledColImage>
				<SyledColMessage>
					<StyledTitle
						content={t(`cart.reserve.error.${error ? 'title' : 'internal'}`)}
						color={'secondary'}
						size={3}
					/>
				</SyledColMessage>
			</Row>
			{error && (
				<>
					<StyledTitleWrapper>
						<StyledServiceErrorWrapper>
							{error.map(e => (
								<StyledServiceErrorRow>
									{getIconByService(e.service_kind)}
									<StyledServiceError>
										{t(`cart.reserve.services.${e.service_kind}`)}
									</StyledServiceError>
								</StyledServiceErrorRow>
							))}
						</StyledServiceErrorWrapper>
					</StyledTitleWrapper>
					<StyledTitleWrapper>
						<Title size={3} content={t('cart.reserve.error.detail')} />
					</StyledTitleWrapper>
				</>
			)}
		</>
	);
};

const mapStateToProps = state => {
	return {
		error: reserveCartError(state)
	};
};

export default connect(mapStateToProps, null)(ErrorResponseReservation);
