import React from 'react';

const LocationIcon = ({ width = '20px', height = '20px', fill = 'black' }) => (
	<svg
		version="1.0"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 240.000000 240.000000"
		preserveAspectRatio="xMidYMid meet"
		width={width}
		height={height}
		fill={fill}
	>
		<metadata>Created by potrace 1.15, written by Peter Selinger 2001-2017</metadata>
		<g
			transform="translate(0.000000,240.000000) scale(0.100000,-0.100000)"
			stroke="none"
		>
			<path
				d="M1046 2384 c-394 -85 -667 -422 -643 -794 19 -288 159 -584 629
        -1325 87 -137 163 -250 168 -250 10 0 271 408 425 665 366 611 449 946 309
        1248 -154 335 -537 531 -888 456z m276 -512 c59 -26 124 -91 151 -151 16 -34
        22 -66 22 -121 0 -91 -24 -149 -85 -210 -61 -61 -119 -85 -210 -85 -336 0
        -413 464 -95 578 62 22 153 18 217 -11z"
			/>
		</g>
	</svg>
);

export default LocationIcon;
