/*----- Core -----*/
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

/*----- Commons -----*/
import Montserrat from '../../commons/mixins/fonts/Montserrat'

/*----- Styles -----*/
const StyledParagraph = styled.p`
	${ Montserrat(14, 300, 'grey') };
	margin: 16px;
`

/*----- Component -----*/
const Paragraph = ({ content }) => <StyledParagraph className="custom-paragraph">{ content }</StyledParagraph>
export default Paragraph

/*----- PropTypes -----*/
Paragraph.propTypes = {
  content: PropTypes.string.isRequired
}
