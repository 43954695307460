import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { withRouter } from 'react-router-dom';

/*----- Commons-----*/
import Montserrat from '../../commons/mixins/fonts/Montserrat';
import Color from '../../commons/Color';

/*----- Components -----*/
import { InputContainer } from '../form/styles';
import { Icon } from 'antd';

const StyledDefaultButton = styled.button`
	${props => Montserrat(props.fontSize, 600, 'white')};
	text-transform: capitalize;
	height: 36px;
	border-radius: ${props => props.borderRadius};
	padding: 0 20px;
	${props =>
		!props.disabled &&
		css`
			cursor: pointer;
		`}
	background: ${props => props.backgroundColor};
	min-width: 115px;
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 200px;
	border: none;
	transition: all 0.3s ease-out;
	&:focus {
		outline: none;
	}
	${props =>
		!props.disabled &&
		css`
			&:hover {
				color: white;
				transform: scale(1.04);
				box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.2);
				text-decoration: none;
			}
		`}

	${props =>
		props.invert &&
		css`
			background: white;
			border: 1.7px solid ${Color.main};
			color: ${Color.main};
			&:hover {
				color: ${Color.main};
			}
		`}

	${props =>
		props.secondary &&
		css`
			background: ${Color.footer};
			border: none;
			color: white;
			&:hover {
				color: 'white' !important;
			}
		`}

	${props =>
		props.secondary &&
		props.invert &&
		css`
			background: none;
			border: 1px solid ${Color.footer};
			color: ${Color.secondary} !important;
		`}

	.default-button__icon {
		width: 16px;
		margin-right: 8px;
	}
`;

const DefaultButton = ({
	content,
	onClick,
	invert,
	icon,
	secondary,
	destination,
	history,
	htmlType,
	className,
	fontSize,
	backgroundColor,
	borderRadius,
	loading,
	disabled
}) => {
	const goToDestination = () => {
		if (destination) {
			history.push(destination);
		}
	};

	let onClickHandler;

	if (onClick) {
		onClickHandler = onClick;
	} else {
		if (destination) {
			onClickHandler = goToDestination;
		}
	}

	return (
		<InputContainer className={className}>
			<StyledDefaultButton
				to={destination}
				className="default-button"
				invert={invert}
				onClick={onClickHandler}
				type={htmlType}
				fontSize={fontSize}
				disabled={loading || disabled}
				backgroundColor={
					loading ? Color.lightMain : disabled ? Color.text : backgroundColor
				}
				borderRadius={borderRadius}
				secondary={secondary}
			>
				{icon && !loading ? (
					<img src={icon} className="default-button__icon" alt={`${content} icon`} />
				) : null}
				{loading ? <Icon type="loading" style={{ fontSize: '24px' }} /> : content}
			</StyledDefaultButton>
		</InputContainer>
	);
};
export default withRouter(DefaultButton);

DefaultButton.defaultProps = {
	htmlType: 'button',
	className: 'input-container',
	fontSize: '14',
	backgroundColor: Color.main,
	borderRadius: '20px',
	loading: false
};

DefaultButton.propTypes = {
	content: PropTypes.string,
	onClick: PropTypes.func,
	invert: PropTypes.bool,
	icon: PropTypes.string,
	destination: PropTypes.string,
	loading: PropTypes.bool
};
