import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

/*----- Components -----*/
import CheckoutConfirmHeader from './CheckoutConfirmHeader';
import CheckoutConfirmList from '../../../../../components/list/checkoutConfirmList/CheckoutConfirmList';
import { ItemCheckoutConfirmContainer } from '../../../../../components/containers/CheckoutContainer';

/*----- Selectors -----*/
import { transferData } from '../../../selectors/showTransferSelector';

/*----- Utils -----*/
import { transferSteps, capitalize } from '../../../../../utilities/utils';

/*----- Service -----*/
import { getCheckoutTransfer } from '../../../../../services/TransferCheckoutService';
import { getId } from '../../../../commons/checkout/CheckoutUtils';
import GenericConfirm from '../../../../commons/confirm/GenericConfirm';
import GenericConfirmCard from '../../../../commons/confirm/GenericConfirm/GenericConfirmCard';
import { getConfirmData } from '../../../../commons/confirm/confirmUtils';

const SuccessResponse = ({ transfer, history }) => {
	const { t } = useTranslation();

	const id = getId(history);
	const dataCheckout = getCheckoutTransfer(id);
	const isMix = transfer.info.kind === 'mix';
	let passengersRequired = transfer.rate.amount_details.occupancy.required;

	const addLodging = (data, type = transfer.info.kind) => {
		data.push({
			label: t(`checkout.lodging.${type === 'in' ? 'destination' : 'pickUp'}`),
			value: dataCheckout[type].hotel_info
		});

		return data;
	};

	return (
		<GenericConfirm
			steps={transferSteps}
			id={id}
			active={2}
			ConfirmCard={
				<GenericConfirmCard
					header={
						<ItemCheckoutConfirmContainer>
							<CheckoutConfirmHeader
								type={`${transfer.info.kind.toUpperCase()} | ${capitalize(
									transfer.info.category.name.toLowerCase()
								)}`}
								title={transfer.name}
								dateIn={dataCheckout.reservation_date_in}
								dateOut={dataCheckout.reservation_date_out}
							/>
						</ItemCheckoutConfirmContainer>
					}
					service="transfers"
					dataCheckout={dataCheckout}
					passengersRequired={passengersRequired}
					taxes={transfer.rate.amount_details.passenger.iva}
					sale={transfer.rate.amount_details.passenger.sale}
					currency={transfer.rate.currency}
				>
					<ItemCheckoutConfirmContainer>
						{isMix ? (
							<>
								<CheckoutConfirmList
									title={t('checkout.arrival')}
									list={addLodging(getConfirmData(dataCheckout.in, t), 'in')}
									lastItem={false}
								/>
								<CheckoutConfirmList
									title={t('checkout.departure')}
									list={addLodging(getConfirmData(dataCheckout.out, t), 'out')}
									lastItem={true}
								/>
							</>
						) : (
							<CheckoutConfirmList
								title={t('checkout.arrival')}
								list={addLodging(
									getConfirmData(dataCheckout, t, transfer.info.kind)
								)}
								lastItem={true}
							/>
						)}
					</ItemCheckoutConfirmContainer>
				</GenericConfirmCard>
			}
		/>
	);
};

const mapStateToProps = state => {
	return {
		transfer: transferData(state)
	};
};

export default connect(mapStateToProps, null)(withRouter(SuccessResponse));
