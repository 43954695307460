/*----- Core -----*/
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/*----- Commons -----*/
import Montserrat from '../../commons/mixins/fonts/Montserrat';
import Device from '../../commons/Device';
import Color from '../../commons/Color';

/*----- Assets -----*/
import VideoRedShape from '../../assets/images/video_red_shape.svg';

/*----- Components -----*/
import Video from '../video/Video';

/*----- Styles-----*/
const StyledHeroWithVideo = styled.article`
	height: 300px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	z-index: 0;

	.hero-with-video__text-container {
		position: absolute;
		z-index: 3;
		max-width: 450px;
		background-color: ${Color.main};
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		flex-direction: column;
		padding-left: 32px;
		@media ${Device.xs} {
			position: relative;
			padding: 56px 0px;
			align-items: center;
		}
	}
	.hero-with-video__red-shape {
		position: absolute;
		top: 0;
		height: 100%;
		right: -176px;
	}
	.hero-with-video__title {
		${Montserrat(40, 900, 'white')};
		line-height: 38px;
		margin: 0;
		z-index: 2;
	}
	.hero-with-video__subtitle {
		${Montserrat(18, 400, 'white')};
		line-height: 38px;
		margin-top: 20px;
	}

	@media ${Device.md} {
		height: 400px;
		.hero-with-video__title {
			font-size: 50px;
			line-height: 55px;
		}
	}

	@media ${Device.xs} {
		flex-direction: column;
		height: 100%;

		.hero-with-video__red-shape {
			display: none;
		}
		.hero-with-video__title {
			text-align: center;
		}
		.video-container {
			position: relative;
			height: 317px;
			width: 100%;
		}
	}
`;
const Cover = styled.div`
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.3);
	position: absolute;
	left: 0;
	top: 0;
	@media ${Device.xs} {
		position: relative;
	}
`;

/*----- Component -----*/
const HeroWithVideo = ({ title, subtitle, videoUrl }) => {
	const { i18n } = useTranslation();
	return (
		<StyledHeroWithVideo className="hero-with-video" language={i18n.language}>
			<div className="hero-with-video__text-container">
				<h1 className="hero-with-video__title">{title}</h1>
				<h2 className="hero-with-video__subtitle">{subtitle}</h2>
				<img src={VideoRedShape} className="hero-with-video__red-shape " alt="" />
			</div>
			<Video videoUrl={videoUrl} height={400} />
			<Cover />
		</StyledHeroWithVideo>
	);
};

export default HeroWithVideo;

/*----- PropTypes -----*/
HeroWithVideo.propTypes = {
	title: PropTypes.string.isRequired
};
