/*----- Core -----*/
import React from 'react';
import PropTypes from 'prop-types';

/*----- Components-----*/
import Title from '../../../text/Title';

/*----- Styles-----*/
import { Container } from './styles';

const PeopleContainer = ({ title, children }) => (
	<Container>
		{children}

		<Title content={title} size={5} />
	</Container>
);

export default PeopleContainer;

/*----- PropTypes -----*/
PeopleContainer.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired
};
