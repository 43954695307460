import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import GenericDetailContainer from '../../../../commons/detail/hotels';

/*----- Utils -----*/
import { hotelSteps } from '../../../../../utilities/utils';
import {
	addCheckoutHotel,
	getCheckoutHotel
} from '../../../../../services/HotelCheckoutService';

import {
	getHotelInformation,
	getDetailCheckout
} from '../../checkout/section/detailsItemsHotel';

/*----- Selectors -----*/
import { showHotelData } from '../../../selectors/showHotelSelector';
import { getId } from '../../../../commons/checkout/CheckoutUtils';

const SuccessResponse = ({ history, showHotel }) => {
	return (
		<GenericDetailContainer
			id={getId(history)}
			service="hotels"
			showData={showHotel}
			getCheckout={getCheckoutHotel}
			active={1}
			dataStep={hotelSteps}
			addCheckout={addCheckoutHotel}
			getInformation={getHotelInformation}
			getDetailCheckout={getDetailCheckout}
		/>
	);
};

const mapStateToProps = state => {
	return {
		showHotel: showHotelData(state)
	};
};

export default connect(mapStateToProps, null)(withRouter(SuccessResponse));
