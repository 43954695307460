/*----- Actions -----*/
import {
	GET_PACKAGES,
	GET_PACKAGES_FAILED,
	GET_PACKAGES_SUCCESS,
	RESET_PACKAGES_FILTER
} from '../actions/searchFormActions';
import {
	addSearchQuery,
	getSearchQuery,
	getWithHotel
} from '../../../services/SearchService';

import PACKAGE_WITH_HOTEL_MOCK from '../PACKAGE_WITH_HOTEL_MOCK_Search.json';

const initialState = {
	data: [],
	isLoading: false,
	error: '',
	metadata: {},
	success: false,
	resetFilter: false
};

export default function packages(state = initialState, action) {
	switch (action.type) {
		case GET_PACKAGES:
			return {
				...state,
				data: [],
				isLoading: true,
				error: '',
				metadata: {},
				success: false
			};
		case GET_PACKAGES_SUCCESS:
			const withHotel = getWithHotel();
			const QUERY = withHotel
				? PACKAGE_WITH_HOTEL_MOCK.metadata.query
				: action.payload.metadata.query;

			const DATA = withHotel ? PACKAGE_WITH_HOTEL_MOCK.data : action.payload.data;

			const METADATA = withHotel
				? PACKAGE_WITH_HOTEL_MOCK.metadata
				: action.payload.metadata;

			addSearchQuery({ ...getSearchQuery(), ...QUERY });

			return {
				...state,
				data: DATA,
				metadata: METADATA,
				isLoading: false,
				success: true,
				resetFilter: false
			};
		case GET_PACKAGES_FAILED:
			return {
				...state,
				isLoading: false,
				error: action.payload,
				success: false,
				resetFilter: false
			};
		case RESET_PACKAGES_FILTER:
			return {
				...state,
				resetFilter: true
			};
		default:
			return state;
	}
}
