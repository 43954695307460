/*----- Core -----*/
import styled from 'styled-components';

/*----- Commons -----*/
import Device from '../../../../commons/Device';

import { Row, Col } from 'antd';

/*----- Export -----*/

export const Main = styled.main`
	margin-bottom: 50px;
`;

export const StyledRow = styled(Row)`
	padding-top: 50px;
	margin: 5px 80px;

	@media ${Device.xs} {
		padding-top: 10px;
		margin: 15px;
	}
`;

export const StyledCol = styled(Col)`
	padding: 10px !important;

	@media ${Device.xs} {
		padding: 0;
	}
`;
