/*----- Core -----*/
import React from 'react';
import PropTypes from 'prop-types';
/*----- Components -----*/
import { Modal } from 'antd';
import Color from '../../commons/Color';

const GenericModal = ({
	visible,
	onCancel,
	title,
	content,
	width = 1000,
	className = 'generic-modal',
	closable,
	bodyStyle
}) => {
	return (
		<Modal
			width={width}
			title={<h3 style={{ color: `${Color.text}`, fontWeight: 'bold' }}>{title}</h3>}
			visible={visible}
			onCancel={onCancel}
			footer={null}
			className={className}
			closable={closable}
			bodyStyle={bodyStyle}
		>
			{content}
		</Modal>
	);
};

GenericModal.propTypes = {
	onCancel: PropTypes.func,
	visible: PropTypes.bool
};

export default GenericModal;
