import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { Row } from 'antd';

import { ButtonCheckoutContainer } from '../../../components/containers/CheckoutContainer';
import DefaultButton from '../../../components/button/DefaultButton';
import CheckoutPassengers from './CheckoutPassengers';
import AddToCart from './AddToCart';
import { useTranslation } from 'react-i18next';
import { StyledSlideDown } from './styles';
import { useState } from 'react';
import { getCart } from '../../../services/CartCheckoutService';

const ReservationCheckout = ({
	continueReservation,
	form,
	id,
	checkoutHandleSubmit,
	addToCartHandler,
	setContinueReservation,
	passengersRequired,
	dataCheckout,
	history,
	setPassengersParsed,
	passengers,
	service
}) => {
	const { t } = useTranslation();
	const [packageAdded, setPackageAdded] = useState(false);
	const cart = getCart();
	useEffect(() => {
		if (cart && service) {
			const found = cart.find(item => item.type === 'package');
			if (service === 'packages' && found) {
				setPackageAdded(true);
				setContinueReservation(true);
			}
		}
	}, [cart, setContinueReservation, service]);

	if (!continueReservation)
		return (
			<AddToCart
				checkoutHandleSubmit={checkoutHandleSubmit}
				addToCartHandler={addToCartHandler}
				continueReservation={() => setContinueReservation(true)}
				service={service}
				form={form}
				id={id}
			/>
		);
	return (
		<>
			<StyledSlideDown>
				<CheckoutPassengers
					form={form}
					passengersRequired={passengersRequired}
					dataCheckout={dataCheckout}
					countPassengers={passengers}
				/>
			</StyledSlideDown>
			<Row type="flex" justify="center">
				<ButtonCheckoutContainer>
					<DefaultButton
						onClick={event =>
							checkoutHandleSubmit(
								event,
								form,
								history,
								service,
								id,
								setPassengersParsed
							)
						}
						content={t('checkout.confirmReservation')}
					/>
				</ButtonCheckoutContainer>
				{!packageAdded && (
					<ButtonCheckoutContainer>
						<DefaultButton
							onClick={() => setContinueReservation(false)}
							content={t('checkout.cancel')}
							secondary
						/>
					</ButtonCheckoutContainer>
				)}
			</Row>
		</>
	);
};

export default withRouter(ReservationCheckout);
