import React from 'react';
import { connect } from 'react-redux';
import { StyledMain } from './styles';
import LoginModal from './loginModal';
import RecoveryPassword from './feedback/RecoveryPassword';
import Content from './Content';
import LoginTabs from './LoginTabs';
import SuccessResponseRecovery from './feedback/SuccessResponseRecovery';
import SuccessResponseRegister from './feedback/SuccessResponseRegister';
import FailedResponseRegister from './feedback/FailedResponseRegister';

/*----- Selectors -----*/
import { visible } from '../../authentication/selectors/loginModalSelector';

/*----- Actions -----*/
import { closeModal } from '../../authentication/actions/loginModalActions';
import { deleteRecovery } from '../../authentication/actions/recoveryActions';

const Login = ({ closeModal, visible, deleteRecovery }) => {
	const closeFunction = () => {
		closeModal();
		deleteRecovery();
	};

	return (
		<LoginModal closeModal={closeFunction} visible={visible}>
			<StyledMain>
				<Content
					Tabs={LoginTabs}
					SuccessRegister={SuccessResponseRegister}
					FailedRegister={FailedResponseRegister}
					RecoveryPasswordForm={RecoveryPassword}
					SuccessRecoveryPassword={SuccessResponseRecovery}
				/>
			</StyledMain>
		</LoginModal>
	);
};

const mapStateToProps = state => {
	return {
		visible: visible(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		closeModal: payload => closeModal(dispatch, payload),
		deleteRecovery: payload => deleteRecovery(dispatch, payload)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
