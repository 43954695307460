import styled from 'styled-components';
import Device from '../../../commons/Device';
import Color from '../../../commons/Color';
import { Row, Col } from 'antd';
import Text from '../../../components/text/Text';

export const StyledResultContainer = styled(Row)`
	padding-top: 0px;
	width: 100%;

	@media ${Device.xs} {
		padding: 0px 24px;
	}
`;

export const StyledCardContainer = styled(Col)`
	margin: 16px;
`;

export const FilterResultContainer = styled(Col)`
	margin: 16px;
`;

export const BreadcrumbsText = styled.p`
	margin-bottom: 14px;
	font-weight: 700;
	font-size: 14px;
	color: ${Color.footer};
`;

export const TitleSection = styled(Text)`
	font-size: 18px;
	color: ${Color.footer};
	@media ${Device.xs} {
		text-align: center;
		margin: 5px 0px 25px 0px;
	}
`;

export const TitleWithIcon = styled.div`
	display: flex;
	align-items: center;
	justify-items: center;
	margin-top: 15px;

	svg {
		width: 25px;
		height: 25px;
		fill: ${Color.main};
	}
	.custom-title {
		color: ${Color.footer};
		font-size: 18px;
		margin: 14px;
	}
`;
