import styled from 'styled-components';
import { Row } from 'antd';

export default styled.main`
	padding-bottom: 64px;
	display: flex;
	flex-direction: column;
	.attributes-list {
		box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
	}
	.hero-text {
		max-width: 100%;
	}
`;

export const StyledTableContainer = styled(Row)`
	overflow: auto;
	padding: 1rem;
`;
