import {
	REGISTER_CONFIRM,
	REGISTER_CONFIRM_SUCCESS,
	REGISTER_CONFIRM_FAILED,
	REGISTER_CONFIRM_ERROR
} from '../actions/registerConfirmActions';

const initialState = {
	isLoading: false,
	success: false,
	invalidToken: false
};

export function registerConfirm(state = initialState, action) {
	switch (action.type) {
		case REGISTER_CONFIRM:
			return {
				...state,
				isLoading: true
			};
		case REGISTER_CONFIRM_SUCCESS:
			return {
				...state,
				success: true,
				isLoading: false
			};
		case REGISTER_CONFIRM_FAILED:
			return {
				...state,
				success: false,
				invalidToken: false,
				isLoading: false
			};
		case REGISTER_CONFIRM_ERROR:
			return {
				...state,
				success: false,
				invalidToken: true,
				isLoading: false
			};
		default:
			return state;
	}
}

export default registerConfirm;
