import { call, put, takeLatest } from 'redux-saga/effects';
import {
	GET_TRANSFERS,
	GET_TRANSFERS_SUCCESS,
	GET_TRANSFERS_FAILED
} from '../actions/searchFormActions';
import { getTransfers } from '../../../api/endpoints/Transfers';

function* shotFetchTransfers(action) {
	function* onSuccess(body) {}

	function* onOk(body) {
		yield put({ type: GET_TRANSFERS_SUCCESS, payload: body });
	}

	function* onError(error) {
		yield put({ type: GET_TRANSFERS_FAILED, payload: error });
	}

	try {
		yield call(getTransfers, action.payload, {
			onSuccess: onSuccess,
			onError: onError,
			onOk: onOk
		});
	} catch (error) {
		yield onError(error.message);
	}
}

export default function* TransfersSagas() {
	yield takeLatest(GET_TRANSFERS, shotFetchTransfers);
}
