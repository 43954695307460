/*----- Core -----*/
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

/*----- Component -----*/
import { Form, Button, Row, Col } from 'antd';
import Title from '../../components/text/Title';
import Paragraph from '../../components/text/Paragraph';
import Color from '../../commons/Color';

/*----- Form items-----*/
import {
	nameRules,
	emailRules,
	phoneRules,
	radioGroupRules,
	commentRules
} from '../formItems/InputRules';
import InputItem from '../formItems/InputItem';
import RadioGroupItem from '../formItems/RadioGroupItem';
import TextAreaItem from '../formItems/TextAreaItem';
import InputNumberItem from '../formItems/InputNumberItem';

const hasErrors = fieldsError => {
	return Object.keys(fieldsError).some(field => fieldsError[field]);
};

const handleFormSubmit = (event, form, sendEmail) => {
	form.validateFields();
	event.preventDefault();
	if (hasErrors(form.getFieldsError())) {
		console.log('Error de validación');
	} else {
		sendEmail(form.getFieldsValue());
		form.resetFields();
	}
};

const { Item } = Form;

const StyledFormContainer = styled.div`
	.ant-form-item label {
		margin: 0px;
	}
	.ant-col .ant-form-item-control-wrapper {
		padding-right: 10px;
	}

	.ant-form-item-label {
		font-weight: 700;
	}

	.alert {
		color: #721c24;
		background-color: #f8d7da;
		width: 100%;
		border-radius: 5px;
		padding: 10px;
		text-align: center;
		font-family: 'Montserrat', sans-serif;
		font-weight: 600;
	}
`;

const ConsultForm = ({ form, sendEmail, requestError }) => {
	const { t } = useTranslation();
	const { getFieldDecorator } = form;
	const optionsRadio = [
		{ value: 'Cliente Final', label: t('consult.form.clientFinal') },
		{ value: 'Empresa', label: t('consult.form.company') }
	];

	return (
		<StyledFormContainer>
			<Row>
				<Title content={t('contact.title')} size={4} color={'secondary'} />
				<Paragraph content={t('contact.subtitle')} />
			</Row>
			<Form
				onSubmit={event => {
					handleFormSubmit(event, form, sendEmail);
				}}
				className="login-form"
			>
				<Row>
					<Col xs={24} md={12}>
						<InputItem
							label={t('consult.form.name')}
							fieldName={'name'}
							placeholder={'Franco Gomez'}
							getFieldDecorator={getFieldDecorator}
							rules={nameRules}
						/>
					</Col>
					<Col xs={24} md={12}>
						<InputItem
							label={t('consult.form.email')}
							fieldName={'email'}
							placeholder={'fgomez@gmail.com'}
							getFieldDecorator={getFieldDecorator}
							rules={emailRules}
						/>
					</Col>
					<Col xs={24} md={12}>
						<InputNumberItem
							label={t('consult.form.telephone')}
							fieldName={'phone'}
							placeholder={'+55 21 2134-1425'}
							getFieldDecorator={getFieldDecorator}
							rules={phoneRules}
						/>
					</Col>
					<Col xs={24} md={12}>
						<RadioGroupItem
							label={t('consult.form.clientType')}
							fieldName={'clientType'}
							initialValue={optionsRadio[0].value}
							getFieldDecorator={getFieldDecorator}
							rules={radioGroupRules}
							options={optionsRadio}
						/>
					</Col>
					<Col span={24}>
						<TextAreaItem
							label={t('consult.form.comments')}
							fieldName={'message'}
							getFieldDecorator={getFieldDecorator}
							rules={commentRules}
							rows={3}
						/>
					</Col>

					<Col span={24}>
						<Item>
							<Button
								type="primary"
								htmlType="submit"
								className="login-form-button"
								style={{
									width: '100%',
									backgroundColor: Color.main,
									fontWeight: 600,
									border: 'none'
								}}
								// disabled  = { isLoading }
							>
								{t('consult.form.send')}
							</Button>
						</Item>
					</Col>
					{requestError ? (
						<Col span={24}>
							<div className="alert">{requestError}</div>
						</Col>
					) : (
						''
					)}
				</Row>
			</Form>
		</StyledFormContainer>
	);
};

function mapStateToProps(state) {
	return {};
}

function mapDispatchToProps(dispatch) {
	return {};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Form.create({ name: 'consult_form' })(ConsultForm));
