/*----- Core -----*/
import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

/*----- Assets -----*/
import hotel_1 from '../../../../assets/images/hotel_1.jpg';

/*----- Ant design -----*/
import { Row, Col } from 'antd';

/*----- Components -----*/
import ConsultForm from '../../../../components/form/ConsultForm';

import HeroWithImage from '../../../../components/hero/HeroWithImage';
import HotelCard from '../../../../components/card/HotelCard';
import SearchFormHotels from '../searchForm';
import SearchFormCard from '../../../../components/card/SearchFormCard';
/*----- Styles -----*/
import Styles from './styles';
/*----- Actions -----*/
import { toggleModal } from '../../../app/AppActions';

import { hotelsData } from './data';

/*----- Export -----*/
const Hotels = props => {
	const { t } = useTranslation();
	const { toggleModal } = props;

	const renderHotels = () => {
		return hotelsData.map((hotel, i) => (
			<Col xs={24} sm={12} lg={6} className="card-container" key={i}>
				<HotelCard
					title={hotel.name}
					imageUrl={hotel.image}
					stars={hotel.star}
					destination="/home"
					onClick={() => toggleModal('Hotel', hotel.name, t(hotel.description))}
				/>
			</Col>
		));
	};
	return (
		<Styles>
			<ConsultForm></ConsultForm>
			<HeroWithImage
				title={t('hotels.title')}
				subtitle={t('hotels.subtitle')}
				imageUrl={hotel_1}
			/>

			<SearchFormCard
				content={<SearchFormHotels header={t('hotels.titleForm')} />}
			/>

			<Row type="flex" justify="start" className="card__list-container">
				{renderHotels()}
			</Row>
		</Styles>
	);
};

const mapStateToProps = state => {
	return {};
};

const mapDispatchToProps = dispatch => {
	return {
		toggleModal: (type, name, description) =>
			toggleModal(dispatch, type, name, description)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Hotels);
