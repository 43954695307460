/*----- Core -----*/
import React from 'react';

/*----- Styles -----*/
import { ArticleCenter, SearchContainer } from './styles';

const SearchFormCard = ({ className, content, marginTopOffset = '-64px' }) => {
	return (
		<ArticleCenter className={className}>
			<SearchContainer marginTopOffset={marginTopOffset}>{content}</SearchContainer>
		</ArticleCenter>
	);
};

export default SearchFormCard;
