/*----- Core -----*/
import React from 'react';
import { connect } from 'react-redux';

/*----- Ant design -----*/
import { Form } from 'antd';

/*----- Components -----*/
import StepsContainer from '../../../../../components/steps/StepsContainer';
import CheckoutActivityData from './CheckoutActivityData';
import {
	ItemCheckoutContainer,
	CheckoutContainer
} from '../../../../../components/containers/CheckoutContainer';

/*----- Actions -----*/
import { activitiesSteps } from '../../../../../utilities/utils';
import ActivityForm from './ActivityForm';
import CheckoutInfo from '../../../../commons/checkout/CheckoutInfo';
import { showActivityData } from '../../../selectors/showActivitySelector';
import { getId } from '../../../../commons/checkout/CheckoutUtils';
import { withRouter } from 'react-router';

const CheckoutActivity = ({ history, showActivity }) => {
	const id = getId(history);

	return (
		<CheckoutContainer>
			<StepsContainer active={2} rate_id={2} dataSteps={activitiesSteps(id)} />
			<ItemCheckoutContainer>
				<CheckoutInfo
					CardCheckoutData={
						<CheckoutActivityData showActivity={showActivity} id={id} />
					}
				/>
			</ItemCheckoutContainer>

			<ActivityForm />
		</CheckoutContainer>
	);
};

const mapStateToProps = state => {
	return {
		showActivity: showActivityData(state)
	};
};

export default connect(
	mapStateToProps,
	null
)(Form.create({ name: 'checkout_form_activity' })(withRouter(CheckoutActivity)));
