import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

/*----- Components -----*/
import Color from '../../../commons/Color';
import { Row, Icon } from 'antd';
import { Form, Col } from 'antd';
import { StyledTextArea } from '../../../components/form/styles';

const ObservationTitle = styled.p`
	display: inline-block;
	padding-top: 16px;
	font-size: 14px;
	font-weight: 700;
	color: ${Color.main};
	&:hover {
		cursor: pointer;
	}

	.anticon svg {
		display: block;
	}
`;

const ObservationForm = ({ form, value }) => {
	const [visible, setVisible] = useState(value ? true : false);
	const { t } = useTranslation();

	return (
		<>
			<ObservationTitle
				onClick={() => {
					setVisible(!visible);
				}}
			>
				{t('checkout.comment.title')} &nbsp;{' '}
				{<Icon type={visible ? 'up' : 'down'} />}
			</ObservationTitle>
			{visible && (
				<Row type="flex">
					<Col span={24}>
						<Form.Item>
							{form.getFieldDecorator(`observation`, {
								initialValue: value
							})(
								<StyledTextArea
									rows={5}
									placeholder={t('checkout.comment.description')}
									withoutLabel
								/>
							)}
						</Form.Item>
					</Col>
				</Row>
			)}
		</>
	);
};

export default ObservationForm;
