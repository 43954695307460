/*----- Core -----*/
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';

/*----- Assets -----*/
import locationIcon from '../../../../assets/icons/location.svg';

/*----- Commons -----*/
import Color from '../../../../commons/Color';

/*----- Components -----*/
import Title from '../../../../components/text/Title';

/*----- Styles -----*/
const StyledMapContainer = styled.div`
	width: 100%;
	padding: 0 5px;
	.map-container {
		height: 50vh;
		width: 100%;
		margin: 15px auto;
	}

	.map-container__icon {
		width: 30px;
	}
	.custom-title {
		margin: 4px 0;
		color: ${Color.text};
		font-weight: bold;
	}
`;

/*----- Components -----*/
const AnyReactComponent = ({ alt }) => (
	<div>
		<img className="map-container__icon" src={locationIcon} alt={alt} />
	</div>
);

const Map = ({ location, address }) => {
	const defaultProps = {
		center: {
			lat: location.latitude || -34.9087897,
			lng: location.longitude || -57.9485282
		},
		zoom: 17
	};

	return (
		<StyledMapContainer>
			<Title content="Ubicación" size={3} />
			<Title content={address} size={5} />
			<div className="map-container">
				<GoogleMapReact
					bootstrapURLKeys={{ key: 'api_key' }}
					defaultCenter={defaultProps.center}
					defaultZoom={defaultProps.zoom}
				>
					<AnyReactComponent
						lat={defaultProps.center.lat}
						lng={defaultProps.center.lng}
						alt="Hotel location"
					/>
				</GoogleMapReact>
			</div>
		</StyledMapContainer>
	);
};

export default Map;

Map.propTypes = {
	address: PropTypes.string.isRequired,
	location: PropTypes.shape({
		location: PropTypes.number,
		longitude: PropTypes.number
	})
};
