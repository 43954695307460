/*----- Core -----*/
import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

/*----- Components -----*/
import ActivityCard from '../../../components/card/ActivityCard';

/*----- Data -----*/
import { activitiesData } from './FeatureData';
/*----- Actions -----*/
import { toggleModal } from '../../app/AppActions';

/*----- Styles -----*/
import Styles from '../HomeStyles';

/*----- Ant design -----*/
import { Row, Col } from 'antd';
import Title from '../../../components/text/Title';

function FeaturedActivities({ toggleModal }) {
	const { t } = useTranslation();

	const renderActivities = () => {
		return activitiesData.map((activity, i) => (
			<Col xs={24} sm={12} lg={6} className="card-container" key={i}>
				<ActivityCard
					title={t(activity.name)}
					imageUrl={activity.image}
					destination="/home"
					onClick={() =>
						toggleModal('Actividad', t(activity.name), t(activity.description))
					}
				/>
			</Col>
		));
	};
	return (
		<Styles>
			<Row className="featured-card__title">
				<Title
					content={t('activities.mainActivities')}
					size={2}
					color={'secondary'}
				/>
			</Row>
			<Row type="flex" justify="center" className="card__list-container">
				{renderActivities()}
			</Row>
		</Styles>
	);
}
function mapStateToProps(state) {
	return {};
}

function mapDispatchToProps(dispatch) {
	return {
		toggleModal: (type, name, description) =>
			toggleModal(dispatch, type, name, description)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedActivities);
