/*----- Core -----*/
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Color from '../../commons/Color';

/*----- Commons -----*/
import OpenSansBold from '../../commons/mixins/fonts/OpenSansBold';

/*----- Styles -----*/
const common = `
	margin: 16px;
`;
const H1 = styled.h1`
	${OpenSansBold(34, 600, 'Black')};
	color: ${props =>
		!props.color || props.color === 'primary'
			? Color.main
			: props.color === 'secondary'
			? Color.footer
			: props.color};
	${common}
`;
const H2 = styled.h2`
	${OpenSansBold(28, 600, 'Black')};
	color: ${props =>
		!props.color || props.color === 'primary'
			? Color.main
			: props.color === 'secondary'
			? Color.footer
			: props.color};
	${common}
`;
const H3 = styled.h3`
	${OpenSansBold(20, 600, 'Black')};
	color: ${props =>
		!props.color || props.color === 'primary'
			? Color.main
			: props.color === 'secondary'
			? Color.footer
			: props.color};
	${common}
`;
const H4 = styled.h4`
	${OpenSansBold(16, 600, 'Black')};
	color: ${props =>
		!props.color || props.color === 'primary'
			? Color.main
			: props.color === 'secondary'
			? Color.footer
			: props.color};
	${common}
`;
const H5 = styled.h5`
	${OpenSansBold(14, 600, 'Black')};
	color: ${props =>
		!props.color || props.color === 'primary'
			? Color.main
			: props.color === 'secondary'
			? Color.footer
			: props.color};
	${common}
`;

/*----- Component -----*/
function Title({ content, size, className, color = 'primary' }) {
	switch (size) {
		case 1:
			return (
				<H1 className={`custom-title ${className}`} color={color}>
					{content}
				</H1>
			);
		case 2:
			return (
				<H2 className={`custom-title ${className}`} color={color}>
					{content}
				</H2>
			);
		case 3:
			return (
				<H3 className={`custom-title ${className}`} color={color}>
					{content}
				</H3>
			);
		case 4:
			return (
				<H4 className={`custom-title ${className}`} color={color}>
					{content}
				</H4>
			);
		case 5:
			return (
				<H5 className={`custom-title ${className}`} color={color}>
					{content}
				</H5>
			);
		default:
			return (
				<H1 className={`custom-title ${className}`} color={color}>
					{content}
				</H1>
			);
	}
}

export default Title;

/*----- PropTypes -----*/
Title.propTypes = {
	size: PropTypes.number.isRequired,
	content: PropTypes.string.isRequired
};
