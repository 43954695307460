import { Row, Col } from 'antd';
import styled, { css } from 'styled-components';
import Color from '../../../commons/Color';
import Device from '../../../commons/Device';

export const StyledCartCheckout = styled.main`
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-bottom: 4rem;
`;

export const StyledConfirmContainer = styled.section`
	margin-top: 3rem;
`;

export const hoverCart = css`
	transition: all 0.75s;
	svg {
		fill: ${Color.main} !important;
		transform: scale(1.125);
	}
`;

export const StyledRow = styled(Row)`
	margin-top: 2rem;
	position: relative;
`;

export const StyledCheckoutRemoveButton = styled.span`
	svg {
		width: 25px;
		height: 25px;
		fill: ${Color.footer};
	}
	display: flex;
	align-items: center;
	height: 100%;
	justify-content: center;
	&:hover {
		${hoverCart}
		cursor:pointer;
	}
`;

export const StyledCheckoutRemoveButtonCol = styled(Col)`
	border-left: 1px solid #0000001f;
	background: #f0efef;
	border-radius: 0 10px 10px 0;
	margin-top: 4px;
	margin-bottom: 4px;
	@media ${Device.xs} {
		position: absolute !important;
		right: 1.5rem;
		bottom: 1.5rem;
		border: none;
		background: white;
	}
`;

export const StyledTitleWrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding-bottom: 3rem;
`;

export const StyledServiceErrorWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
`;

export const StyledServiceError = styled.h5`
	padding: 1rem;
	width: 100%;
	text-align: start;
	margin: 0;
`;

export const StyledServiceErrorRow = styled.div`
	display: flex;
	flex-direction: row;
	height: 50px;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	svg {
		height: 30px;
		width: 30px;
	}
`;
