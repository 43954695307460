import styled from 'styled-components';
import Text from '../../../../components/text/Text';
import Device from '../../../../commons/Device';
import Color from '../../../../commons/Color';
import SearchSelect from '../../../../components/form/SearchSelect';

export const MainDiv = styled.div`
	background-color: #ffffff;
	border-radius: 10px;
	padding: 30px;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 20px #00000029;
`;

const CommonText = styled(Text)`
	font-size: 16px;
`;

export const StyledTitle = styled(CommonText)`
	margin-bottom: 30px;

	@media ${Device.xs} {
		margin-bottom: 10px;
	}
`;

export const StyledText = styled(CommonText)`
	margin: 0px;

	@media ${Device.xs} {
		font-size: 12px;
	}
`;

export const StyledTextMail = styled(CommonText)`
	color: ${Color.main};
	margin: 25px 0px;

	@media ${Device.xs} {
		font-size: 12px;
	}
`;

export const StyledTextSelect = styled(Text)`
	font-size: 12px;
	color: #86858a;

	@media ${Device.xs} {
		font-size: 12px;
	}
`;

export const StyledSearchSelect = styled(SearchSelect)`
	padding: 0px !important;
	width: 20%;

	.ant-select-selection {
		border-radius: 10px;
	}

	@media ${Device.xs} {
		width: 100%;
	}
`;
