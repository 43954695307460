/*----- Core -----*/
import React from 'react';
import PropTypes from 'prop-types';
import DefaultSelect from '../../DefaultSelect';
import getOptions from './options';

const ChildrenAgeSelect = ({ index, onChange, className, value }) => (
	<div key={index} className={className}>
		<DefaultSelect
			defaultValue={value}
			name={`Edad del menor ${index + 1}`}
			options={getOptions()}
			onChange={onChange}
		/>
	</div>
);

export default ChildrenAgeSelect;

/*----- PropTypes -----*/
ChildrenAgeSelect.propTypes = {
	index: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
	className: PropTypes.string
};
