import React from 'react';
import { useTranslation } from 'react-i18next';
import ResponseWithImage from '../ResponseWithImage';
import ErrorRegisterConfirmImage from '../../../assets/images/ErrorRegisterConfirmImage.svg';

const ErrorResponseReservation = () => {
	const { t } = useTranslation();

	return (
		<ResponseWithImage
			image={ErrorRegisterConfirmImage}
			title={t('reservation.error')}
		/>
	);
};

export default ErrorResponseReservation;
