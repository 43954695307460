/*----- Core -----*/
import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

/*----- Components -----*/
import PackageCard from '../../../../components/card/PackageCard';
import ConsultForm from '../../../../components/form/ConsultForm';
import HeroWithImage from '../../../../components/hero/HeroWithImage';

/*----- Assets -----*/
import backgroundImage from '../../../../assets/images/packages/catartaseb.jpg';

/*----- Styles -----*/
import { Main, StyledRow, StyledCol } from './styles';

/*----- Actions -----*/
import { toggleModal } from '../../../app/AppActions';

/*----- Data -----*/
import { packagesData } from './data';
import SearchFormPackages from '../searchForm';
import SearchFormCard from '../../../../components/card/SearchFormCard';

const Packages = props => {
	const { t } = useTranslation();
	const { toggleModal } = props;

	const renderPackages = () => {
		return packagesData.map((packageCard, i) => (
			<StyledCol xs={24} sm={12} lg={6} key={i}>
				<PackageCard
					title={t(packageCard.name)}
					imageUrl={packageCard.image}
					services={packageCard.services}
					onClick={() =>
						toggleModal('Paquete', t(packageCard.name), t(packageCard.description))
					}
				/>
			</StyledCol>
		));
	};

	return (
		<Main>
			<ConsultForm />
			<HeroWithImage
				title={t('packages.title')}
				subtitle={t('packages.subtitle')}
				imageUrl={backgroundImage}
			/>

			<SearchFormCard
				content={<SearchFormPackages header={t('packages.titleForm')} />}
			/>

			<StyledRow type="flex" justify="center">
				{renderPackages()}
			</StyledRow>
		</Main>
	);
};

const mapStateToProps = state => {
	return {};
};

const mapDispatchToProps = dispatch => {
	return {
		toggleModal: (type, name, description) =>
			toggleModal(dispatch, type, name, description)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Packages);
