import React from 'react';
import {
	Styles,
	LogoImage,
	StyledCol,
	MainRow,
	BackgroundImage,
	StyledModal,
	StyledLoginMotto
} from './styles';
import logo from '../../assets/logo.svg';
import Background from '../../assets/images/header-back.png';

const LoginModal = ({ closeModal, visible, children }) => {
	return (
		<StyledModal
			visible={visible}
			onCancel={closeModal}
			footer={null}
			width="976px"
			closable={false}
			bodyStyle={{ padding: '0px' }}
			content={
				<Styles>
					<MainRow>
						<StyledCol span={12}>
							<BackgroundImage src={Background} alt="back" />
						</StyledCol>
						<StyledCol span={12}>
							<LogoImage src={logo} alt="logo" />
							<StyledLoginMotto />
						</StyledCol>
					</MainRow>
					{children}
				</Styles>
			}
		/>
	);
};

export default LoginModal;
