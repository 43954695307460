import MoonIcon from '../../../../../assets/icons/moon-clasic.svg';
import MapIcon from '../../../../../assets/icons/services/map.svg';
import RoomIcon from '../../../../../assets/icons/checkout/room.svg';
import HotelIcon from '../../../../../assets/icons/custom_icons/hotel.svg';
import RegimeIcon from '../../../../../assets/icons/checkout/reception-bell.svg';
import PersonIcon from '../../../../../assets/icons/custom_icons/user.svg';
import TransfersIcon from '../../../../../assets/icons/checkout/transfer.svg';

import { getSearchQuery } from '../../../../../services/SearchService';
import moment from 'moment';
import {
	getHotelInformation,
	getPassengerInformation
} from '../../../../hotels/views/checkout/section/detailsItemsHotel';

export const getPackagesInformation = (packages, dataCheckout) => {
	let searchQuery = getSearchQuery();
	let withHotel = searchQuery.withHotel;

	let rateInformation = withHotel
		? getHotelInformation(packages.hotel, dataCheckout.item_id)
		: {};

	const passengerData = getPassengerInformation();

	rateInformation.currency = packages.rate.currency;
	rateInformation.amount = packages.rate.amount_details.passenger.sale;
	rateInformation.iva = packages.rate.amount_details.passenger.iva;

	withHotel
		? (rateInformation.hotel = packages.hotel.name)
		: (rateInformation.passengers = passengerData.passengersText);

	return {
		name: packages.name,
		image: packages.image,
		date_in: searchQuery.date_in,
		date_out: searchQuery.date_out,
		...rateInformation
	};
};

export const getDetailCheckout = (
	data,
	keys = ['passengers', 'regime', 'roomType', 'transfers']
) => {
	let icons = [];
	if (keys.includes('hotel')) {
		icons.push({ icon: HotelIcon, text: data.hotel });
	}
	if (keys.includes('passengers')) {
		icons.push({ icon: PersonIcon, text: data.passengers });
	}
	if (keys.includes('regime')) {
		icons.push({ icon: RegimeIcon, text: data.regime });
	}
	if (keys.includes('roomType')) {
		icons.push({ icon: RoomIcon, text: data.roomType });
	}
	if (keys.includes('transfers')) {
		icons.push({ icon: TransfersIcon, text: 'Traslados' });
	}
	if (keys.includes('nights')) {
		icons.push({ icon: MoonIcon, text: data.nights });
	}
	if (keys.includes('activities')) {
		icons.push({ icon: MapIcon, text: 'Actividades' });
	}
	return icons;
};

export const getDetailCheckoutWithoutHotel = data => {
	return getDetailCheckout(data, ['passengers', 'transfers', 'activities']);
};

export const getDetailCheckoutWithHotel = data => {
	return getDetailCheckout(data, [
		'hotel',
		'passengers',
		'regime',
		'roomType',
		'transfers',
		'nights'
	]);
};

export const getDetailReservationCard = (data, keys) => {
	if (keys && !keys.includes('nights')) keys.push('transfers');

	return getDetailCheckout(data, keys);
};

export const getNights = () => {
	let searchQuery = getSearchQuery();
	let nights = moment(searchQuery.date_out).diff(
		moment(searchQuery.date_in),
		'days'
	);
	nights = `${nights} ${nights > 1 ? 'noches' : 'noche'}`;
	return nights;
};
