import React from 'react';
import {
	ItemContainer,
	ButtonContainer,
	StyledCheckoutContainer,
	StyledBoxContainer,
	ItemConfirmContainer,
	StyledCol
} from './styles';
import { Col } from 'antd';

export const ItemCheckoutContainer = ({ size = 21, ...props }) => (
	<ItemContainer type="flex" justify="center">
		<Col span={size}>{props.children}</Col>
	</ItemContainer>
);

export const ButtonCheckoutContainer = props => (
	<ButtonContainer type="flex" justify="center">
		<Col>{props.children}</Col>
	</ButtonContainer>
);

export const CheckoutContainer = props => (
	<StyledCheckoutContainer>{props.children}</StyledCheckoutContainer>
);

export const CheckoutBoxContainer = props => (
	<StyledBoxContainer>{props.children}</StyledBoxContainer>
);

export const ItemCheckoutConfirmContainer = props => (
	<ItemConfirmContainer type="flex" justify="center">
		<Col span={24}>{props.children}</Col>
	</ItemConfirmContainer>
);

export const ItemCheckoutConfirmContainerRow = props => (
	<ItemConfirmContainer type="flex" justify="center">
		<StyledCol span={24}>{props.children}</StyledCol>
	</ItemConfirmContainer>
);
