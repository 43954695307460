import styled, { css } from 'styled-components';
import Device from '../../../commons/Device';
import defaultHotel from '../../../assets/images/default_background_hotel.jpeg';
import Color from '../../../commons/Color';
import { Col, Row } from 'antd';

export const RowCardContainer = styled(Row)`
	max-width: 1000px;
	border-radius: 6px;
	border: 1px solid lightgrey;
	background-color: white;
	width: 100%;
	margin: 16px 0;
	margin-top: 0px;
	box-shadow: 0px 8px 20px #0000001f;
	transition: all 0.2s ease-in;

	&:hover {
		box-shadow: 0 10px 14px 0px rgba(0, 0, 0, 0.1);
		transform: scale(1.02);
	}
`;

export const CardDescription = styled.p`
	line-height: 18px;
	font-size: 11px;
	font-weight: 400;
	color: ${Color.text};
	margin: 0px;
	order: 3;

	@media ${Device.xs} {
		font-size: 12px;
		text-align: center;
		order: 4;
	}
`;

export const IconsRow = styled(Row)`
	width: 90%;
	border-top: 1px solid #00000029;
	bottom: 0;

	@media ${Device.xs} {
		display: none !important;
	}
`;

export const InfoRow = styled(Row)`
	@media ${Device.sm} {
		padding-top: 3%;
		height: 85%;
	}
`;

export const ColCardInfo = styled(Col)`
	height: fit-content;
	padding: 15px 35px 0px 35px !important;

	@media ${Device.xs} {
		padding: 16px 25px 16px 25px !important;
	}
`;

export const ColCardImage = styled(Col)`
	${props =>
		props.image_url &&
		css`
			background-image: url(${props.image_url}), url(${defaultHotel});
		`}
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
	background-position: center center;
	background-size: cover;
	height: 260px;
	align-self: center;
`;
