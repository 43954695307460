/*----- Imports -----*/
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Text from '../../components/text/Text';
import Color from '../../commons/Color';
import { formatCurrency } from '../../utilities/utils';
import Device from '../../commons/Device';

/*----- Ant design-----*/
import { Slider } from 'antd';

/*----- Components-----*/
import Label from './Label';
import { InputContainer } from './styles';

const TextContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const TextValue = styled(Text)`
	color: ${props => (props.disabled ? Color.text : Color.main)};
	font-size: 14px;
	line-height: 28px;
	@media ${Device.xs} {
		font-size: 17px;
		line-height: 32px;
	}
`;

export const TextTotal = styled(Text)`
	color: ${Color.text};
	font-size: 14px;
	line-height: 28px;
	@media ${Device.xs} {
		font-size: 16px;
		line-height: 32px;
	}
`;

const CustomSlider = (
	{
		min,
		max,
		name,
		content = '',
		onChange,
		value,
		onAfterChange,
		className,
		disabled = false
	},
	ref
) => (
	<InputContainer className={className}>
		<Label name={name} />
		<TextContainer>
			<TextValue
				bold
				content={formatCurrency(value[0])}
				disabled={disabled}
			/>
			<TextValue
				bold
				content={formatCurrency(value[1])}
				disabled={disabled}
			/>
		</TextContainer>
		<Slider
			value={value}
			min={min}
			max={max}
			range
			onChange={onChange}
			ref={ref}
			onAfterChange={onAfterChange}
			disabled={disabled}
			tooltipVisible={null}
		/>
		<TextContainer>
			<TextTotal content={formatCurrency(min)} />
			<TextTotal content={formatCurrency(max)} />
		</TextContainer>
	</InputContainer>
);
export default forwardRef(CustomSlider);

/*----- PropTypes -----*/
CustomSlider.propTypes = {
	min: PropTypes.number.isRequired,
	max: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	disabled: PropTypes.bool
};
