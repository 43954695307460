import BASE_URL from '../BaseUrl';
import { getAuthToken } from '../../sessions/loginSession';
import requestDecorator from '../../decorators/RequestDecorator';
import { stringify } from '../../utilities/queryString';
import i18n from 'i18next';

export function* getMyReservations(params, cbResponse) {
	yield requestDecorator(
		function* (params) {
			return yield fetch(
				`${BASE_URL}/reserves/index${params ? '?' : ''}${stringify(params)}`,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${getAuthToken()}`,
						'Accept-Language': `${i18n.language}`
					}
				}
			);
		},
		params,
		cbResponse
	);
}

export function* cancelReservation(params, cbResponse) {
	yield requestDecorator(
		function* (params) {
			return yield fetch(`${BASE_URL}/reserves/cancel`, {
				method: 'POST',
				body: JSON.stringify(params),
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getAuthToken()}`,
					'Accept-Language': `${i18n.language}`
				}
			});
		},
		params,
		cbResponse
	);
}
