/*----- Core -----*/
import React from 'react';
import { Route, Redirect } from 'react-router';
import { isLogged } from '../../authentication/currentUser';

const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={props =>
			!isLogged() ? <Redirect to="/home" /> : <Component {...props} />
		}
	/>
);

export default PrivateRoute;
