/*----- Core -----*/
import React from 'react';
import { Route, Switch, Redirect } from 'react-router';

/*----- Components -----*/
import PageUnderConstruction from '../../components/PageUnderConstruction';
import ScrollToTop from '../../components/commons/ScrollToTop';

/*----- Nav Components -----*/
import Header from '../../components/commons/nav/Header';
import Footer from '../../components/commons/nav/Footer';

/*----- Modules -----*/
import Home from '../home/Home';
import Hotels from '../hotels/views/index';
import Transfers from '../tranfers/views/index';
import Contact from '../contact/Contact';
import AboutUs from '../about_us/AboutUs';
import MyReservations from '../my_reservations/views/index';
import Activities from '../activities/views/index';
import Packages from '../packages/views/index';
import Hotel from '../hotels/views/detail';
import CheckoutHotel from '../hotels/views/checkout';
import CheckoutConfirm from '../hotels/views/confirm';
import CheckoutTransferConfirm from '../tranfers/views/confirm';

import ReservationHotel from '../hotels/views/reservation';
import ReservationTransfer from '../tranfers/views/reservation';
import ReservationActivity from '../activities/views/reservation';
import ReservationPackage from '../packages/views/reservation';

import ActivityDetail from '../activities/views/detail';
import PackageDetail from '../packages/views/detail';
import CheckoutActivity from '../activities/views/checkout';
import CheckoutActivityConfirm from '../activities/views/confirm';

import CheckoutTransfer from '../tranfers/views/checkout';
import SearchHotel from '../hotels/views/search';
import SearchTransfers from '../tranfers/views/search';

import SearchActivities from '../activities/views/search';
import CheckoutPackages from '../packages/views/checkout';
import CheckoutPackageConfirm from '../packages/views/confirm';
import SearchPackages from '../packages/views/search';
import DetailsPackageHotel from '../packages/views/detail/hotels';

import CartCheckout from '../cart/views/detail';
import CartConfirm from '../cart/views/confirm';
import CartReservation from '../cart/views/reservation';

import PrivateRoute from './PrivateRoute';
import ConfirmRegister from '../login/confirmRegister';
import LoginMobile from '../login/loginMobile';
import NavigationDrawer from '../../components/navigationDrawer';
import { isMobile } from '../../utilities/utils';

const ViewsContainer = () => (
	<>
		{isMobile() ? <NavigationDrawer /> : <Header />}
		<ScrollToTop>
			<Switch>
				<Route exact path={'/'} render={() => <Redirect to="/home" />} />
				<Route exact path="/home" component={Home} />
				<Route exact path="/login" component={LoginMobile} />
				<Route exact path="/register/confirm" component={ConfirmRegister} />
				<PrivateRoute exact path="/transfers" component={Transfers} />
				<PrivateRoute exact path="/hotels" component={Hotels} />
				<Route exact path="/contact" component={Contact} />
				<PrivateRoute exact path="/activities" component={Activities} />
				<PrivateRoute
					exact
					path="/my_reservations/search"
					component={MyReservations}
				/>
				<Route exact path="/about_us" component={AboutUs} />
				<PrivateRoute exact path="/packages" component={Packages} />
				<PrivateRoute
					exact
					path="/specials"
					render={() => <PageUnderConstruction />}
				/>

				<PrivateRoute exact path="/hotels/search" component={SearchHotel} />
				<PrivateRoute exact path="/hotels/:hotelId" component={Hotel} />
				<PrivateRoute
					exact
					path="/hotels/:rateId/checkout/confirm"
					component={CheckoutConfirm}
				/>
				<PrivateRoute
					exact
					path="/hotels/:rateId/reservation"
					component={ReservationHotel}
				/>

				<PrivateRoute exact path="/transfers/search" component={SearchTransfers} />
				<PrivateRoute
					exact
					path="/transfers/:transferId/checkout"
					component={CheckoutTransfer}
				/>
				<PrivateRoute
					exact
					path="/transfers/:rateId/checkout/confirm"
					component={CheckoutTransferConfirm}
				/>
				<PrivateRoute
					exact
					path="/transfers/:rateId/reservation"
					component={ReservationTransfer}
				/>

				<PrivateRoute exact path="/activities/search" component={SearchActivities} />
				<PrivateRoute
					exact
					path="/activities/:activityId"
					component={ActivityDetail}
				/>
				<PrivateRoute
					exact
					path="/activities/:rateId/reservation"
					component={ReservationActivity}
				/>
				<PrivateRoute
					exact
					path="/activities/:activityId/checkout"
					component={CheckoutActivity}
				/>
				<PrivateRoute
					exact
					path="/activities/:activityId/checkout/confirm"
					component={CheckoutActivityConfirm}
				/>

				<PrivateRoute exact path="/packages/search" component={SearchPackages} />
				<PrivateRoute exact path="/packages/:packageId" component={PackageDetail} />
				<PrivateRoute
					exact
					path="/hotels/:rateId/checkout"
					component={CheckoutHotel}
				/>
				<PrivateRoute
					exact
					path="/packages/:packageId/checkout"
					component={CheckoutPackages}
				/>
				<PrivateRoute
					exact
					path="/packages/:packageId/reservation"
					component={ReservationPackage}
				/>
				<PrivateRoute
					exact
					path="/packages/:packageId/hotels/:hotelId"
					component={DetailsPackageHotel}
				/>
				<PrivateRoute
					exact
					path="/packages/:rateId/checkout/confirm"
					component={CheckoutPackageConfirm}
				/>
				<PrivateRoute exact path="/cart" component={CartCheckout} />
				<PrivateRoute exact path="/cart/confirm" component={CartConfirm} />
				<PrivateRoute exact path="/cart/reservation" component={CartReservation} />
			</Switch>
		</ScrollToTop>
		<Footer />
	</>
);
export default ViewsContainer;
