import React from 'react';
import PropTypes from 'prop-types';

/*----- Components -----*/
import { Modal } from 'antd';
import { Img, Text } from './styles';

/*----- Assets -----*/
import SpinnerImage from '../../../assets/images/spinner-search.svg';

const SpinnerModal = ({ visible, spinnerText }) => (
	<Modal
		width={100}
		visible={visible}
		footer={null}
		closable={false}
		className="generic-modal spinner-modal"
	>
		<Img src={SpinnerImage} alt="Spinner search" />
		<Text>{spinnerText}</Text>
	</Modal>
);

export default SpinnerModal;

SpinnerModal.defaultProps = {
	visible: false,
	spinnerText: ''
};

SpinnerModal.propTypes = {
	visible: PropTypes.bool.isRequired,
	spinnerText: PropTypes.string.isRequired
};
