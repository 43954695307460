/*----- Core -----*/
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

/*----- Components -----*/
import Title from '../../components/text/Title'
import DefaultButton from '../button/DefaultButton'
/*----- Commons -----*/
import Device from '../../commons/Device'
import {Col, Row} from 'antd'

import CardCommonStyles from '../../commons/styles/Card'

const SM_HEIGHT = "min-height: 340px; height: 100%"
const MD_HEIGHT = "min-height: 210px; height: 100%"

/*----- Styles-----*/
const StyledPromotionCard = styled.article`

	${ CardCommonStyles }
	position: relative;

	.content-button{
		position: absolute;
    	bottom: 0;
	}

	.default-button{
		margin: 0 auto;
		min-width: 160px;
	}

	.product-card__title-container{
		.custom-title{
			min-height: 38px;
		}
	}

	@media ${Device.sm} {
		${ SM_HEIGHT }
	}

	@media ${Device.md} {
		${ MD_HEIGHT }
	}

	.custom-title{
		margin-bottom:40px;
	}

	@media ${Device.xs} {
		/* .custom-title{
			margin-bottom:10px;
		} */
		.content-button{
			position:static;
		}

		.product-card__title-container{
			.custom-title{
				min-height: initial;
				margin-bottom:0px;
			}
		}
	
	}
`

/*----- Export -----*/
const ActivityCard = ({ imageUrl, title, destination, onClick }) => (
	<StyledPromotionCard >		

		<Row type="flex" >
			<Col span={24}
				className="product-card__image-container" 
				style={{backgroundImage: `url(${imageUrl})`}}>
			</Col>

			<Col span={24}>
				<div className="product-card__title-container">
					<Title content={title} size={3} />
				</div>
			</Col>
				
			<Col span={24} className="content-button">
				<DefaultButton 
					content="consultar" 
					destination={destination} 
					onClick={onClick} />
			</Col>

		</Row>
	</StyledPromotionCard>
)
export default ActivityCard

/*----- PropTypes -----*/
ActivityCard.propTypes = {
	imageUrl: PropTypes.string.isRequired
}
