/* Date and location */
export const LOGIN = "LOGIN"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILED = "LOGIN_FAILED"
export const LOGOUT = "LOGOUT"
export const RECOVERY_USER = "RECOVERY_USER"

export const login = (dispatch, param) =>
  dispatch({ type: LOGIN, payload: param })

export const logout = (dispatch, param) =>
  dispatch({ type: LOGOUT, payload: param });

export const recovery_user = (dispatch, param) =>
  dispatch({ type: RECOVERY_USER, payload: param });

