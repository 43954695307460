import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Radio } from 'antd';
import Color from '../../commons/Color';
import Device from '../../commons/Device';

const { Group } = Radio;

const StyledRadio = styled(Group)`
	.ant-time-picker {
		width: 100% !important;
	}

	.ant-radio-button-wrapper {
		color: ${Color.text};
		font-size: 15px;
		@media ${Device.xs} {
			font-size: 12px;
			padding: 0px 8px 1px 8px;
		}
	}

	.ant-radio-button-wrapper:hover {
		color: ${Color.main};
	}

	.ant-radio-button-wrapper-checked {
		border-color: ${Color.main} !important;
		color: ${Color.main} !important;
		font-weight: 700;
		box-shadow: -1px 0 0 0 ${Color.main} !important;
	}
`;

const CustomRadio = ({ onChange, afterChange, value, options }, ref) => {
	const getOptions = () => {
		return options.map((opt, idx) => (
			<Radio.Button value={opt.value} key={idx}>
				{opt.label}
			</Radio.Button>
		));
	};

	return (
		<StyledRadio
			onChange={e => {
				onChange(e);
				afterChange(e);
			}}
			value={value}
			ref={ref}
		>
			{getOptions()}
		</StyledRadio>
	);
};

export default forwardRef(CustomRadio);
