import React from 'react';
import { connect } from 'react-redux';
import LoginForm from './forms/loginForm';
import DefaultTab from '../../components/tabs/defaultTab';
import RegisterForm from './forms/registerForm';
import { useTranslation } from 'react-i18next';
import { StyledTitle, StyledDiv } from './styles';

/*----- Selectors -----*/
import { isLoadingRegister } from '../../authentication/selectors/registerSelector';
import { isLoading, error } from '../../authentication/selectors/loginSelector';

/*----- Actions -----*/
import { register } from '../../authentication/actions/registerActions';
import { login } from '../../authentication/actions/loginActions';
import { openRecovery } from '../../authentication/actions/recoveryActions';

const LoginTabs = ({
	login,
	register,
	isLoading,
	error,
	isLoadingRegister,
	openRecovery,
	openModal,
	stayRegister
}) => {
	const { t } = useTranslation();

	const openRecoveryLogin = () => {
		openRecovery();
		if (openModal) openModal();
	};

	const loginForm = {
		name: t('authentication.login.title'),
		content: (
			<LoginForm
				onSubmit={login}
				isLoading={isLoading}
				error={error}
				openRecovery={openRecoveryLogin}
			/>
		)
	};

	const onSubmitRegister = params => {
		if (openModal) openModal();
		register({
			data: params,
			stay: stayRegister ? true : false
		});
	};

	const registerForm = {
		name: t('authentication.register.title'),
		content: (
			<RegisterForm onSubmit={onSubmitRegister} isLoading={isLoadingRegister} />
		)
	};

	return (
		<StyledDiv>
			<StyledTitle>{t('authentication.welcome')}</StyledTitle>
			<DefaultTab width="100%" color="#B01A24" tabs={[loginForm, registerForm]} />
		</StyledDiv>
	);
};

const mapStateToProps = state => {
	return {
		error: error(state),
		isLoading: isLoading(state),
		isLoadingRegister: isLoadingRegister(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		login: payload => login(dispatch, payload),
		register: payload => register(dispatch, payload),
		openRecovery: payload => openRecovery(dispatch, payload)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginTabs);
