import React from 'react';
import {
	StyledRoomDescription,
	StyledReservationPanalItemIcon,
	Title
} from './styles';

const RoomDescriptionCard = ({ typeRoom, items }) => {
	const getItems = () => {
		return items.map((item, idx) => (
			<StyledReservationPanalItemIcon
				key={idx}
				icon={item.icon}
				description={item.text}
			/>
		));
	};

	return (
		<StyledRoomDescription>
			<Title content={`${typeRoom}`} size={4} color="secondary" />
			{getItems()}
		</StyledRoomDescription>
	);
};

export default RoomDescriptionCard;
