export const driver = window.localStorage;

export const addCheckoutCart = data => {
	driver.setItem('checkout_cart', JSON.stringify(data));
};

export const removeCheckoutCart = () => {
	if (driver.getItem('checkout_cart')) driver.removeItem('checkout_cart');
};

export const getCheckoutCart = () => JSON.parse(driver.getItem('checkout_cart'));

export const addCart = data => JSON.parse(driver.setItem('cart', data));
export const getCart = () => JSON.parse(driver.getItem('cart'));
export const removeCart = () => {
	if (driver.getItem('cart')) driver.removeItem('cart');
};
