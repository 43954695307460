import React, { useEffect } from 'react';
import { connect } from 'react-redux';

/*----- Components -----*/
import SuccessResponse from './section/SuccessResponse';
import ErrorResponse from './section/ErrorResponse';
import ReservationContent from '../../../commons/reservation/ReservationContent';

/*----- Actions -----*/
import { reserveCart } from '../../actions/reserveActions';

/*----- Selectors -----*/
import {
	reserveCartIsLoading,
	reserveCartError,
	reserveCartSuccess,
	reserveCartData
} from '../../selectors/reserveCartSelector';

/*----- Utils -----*/
import { getCart } from '../../../../services/CartCheckoutService';
import { parseCartData } from '../../../../utilities/utils';

const ReserveCart = ({ history, error, success, isLoading, reserveCart, data }) => {
	useEffect(() => {
		let checkoutData = getCart();

		if (!checkoutData) {
			history.push('/cart');
			return;
		}

		let parsedCartData = parseCartData(checkoutData);
		reserveCart(parsedCartData);
	}, [history, history.location.pathname, reserveCart]);

	return (
		<ReservationContent
			error={error}
			success={success}
			isLoading={isLoading}
			SuccessResponse={SuccessResponse}
			errorResponse={<ErrorResponse />}
		/>
	);
};

const mapStateToProps = state => {
	return {
		isLoading: reserveCartIsLoading(state),
		error: reserveCartError(state),
		success: reserveCartSuccess(state),
		data: reserveCartData(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		reserveCart: payload => reserveCart(dispatch, payload)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReserveCart);
