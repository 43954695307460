import React from 'react';
import { connect } from 'react-redux';

/*----- Components -----*/
import GenericCheckout from '../../../commons/checkout/GenericCheckout';

/*----- Actions -----*/
import { showHotel } from '../../actions/showHotelActions';

/*----- Selectors -----*/
import {
	showHotelIsLoading,
	showHotelSuccess
} from '../../selectors/showHotelSelector';

const HotelFinder = ({ showHotel, success, isLoading, SuccessResponse }) => (
	<GenericCheckout
		showData={showHotel}
		success={success}
		isLoading={isLoading}
		service="hotels"
		SuccessResponse={SuccessResponse}
	/>
);

const mapStateToProps = state => {
	return {
		isLoading: showHotelIsLoading(state),
		success: showHotelSuccess(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		showHotel: payload => showHotel(dispatch, payload)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(HotelFinder);
