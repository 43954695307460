/*----- Actions -----*/
import {
	GET_ACTIVITIES,
	GET_ACTIVITIES_FAILED,
	GET_ACTIVITIES_SUCCESS,
	RESET_ACTIVITY_TRANSFER
} from '../actions/searchFormActions';
import { addSearchQuery } from '../../../services/SearchService';
import { parseMetadata } from '../../../utilities/queryString';

const initialState = {
	data: [],
	isLoading: false,
	error: '',
	metadata: {},
	success: false,
	resetFilter: false
};

export default function activities(state = initialState, action) {
	switch (action.type) {
		case GET_ACTIVITIES:
			return {
				...state,
				data: [],
				isLoading: true,
				error: '',
				success: false
			};
		case GET_ACTIVITIES_SUCCESS:
			let metadata = parseMetadata(action.payload.metadata, true);
			addSearchQuery(metadata.query);
			return {
				...state,
				data: action.payload.data,
				metadata: metadata,
				isLoading: false,
				success: true,
				resetFilter: false
			};
		case GET_ACTIVITIES_FAILED:
			return {
				...state,
				isLoading: false,
				error: action.payload,
				success: false,
				resetFilter: false
			};
		case RESET_ACTIVITY_TRANSFER:
			return {
				...state,
				resetFilter: true
			};
		default:
			return state;
	}
}
