import React, { useState } from 'react';
import { StyledBurgerMenu, StyledOptionsItems, StyledIconMenu } from './styles';
import MenuDrawer from './MenuDrawer';
import HeaderItems from './HeaderItems';
import ServicesNavBar from '../commons/nav/Header/ServicesNavBar';
import { isLogged } from '../../authentication/currentUser';
import DropdownCart from '../../modules/cart/views/dropdown';
import { withRouter } from 'react-router';

const NavigationDrawer = ({ history }) => {
	const [visible, setVisible] = useState(false);
	const showProfile = history.location.pathname !== '/login' && !isLogged();
	const showServiceNavBar = () => {
		let paths = history.location.pathname.split('/');
		let currentStep = paths[paths.length - 1];
		if (
			currentStep === 'cart' ||
			currentStep === 'confirm' ||
			currentStep === 'checkout' ||
			currentStep === 'reservation'
		)
			return false;
		else return true;
	};
	return (
		<StyledBurgerMenu>
			<StyledOptionsItems showProfile>
				<MenuDrawer visible={visible} onClose={() => setVisible(false)} />
				<StyledIconMenu type="menu" onClick={() => setVisible(true)} />
				<HeaderItems showProfile={showProfile} />
			</StyledOptionsItems>
			{isLogged() && showServiceNavBar() && <ServicesNavBar />}
			<DropdownCart />
		</StyledBurgerMenu>
	);
};

export default withRouter(NavigationDrawer);
