import React from 'react';
import DefaultButton from '../../../button/DefaultButton';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { StyledPrice, TextPrice, AmountPrice, PriceAmountContainer } from './styles';

const Price = ({
	price,
	currency,
	action = 'general.seeDetail',
	destination,
	onClick,
	title = 'checkout.totalPrice'
}) => {
	const { t } = useTranslation();

	return (
		<StyledPrice>
			<PriceAmountContainer>
				<TextPrice content={t(title)} />
				<AmountPrice content={`${currency} ${price.toFixed(2)}`} />
				<TextPrice content={t('checkout.taxes')} />
			</PriceAmountContainer>
			<DefaultButton
				content={t(action)}
				destination={destination}
				onClick={onClick}
			/>
		</StyledPrice>
	);
};

export default Price;

Price.propTypes = {
	label: PropTypes.string,
	action: PropTypes.string
};
