import styled from 'styled-components';
import Device from '../../../commons/Device';
import Color from '../../../commons/Color';
import Text from '../../text/Text';

export const StyledCard = styled.div`
	width: 100%;
`;

export const TitleContainer = styled.div`
	@media ${Device.xs} {
		padding: 10px 0px;
	}
`;

export const DescriptionContainer = styled.div`
	padding-top: 14px;
	line-height: 18px;

	@media ${Device.xs} {
		padding-bottom: 16px;
	}
`;

export const StyledText = styled(Text)`
	color: #707070;
	font-size: 14px;

	@media ${Device.xs} {
		font-size: 12px;
	}
`;

export const StyledSubtitle = styled(Text)`
	color: ${Color.main};
	font-size: 14px;

	@media ${Device.xs} {
		font-size: 12px;
	}
`;

export const StyledTitle = styled(Text)`
	font-size: 20px;

	@media ${Device.xs} {
		text-align: center;
	}
`;
