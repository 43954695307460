import React from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { openModal } from '../../authentication/actions/loginModalActions';
import styled from 'styled-components';

const StyledButton = styled(Button)`
	box-shadow: unset !important;
`;

const LoginButton = ({ children, openModal, className }) => {
	return (
		<StyledButton
			className={className}
			onClick={e => {
				e.preventDefault();
				openModal();
			}}
		>
			{children}
		</StyledButton>
	);
};

function mapDispatchToProps(dispatch) {
	return {
		openModal: payload => openModal(dispatch, payload)
	};
}

export default connect(null, mapDispatchToProps)(LoginButton);
