import styled from 'styled-components';
import { Table, Radio } from 'antd';
import Text from '../../../../../components/text/Text';
import Color from '../../../../../commons/Color';
import OpenSansBold from '../../../../../commons/mixins/fonts/OpenSansBold';
import { Icon } from 'antd';

export const StyledTable = styled(Table)`
	box-shadow: 0px 3px 20px #00000029;
	border-radius: 10px !important;

	.ant-spin-container {
		border: ${props => (props.isSelected ? '1px solid #86858A' : 'initial')};
		border-radius: 10px !important;
	}
	.ant-table-body,
	.ant-table-content,
	.ant-table,
	.ant-spin-container,
	table {
		border-radius: 10px !important;
	}

	.ant-table-thead tr th:last-child {
		border-top-right-radius: 10px !important;
	}
	.ant-table-thead tr th:first-child {
		border-top-left-radius: 10px !important;
	}
	.ant-table-body tr td:last-child {
		border-bottom-right-radius: 10px !important;
	}

	.ant-table-body tr td:first-child {
		border-bottom-left-radius: 10px !important;
	}

	.ant-table-thead th {
		background: white;
	}

	.ant-table-column-title {
		${OpenSansBold(16, 700, Color.footer)};
	}

	.ant-table-row:hover td {
		background: white !important;
	}
`;

export const StyledText = styled(Text)`
	font-size: 16px;
`;

export const StyledRadio = styled(Radio)`
	&.ant-radio-wrapper {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	&.ant-radio-wrapper-checked {
		.ant-radio-checked .ant-radio-inner {
			border-color: ${Color.main};
		}

		.ant-radio-inner::after {
			background-color: ${Color.main};
		}
	}
`;

export const StyledRadioGroup = styled(Radio.Group)`
	width: 95%;
	margin-top: 24px !important;
`;

export const SeeMoreRooms = styled(Text)`
	text-align: right;
	margin: 0px;
	font-size: 16px;
`;

export const StyledList = styled.div`
	margin-bottom: 35px;
`;

export const StyledTdPrice = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
`;

export const StyledIconArrow = styled(Icon)`
	width: 7px;
	margin: 7px 8px 0px 5px;
	svg {
		fill: ${Color.main};
	}
`;

export const StyledSeeRooms = styled.div`
	margin-top: 18px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

export const StyledContainerSeeRooms = styled.div`
	display: flex;
	&:hover {
		cursor: pointer;
	}
`;
