import React from 'react';

/*----- Components -----*/
import Filter from '../filter';
import ResultList from './ResultList';

import { connect } from 'react-redux';
import {
	packagesPagination,
	packagesQuery,
	packagesData,
	packagesIsLoading,
	packagesSuccess
} from '../../selectors/packagesSelector';

/*----- Actions -----*/
import { getPackages } from '../../actions/searchFormActions';
import SearchResultContainer from '../../../../components/search/SearchResultContainer';

const PackagesResultContainer = ({
	packagesData,
	pagination,
	query,
	getPackages,
	isLoading,
	success
}) => {
	return (
		<SearchResultContainer
			resultData={packagesData}
			pagination={pagination}
			query={query}
			getData={getPackages}
			Filter={Filter}
			service="packages"
			ResultList={ResultList}
			isLoading={isLoading}
			success={success}
		/>
	);
};

const mapStateToProps = state => {
	return {
		packagesData: packagesData(state),
		pagination: packagesPagination(state),
		query: packagesQuery(state),
		isLoading: packagesIsLoading(state),
		success: packagesSuccess(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getPackages: payload => getPackages(dispatch, payload)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PackagesResultContainer);
