import { call, put, takeLatest } from 'redux-saga/effects';
import {
	RESERVE_TRANSFER,
	RESERVE_TRANSFER_SUCCESS,
	RESERVE_TRANSFER_FAILED
} from '../actions/reserveActions';
import { reserveTransfer } from '../../../api/endpoints/Transfers';

import { removeCheckoutTransfer } from '../../../services/TransferCheckoutService';

function* shotFetchTransfers(action) {
	function* onSuccess(body) {}

	function* onOk(body) {
		yield put({ type: RESERVE_TRANSFER_SUCCESS, payload: body.data });
	}

	function* onError(error) {
		removeCheckoutTransfer(action.payload);
		yield put({ type: RESERVE_TRANSFER_FAILED, payload: error });
	}

	try {
		yield call(reserveTransfer, action.payload, {
			onSuccess: onSuccess,
			onError: onError,
			onOk: onOk
		});
	} catch (error) {
		yield onError(error.message);
	}
}

export default function* ReserveTransferSagas() {
	yield takeLatest(RESERVE_TRANSFER, shotFetchTransfers);
}
