import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledCol } from './styles';
import { Form } from 'antd';
import { parse } from '../../../utilities/queryString';
import { activitiesDestinations } from '../../../utilities/constants';
import SearchSelect from '../SearchSelect';
import { withRouter } from 'react-router';

const { Item } = Form;

const SearchFormDestinations = ({
	history,
	form,
	xs = 24,
	md = 5,
	options = activitiesDestinations
}) => {
	const { t } = useTranslation();
	const { getFieldDecorator } = form;

	const [initialCityCode, setInitialCityCode] = useState(null);
	useEffect(() => {
		let params = parse(history.location.search, options);
		if (!params) {
			return;
		}

		setInitialCityCode(params.city_code);
	}, [history, history.location.search, options]);

	return (
		<StyledCol xs={xs} md={md}>
			<Item>
				{getFieldDecorator('city_code', {
					rules: [{ required: true, whitespace: true }],
					validateTrigger: ['onSubmit'],
					initialValue: initialCityCode
				})(<SearchSelect name={t('general.destination')} options={options} />)}
			</Item>
		</StyledCol>
	);
};

export default withRouter(SearchFormDestinations);
