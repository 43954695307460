export const GET_PACKAGES = 'GET_PACKAGES';
export const GET_PACKAGES_SUCCESS = 'GET_PACKAGES_SUCCESS';
export const GET_PACKAGES_FAILED = 'GET_PACKAGES_FAILED';
export const RESET_PACKAGES_FILTER = 'RESET_PACKAGES_FILTER';

export const getPackages = (dispatch, param) => {
	dispatch({ type: GET_PACKAGES, payload: param });
};

export const resetPackagesFilter = (dispatch, params) =>
	dispatch({ type: RESET_PACKAGES_FILTER, payload: params });
