import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

/*----- Components -----*/
import ResponseContent from '../../../components/responseContent';
import { StyledContainerLoading } from './styles';
import SpinnerModal from '../../../components/modal/SpinnerModal';
import ErrorResponse from '../ErrorResponse';
import { getId } from './CheckoutUtils';

const GenericCheckout = ({
	showData,
	history,
	success,
	isLoading,
	SuccessResponse,
	service
}) => {
	const { t } = useTranslation();

	useEffect(() => {
		let id = getId(history);

		showData(id);
	}, [history, history.location.pathname, showData]);

	const Spinner = () => (
		<SpinnerModal
			visible={isLoading}
			spinnerText={t(`${service}.searchingDetail`)}
		/>
	);

	return (
		<StyledContainerLoading isLoading={isLoading}>
			<ResponseContent
				isLoading={isLoading}
				Loading={Spinner}
				successResponse={success}
				Success={SuccessResponse}
				Error={ErrorResponse}
			/>
		</StyledContainerLoading>
	);
};

export default withRouter(GenericCheckout);
