import React from 'react';
import { Row } from 'antd';

import KindBasedFields from './KindBasedFields';
import LodgingField from './LodgingField';

const ArrivalFields = ({ form, isAirport, dataCheckout, transferKind, isMix }) => {
	return (
		<Row>
			<KindBasedFields
				form={form}
				isAirport={isAirport}
				dataCheckout={dataCheckout}
				transferKind={transferKind}
				isMix={isMix}
			/>
			{(!isMix || (transferKind === 'in' && isMix)) && (
				<LodgingField
					form={form}
					dataCheckout={dataCheckout}
					transferKind={transferKind}
					isMix={isMix}
				/>
			)}
		</Row>
	);
};

export default ArrivalFields;
