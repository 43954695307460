/*----- Core -----*/
import React from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';
import {
	StyledCard,
	CardTitle,
	ValidityText,
	TypeTitle,
	ContentInformation,
	StyledSeeDetails
} from './styles';
import SaleCard from '../SaleCard';
import { CardDescription } from '../SaleCard/styles';
import { capitalize } from '../../../utilities/utils';

const TransferSaleCard = ({
	title,
	description,
	validitiesFrom,
	validitiesTo,
	kind,
	category,
	details,
	...others
}) => {
	const { t } = useTranslation();
	const typeDescription = `${kind.toUpperCase()} | ${capitalize(
		category.toLowerCase()
	)}`;

	return (
		<StyledCard>
			<SaleCard
				{...others}
				withImage={false}
				cardInformation={
					<ContentInformation>
						<TypeTitle content={typeDescription} size={3} />
						<CardTitle content={title} size={3} color={'secondary'} />

						<CardDescription>{description}</CardDescription>

						<StyledSeeDetails
							kind={typeDescription}
							title={title}
							details={details}
						/>

						<ValidityText
							content={`${t('general.validity')} ${Moment(validitiesFrom).format(
								'DD/MM/YYYY'
							)} - ${Moment(validitiesTo).format('DD/MM/YYYY')}`}
							size={5}
							marginBottom={details.length ? '32px' : '12px'}
						/>
					</ContentInformation>
				}
			/>
		</StyledCard>
	);
};

export default TransferSaleCard;
