/* Date and location */
export const RECOVERY_USER    = "RECOVERY_USER"
export const RECOVERY_SUCCESS = "RECOVERY_SUCCESS"
export const RECOVERY_FAILED  = "RECOVERY_FAILED"
export const DELETE_RECOVERY  = "DELETE_RECOVERY"
export const OPEN_RECOVERY    = "OPEN_RECOVERY"

export const recoveryUser = (dispatch, param) =>
  dispatch({ type: RECOVERY_USER, payload: param });

export const deleteRecovery = (dispatch, param) =>
  dispatch({ type: DELETE_RECOVERY, payload: param });

export const openRecovery = (dispatch, param) =>
  dispatch({ type: OPEN_RECOVERY, payload: param });