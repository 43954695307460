/** * Core ***/
import React from 'react';
import styled from 'styled-components';

const StyledVideo = styled.div`
	height: 250px;
	max-height: auto;
	position: relative;
	overflow: hidden;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	video {
		position: absolute;
		z-index: 1;
		min-width: 100%;
		min-height: 100%;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		max-width: inherit;
	}
	.video-container__cover {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.2);
		z-index: 2;
	}
`;

const Video = ({ height, width, videoUrl }) => (
	<StyledVideo style={{ height: height, width: width }} className="video-container">
		<div className="video-container__cover"></div>
		<video preload="true" autoPlay="autoplay" loop="loop" muted="muted">
			<source src={videoUrl} type="video/mp4"></source>
		</video>
	</StyledVideo>
);
export default Video;
