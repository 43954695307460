import React from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { StyledSelect, StyledFlag } from './styles';

/*----- Assets -----*/
import LangBR from '../../../../assets/icons/brazil.svg';
import LangES from '../../../../assets/icons/argentina.svg';
import LangEN from '../../../../assets/icons/usa.png';

const { Option } = Select;

const SelectLanguage = ({ logged, withoutText }) => {
	const { t, i18n } = useTranslation();

	return (
		<StyledSelect
			logged={logged}
			defaultValue={i18n.language}
			onChange={value => {
				i18n.changeLanguage(value);
			}}
		>
			<Option value="es">
				<StyledFlag src={LangES} alt="ES" />
				{!withoutText && t('general.lng.es')}
			</Option>
			<Option value="pt-BR">
				<StyledFlag src={LangBR} alt="BR" />
				{!withoutText && t('general.lng.pt')}
			</Option>
			<Option value="en">
				<StyledFlag src={LangEN} alt="EN" />
				{!withoutText && t('general.lng.en')}
			</Option>
		</StyledSelect>
	);
};

export default SelectLanguage;
