import React from 'react';

/*----- Components -----*/
import Filter from '../filter';
import ResultList from './ResultList';

import { connect } from 'react-redux';
import {
	activitiesPagination,
	activitiesQuery,
	activitiesData,
	activitiesIsLoading,
	activitiesSuccess
} from '../../selectors/activitiesSelector';
import { getActivities } from '../../actions/searchFormActions';
import SearchResultContainer from '../../../../components/search/SearchResultContainer';

const ActivitiesResultContainer = ({
	activitiesData,
	pagination,
	query,
	getActivities,
	isLoading,
	success
}) => {
	return (
		<SearchResultContainer
			resultData={activitiesData}
			pagination={pagination}
			query={query}
			getData={getActivities}
			Filter={Filter}
			service="activities"
			ResultList={ResultList}
			isLoading={isLoading}
			success={success}
		/>
	);
};

const mapStateToProps = state => {
	return {
		activitiesData: activitiesData(state),
		pagination: activitiesPagination(state),
		query: activitiesQuery(state),
		isLoading: activitiesIsLoading(state),
		success: activitiesSuccess(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getActivities: payload => getActivities(dispatch, payload)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ActivitiesResultContainer);
