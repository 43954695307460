import styled from 'styled-components';
import Arrow from '../../../assets/icons/icon-components/Arrow';
import Text from '../../text/Text';

export const StyledFilterData = styled.div`
	background-color: #b01a24;
	height: 70px;
	margin: 0px;
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
`;

export const StyledText = styled.span`
	color: #fff;
	font-size: 12px;
	font-weight: ${props => props.weight || 700};
	padding: 10px;
`;

export const ArrowBottom = styled(Arrow)`
	&.arrow-content {
		margin: 0px !important;
	}
`;

export const Div = styled.div`
	display: flex;
`;

export const StyledHeader = styled(Text)`
	font-size: 16px;
	font-weight: bold;
	text-align: center;
	margin-top: 24px;
	padding: 0px 10px;
`;
