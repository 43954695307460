import cataratas_argentina from '../../../../assets/images/activities/cataratas_argentinas.jpg';
import cataratas_brasileras from '../../../../assets/images/activities/cataratas_brasileras.jpg';
import minas_de_wanda from '../../../../assets/images/activities/minas_de_wanda.jpg';
import ruinas_de_san_ignacio from '../../../../assets/images/activities/ruinas_de_san_ignacio.jpg';
import represa_de_itaipu from '../../../../assets/images/activities/represa_de_itaipu.jpg';
import city_tour_foz_do_iguazu from '../../../../assets/images/activities/city_tour_foz_do_iguazu.jpg';
import parque_de_aves from '../../../../assets/images/activities/parque_de_aves.jpg';
import city_tour_puerto_iguazu from '../../../../assets/images/activities/city_tour_puerto_iguazu.jpg';

export const activiesData = [
	{
		name: 'activities.data.cataratasArgentinas.name',
		image: cataratas_argentina,
		description: 'activities.data.cataratasArgentinas.description'
	},
	{
		name: 'activities.data.paqueteDeAves.name',
		image: parque_de_aves,
		description: 'activities.data.paqueteDeAves.description'
	},
	{
		name: 'activities.data.cataratasBrasileras.name',
		image: cataratas_brasileras,
		description: 'activities.data.cataratasBrasileras.description'
	},
	{
		name: 'activities.data.ruinasDeSanIgnacio.name',
		image: ruinas_de_san_ignacio,
		description: 'activities.data.ruinasDeSanIgnacio.description'
	},
	{
		name: 'activities.data.cityTourFozDoIguazu.name',
		image: city_tour_foz_do_iguazu,
		description: 'activities.data.cityTourFozDoIguazu.description'
	},
	{
		name: 'activities.data.minasDeWanda.name',
		image: minas_de_wanda,
		description: 'activities.data.minasDeWanda.description'
	},
	{
		name: 'activities.data.represaDeItaipu.name',
		image: represa_de_itaipu,
		description: 'activities.data.represaDeItaipu.description'
	},
	{
		name: 'activities.data.cityTourPuertoIguazu.name',
		image: city_tour_puerto_iguazu,
		description: 'activities.data.cityTourPuertoIguazu.description'
	}
];

export const searchedData = [
	{
		name: 'Cataratas Argentinas',
		image: cataratas_argentina,
		description:
			'Visita a Cataratas Argentinas contando con tres circuitos tradicionales.',
		duration: 'Dia completo',
		departures: 'Lunes a Lunes',
		actionsDescription:
			'Visita a Cataratas Argentinas contando con tres circuitos tradicionales:Garganta del Diablo (2 horas de paseo, no posee escaleras), Superior (1:30 h de paseo, no posee escaleras) e Inferior (2 horas de paseo, nivel de dificultad elevado por la cantidad de escaleras). Parada para almuerzo en Churrasquería, además del tradicional asado criollo, pueden degustar de la cocina típica regional (no incluido).',
		noInclude: [
			'Ingresos al Parque Nacional',
			'Comidas',
			'Bebidas',
			'Propinas',
			'Tasa Eco Turística'
		]
	},
	{
		name: 'Ruinas de San Ignacio',
		image: ruinas_de_san_ignacio,
		description:
			'Ruinas de San Ignacio Miní, Minas de Piedras Preciosas en Wanda y plantaciones de Yerba Mate y Té.',
		duration: 'Dia completo',
		departures: 'Lunes a Lunes',
		actionsDescription:
			'Visita a Cataratas Argentinas contando con tres circuitos tradicionales:Garganta del Diablo (2 horas de paseo, no posee escaleras), Superior (1:30 h de paseo, no posee escaleras) e Inferior (2 horas de paseo, nivel de dificultad elevado por la cantidad de escaleras). Parada para almuerzo en Churrasquería, además del tradicional asado criollo, pueden degustar de la cocina típica regional (no incluido).',
		noInclude: [
			'Ingresos al Parque Nacional',
			'Comidas',
			'Bebidas',
			'Propinas',
			'Tasa Eco Turística'
		]
	}
];

export const activityOptions = () => {
	let data = [{ value: '', label: '' }];
	activiesData.forEach((activity, idx) => {
		data.push({
			value: idx,
			label: activity.name
		});
	});

	return data;
};
