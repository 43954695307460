/*----- Core -----*/
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

/*----- Commons -----*/
import Montserrat from '../../commons/mixins/fonts/Montserrat';
import Device from '../../commons/Device';

/*----- Styles-----*/
const StyledHeroWithImage = styled.article`
	height: 300px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	position: relative;
	padding: 20px;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	.hero-with-image__text-container {
		position: relative;
		z-index: 1;
		width: 100%;
	}
	.hero-with-image__title {
		${Montserrat(40, 900, 'white')};
		line-height: 38px;
		margin: 0;
		text-align: center;
	}
	.hero-with-image__subtitle {
		${Montserrat(18, 400, 'white')};
		line-height: 38px;
		margin-top: 10px;
		text-align: center;
		@media ${Device.xs} {
			line-height: 28px;
		}
	}
	@media ${Device.sm} {
		padding: 80px;
	}
	@media ${Device.md} {
		height: 400px;
		.hero-with-image__title {
			font-size: 60px;
			line-height: 60px;
		}
	}
	@media ${Device.lg} {
		.hero-with-image__title {
			font-size: 80px;
			line-height: 80px;
		}
	}
`;
const Cover = styled.div`
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.4);
	position: absolute;
	left: 0;
	top: 0;
`;

/*----- Component -----*/
const HeroWithImage = ({ title, subtitle, imageUrl }) => (
	<StyledHeroWithImage
		className="hero-with-image"
		style={{ backgroundImage: `url(${imageUrl})` }}
	>
		<div className="hero-with-image__text-container">
			<h1 className="hero-with-image__title">{title}</h1>
			<h2 className="hero-with-image__subtitle">{subtitle}</h2>
		</div>
		<Cover />
	</StyledHeroWithImage>
);
export default HeroWithImage;

/*----- PropTypes -----*/
HeroWithImage.propTypes = {
	title: PropTypes.string.isRequired,
	imageUrl: PropTypes.string.isRequired
};
