import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
	SHOW_TRANSFER,
	SHOW_TRANSFER_SUCCESS,
	SHOW_TRANSFER_FAILED
} from '../actions/showTransferActions';
import { showTransfer } from '../../../api/endpoints/Transfers';

import { removeCheckoutTransfer } from '../../../services/TransferCheckoutService';

function* shotFetchTransfers(action) {
	function* onSuccess(body) {}

	function* onOk(body) {
		yield put({ type: SHOW_TRANSFER_SUCCESS, payload: body.data });
	}

	function* onError(error) {
		removeCheckoutTransfer(action.payload);
		yield put({ type: SHOW_TRANSFER_FAILED, payload: error });
		yield put(push('/transfers/search'));
	}

	try {
		yield call(showTransfer, action.payload, {
			onSuccess: onSuccess,
			onError: onError,
			onOk: onOk
		});
	} catch (error) {
		yield onError(error.message);
	}
}

export default function* TransferSagas() {
	yield takeLatest(SHOW_TRANSFER, shotFetchTransfers);
}
