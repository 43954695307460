import {
	SHOW_TRANSFER,
	SHOW_TRANSFER_FAILED,
	SHOW_TRANSFER_SUCCESS
} from '../actions/showTransferActions';

const initialState = {
	data: [],
	isLoading: false,
	error: '',
	success: false
};

export default function transfer(state = initialState, action) {
	switch (action.type) {
		case SHOW_TRANSFER:
			return {
				...state,
				data: [],
				isLoading: true,
				error: '',
				success: false
			};
		case SHOW_TRANSFER_SUCCESS:
			return {
				...state,
				data: action.payload,
				isLoading: false,
				success: true
			};
		case SHOW_TRANSFER_FAILED:
			return {
				...state,
				isLoading: false,
				error: action.payload,
				success: false
			};
		default:
			return state;
	}
}
