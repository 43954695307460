import styled from 'styled-components';
import { Row, Col } from 'antd';
import GenericModal from '../../components/modal/GenericModal';
import LoginMotto from '../../components/icons/LoginMotto';
import CenterContainer from '../../components/containers/CenterContainer';

export const Styles = styled.div`
	position: relative;
`;

export const StyledModal = styled(GenericModal)`
	.ant-modal-content {
		border-radius: 10px;
	}
	.ant-modal-header {
		border: none;
		padding: 0px;
	}
	position: relative;
	justify-content: center;
	align-items: center;
`;

export const MainRow = styled(Row)`
	&& {
		height: 757px;
	}
	width: 976px;
	display: flex;
	z-index: 50;
	position: relative;
	overflow: hidden;
	border-radius: 10px;
`;

export const LogoImage = styled.img`
	width: 202px;
	height: 43px;
	margin: 32px;
`;

export const BackgroundImage = styled.img`
	border-radius: 10px 0px 0px 10px;
`;

export const StyledCol = styled(Col)`
	&& {
		display: flex;
	}
	height: 50%;
	flex-direction: column;
	align-items: flex-end;
	justify-content: space-between;
`;

export const StyledText = styled.span`
	&& {
		font-weight: ${props => props.weight};
		margin: ${props => props.margin};
	}
	color: ${props => props.color};
	font-size: 27px;
	font-family: 'ProximaNova';
`;

export const StyledMain = styled.div`
	z-index: 100;
	position: absolute;
	background-color: white;
	width: 341px;
	min-height: 461px;
	box-shadow: 0px 3px 20px #00000029;
	border-radius: 10px;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const StyledTitle = styled.h1`
	font-size: 24px;
	font-weight: 700;
	color: #b01a24;
	margin-top: 25px;
`;

export const StyledLoginMotto = styled(LoginMotto)`
	margin-right: 32px;
`;

export const StyledDiv = styled.div`
	${CenterContainer}
	flex-direction: column;
	.ant-tabs-ink-bar,
	.ant-tabs-ink-bar-no-animated {
		background-color: #b01a24 !important;
	}
	.ant-tabs-tab-active {
		color: #000 !important;
	}
`;
