import React from 'react';
import { MainDiv, TitleContainer, StyledText } from './styles';
import { useTranslation } from 'react-i18next';

const CheckoutInfo = ({ header, CardCheckoutData }) => {
	const { t } = useTranslation();
	return (
		<MainDiv>
			<StyledText content={t('checkout.title')} />
			<TitleContainer>{header}</TitleContainer>
			{CardCheckoutData}
		</MainDiv>
	);
};

export default CheckoutInfo;
