import packageAdrenalina from '../../../../assets/images/packages/adrenalina.jpg';
import packageAventura from '../../../../assets/images/packages/aventura.jpg';
import packageBeachWater from '../../../../assets/images/packages/beach_water.jpg';
import packageCultureWorld from '../../../../assets/images/packages/culture_world.jpg';
import packageEcologico from '../../../../assets/images/packages/ecologico.jpg';
import packageFinishWorld from '../../../../assets/images/packages/finish_world.jpg';
import packageFullWeek from '../../../../assets/images/packages/full_week.jpg';
import packageJesuitico from '../../../../assets/images/packages/jesuitico.jpg';
import packageLunaDeMiel from '../../../../assets/images/packages/luna_de_miel.jpg';
import packageMasCataratas from '../../../../assets/images/packages/mas_catarats.jpg';
import packagePromocional from '../../../../assets/images/packages/promocional.jpg';
import packageRaices from '../../../../assets/images/packages/raices.jpg';

export const defaultPackage = {
	name: 'Más Cataratas',
	image: packageMasCataratas
};

export const packagesData = [
	{
		name: 'packages.data.masCataratas.name',
		image: packageMasCataratas,
		services: ['transfer', 'assistance'],
		description: 'packages.data.masCataratas.description'
	},
	{
		name: 'packages.data.finishWorld.name',
		image: packageFinishWorld,
		services: ['transfer', 'assistance', 'excursions'],
		description: 'packages.data.finishWorld.description'
	},
	{
		name: 'packages.data.beachWater.name',
		image: packageBeachWater,
		services: ['transfer', 'assistance', 'lunch', 'in'],
		description: 'packages.data.beachWater.description'
	},
	{
		name: 'packages.data.ecologico.name',
		image: packageEcologico,
		services: ['transfer', 'assistance', 'lunch'],
		description: 'packages.data.ecologico.description'
	},
	{
		name: 'packages.data.fullWeek.name',
		image: packageFullWeek,
		services: ['transfer', 'assistance'],
		description: 'packages.data.fullWeek.description'
	},
	{
		name: 'packages.data.adrenalina.name',
		image: packageAdrenalina,
		services: ['transfer', 'assistance', 'in'],
		description: 'packages.data.adrenalina.description'
	},
	{
		name: 'packages.data.aventura.name',
		image: packageAventura,
		services: ['transfer', 'assistance', 'in', 'lunch'],
		description: 'packages.data.aventura.description'
	},
	{
		name: 'packages.data.lunaDeMiel.name',
		image: packageLunaDeMiel,
		services: ['transfer', 'assistance', 'dinner'],
		description: 'packages.data.lunaDeMiel.description'
	},
	{
		name: 'packages.data.raices.name',
		image: packageRaices,
		services: ['transfer', 'assistance', 'lunch'],
		description: 'packages.data.raices.description'
	},
	{
		name: 'packages.data.jesuitico.name',
		image: packageJesuitico,
		services: ['transfer', 'assistance'],
		description: 'packages.data.jesuitico.description'
	},
	{
		name: 'packages.data.promocional.name',
		image: packagePromocional,
		services: ['transfer', 'assistance'],
		description: 'packages.data.promocional.description'
	},
	{
		name: 'packages.data.cultureWorld.name',
		image: packageCultureWorld,
		services: ['transfer', 'assistance'],
		description: 'packages.data.cultureWorld.description'
	}
];
