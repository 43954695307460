import React from 'react';
import { useTranslation } from 'react-i18next';

import {
	StyledContainer,
	StyledTitle,
	StyledInformation,
	TextPrice
} from './styles';

const ReservationPanelHeader = ({
	panelTitle = 'reservation.reserveDetail',
	amount,
	textTotal,
	currency
}) => {
	const { t } = useTranslation();
	return (
		<StyledContainer>
			<StyledTitle content={t(panelTitle)} size={4} color="secondary" />
			<StyledInformation content={textTotal} />
			<TextPrice content={`${currency} ${amount.toFixed(2)}`} />
			<StyledInformation content={t('checkout.taxes')} />
		</StyledContainer>
	);
};

export default ReservationPanelHeader;
