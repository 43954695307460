/*----- Core -----*/
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

/*----- Components -----*/
import Reservation from '../../../../commons/reservation';

/*----- Selectors -----*/
import {
	packagesSteps,
	packagesWithHotelSteps,
	isMobile
} from '../../../../../utilities/utils';
import { reservePackagesData } from '../../../selectors/reservePackagesSelector';

/*----- Assets -----*/
import EndReservation from '../../../../../assets/images/package-end-reservation.svg';

import { getSearchQuery } from '../../../../../services/SearchService';

const SuccessResponse = ({ data }) => {
	const withHotel = getSearchQuery().with_hotel;
	return (
		<Reservation
			code={withHotel ? 'TEST' : data.reservation_id}
			dataSteps={withHotel ? packagesWithHotelSteps() : packagesSteps()}
			imageReservation={EndReservation}
			active={withHotel ? (isMobile() ? 6 : 5) : 4}
		/>
	);
};

const mapStateToProps = state => {
	return {
		data: reservePackagesData(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(SuccessResponse));
