import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MobileModal from '../../modal/MobileModal';
import DefaultButton from '../../button/DefaultButton';
import { ContainerButton, StyledFilterMobile } from './styles';

import FilterImg from '../../../assets/icons/filter.svg';

const FilterMobile = ({ Filter }) => {
	const [visible, setVisible] = useState(false);
	const { t } = useTranslation();

	return (
		<StyledFilterMobile>
			<MobileModal
				visible={visible}
				onCancel={() => setVisible(false)}
				title={t('general.filters')}
				content={<Filter onSubmit={() => setVisible(false)} />}
			/>
			<ContainerButton className="container-button">
				<DefaultButton
					invert={true}
					content={t('general.filter')}
					onClick={() => setVisible(true)}
					icon={FilterImg}
				/>
			</ContainerButton>
		</StyledFilterMobile>
	);
};

export default FilterMobile;
