import React from 'react';
import { useTranslation } from 'react-i18next';
import FilterFormFronCategories from './FilterFormCategories';

const FilterFormTransferCategory = ({
	form,
	fetchData,
	defaultValues = [],
	disabled
}) => {
	const { t } = useTranslation();

	const categories = [
		{ value: 'all', label: t('transfers.all') },
		{ value: 'regular', label: t('transfers.data.regular.name') },
		{ value: 'private', label: t('transfers.data.private.name') },
		{ value: 'premium', label: t('transfers.data.premium.name') }
	];

	return (
		<FilterFormFronCategories
			name={t('transfers.kindOfTransfer')}
			form={form}
			options={categories}
			fieldName={'category_codes'}
			fetchData={fetchData}
			defaultValues={defaultValues}
			disabled={disabled}
		/>
	);
};

export default FilterFormTransferCategory;
