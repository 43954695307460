export const RESET_CART = 'RESET_CART';
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';

export const resetCart = dispatch => dispatch({ type: RESET_CART });

export const addToCart = (dispatch, payload) =>
	dispatch({ type: ADD_TO_CART, payload: payload });

export const removeFromCart = (dispatch, payload) =>
	dispatch({ type: REMOVE_FROM_CART, payload: payload });
