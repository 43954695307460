import React from 'react';
import { useTranslation } from 'react-i18next';
import GenericFeedback from './GenericFeedback';
import { connect } from 'react-redux';
import { deleteRegister } from '../../../authentication/actions/registerActions';

const FailedResponseRegister = ({ deleteRegister, closeModalMobile }) => {
	const { t } = useTranslation();

	const closeModal = () => {
		deleteRegister();
		if (closeModalMobile) closeModalMobile();
	};

	return (
		<GenericFeedback
			closeModal={closeModal}
			title={t('authentication.register.feedback.failed.title')}
			subTitle={t('authentication.register.feedback.failed.subTitle')}
			content={t('authentication.register.feedback.failed.content')}
			subContent={t('authentication.register.feedback.failed.subContent')}
		/>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		deleteRegister: payload => deleteRegister(dispatch, payload)
	};
};

export default connect(null, mapDispatchToProps)(FailedResponseRegister);
