import React from 'react';
import { connect } from 'react-redux';

/*----- Components -----*/
import Filter from '../filter';
import ResultList from './ResultList';

/*----- Selectors -----*/
import {
	transfersData,
	transfersPagination,
	transfersQuery,
	transfersIsLoading,
	transfersSuccess
} from '../../selectors/transfersSelector';

/*----- Actions -----*/
import { getTransfers } from '../../actions/searchFormActions';
import SearchResultContainer from '../../../../components/search/SearchResultContainer';

const TransferResultContainer = ({
	transfersData,
	pagination,
	query,
	getTransfers,
	isLoading,
	success
}) => {
	return (
		<SearchResultContainer
			resultData={transfersData}
			pagination={pagination}
			query={query}
			getData={getTransfers}
			Filter={Filter}
			service="transfers"
			ResultList={ResultList}
			isLoading={isLoading}
			success={success}
		/>
	);
};

const mapStateToProps = state => {
	return {
		transfersData: transfersData(state),
		pagination: transfersPagination(state),
		query: transfersQuery(state),
		isLoading: transfersIsLoading(state),
		success: transfersSuccess(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getTransfers: payload => getTransfers(dispatch, payload)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TransferResultContainer);
