import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { cartData } from '../../selectors/cartSelector';
import { removeFromCart } from '../../actions/cartActions';
import {
	getTotalAmount,
	getTotalPrice,
	getIconByService
} from '../../../../utilities/utils';

import { ReactComponent as TrashIcon } from '../../../../assets/icons/trash-icon.svg';

import {
	StyledDropdownSection,
	StyledDropdownItem,
	StyledDropdownItemTitle,
	StyledDropdownItemPrice,
	StyledDropdownTotalTitle,
	StyledButton,
	StyledTitleWrapper,
	StyledDropdownItemIcon
} from './styles';

const DropdownCartItems = ({ cartData, removeFromCart, openDetailedCart }) => {
	const { t } = useTranslation();
	return (
		<>
			<StyledDropdownSection>
				{cartData.map((item, index) => (
					<StyledDropdownItem key={index}>
						<StyledDropdownItemIcon>
							{getIconByService(item.type)}
						</StyledDropdownItemIcon>
						<StyledTitleWrapper>
							<StyledDropdownItemTitle>{item.name}</StyledDropdownItemTitle>
						</StyledTitleWrapper>
						<StyledDropdownItemPrice>
							{item.currency || '$'}
							{getTotalPrice(item).toFixed(2)}
						</StyledDropdownItemPrice>
						<StyledDropdownItemIcon absolute>
							<TrashIcon onClick={() => removeFromCart(item.uuid)} />
						</StyledDropdownItemIcon>
					</StyledDropdownItem>
				))}
			</StyledDropdownSection>
			<StyledDropdownSection>
				<StyledDropdownItem>
					<StyledDropdownTotalTitle>Total</StyledDropdownTotalTitle>
					<StyledDropdownItemPrice>
						{cartData[0].currency || '$'}
						{getTotalAmount(cartData).toFixed(2)}
					</StyledDropdownItemPrice>
				</StyledDropdownItem>
				<StyledButton
					content={t('cart.dropdown.button')}
					onClick={openDetailedCart}
				/>
			</StyledDropdownSection>
		</>
	);
};
const mapStateToProps = state => {
	return {
		cartData: cartData(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		removeFromCart: payload => removeFromCart(dispatch, payload)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DropdownCartItems);
