export const driver = window.localStorage;

export const addCheckoutTransfer = (id, data) => {
	let transfers = JSON.parse(driver.getItem('checkout_transfer')) || {};
	transfers[id] = data;
	driver.setItem('checkout_transfer', JSON.stringify(transfers));
};

export const removeCheckoutTransfer = id => {
	const transfers = JSON.parse(driver.getItem('checkout_transfer')) || {};
	delete transfers[id];
	driver.setItem('checkout_transfer', JSON.stringify(transfers));
};

export const getCheckoutTransfer = id => {
	const transfers = JSON.parse(driver.getItem('checkout_transfer'));
	return transfers ? transfers[id] : null;
};
