import styled from 'styled-components';

/*----- Components -----*/
import ChildrenAgeSelect from '../ChildrenAgeSelect';
import ButtonWithIcon from '../../../button/ButtonWithIcon';

import Device from '../../../../commons/Device';

export const StyledChildrenAgeSelect = styled(ChildrenAgeSelect)`
	margin-top: 8px;
`;

export const StyledButton = styled(ButtonWithIcon)`
	@media ${Device.xs} {
		width: 36px;
		height: 36px;
		justify-content: center;
	}
`;

export const StyledValue = styled.span`
	@media ${Device.xs} {
		font-size: 16px;
	}
`;
