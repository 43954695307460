import React from 'react';
import { useTranslation } from 'react-i18next';
import NoResultsImage from '../../../assets/images/NoResults.svg';
import ResponseWithImage from '../ResponseWithImage';

const NoResults = () => {
	const { t } = useTranslation();

	return (
		<ResponseWithImage
			image={NoResultsImage}
			title={t('general.noResults.title')}
			subtitle={t('general.noResults.subtitle')}
		/>
	);
};

export default NoResults;
