/*----- Imports -----*/
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

/*----- Components-----*/
import { InputContainer } from './styles';
import Label from './Label';
import { Select } from 'antd';
const { Option } = Select;

/*----- Component -----*/
const DefaultSelect = ({ name, onChange, options, defaultValue }, ref) => {
	return (
		<InputContainer>
			<Label name={name} htmlFor={name} />
			<Select
				ref={ref}
				defaultValue={defaultValue || options[0].value}
				onChange={onChange}
				size="large"
			>
				{options.map((option, i) => {
					return (
						<Option key={`${i}-${option.value}`} value={option.value}>
							{option.label}
						</Option>
					);
				})}
			</Select>
		</InputContainer>
	);
};
export default forwardRef(DefaultSelect);

/*----- PropTypes -----*/
DefaultSelect.propTypes = {
	name: PropTypes.string.isRequired,
	options: PropTypes.array,
	onChange: PropTypes.func
};
