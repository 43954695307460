import styled from 'styled-components';
import { StyledServiceCard } from '../../../../../components/carousel/styles';
import ReservationPanalItemIcon from '../../../../../components/panel/ReservationPanalItemIcon';
import { StyledTitle } from '../../../../../components/panel/ReservationPanelHeader/styles';

export const StyledRooms = styled.article`
	margin-bottom: 100px;
`;

export const StyledRoomDescription = styled(StyledServiceCard)`
	height: initial;
	width: initial;
	margin: 15px 25px 25px 25px;
	border-radius: 10px;
	padding: 25px 0px;
`;

export const StyledReservationPanalItemIcon = styled(ReservationPanalItemIcon)`
	.icon-text {
		font-size: 14px !important;
	}
`;

export const Title = styled(StyledTitle)`
	margin: 0px 0px 8px 0px;
`;

export const ChooseRoomTitle = styled(StyledTitle)`
	padding-left: 25px;
	padding-bottom: 5px;
`;

export const RoomContainer = styled.div``;
