import { call, put, takeLatest } from 'redux-saga/effects';
import {
	GET_ACTIVITIES,
	GET_ACTIVITIES_SUCCESS,
	GET_ACTIVITIES_FAILED
} from '../actions/searchFormActions';
import { getActivities } from '../../../api/endpoints/Activities';

function* shotFetchActivities(action) {
	function* onSuccess(body) {}

	function* onOk(body) {
		yield put({ type: GET_ACTIVITIES_SUCCESS, payload: body });
	}

	function* onError(error) {
		yield put({ type: GET_ACTIVITIES_FAILED, payload: error });
	}

	try {
		yield call(getActivities, action.payload, {
			onSuccess: onSuccess,
			onError: onError,
			onOk: onOk
		});
	} catch (error) {
		yield onError(error.message);
	}
}

export default function* ActivitiesSagas() {
	yield takeLatest(GET_ACTIVITIES, shotFetchActivities);
}
