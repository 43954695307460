import { call, put, takeLatest } from 'redux-saga/effects';
import {
	RESERVE_ACTIVITY,
	RESERVE_ACTIVITY_SUCCESS,
	RESERVE_ACTIVITY_FAILED
} from '../actions/reserveActions';
import { reserveActivity } from '../../../api/endpoints/Activities';

import { removeCheckoutActivity } from '../../../services/ActivityCheckoutService';

function* shotReserveActivity(action) {
	function* onSuccess(body) {}

	function* onOk(body) {
		yield put({ type: RESERVE_ACTIVITY_SUCCESS, payload: body.data });
	}

	function* onError(error) {
		removeCheckoutActivity(action.payload);
		yield put({ type: RESERVE_ACTIVITY_FAILED, payload: error });
	}

	try {
		yield call(reserveActivity, action.payload, {
			onSuccess: onSuccess,
			onError: onError,
			onOk: onOk
		});
	} catch (error) {
		yield onError(error.message);
	}
}

export default function* ReserveActivitySagas() {
	yield takeLatest(RESERVE_ACTIVITY, shotReserveActivity);
}
