import React, { useState } from 'react';
import {
	StyledDiv,
	TitleContainer,
	StyledInformation,
	Content,
	TitleInfo,
	ContentInfo,
	StyledIcon,
	StyledIconArrow,
	StyleTitle,
	TitleLeft,
	TitleRight
} from './styles';
import { useTranslation } from 'react-i18next';
import Warning from '../../../assets/icons/warning.svg';

const Information = ({ title, content }) => (
	<StyledInformation>
		<TitleInfo content={title} />
		<ContentInfo content={content} />
	</StyledInformation>
);

const MoreInformationCard = ({ margin, mobile }) => {
	const { t } = useTranslation();
	const [visible, setVisible] = useState(false);

	return (
		<StyledDiv margin={margin} onClick={() => setVisible(!visible)}>
			<TitleContainer>
				<TitleLeft>
					<StyledIcon src={Warning} alt="warning icon" />
					<StyleTitle content={t('moreInformation.self')} />
				</TitleLeft>
				<TitleRight>
					{mobile && (
						<StyledIconArrow
							type={visible ? 'up' : 'down'}
							onClick={() => setVisible(!visible)}
						/>
					)}
				</TitleRight>
			</TitleContainer>
			<Content visible={!mobile || visible}>
				<Information
					title={t('moreInformation.documentation.title')}
					content={t('moreInformation.documentation.content')}
				/>
				<Information
					title={t('moreInformation.children.title')}
					content={t('moreInformation.children.content')}
				/>
				<Information
					title={t('moreInformation.cancellation.title')}
					content={t('moreInformation.cancellation.content')}
				/>
				<Information
					title={t('moreInformation.meetingPoint.title')}
					content={t('moreInformation.meetingPoint.content')}
				/>
			</Content>
		</StyledDiv>
	);
};

export default MoreInformationCard;
