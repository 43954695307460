import { call, put, takeLatest } from 'redux-saga/effects';
import {
	CANCEL_RESERVATION,
	CANCEL_RESERVATION_SUCCESS,
	CANCEL_RESERVATION_FAILED
} from '../actions/myReservationsActions';
import { cancelReservation } from '../../../api/endpoints/Reservations';

function* shotCancelReservation(action) {
	function* onSuccess() {
		yield put({ type: CANCEL_RESERVATION_SUCCESS });
		window.location.reload();
	}

	function* onOk() {
		yield put({ type: CANCEL_RESERVATION_SUCCESS });
		window.location.reload();
	}

	function* onError(error) {
		yield put({ type: CANCEL_RESERVATION_FAILED, payload: error });
	}
	try {
		yield call(cancelReservation, action.payload, {
			onSuccess: onSuccess,
			onError: onError,
			onOk: onOk
		});
	} catch (error) {
		yield onError(error.message);
	}
}

export default function* CancelReservationSagas() {
	yield takeLatest(CANCEL_RESERVATION, shotCancelReservation);
}
