import React from 'react';
import ImageGallery from './ImageGallery';
import { StyledModal } from './styles';

const GalleryModal = ({ onCancel, visible, images }) => (
	<StyledModal visible={visible} footer={null} onCancel={onCancel}>
		<ImageGallery images={images} width={'56%'} sliderPadding={'27px 0px'} />
	</StyledModal>
);

export default GalleryModal;
