export const GET_TRANSFERS = 'GET_TRANSFERS';
export const GET_TRANSFERS_SUCCESS = 'GET_TRANSFERS_SUCCESS';
export const GET_TRANSFERS_FAILED = 'GET_TRANSFERS_FAILED';
export const RESET_TRANSFER_FILTER = 'RESET_TRANSFER_FILTER';

export const getTransfers = (dispatch, param) =>
	dispatch({ type: GET_TRANSFERS, payload: param });

export const resetTransferFilter = (dispatch, params) =>
	dispatch({ type: RESET_TRANSFER_FILTER, payload: params });
