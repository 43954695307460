/*----- Actions -----*/
import { SET_SELECT_DATE_ACTIVITY } from '../actions/detailsActions';

const initialState = {
	selectDate: false
};

export default function detailsActivities(state = initialState, action) {
	switch (action.type) {
		case SET_SELECT_DATE_ACTIVITY:
			return {
				...state,
				selectDate: action.payload
			};
		default:
			return state;
	}
}
