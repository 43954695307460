import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
	REGISTER,
	REGISTER_SUCCESS,
	REGISTER_FAILED
} from '../actions/registerActions';

import { register } from '../../api/endpoints/Sessions';

function* registerUser(action) {
	function* onSuccess(body) {
		yield put({
			type: REGISTER_SUCCESS,
			payload: body.data,
			email: action.payload.data.email
		});
		if (!action.payload.stay) yield put(push('/home'));
	}

	function* onError(error) {
		yield put({ type: REGISTER_FAILED, error: error });
	}

	try {
		yield call(register, action.payload.data, {
			onSuccess: onSuccess,
			onError: onError
		});
	} catch (error) {
		yield onError(error.message);
	}
}

function* RegisterSaga() {
	yield takeLatest(REGISTER, registerUser);
}

export default RegisterSaga;
