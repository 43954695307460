import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'antd';
import {
	StyledTotalPrice,
	StyledTotalRow,
	StyledCol,
	StyledPriceTaxes
} from './styles';

const TotalAmountRow = ({ currency, price, roundedTop = false }) => {
	const { t } = useTranslation();
	return (
		<StyledTotalRow
			type="flex"
			justify="center"
			align="center"
			roundedTop={roundedTop}
		>
			<Col md={23}>
				<StyledCol md={18}>
					<StyledTotalPrice content="TOTAL A PAGAR" />
				</StyledCol>
				<StyledCol md={6}>
					<StyledTotalPrice content={`${currency} ${price.toFixed(2)}`} />
					<StyledPriceTaxes content={t('checkout.taxes')} />
				</StyledCol>
			</Col>
			<Col md={1}></Col>
		</StyledTotalRow>
	);
};

export default TotalAmountRow;
