import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getUserCurrency, isMobile } from '../../../../utilities/utils';

/*----- Components -----*/
import {
	StyledText,
	StyledTitle,
	DescriptionContainer,
	StyledIconArrow,
	ArrowDiv,
	StyledArrowText
} from './styles';

const ActivityDescription = ({
	title = 'general.knowMore',
	description,
	fromPackage = false
}) => {
	const { t } = useTranslation();
	const [showShortDescription, setShowShortDescription] = useState(isMobile());
	const slug = { ARS: 'Día', R$: 'Dia', USD: 'Day' };

	let substring = description.substring(0, 445).split(' ');
	let shortDescription = `${substring.slice(0, substring.length - 1).join(' ')} ...`;

	const packagedays =
		fromPackage &&
		description.split(/Día \d\)|Dia \d\)|Day \d\)/).filter(nonEmpty => nonEmpty);

	if (fromPackage) {
		return (
			<DescriptionContainer>
				<StyledTitle content={t(title)} size={3} />
				{description.match(/Día \d\)|Dia \d\)|Day \d\)/) ? (
					packagedays.map((day, index) => (
						<>
							<StyledTitle
								content={`${slug[getUserCurrency().name]} ${index + 1}`}
								size={4}
							/>
							<StyledText content={day} />
						</>
					))
				) : (
					<StyledText content={description} />
				)}
			</DescriptionContainer>
		);
	}
	return (
		<DescriptionContainer>
			<StyledTitle content={t(title)} size={3} />
			<StyledText content={showShortDescription ? shortDescription : description} />
			{isMobile() && (
				<ArrowDiv>
					<StyledArrowText bold content={t('general.seeMore')} />
					<StyledIconArrow
						type={showShortDescription ? 'down' : 'up'}
						onClick={() => setShowShortDescription(!showShortDescription)}
					/>
				</ArrowDiv>
			)}
		</DescriptionContainer>
	);
};

export default ActivityDescription;
