import {
	RESERVE_HOTEL,
	RESERVE_HOTEL_FAILED,
	RESERVE_HOTEL_SUCCESS
} from '../actions/reserveActions';

const initialState = {
	data: [],
	isLoading: false,
	error: '',
	success: false
};

export default function reserveHotel(state = initialState, action) {
	switch (action.type) {
		case RESERVE_HOTEL:
			return {
				...state,
				data: [],
				isLoading: true,
				error: '',
				success: false
			};
		case RESERVE_HOTEL_SUCCESS:
			return {
				...state,
				data: action.payload,
				isLoading: false,
				success: true
			};
		case RESERVE_HOTEL_FAILED:
			return {
				...state,
				isLoading: false,
				error: action.payload,
				success: false
			};
		default:
			return state;
	}
}
