import React from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';

import { StyledCol } from '../styles';
import { StyledInput } from '../../../../components/form/styles';

const LodgingField = ({
	form,
	dataCheckout,
	transferKind = 'in',
	onlyLabel = false,
	fieldType = '',
	isMix
}) => {
	const { t } = useTranslation();
	const { getFieldDecorator } = form;
	const kind = transferKind === 'in' ? 'destination' : 'pickUp';
	const label = onlyLabel ? 'self' : kind;
	const fieldId = fieldType ? `_${fieldType}` : '';
	const mixPrefix = isMix ? `${transferKind}_` : '';
	let initialValue = '';

	if (dataCheckout) {
		if (fieldType) {
			initialValue = dataCheckout[fieldType].hotel_info;
		} else {
			initialValue = dataCheckout.hotel_info;
		}
	}
	return (
		<StyledCol xs={24} sm={12} md={4}>
			<Form.Item label={t(`checkout.lodging.${label}`)}>
				{getFieldDecorator(`${mixPrefix}lodging${fieldId}`, {
					rules: [
						{
							required: true,
							whitespace: true,
							message: t('checkout.lodging.error')
						}
					],
					initialValue: initialValue
				})(<StyledInput type="text" placeholder={''} withoutLabel />)}
			</Form.Item>
		</StyledCol>
	);
};

export default LodgingField;
