/*----- Core -----*/
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

/*----- Components -----*/
import GenericConfirmCard from '../../../../commons/confirm/GenericConfirm/GenericConfirmCard';
import GenericConfirm from '../../../../commons/confirm/GenericConfirm';

/*----- Utils -----*/
import { hotelSteps } from '../../../../../utilities/utils';
import { getCheckoutHotel } from '../../../../../services/HotelCheckoutService';
import { getId } from '../../../../commons/checkout/CheckoutUtils';
import { showHotelData } from '../../../selectors/showHotelSelector';

import ConfirmDetailsCard from '../../../../commons/confirm/ConfirmDetailsCard';
import { useTranslation } from 'react-i18next';

import {
	getHotelInformation,
	getDetailCheckout
} from '../../checkout/section/detailsItemsHotel';

const SuccessResponse = ({ history, showHotel }) => {
	const { t } = useTranslation();
	const id = getId(history);
	const dataCheckout = getCheckoutHotel(id);
	const hotelInformation = getHotelInformation(showHotel, dataCheckout.item_id);
	console.clear();
	console.log(hotelInformation);
	return (
		<GenericConfirm
			steps={hotelSteps}
			id={id}
			active={3}
			title={showHotel.name}
			ConfirmCard={
				<GenericConfirmCard
					header={
						<ConfirmDetailsCard
							type={t('hotels.room')}
							name={hotelInformation.roomType}
							items={getDetailCheckout(hotelInformation, ['regime', 'passengers'])}
							dateFrom={hotelInformation.date_in}
							dateTo={hotelInformation.date_out}
						/>
					}
					service="hotels"
					dataCheckout={dataCheckout}
					taxes={hotelInformation.iva}
					currency={hotelInformation.currency}
					sale={hotelInformation.amount}
				/>
			}
		/>
	);
};

const mapStateToProps = state => {
	return {
		showHotel: showHotelData(state)
	};
};

export default connect(mapStateToProps, null)(withRouter(SuccessResponse));
