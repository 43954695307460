import React from 'react';

const TicketIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.312 23.312">
		<path
			className="a"
			d="M20.429,5.838l-.483.483a2.089,2.089,0,0,1-2.955-2.955l.483-.483L14.591,0,0,14.591l2.883,2.883.483-.483a2.089,2.089,0,0,1,2.955,2.955l-.483.483,2.883,2.883L23.312,8.721ZM7.723,20.381a3.457,3.457,0,0,0-4.792-4.792l-1-1L14.591,1.932l1,1a3.457,3.457,0,0,0,4.792,4.792l1,1L8.721,21.38Z"
		/>
		<rect
			className="a"
			width="1.366"
			height="1.372"
			transform="translate(12.646 9.696) rotate(-45)"
		/>
		<rect
			className="a"
			width="1.366"
			height="1.372"
			transform="translate(10.707 7.756) rotate(-45)"
		/>
		<rect
			className="a"
			width="1.366"
			height="1.372"
			transform="translate(14.586 11.636) rotate(-45)"
		/>
	</svg>
);
export default TicketIcon;
