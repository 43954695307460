import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledFilterData, StyledText, ArrowBottom, Div } from './styles';
import { activitiesDestinations } from '../../../utilities/constants';
import { parse } from '../../../utilities/queryString';
import moment from 'moment';
import { withRouter } from 'react-router';
import MobileModal from '../../modal/MobileModal';

const SearchFormMobile = ({ history, service, SearchForm }) => {
	const { t } = useTranslation();

	const [initialDateIn, setInitialDateIn] = useState(moment());
	const [initialDateOut, setInitialDateOut] = useState(moment().add(1, 'days'));
	const [initialCityCode, setInitialCityCode] = useState(null);
	const [visible, setVisible] = useState(false);

	let params = parse(history.location.search, activitiesDestinations);

	useEffect(() => {
		if (!params) {
			history.push(`/${service}`);
			return;
		}

		setInitialDateIn(moment(params.date_in).format('DD/MM/YY'));
		setInitialDateOut(moment(params.date_out).format('DD/MM/YY'));
		setInitialCityCode(
			activitiesDestinations.find(city => city.value === params.city_code).label
		);
	}, [history, params, service]);

	return (
		<StyledFilterData>
			<MobileModal
				visible={visible}
				onCancel={() => setVisible(false)}
				title={t('general.editSearch')}
				content={
					<SearchForm
						header={t(`${service}.titleForm`)}
						onSubmit={() => setVisible(false)}
					/>
				}
			/>
			<StyledText>{initialCityCode}</StyledText>
			<StyledText weight={400}>{`${initialDateIn} al ${initialDateOut}`}</StyledText>
			<Div
				onClick={e => {
					e.preventDefault();
					setVisible(true);
				}}
			>
				<StyledText>{t('general.edit')}</StyledText>
				<ArrowBottom width={'12px'} rotateDeg={270} color={'#FFF'} />
			</Div>
		</StyledFilterData>
	);
};

export default withRouter(SearchFormMobile);
