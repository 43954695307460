import styled from 'styled-components';
import Color from '../../../../commons/Color';
import Device from '../../../../commons/Device';
import Title from '../../../../components/text/Title';
import Text from '../../../../components/text/Text';

export const StyledReservationData = styled.div`
	width: 100%;
	margin-top: 35px;

	@media ${Device.xs} {
		margin-top: 0px;
	}
`;

export const SuccessTitle = styled(Title)`
	text-align: center;
	font-size: 32px;
	margin: 0px;
	@media ${Device.xs} {
		font-size: 16px;
	}
`;

export const SuccessSubTitle = styled(Text)`
	text-align: center;
	font-size: 20px;
	margin-bottom: 24px;

	@media ${Device.xs} {
		font-size: 12px;
	}
`;

export const ContainerReservationCode = styled.div`
	padding: 10px 0px;
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	justify-content: center;

	@media ${Device.xs} {
		padding: 5px 0px;
	}
`;

export const Span = styled.span`
	font-size: 18px;
	font-weight: 400;
	color: ${Color.footer};
	padding: 10px;

	@media ${Device.xs} {
		font-size: 14px;
	}
`;
