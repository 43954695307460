export const REGISTER = 'REGISTER'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAILED = 'REGISTER_FAILED'
export const DELETE_REGISTER = 'DELETE_REGISTER'

export const register = (dispatch, param) => {
  dispatch({ type: REGISTER, payload: param })
}

export const deleteRegister = (dispatch, param) => {
  dispatch({ type: DELETE_REGISTER, payload: param })
}