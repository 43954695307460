import React from 'react';
import { StyledCard, TitleContainer, StyledStars, StyledTitle } from './styles';
import SaleCard from '../SaleCard';

const HotelSaleCard = ({ title, stars, regime, ...others }) => (
	<SaleCard
		{...others}
		withoutIcons
		titlePrice="checkout.totalPriceSince"
		action="general.seeDetail"
		cardInformation={
			<StyledCard>
				<TitleContainer>
					<StyledStars stars={stars} />
					<StyledTitle bold content={title} />
				</TitleContainer>
			</StyledCard>
		}
	/>
);

export default HotelSaleCard;
