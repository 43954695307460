import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
	SHOW_PACKAGE,
	SHOW_PACKAGE_SUCCESS,
	SHOW_PACKAGE_FAILED
} from '../actions/showPackageActions';
import { showPackages } from '../../../api/endpoints/Packages';

import { removeCheckoutPackages } from '../../../services/PackagesCheckoutService';
import { getWithHotel } from '../../../services/SearchService';

function* shotFetchPackage(action) {
	function* onSuccess(body) {}

	function* onOk(body) {
		yield put({ type: SHOW_PACKAGE_SUCCESS, payload: body.data });
	}

	function* onError(error) {
		removeCheckoutPackages(action.payload);
		yield put({ type: SHOW_PACKAGE_FAILED, payload: error });
		yield put(push('/packages/search'));
	}

	try {
		if (getWithHotel()) yield call(onOk, {});
		else {
			yield call(showPackages, action.payload, {
				onSuccess: onSuccess,
				onError: onError,
				onOk: onOk
			});
		}
	} catch (error) {
		yield onError(error.message);
	}
}

export default function* PackageSagas() {
	yield takeLatest(SHOW_PACKAGE, shotFetchPackage);
}
