import aboutUs from './aboutUs.json';
import activities from './activities.json';
import checkout from './checkout.json';
import consult from './consult.json';
import contact from './contact.json';
import general from './general.json';
import hotels from './hotels.json';
import packages from './packages.json';
import reservation from './reservation.json';
import transfers from './transfers.json';
import authentication from './authentication.json';
import moreInformation from './moreInformation.json';
import cart from './cart.json';
import myReservations from './myReservations.json';

export default {
	aboutUs: aboutUs,
	activities: activities,
	checkout: checkout,
	consult: consult,
	contact: contact,
	general: general,
	hotels: hotels,
	packages: packages,
	reservation: reservation,
	transfers: transfers,
	authentication: authentication,
	moreInformation: moreInformation,
	cart: cart,
	myReservations: myReservations
};
