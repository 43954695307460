import React from 'react';
import { useTranslation } from 'react-i18next';
import FilterFormFronCategories from './FilterFormCategories';

const FilterFormHotelRegimes = ({
	form,
	fetchData,
	defaultValues = [],
	disabled
}) => {
	const { t } = useTranslation();

	const categories = [
		{ value: 'all', label: t('hotels.regimes.all') },
		{ value: 'BB', label: t('hotels.regimes.breakfast') },
		{ value: 'HB', label: t('hotels.regimes.halfBoard') },
		{ value: 'FB', label: t('hotels.regimes.fullBoard') },
		{ value: 'AI', label: t('hotels.regimes.allInclusive') }
	];

	return (
		<FilterFormFronCategories
			name={t('hotels.regime')}
			form={form}
			options={categories}
			fieldName={'regime_codes'}
			fetchData={fetchData}
			defaultValues={defaultValues}
			disabled={disabled}
		/>
	);
};

export default FilterFormHotelRegimes;
