/*----- Actions -----*/
import { REFRESH_TOKEN, REFRESH_FAILED, REFRESH_SUCCESS } from '../actions/refreshActions'

/*----- Services -----*/
import { updateToken, destroySession } from '../../sessions/loginSession'

const initialState = {
  isLoading: false,
  error:     ''
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REFRESH_TOKEN:
      return state
    case REFRESH_SUCCESS:
      updateToken(action.payload)
      return state
    case REFRESH_FAILED:
      destroySession()
      return state
    default:
      return state
  }
}