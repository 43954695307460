import React from 'react';
import { StyledModal } from './styles';

const LoginModal = ({ closeModal, visible, content }) => {
	return (
		<StyledModal
			visible={visible}
			onCancel={closeModal}
			closable={false}
			footer={null}
			bodyStyle={{ padding: '0px' }}
			content={content}
		/>
	);
};

export default LoginModal;
