import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import Color from '../../../commons/Color';
import Device from '../../../commons/Device';

const StyledData = styled.div`
	margin-bottom: 0px;
	@media ${Device.xs} {
		margin-bottom: 16px;
	}

	${props =>
		props.lastItemData &&
		css`
			@media ${Device.xs} {
				margin-bottom: 0px;
			}
		`}
`;

const Span = styled.span`
	font-size: 14px;
	color: ${Color.text};

	@media ${Device.xs} {
		font-size: 12px;
	}
`;

const Data = styled.p`
	padding-top: 6px;
	color: ${Color.footer};
	font-size: 20px;
	text-transform: capitalize;
	margin: 0px;

	@media ${Device.xs} {
		font-size: 16px;
	}
`;

const CheckoutData = ({ label, value, lastItemData }) => (
	<StyledData lastItemData={lastItemData}>
		<Span>{label}</Span>
		<Data className="data">{value}</Data>
	</StyledData>
);

export default CheckoutData;

CheckoutData.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	lastItemData: PropTypes.bool
};
