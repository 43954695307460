import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	StyledServiceCard,
	StyledImage,
	StyledName,
	ContentImages,
	StyledStars,
	StyledDefaultButton,
	ContentCard,
	StyledPackagesServices
} from './styles';
import getPackagesServices from '../commons/getPackagesServices';

const ServiceCard = ({ image, title, services, stars, onClick }) => {
	const { t } = useTranslation();
	return (
		<StyledServiceCard>
			<ContentImages>
				<StyledImage src={image} />
				<StyledStars stars={stars} />
			</ContentImages>
			<ContentCard>
				<StyledName content={title} />
				<StyledPackagesServices>
					{getPackagesServices(services)}
				</StyledPackagesServices>
				<StyledDefaultButton onClick={onClick} content={t('general.consult')} />
			</ContentCard>
		</StyledServiceCard>
	);
};

export default ServiceCard;
