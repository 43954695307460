import styled, { css } from 'styled-components';
import WaterMark from '../../assets/images/watermark.svg';
import Device from '../../commons/Device';

export const Main = styled.main`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-color: white;
	padding-bottom: 60px;

	${props =>
		props.showWaterMark &&
		css`
			background-image: url(${WaterMark});
			background-position: left bottom;
			background-repeat: no-repeat;
		`};

	${props =>
		props.isLoading &&
		css`
			padding-bottom: 60vh;
		`};
`;

export const StyledSearchFormContainer = styled.div`
	background: white;
	width: 900px;
	justify-content: center;

	@media ${Device.xs} {
		display: none;
	}
`;

export const FilterFormContainer = styled.div`
	display: flex !important;
	justify-content: center;
	width: 100%;
`;

export const FilterDataContainer = styled.div`
	width: 100%;
	display: none;
	@media ${Device.xs} {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`;
