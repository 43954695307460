import styled from 'styled-components';
import Text from '../../../../components/text/Text';
import Device from '../../../../commons/Device';

export const MainDiv = styled.div``;

export const ObservationDiv = styled.div`
	margin-top: 16px;
`;

export const StyledTitle = styled(Text)`
	font-size: 28px;

	@media ${Device.xs} {
		font-size: 18px;
	}
`;
