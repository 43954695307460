import React, { Fragment } from 'react';

/*----- Components -----*/
import { Row, Form } from 'antd';
import { StyledCol } from '../styles';
import TitleWithLabel from '../../../../components/text/TitleWithLabel';
import {
	StyledInput,
	StyledDatePicker,
	StyledSelectItemContainer
} from '../../../../components/form/styles';

import {
	handleDisabledAfterDate,
	handlerBetweenDates
} from '../../../../components/date-picker/Handlers';

import Moment from 'moment';
import { useTranslation } from 'react-i18next';
import { countriesOptions } from '../../../../utilities/constants';
import SelectItem from '../../../../components/formItems/SelectItem';

const DataPassengerForm = ({
	idx,
	form,
	isAdult,
	isOwner,
	endDate,
	initDate,
	extraText,
	dataPassenger,
	passengerLabel,
	defaultPickerValue
}) => {
	const { getFieldDecorator } = form;
	const { t } = useTranslation();

	return (
		<Fragment>
			<TitleWithLabel title={passengerLabel} text={extraText} />
			<Row>
				<StyledCol xs={24} sm={12} md={4}>
					<Form.Item label={t('checkout.passengers.name.title')}>
						{getFieldDecorator(`name_${idx}`, {
							rules: [
								{
									required: true,
									whitespace: true,
									message: t('checkout.passengers.name.error')
								}
							],
							initialValue: dataPassenger.name
						})(
							<StyledInput
								name="name"
								type="text"
								placeholder={'Franco'}
								withoutLabel
							/>
						)}
					</Form.Item>
				</StyledCol>

				<StyledCol xs={24} sm={12} md={4}>
					<Form.Item label={t('checkout.passengers.surname.title')}>
						{getFieldDecorator(`lastname_${idx}`, {
							rules: [
								{
									required: true,
									whitespace: true,
									message: t('checkout.passengers.surname.error')
								}
							],
							initialValue: dataPassenger.lastname
						})(
							<StyledInput
								name="lastname"
								type="text"
								placeholder={'Gomez'}
								withoutLabel
							/>
						)}
					</Form.Item>
				</StyledCol>

				<StyledCol xs={24} sm={12} md={4}>
					<StyledSelectItemContainer>
						<SelectItem
							label={t('checkout.passengers.nationality.title')}
							fieldName={`nationality_${idx}`}
							placeholder="Nacionalidad"
							getFieldDecorator={getFieldDecorator}
							options={countriesOptions}
							rules={[
								{
									required: true,
									whitespace: true,
									message: t('checkout.passengers.nationality.error')
								}
							]}
						/>
					</StyledSelectItemContainer>
				</StyledCol>

				<StyledCol xs={24} sm={12} md={4}>
					<Form.Item label={t('checkout.passengers.identification.title')}>
						{getFieldDecorator(`dni_passport_${idx}`, {
							rules: [
								{
									required: true,
									whitespace: true,
									message: t('checkout.passengers.identification.error')
								}
							],
							initialValue: dataPassenger.dni_passport
						})(
							<StyledInput
								name="dni_passport"
								type="text"
								placeholder={'38456789'}
								withoutLabel
							/>
						)}
					</Form.Item>
				</StyledCol>
				<StyledCol xs={24} sm={12} md={4}>
					<Form.Item label={t('checkout.passengers.birthdate.title')}>
						{getFieldDecorator(`date_of_birthday_${idx}`, {
							rules: [
								{
									required: true,
									message: t('checkout.passengers.birthdate.error')
								}
							],
							validateTrigger: ['onSubmit'],
							initialValue: dataPassenger.date_of_birthday
								? Moment(dataPassenger.date_of_birthday, 'DD/MM/YYYY')
								: null
						})(
							<StyledDatePicker
								funcDisabledDate={current => {
									if (isAdult) {
										return handleDisabledAfterDate(current, defaultPickerValue);
									}
									return !handlerBetweenDates(current, initDate, endDate);
								}}
								defaultPickerValue={defaultPickerValue}
								afterChange={date => {
									form.setFieldsValue({ date_out: date });
								}}
								withoutLabel
							/>
						)}
					</Form.Item>
				</StyledCol>
				<StyledCol xs={24} sm={12} md={4}>
					{isAdult && (
						<Form.Item label={t('checkout.passengers.phone.title')}>
							{getFieldDecorator(`phone_${idx}`, {
								rules: [
									{
										required: isOwner,
										whitespace: true,
										message: t('checkout.passengers.phone.error')
									}
								],
								initialValue: dataPassenger.phone
							})(
								<StyledInput
									name="phone"
									type="text"
									placeholder="221-888-8888"
									withoutLabel
								/>
							)}
						</Form.Item>
					)}
				</StyledCol>
			</Row>
		</Fragment>
	);
};

export default DataPassengerForm;
