import React, { createRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

/*----- Form items -----*/
import Styles, { StyledTitle } from '../styles';

import IsAirportRadio from './IsAirportRadio';
import ArrivalFields from './ArrivalFields';

const ArrivalForm = ({ form, dataCheckout, transferKind, isMix = false }) => {
	const { t } = useTranslation();
	const initialState = dataCheckout
		? isMix
			? {
					in: dataCheckout['in_is_airport'] === 'true',
					out: dataCheckout['out_is_airport'] === 'true'
			  }
			: { [transferKind]: dataCheckout.is_airport === 'true' }
		: isMix
		? { in: true, out: true }
		: { [transferKind]: true };
	const [isAirport, setIsAirport] = useState(initialState);
	const kind = transferKind === 'in' ? 'arrival' : 'departure';
	const isAirportRef = createRef();
	return (
		<Styles>
			<StyledTitle content={t(`checkout.${kind}`)} size={4} />
			<IsAirportRadio
				form={form}
				isAirport={isAirport[transferKind]}
				setIsAirport={setIsAirport}
				transferKind={transferKind}
				isMix={isMix}
				ref={isAirportRef}
			/>

			<ArrivalFields
				form={form}
				isAirport={isAirport[transferKind]}
				dataCheckout={dataCheckout}
				transferKind={transferKind}
				isMix={isMix}
			/>
		</Styles>
	);
};

export default ArrivalForm;
