export const driver = window.localStorage;

export const startSession = authData => {
	driver.setItem('auth', JSON.stringify(authData.jwt));
	driver.setItem('refresh', JSON.stringify(authData.refresh_token));
};

export const destroySession = () => {
	driver.removeItem('auth');
	driver.removeItem('refresh');
	driver.removeItem('cart');
	driver.removeItem('checkout_cart');
	driver.removeItem('search_query');
	driver.removeItem('date_search');
	window.location.reload();
};

export const getAuthToken = () => {
	return JSON.parse(driver.getItem('auth'));
};

export const getRefreshToken = () => {
	return JSON.parse(driver.getItem('refresh'));
};

export const isAuth = () => {
	return driver.getItem('auth') != null;
};

export const updateToken = authData => {
	startSession(authData);
};
