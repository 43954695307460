import React from 'react';
import {
	StyledDiv,
	StyledImage,
	ContentRow,
	TitleText,
	ContentText,
	SubContentText,
	FooterText,
	FooterRow
} from './styles';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../../utilities/utils';

const GenericResponse = ({
	image,
	title,
	firstContent,
	secondContent,
	thirdContent = ''
}) => {
	const { t } = useTranslation();
	return (
		<StyledDiv>
			<StyledImage src={image} />
			<ContentRow>
				<TitleText content={title} />
				{isMobile() ? (
					<ContentText
						content={`${firstContent} ${secondContent} ${thirdContent}`}
					/>
				) : (
					<>
						<ContentText content={firstContent} />
						<ContentText content={secondContent} />
						<ContentText content={thirdContent} />
					</>
				)}
				<SubContentText
					content={t('authentication.register.feedback.confirm.subContent')}
				/>
			</ContentRow>
			<FooterRow>
				<FooterText content={t('authentication.register.feedback.confirm.footer')} />
			</FooterRow>
		</StyledDiv>
	);
};

export default GenericResponse;
