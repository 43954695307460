import styled from 'styled-components';
import Device from '../../../../commons/Device';

export default styled.main`
	margin-bottom: 50px;

	.card__list-container {
		padding-top: 50px;
		margin: 5px 80px;
	}

	.filter-form {
		margin-top: -64px;
	}

	.card-container {
		padding: 10px;
	}

	@media ${Device.xs} {
		.card__list-container {
			padding-top: 10px;
			margin: 15px;
		}

		.card-container {
			padding: 0;
		}
	}
`;
