import React from 'react';
import { useState } from 'react';
import { Div, StyledText } from './styles';
import SeeDetailsModal from './SeeDetailsModal';
import { useTranslation } from 'react-i18next';

const SeeDetails = ({ kind, title, details, className }) => {
	const { t } = useTranslation();
	const [visible, setVisible] = useState(false);

	return (
		<Div className={className}>
			<SeeDetailsModal
				visible={visible}
				onCancel={() => setVisible(false)}
				kind={kind}
				title={title}
				details={details}
			/>
			<StyledText
				onClick={e => {
					e.preventDefault();
					setVisible(true);
				}}
			>
				{details.length ? t('general.seeDetail') : ''}
			</StyledText>
		</Div>
	);
};

export default SeeDetails;
