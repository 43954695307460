import React from 'react';

/*----- Assets -----*/
import StarIcon from '../../assets/icons/star.svg';

const Stars = ({
	stars,
	style = { width: '16px', marginRight: '8px' },
	className = 'stars-container'
}) => {
	const renderStars = () => {
		let items = [];
		for (let i = 0; i < stars; i++) {
			items.push(<img src={StarIcon} key={i} alt="star icon" style={style} />);
		}
		return items;
	};

	return <div className={className}>{renderStars()}</div>;
};

export default Stars;
