import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { cartData } from '../../selectors/cartSelector';
import DropdownCartItems from '../items';

import { ReactComponent as ShoppingCartIcon } from '../../../../assets/icons/shopping-cart.svg';
import { ReactComponent as EmptyIcon } from '../../../../assets/icons/warning.svg';
import {
	StyledDropdownContainer,
	StyledLink,
	StyledDiv,
	StyledImg,
	StyledText,
	StyledDropdownHeader,
	StyledDropdownSubtitle,
	StyledDropdownSection,
	StyledDropdownEmpty,
	StyledDropdownEmptyLogo
} from './styles';

const DropdownCart = ({ history, cartData }) => {
	const { t } = useTranslation();
	const [showDropdownCart, setShowDropdownCart] = useState(false);
	const dropdownRef = useRef(null);

	const closeOnClickOutside = event => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target))
			setShowDropdownCart(false);
	};

	const openDetailedCart = () => {
		setShowDropdownCart(false);
		history.push('/cart');
	};

	useEffect(() => {
		document.addEventListener('click', closeOnClickOutside, true);
		return () => {
			document.removeEventListener('click', closeOnClickOutside, true);
		};
	});

	return (
		<>
			<StyledLink onClick={() => setShowDropdownCart(!showDropdownCart)}>
				<StyledDiv showDropdown={showDropdownCart}>
					<StyledImg>
						<ShoppingCartIcon />
					</StyledImg>
					<StyledText content={cartData.length} />
				</StyledDiv>
			</StyledLink>
			<StyledDropdownContainer showDropdown={showDropdownCart} ref={dropdownRef}>
				<StyledDropdownHeader>{t('cart.dropdown.title')}</StyledDropdownHeader>
				<StyledDropdownSubtitle>
					{t('cart.dropdown.subtitle')}
				</StyledDropdownSubtitle>
				{cartData.length ? (
					<DropdownCartItems openDetailedCart={openDetailedCart} />
				) : (
					<StyledDropdownSection>
						<StyledDropdownEmptyLogo>
							<EmptyIcon />
						</StyledDropdownEmptyLogo>
						<StyledDropdownEmpty>{t('cart.dropdown.empty')}</StyledDropdownEmpty>
					</StyledDropdownSection>
				)}
			</StyledDropdownContainer>
		</>
	);
};

const mapStateToProps = state => {
	return {
		cartData: cartData(state)
	};
};

export default connect(mapStateToProps)(withRouter(DropdownCart));
