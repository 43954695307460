/*----- Core -----*/
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';

import ReservationPanel from './ReservationPanel';
import ReservationPanelDetail from './ReservationPanelDetail';
import { showPackageData } from '../../modules/packages/selectors/showPackageSelector';
import detailsItemsPackages from '../../modules/packages/views/detail/section/detailsItemsPackages';
import { getSearchQuery } from '../../services/SearchService';

const ReservationPanelPackage = ({ history, showPackage, withHotel }) => {
	const { t } = useTranslation();
	useEffect(() => {
		if (showPackage) {
			console.clear();
			console.log(showPackage);
		}
	}, [showPackage]);
	return (
		<ReservationPanel
			currency={showPackage.rate.currency}
			amount={showPackage.rate.amount_with_taxes}
			textTotal={t('checkout.totalPrice')}
			dateFrom={showPackage.rate.cotization_date}
			dateTo={getSearchQuery().date_out}
			textButton={withHotel ? t('packages.goToHotel') : t('general.reserve')}
			panelTitle={
				withHotel
					? t('packages.packageWithHotel')
					: t('packages.packageWithoutHotel')
			}
			dateLabel={t('general.date')}
			onClick={() => {
				withHotel
					? history.push(
							`/packages/${showPackage.rate.item_id}/hotels/${showPackage.rate.hotel[0].uuid}`
					  )
					: history.push(`/packages/${showPackage.rate.item_id}/checkout`);
			}}
		>
			<ReservationPanelDetail items={detailsItemsPackages(withHotel)} />
		</ReservationPanel>
	);
};

const mapStateToProps = state => {
	return {
		showPackage: showPackageData(state)
	};
};

export default connect(mapStateToProps, null)(withRouter(ReservationPanelPackage));
