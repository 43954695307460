import OpenSans from '../mixins/fonts/OpenSans';
import Color from '../Color';

export default `
	border-radius: 6px;
	margin: 16px 0;
	margin-top:0px;
	background-color: white;
	display: flex;
	justify-content: flex-start;
	align-items: start;
	border: 1px solid lightgrey;
	width: 100%;
	transition: all 0.2s ease-in;
	&:hover {
		box-shadow: 0 10px 14px 0px rgba(0, 0, 0, 0.1);
		transform: scale(1.02);
	}

	.product-card__image-container {
		background-position: center center;
		background-size: cover;
		min-height: 200px;
		display: flex;
	}
	.product-card__data-container {
		.custom-title {
			margin: 0;
		}
	}

	.product-card__title-container{
		//margin-top:15px;
		.custom-title{
			color:${Color.footer}
			text-align:center;
			// margin-bottom:10px;
		}
	}

	.card__description p{
		${OpenSans(12, 400, 'black')}
		padding: 5px 15px;
		padding-bottom: 26px;
		color: #707070;
	}

	.ant-row-flex{
		width: 100%
	}

	.product-card-label{
		position: absolute;
		right:5px;
		margin-top:5px;
		border-radius:5px;
		background-color: white;
		height:min-content;
		padding: 5px 10px;
		${OpenSans(16, 700, Color.main)}
	}

`;
