import React from "react"

export default function Bed() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 510.67 510.67"
      version="1.1"
      viewBox="0 0 510.67 510.67"
      xmlSpace="preserve"
    >
      <path d="M473.139 310.04v-84.255c0-18.156-14.772-32.928-32.928-32.928H147.275a7.499 7.499 0 00-7.5 7.5c0 4.143 3.357 7.5 7.5 7.5h292.936c9.886 0 17.928 8.042 17.928 17.928v83.992H424.47v-17.181c0-18.036-14.673-32.709-32.709-32.709h-78.926c-18.035 0-32.708 14.673-32.708 32.709v17.181h-49.584v-17.181c0-18.036-14.673-32.709-32.708-32.709h-78.926c-18.036 0-32.709 14.673-32.709 32.709v17.181H52.531v-83.992c0-9.886 8.042-17.928 17.928-17.928h46.815c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5H70.459c-18.156 0-32.928 14.771-32.928 32.928v84.255C16.453 312.367 0 330.278 0 351.966V464.73a7.5 7.5 0 007.5 7.5h109.774c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5H15v-78.191h383.211c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5H15v-12.072c0-14.992 12.196-27.188 27.188-27.188h426.293c14.992 0 27.189 12.196 27.189 27.188v12.072h-67.459c-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5h67.459v78.191H147.275c-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5H503.17a7.5 7.5 0 007.5-7.5V351.966c0-21.688-16.453-39.599-37.531-41.926zm-178.012-17.443c0-9.765 7.943-17.709 17.708-17.709h78.926c9.765 0 17.709 7.944 17.709 17.709v17.181H295.127v-17.181zm-193.927 0c0-9.765 7.944-17.709 17.709-17.709h78.926c9.765 0 17.708 7.944 17.708 17.709v17.181H101.2v-17.181zM195.415 168.693h40.166c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5h-23.118l28.647-31.255c4.322-4.717.855-12.567-5.529-12.567h-36.392a7.499 7.499 0 00-7.5 7.5c0 4.143 3.357 7.5 7.5 7.5h19.344l-28.647 31.255c-4.322 4.717-.856 12.567 5.529 12.567zM282.542 124.06h64.725c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5H299.59l53.206-58.052c4.324-4.717.855-12.567-5.529-12.567h-60.936c-4.087 0-7.5 3.413-7.5 7.5s3.413 7.5 7.5 7.5h43.888l-53.206 58.052c-4.324 4.716-.855 12.567 5.529 12.567z" />
    </svg>
  )
}