import React from 'react';
import { Skeleton } from 'antd';
import { Item } from './styles';

const LoadingList = ({ loading }) => {
	const fakeList = [...Array(3).keys()];

	if (!loading) return '';

	return fakeList.map((item, key) => (
		<Item key={key}>
			<Skeleton loading={loading} active>
				<div />
			</Skeleton>
		</Item>
	));
};

export default LoadingList;
