import { call, put, takeLatest } from 'redux-saga/effects';
import {
	GET_MY_RESERVATIONS,
	GET_MY_RESERVATIONS_SUCCESS,
	GET_MY_RESERVATIONS_FAILED
} from '../actions/myReservationsActions';
import { getMyReservations } from '../../../api/endpoints/Reservations';

function* shotFetchMyReservations(action) {
	function* onSuccess() {}

	function* onOk(body) {
		yield put({ type: GET_MY_RESERVATIONS_SUCCESS, payload: body });
	}

	function* onError(error) {
		yield put({ type: GET_MY_RESERVATIONS_FAILED, payload: error });
	}

	function* onPartialContent() {
		yield put({ type: 'GET_MY_RESERVATIONS_CONTINUE' });
	}

	try {
		yield call(getMyReservations, action.payload, {
			onSuccess: onSuccess,
			onError: onError,
			onOk: onOk,
			onPartialContent: onPartialContent
		});
	} catch (error) {
		yield onError(error.message);
	}
}

export default function* MyReservationsSagas() {
	yield takeLatest(GET_MY_RESERVATIONS, shotFetchMyReservations);
}
