/*----- Core -----*/
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { StyledTextCheckout } from '../../modules/commons/checkout/CardCheckoutData/styles';

const Styles = styled.div`
	display: flex;
	align-items: center;
`;

const StyledIcon = styled.img`
	width: 16px;
	height: 16px;
	color: #707070;
	margin-right: 12px;
`;

const ReservationPanelItemIcon = ({ icon, description }) => {
	return (
		<Styles>
			<StyledIcon src={icon} />
			<StyledTextCheckout content={description} className="icon-text" />
		</Styles>
	);
};

export default ReservationPanelItemIcon;

/*----- PropTypes -----*/
ReservationPanelItemIcon.propTypes = {
	description: PropTypes.string.isRequired
};
