import {
	RESERVE_ACTIVITY,
	RESERVE_ACTIVITY_FAILED,
	RESERVE_ACTIVITY_SUCCESS
} from '../actions/reserveActions';

const initialState = {
	data: [],
	isLoading: false,
	error: '',
	success: false
};

export default function reserveActivity(state = initialState, action) {
	switch (action.type) {
		case RESERVE_ACTIVITY:
			return {
				...state,
				data: [],
				isLoading: true,
				error: '',
				success: false
			};
		case RESERVE_ACTIVITY_SUCCESS:
			return {
				...state,
				data: action.payload,
				isLoading: false,
				success: true
			};
		case RESERVE_ACTIVITY_FAILED:
			return {
				...state,
				isLoading: false,
				error: action.payload,
				success: false
			};
		default:
			return state;
	}
}
