/*----- Core -----*/
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

/*----- Components -----*/
import RoomList from './RoomList';
import { StyledTitle } from '../../DetailDescription/styles';
import { StyledRadioGroup } from './styles';

/*----- Actions -----*/
import { setHotelRoom } from '../../../../hotels/actions/hotelRoomActions';

/*----- Selectors -----*/
import {
	hotelRoom,
	selectedRoom
} from '../../../../hotels/selectors/hotelRoomSelector';

/*----- Utils -----*/
import { groupByRoomType } from './hotelRoomsUtils';

const HotelRooms = ({
	setHotelRoom,
	hotelRoom,
	showData,
	selectedRoom,
	getInformation,
	getDetailCheckout
}) => {
	const ref = useRef();

	useEffect(() => {
		if (selectedRoom) {
			ref.current.scrollIntoView({
				behavior: 'smooth',
				block: 'start'
			});
		}
	}, [selectedRoom]);

	const { t } = useTranslation();
	const rates = groupByRoomType(showData.rates.sort((a, b) => a.amount - b.amount));

	const getRooms = () => {
		return Object.keys(rates).map((rate, idx) => (
			<RoomList
				key={idx}
				roomType={rate}
				rooms={rates[rate]}
				roomSelected={hotelRoom}
				showHotel={showData}
				getInformation={getInformation}
				getDetailCheckout={getDetailCheckout}
			/>
		));
	};

	return (
		<div ref={ref}>
			<StyledTitle content={t('general.chooseYourRoom')} size={3} />
			<StyledRadioGroup
				onChange={value => {
					const rate = showData.rates.find(
						rate => value.target.value === rate.item_id
					);
					console.clear();
					console.log(rate);
					setHotelRoom(rate);
				}}
				value={hotelRoom ? hotelRoom.item_id : null}
			>
				{getRooms()}
			</StyledRadioGroup>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		hotelRoom: hotelRoom(state),
		selectedRoom: selectedRoom(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setHotelRoom: payload => setHotelRoom(dispatch, payload)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(HotelRooms);
