import styled, { css } from 'styled-components';
import Color from '../../commons/Color';
import Device from '../../commons/Device';
import OpenSansBold from '../../commons/mixins/fonts/OpenSansBold';

const StyledSteps = styled.div`
	padding: 30px 20px;
	background-color: #ffffff;
	width: 100%;
	.ant-steps
		.ant-steps-item-container
		.ant-steps-item-content
		.ant-steps-item-title {
		${OpenSansBold(12, 600, '#ffffff')}
	}

	.ant-steps-item-icon > .ant-steps-icon {
		top: 2px;
	}

	.ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
		top: -16px;
	}

	svg {
		fill: white;
		width: 16px;
		height: 16px;
	}

	.hotel-icon,
	.suitcase-icon,
	.room-icon,
	.confirmation-icon,
	.bus-icon {
		width: 14px;
		height: 14px;
	}

	.ant-steps-dot .ant-steps-item-content,
	.ant-steps-dot.ant-steps-small .ant-steps-item-content {
		width: 160px;
	}

	.ant-steps-item-container .ant-steps-item-content .ant-steps-item-title {
		color: ${Color.main} !important;
	}

	.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
	.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
		width: 30px;
		height: 30px;
		line-height: 30px;
	}

	.ant-steps-dot .ant-steps-item-tail,
	.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
		top: 10px;
		margin: 0 0 0 70px;
		padding: 0;
		left: 17px;
		width: ${props =>
			props.steps === 4 ? '94.2%' : props.steps === 5 ? '92%' : '94.2%'};
	}

	.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
		background-color: ${Color.main};
	}

	.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
		background-color: ${Color.text};
	}
	.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
		background-color: ${Color.text};
	}

	.ant-steps-item {
		svg {
			fill: ${Color.main};
			stroke: ${Color.main};
			stroke-width: 0.2px;
		}
		.user-icon {
			stroke-width: 15px;
		}
		.ant-steps-item-icon {
			background-color: white;
			border: 2px solid ${Color.main};
			width: 30px;
			height: 30px;
			margin-left: 64px;
		}

		.ant-steps-item-content .ant-steps-item-title {
			color: ${Color.main};
		}
	}

	.ant-steps-item-active {
		svg {
			fill: white;
			stroke: white;
		}
		.ant-steps-item-icon {
			background-color: ${Color.main};
			border-color: #aa0000;
		}

		.ant-steps-item-content .ant-steps-item-title {
			color: ${Color.main};
		}
	}

	.ant-steps-item-disabled {
		svg {
			fill: ${Color.text};
			stroke: ${Color.text};
		}
		.ant-steps-item-icon {
			border-color: ${Color.text};
		}
		.ant-steps-item-content .ant-steps-item-title {
			color: ${Color.text} !important;
		}

		&.ant-steps-item-finish {
			svg {
				opacity: 0.5;
			}
			.ant-steps-item-icon {
				opacity: 0.5;
			}
			.ant-steps-item-content .ant-steps-item-title {
				opacity: 0.5;
			}

			& > .ant-steps-item-container > .ant-steps-item-tail::after {
				background-color: ${Color.text} !important;
				opacity: 0.5;
			}
		}
	}

	.ant-steps:not(.ant-steps-navigation)
		.ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
		> .ant-steps-item-container[role='button']:hover {
		.ant-steps-item-title {
			color: ${Color.main}95 !important;
		}
		.ant-steps-item-icon {
			border-color: ${Color.main}95;
			svg {
				fill: ${Color.main}95;
				stroke: ${Color.main}95;
			}
		}
	}

	.ant-steps-item-process
		> .ant-steps-item-container
		> .ant-steps-item-icon
		> .ant-steps-icon {
		top: -5px;
	}

	@media ${Device.xs} {
		padding: 25px 20px;
		padding-bottom: 0px;
		${window.location.pathname.includes('cart') &&
		css`
			.ant-steps {
				margin-left: 10px;
			}
			.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon {
				margin-left: -10px;
			}
		`}
		.ant-steps
			.ant-steps-item-container
			.ant-steps-item-content
			.ant-steps-item-title {
			${OpenSansBold(10, 600, '#ffffff')}
		}

		.ant-steps-dot .ant-steps-item-tail,
		.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
			left: -52px;
			top: 13px;
			width: ${props =>
				props.steps === 4 ? '91.2%' : props.steps === 5 ? '87%' : '80%'};
		}

		.ant-steps-item-content {
			display: none !important;
		}

		.ant-steps-dot .ant-steps-item-icon,
		.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
			margin-left: 0px;
		}
	}

	@media ${Device.md} {
		.ant-steps {
			overflow-x: hidden;
		}
	}
`;
export default StyledSteps;
