/*----- Core -----*/
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { resolve } from 'dns';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

/*----- Components -----*/
import ConsultConfirmationModal from '../../../components/commons/ConsultConfirmationModal';
import ContainerContactForm from './ContainerContactForm';

/*----- Assets -----*/
import profile_pic from '../../../assets/images/profile-pic.jpeg';

/*----- Commons -----*/
import Montserrat from '../../../commons/mixins/fonts/Montserrat';
import Color from '../../../commons/Color';

/* Actions & Selectors */
import {
	typeConsultSelector,
	nameConsultSelector,
	alertVisibleSelector
} from '../../app/AppSelectors';
import { toggleModal, toggleAlert } from '../../app/AppActions';

/* Utils */
import { sendEmailSimple } from '../../../utils/ManagerEmail';

/*----- Styles-----*/
const StyledContactForm = styled.article`
	max-width: 400px;
	width: 100%;
	background: white;
	border-radius: 8px;
	padding: 16px;
	.handler-button {
		max-width: 100%;
		width: 100%;
	}
	.contact-form {
		.label {
			color: rgb(0, 0, 0);
			font-family: 'Montserrat', sans-serif;
			font-size: 12px;
		}
		.input {
			::placeholder {
				color: #cfcfcf;
				font-family: 'Montserrat', sans-serif;
			}
		}
		.button {
			background: #ff0000;
			width: 100%;
			border: none;
			font-family: 'Montserrat', sans-serif;
			font-weight: 600;
		}
		.btn-primary:active {
			background: #ff5050 !important;
			border: none !important;
		}
	}
	.alert {
		color: #721c24;
		background-color: #f8d7da;
		width: 100%;
		border-radius: 5px;
		padding: 10px;
		text-align: center;
		font-family: 'Montserrat', sans-serif;
		font-weight: 600;
		margin-top: 5px;
	}
	.person-pic {
		display: flex;
		border-bottom: 1px solid ${Color.main};
		padding: 32px 0;
		margin-bottom: 32px;
		.person-pic__text-container {
			padding-left: 16px;
			max-width: 240px;
		}
		.person-pic__greeting {
			${Montserrat(20, 600, 'grey')};
			margin: 0;
		}
		.person-pic__greeting-detail {
			${Montserrat(12, 400, 'grey')};
			margin: 0;
		}
		.person-pic__image {
			width: 60px;
			border-radius: 50%;
		}
	}
`;
const ContactForm = props => {
	const { t } = useTranslation();
	const [alert, setAlert] = useState('');

	const sendEmailConsult = async formValues => {
		let subject = 'Consulta desde EBGroup';
		let header = `<p>Consulta de contacto</p>`;
		let body = `<p>
				Datos del emisor: <br>
				- Email: ${formValues.email} <br>
				- Nombre: ${formValues.name} <br>
				${formValues.phone !== '' ? `- Telefono: ${formValues.phone} <br> ` : ''}
				- Asunto: ${subject} <br>
				- Mensaje: <br>
					${formValues.message}
			</p>`;
		const response = await sendEmailSimple(subject, header, body, formValues.email);
		if (response.ok) {
			props.toggleModal();
			props.toggleAlert(true);
			setTimeout(function () {
				props.toggleAlert(false);
			}, 2000);
		} else {
			setAlert('Fallo al enviar el formulario');
			setTimeout(function () {
				resolve(setAlert(''));
			}, 2000);
		}
	};

	return (
		<StyledContactForm className="contact-form">
			<div className="person-pic">
				<div className="person-pic__image-container">
					<img
						src={profile_pic}
						alt="Foto de perfil"
						className="person-pic__image"
					/>
				</div>
				<div className="person-pic__text-container">
					<p className="person-pic__greeting">{t('contact.form.title')}</p>
					<p className="person-pic__greeting-detail">{t('contact.form.subtitle')}</p>
				</div>
			</div>
			<ConsultConfirmationModal />
			<ContainerContactForm sendEmail={sendEmailConsult} requestError={alert} />
			{/* <Form className="contact-form" id="contact-form" ref={form}>
				<Form.Group controlId="formName">
					<Form.Label className="label">Nombre / Empresa</Form.Label>
					<Form.Control className="input" active={false} type="text" placeholder="Franco Gomez" onChange={(e) => { handleName(e) }} required />
				</Form.Group>
				<Form.Group controlId="formEmail">
					<Form.Label className="label">Email</Form.Label>
					<Form.Control className="input" type="text" placeholder="fgomez@gmail.com" onChange={(e) => { handleEmail(e) }} required />
				</Form.Group>
				<Form.Group controlId="forPhone">
					<Form.Label className="label">Telefono</Form.Label>
					<Form.Control className="input" type="number" placeholder="+54 9 11 645 22139" onChange={(e) => { handlePhone(e) }} />
				</Form.Group>
				<Form.Group controlId="formSubject">
					<Form.Label className="label">Asunto</Form.Label>
					<Form.Control className="input" type="text" placeholder="Asunto" onChange={(e) => { handleSubject(e) }} required />
				</Form.Group>
				<Form.Group controlId="formMessage">
					<Form.Label className="label">Mensaje</Form.Label>
					<Form.Control className="input" as="textarea" rows="3" placeholder="Escriba aquí su mensaje" onChange={(e) => { handleMessage(e) }} required />
				</Form.Group>
				<Button variant="primary" className="button" type="submit" onClick={(e) => sendEmail(e)}>
					Enviar
				</Button>
				{alert !== "" ?
					< div className="alert">
						{alert}
					</div>
					:
					null
				}
			</Form> */}
		</StyledContactForm>
	);
};
function mapStateToProps(state) {
	return {
		alertVisible: alertVisibleSelector(state),
		typeConsult: typeConsultSelector(state),
		nameConsult: nameConsultSelector(state)
	};
}

function mapDispatchToProps(dispatch) {
	return {
		toggleModal: (type, name) => toggleModal(dispatch, type, name),
		toggleAlert: state => toggleAlert(dispatch, state)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
