import styled, { css } from 'styled-components';
import Text from '../../../../components/text/Text';
import CenterContainer from '../../../../components/containers/CenterContainer';
import Color from '../../../../commons/Color';
import Device from '../../../../commons/Device';
import { hoverCart } from '../styles';

export const StyledDiv = styled.div`
	${CenterContainer}
	position:absolute;
	right: 4rem;
	@media ${Device.xs} {
		top: 0;
		right: 1.5rem;
	}
	&:hover {
		${hoverCart}
	}
	${props => props.showDropdown && hoverCart}
`;

export const StyledLink = styled.a`
	font-size: 12px;
	font-weight: bold;
	${CenterContainer}
`;

export const StyledText = styled(Text)`
	color: ${Color.main};
	font-weight: bolder;
	font-size: 15px;
	margin: -0.5rem;
`;

export const StyledImg = styled.div`
	margin: 0.75rem;
	svg {
		color: ${Color.main};
		width: 30px;
		height: 30px;
		fill: #606060;
		transition: all 0.75s;
		@media ${Device.xs} {
			width: 20px;
		}
	}
`;

export const StyledDropdownContainer = styled.div`
	position: absolute;
	top: 122px;
	right: 0px;
	max-width: 400px;
	border-radius: 0 0 8px 8px;
	box-shadow: -5px 25px 20px #0000001f;
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding-top: 1rem;
	opacity: 0;
	visibility: hidden;
	z-index: 2;
	-webkit-transform: translateY(-35%) scale(0.9);
	-ms-transform: translateY(-35%) scale(0.9);
	transform: translateY(-35%) scale(0.9);
	-webkit-transition: all 0.15s cubic-bezier(0.17, 0.67, 0.81, 0.31);
	-o-transition: all 0.15s cubic-bezier(0.17, 0.67, 0.81, 0.31);
	transition: all 0.15s cubic-bezier(0.17, 0.67, 0.81, 0.31);
	@media ${Device.xs} {
		top: 55px;
		right: 0px;
		max-width: 100vw;
	}
	${props =>
		props.showDropdown &&
		css`
			-webkit-transform: translateY(0%) scale(1);
			-ms-transform: translateY(0%) scale(1);
			transform: translateY(0%) scale(1);
			opacity: 1;
			visibility: visible;
		`}
`;

export const StyledDropdownHeader = styled.h1`
	font-family: 'Montserrat', sans-serif;
	font-size: 24px;
	font-weight: bold;
	color: ${Color.main};
`;

export const StyledDropdownSubtitle = styled.h3`
	font-family: 'Montserrat', sans-serif;
	font-size: 18px;
	font-weight: 400;
	color: ${Color.text};
`;

export const StyledDropdownSection = styled.section`
	width: 100%;
	padding: 0 1rem;
`;

export const StyledDropdownEmpty = styled.h4`
	font-family: 'OpenSans';
	font-size: 14px;
	color: ${Color.text};
	padding: 1rem 3rem 2rem 3rem;
	text-align: center;
`;

export const StyledDropdownEmptyLogo = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 1rem;
	& > svg:first-child {
		path {
			fill: lightgray;
		}
	}
`;
