import React from 'react';
import { StyledDiv, StyledLoginButton, StyledImg, StyledText } from './styles';
import Login from '../../modules/login';
import { isLogged, getCurrentUser } from '../../authentication/currentUser';
import { useTranslation } from 'react-i18next';
import DropdownProfile from './DropdownProfile';
import UserIcon from '../../assets/icons/header-user.svg';

const IconWithText = ({ content }) => (
	<StyledDiv>
		<StyledImg src={UserIcon} alt="user-icon" />
		<StyledText content={content} />
	</StyledDiv>
);

export const Profile = () => {
	const { t } = useTranslation();

	return (
		<StyledDiv>
			<Login />
			{isLogged() && (
				<DropdownProfile
					content={<IconWithText content={getCurrentUser().name} />}
				/>
			)}
			{!isLogged() && (
				<StyledLoginButton>
					<IconWithText content={t('authentication.login.self')} />
				</StyledLoginButton>
			)}
		</StyledDiv>
	);
};

export default Profile;
