import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledTitle, DescriptionContainer } from '../DetailDescription/styles';
import RegimeIcon from '../../../../assets/icons/checkout/reception-bell.svg';
import ReservationPanalItemIcon from '../../../../components/panel/ReservationPanalItemIcon';
import { getDetailCheckout } from '../../../hotels/views/checkout/section/detailsItemsHotel';
import { getHotelInformation } from '../../../hotels/views/checkout/section/detailsItemsHotel';
import { StyledTable, StyledTdPrice } from '../hotels/HotelRooms/styles';

const PackageRoom = ({ data }) => {
	const { t } = useTranslation();
	let dataInformation = getHotelInformation(data, data.item_id, true);

	const hotelInformation = {
		nights: dataInformation.nights,
		passengers: dataInformation.passengers,
		regime: dataInformation.regime,
		roomType: dataInformation.roomType
	};

	const columns = [
		{
			title: t('hotels.roomType'),
			dataIndex: 'roomType',
			width: '50%',
			render: () => {
				const getIcons = () => {
					let icons = getDetailCheckout(hotelInformation, [
						'roomType',
						'nights',
						'passengers'
					]);
					return icons.map((icon, idx) => (
						<ReservationPanalItemIcon
							key={idx}
							icon={icon.icon}
							description={icon.text}
						/>
					));
				};

				return {
					children: <>{getIcons()}</>,
					props: {
						rowSpan: 4
					}
				};
			}
		},
		{
			title: t('hotels.regime'),
			dataIndex: 'regime',
			width: '50%',
			render: () => {
				return (
					<StyledTdPrice>
						<ReservationPanalItemIcon
							icon={RegimeIcon}
							description={hotelInformation.regime}
						/>
					</StyledTdPrice>
				);
			}
		}
	];

	return (
		<DescriptionContainer>
			<StyledTitle content={t('packages.roomDetailTitle')} size={3} />
			<StyledTable
				columns={columns}
				pagination={false}
				dataSource={[{ ...hotelInformation }]}
				bordered
			/>
		</DescriptionContainer>
	);
};

export default PackageRoom;
