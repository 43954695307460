import React from 'react';
import ErrorRegisterConfirmImage from '../../../assets/images/ErrorRegisterConfirmImage.svg';
import ErrorRegisterConfirmImageMobile from '../../../assets/images/ErrorRegisterConfirmImageMobile.svg';
import { useTranslation } from 'react-i18next';
import GenericResponse from './GenericResponse';
import { isMobile } from '../../../utilities/utils';

const ErrorResponse = () => {
	const { t } = useTranslation();
	return (
		<GenericResponse
			image={
				isMobile() ? ErrorRegisterConfirmImageMobile : ErrorRegisterConfirmImage
			}
			title={t('authentication.register.feedback.confirm.error.title')}
			firstContent={t('authentication.register.feedback.confirm.error.content.1')}
			secondContent={t('authentication.register.feedback.confirm.error.content.2')}
		/>
	);
};

export default ErrorResponse;
