import React from 'react';
import { ItemCheckoutContainer } from '../../../../components/containers/CheckoutContainer';
import PassengerForm from '../PassengerForm';
import Text from '../../../../components/text/Text';
import { useTranslation } from 'react-i18next';

const CheckoutPassengers = ({
	form,
	passengersRequired,
	dataCheckout,
	countPassengers,
	size = 21
}) => {
	const { t } = useTranslation();

	return (
		<ItemCheckoutContainer size={size}>
			<Text content={t('checkout.passengers.title')} />
			<PassengerForm
				form={form}
				countPassengers={countPassengers}
				dataCheckout={dataCheckout}
				passengersRequired={passengersRequired}
			/>
		</ItemCheckoutContainer>
	);
};

export default CheckoutPassengers;
