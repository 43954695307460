import React, { useEffect } from 'react';
import { connect } from 'react-redux';

/*----- Components -----*/
import SuccessResponse from './section/SuccessResponse';
import ReservationContent from '../../../commons/reservation/ReservationContent';

/*----- Actions -----*/
import { reserveActivity } from '../../actions/reserveActions';

/*----- Selectors -----*/
import {
	reserveActivityIsLoading,
	reserveActivityError,
	reserveActivitySuccess
} from '../../selectors/reserveActivitySelector';

/*----- Utils -----*/
import { getCheckoutActivity } from '../../../../services/ActivityCheckoutService';
import { getId } from '../../../commons/checkout/CheckoutUtils';

const ReserveActivity = ({
	history,
	error,
	success,
	isLoading,
	reserveActivity
}) => {
	useEffect(() => {
		let id = getId(history);
		let checkoutData = getCheckoutActivity(id);

		if (!checkoutData) {
			history.push('/activities');
			return;
		};
		let params = {
			id: id,
			checkout: checkoutData
		};
		reserveActivity(params);
	}, [history, history.location.pathname, reserveActivity]);

	return (
		<ReservationContent
			error={error}
			success={success}
			isLoading={isLoading}
			SuccessResponse={SuccessResponse}
		/>
	);
};

const mapStateToProps = state => {
	return {
		isLoading: reserveActivityIsLoading(state),
		error: reserveActivityError(state),
		success: reserveActivitySuccess(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		reserveActivity: payload => reserveActivity(dispatch, payload)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReserveActivity);
