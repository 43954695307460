import React from 'react';
import ServiceCard from './ServiceCard';
import { useTranslation } from 'react-i18next';
import { StyledServicesCarousel, StyledTitle, StyledCarousel } from './styles';
import { connect } from 'react-redux';
import { toggleModal } from '../../modules/app/AppActions';

const ServicesCarousel = ({ className, title, data, type, toggleModal }) => {
	const { t } = useTranslation();

	const renderData = () => {
		return data.map((item, i) => (
			<ServiceCard
				key={i}
				image={item.image}
				stars={item.star || 0}
				title={t(item.name)}
				services={item.services || []}
				onClick={() => toggleModal({ type }, t(item.name), t(item.description))}
			/>
		));
	};

	return (
		<StyledServicesCarousel>
			<StyledTitle content={title} />
			<StyledCarousel
				className={className}
				dots={false}
				centerMode={true}
				slidesToShow={1}
				infinite={false}
				centerPadding="30px"
			>
				{renderData()}
			</StyledCarousel>
		</StyledServicesCarousel>
	);
};

function mapDispatchToProps(dispatch) {
	return {
		toggleModal: (type, name, description) =>
			toggleModal(dispatch, type, name, description)
	};
}

export default connect(null, mapDispatchToProps)(ServicesCarousel);
