/*----- Core -----*/
import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

/*----- Components -----*/
import StepsContainer from '../../../../../components/steps/StepsContainer';
import {
	ItemCheckoutContainer,
	CheckoutContainer
} from '../../../../../components/containers/CheckoutContainer';
import CardCheckoutData from '../../../../commons/checkout/CardCheckoutData';
import CheckoutInfo from '../../../../commons/checkout/CheckoutInfo';
import { getId } from '../../../../commons/checkout/CheckoutUtils';

/*----- Utils -----*/
import {
	packagesSteps,
	packagesWithHotelSteps
} from '../../../../../utilities/utils';

/*----- Actions -----*/
import {
	getCheckoutPackages,
	getPackageHotel
} from '../../../../../services/PackagesCheckoutService';
import {
	getDetailCheckoutWithHotel,
	getDetailCheckoutWithoutHotel,
	getPackagesInformation
} from './detailsItemsPackages';

import { showPackageData } from '../../../selectors/showPackageSelector';
import DefaultImage from '../../../../../assets/images/DefaultCardImage.svg';
import PackagesForm from './PackagesForm';
import { getSearchQuery } from '../../../../../services/SearchService';
import { getHotelInformation } from '../../../../hotels/views/checkout/section/detailsItemsHotel';

const SuccessResponse = ({ history, showPackage }) => {
	const { t } = useTranslation();
	const searchQuery = getSearchQuery();
	const withHotel = searchQuery.with_hotel;
	const id = getId(history);
	const dataCheckout = getCheckoutPackages(id);
	const hotelRoom = getPackageHotel();
	let packagesInformation = getPackagesInformation(showPackage, dataCheckout);
	const itemsList = withHotel
		? getDetailCheckoutWithHotel(
				getHotelInformation(hotelRoom, hotelRoom.item_id, true)
		  )
		: getDetailCheckoutWithoutHotel(packagesInformation);
	return (
		<CheckoutContainer>
			<StepsContainer
				active={2}
				rate_id={id}
				dataSteps={withHotel ? packagesWithHotelSteps(id) : packagesSteps(id)}
			/>
			<ItemCheckoutContainer>
				<CheckoutInfo
					CardCheckoutData={
						<CardCheckoutData
							name={packagesInformation.name}
							dateFrom={packagesInformation.date_in}
							dateTo={searchQuery.date_out}
							totalPrice={packagesInformation.amount + packagesInformation.iva}
							image={packagesInformation.image || DefaultImage}
							currency={packagesInformation.currency}
							items={itemsList}
							type={t(`packages.package${withHotel ? 'WithHotel' : 'WithoutHotel'}`)}
						/>
					}
				/>
			</ItemCheckoutContainer>
			<PackagesForm withHotel={withHotel} />
		</CheckoutContainer>
	);
};

const mapStateToProps = state => {
	return {
		showPackage: showPackageData(state)
	};
};

export default connect(mapStateToProps, null)(withRouter(SuccessResponse));
