import React from 'react';
import { StyledSubtitle, SubtitleContainer } from '../detailTitle/styles';
import DetailTitle from '../detailTitle';
import MapText from './MapText';

const HotelTitle = ({ showData }) => (
	<DetailTitle
		title={showData.name}
		subtitle={
			<SubtitleContainer>
				<StyledSubtitle
					content={`${showData.info.address}, ${showData.city.name}`}
					size={4}
				/>
				<MapText
					title={showData.name}
					location={{
						latitude: showData.info.geolocation.latitude,
						longitude: showData.info.geolocation.longitude
					}}
					address={`${showData.info.address}, ${showData.city.name}`}
				/>
			</SubtitleContainer>
		}
	/>
);

export default HotelTitle;
