import React from 'react';
import styled from 'styled-components';
import Color from '../../../../commons/Color';
import Device from '../../../../commons/Device';
import PropTypes from 'prop-types';
import Text from '../../../text/Text';

const StyedText = styled(Text)`
	padding-top: 4px;
	font-size: 13px;
	color: ${Color.main};
	&:hover {
		cursor: pointer;
	}

	@media ${Device.xs} {
		text-align: center;
		padding-top: 0px;
		padding-bottom: 13px;
	}
`;

const SeeDetail = ({ handlerClick, text }) => (
	<div onClick={handlerClick}>
		<StyedText content={text} bold />
	</div>
);

export default SeeDetail;

SeeDetail.propTypes = {
	text: PropTypes.string.isRequired,
	handlerClick: PropTypes.func.isRequired
};
