/*----- Core -----*/
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

/*----- Components-----*/
import Label from './Label';
import { InputContainer } from './styles';
import { TimePicker } from 'antd';

const format = 'HH:mm';

/*----- Component -----*/
const CustomTimePicker = (
	{ name, onChange, placeholder = name, withoutLabel, className, value },
	ref
) => (
	<InputContainer className={className}>
		{withoutLabel ? '' : <Label htmlFor={name} name={name} />}

		<TimePicker
			value={value}
			format={format}
			placeholder={placeholder}
			onChange={onChange}
			size="large"
			ref={ref}
		/>
	</InputContainer>
);
export default forwardRef(CustomTimePicker);

/*----- PropTypes -----*/
CustomTimePicker.propTypes = {
	onChange: PropTypes.func.isRequired
};
