import React from 'react';
import { isMobile } from '../../utilities/utils';

/*----- Components -----*/
import ResultContainer from '../../modules/commons/resultContainer';
import Pagination from '../pagination';
import ResponseContent from '../../components/responseContent';
import ErrorResponse from '../../modules/commons/ErrorResponse';
import NoResults from '../../modules/commons/NoResults';
import LoadingList from '../../modules/commons/LoadingList';
import FilterFormMobile from '../form/FilterFormMobile';
import MoreInformationCard from '../card/MoreInformationCard';

const SearchResultContainer = ({
	resultData,
	pagination,
	query,
	getData,
	Filter,
	moreRooms = false,
	service,
	ResultList,
	isLoading,
	success,
	resultTitle = ''
}) => {
	const FakeList = () => <LoadingList loading={isLoading} />;

	return (
		<>
			<ResultContainer
				filter={
					<>
						<Filter />
						<MoreInformationCard margin="24px 0px 0px" />
					</>
				}
				content={
					<ResponseContent
						isLoading={isLoading}
						Loading={FakeList}
						successResponse={success}
						Success={resultData.length ? ResultList : NoResults}
						Error={ErrorResponse}
					/>
				}
				hideTitleContent={!resultData.length}
				resultTitle={resultTitle}
			/>
			{pagination && resultData.length && (
				<Pagination
					pagination={pagination}
					query={query}
					fetchData={getData}
					service={service}
					moreRooms={moreRooms}
				/>
			)}
			{isMobile() && <FilterFormMobile Filter={Filter} />}
		</>
	);
};

export default SearchResultContainer;
