import { combineReducers } from 'redux';
import Moment from 'moment';
import {
	SET_LOCATION,
	SET_ENTRY_DATE,
	SET_EXIT_DATE,
	ADD_ROOM,
	REMOVE_ROOM,
	ADD_ADULT,
	REMOVE_ADULT,
	ADD_YOUNGER,
	REMOVE_YOUNGER,
	CHANGE_YOUNGER_AGE,
	CLEAR_NOTIFICATION,
	SET_NOTIFICATION
} from './HomeConstants';

/*----- Reducer -----*/
const minAdults = 1;
const maxAdults = 6;

const roomModel = { adults: minAdults, youngers: [] };
const initialState = {
	location: 'Location',
	entryDate: Moment().format('YYYY-MM-DD'),
	exitDate: Moment().format('YYYY-MM-DD'),
	rooms: [roomModel],
	notification: null
};
/*----- Reducer methods -----*/
const removeRoom = (rooms, payload) => {
	return rooms.length === 1
		? rooms
		: rooms.filter((el, i) => {
				return i !== payload;
		  });
};
const adultCounter = (rooms, roomId, type) => {
	const typeOperation = adults => {
		return type === 'add'
			? (type = adults === maxAdults ? adults : adults + 1)
			: (type = adults === minAdults ? adults : adults - 1);
	};
	return rooms.map((room, i) => {
		let adults = room.adults;
		return i === roomId
			? {
					...room,
					adults: typeOperation(adults)
			  }
			: room;
	});
};
const youngerCounter = (rooms, roomId, type) => {
	const operation = youngers => {
		return type === 'add'
			? [...youngers, 1]
			: youngers.filter((_, i) => {
					return i !== youngers.length - 1;
			  });
	};
	return rooms.map((room, i) => {
		let youngers = room.youngers;
		return i === roomId
			? {
					...room,
					youngers: operation(youngers)
			  }
			: room;
	});
};
const changeYoungerAge = (rooms, payload) => {
	const { roomId, youngerId, value } = payload;
	return rooms.map((room, i) => {
		if (i === roomId) {
			return {
				...room,
				youngers: room.youngers.map((younger, i) => {
					if (i !== youngerId) {
						return younger;
					}
					return value;
				})
			};
		}
		return room;
	});
};
export function filter(state = initialState, action) {
	switch (action.type) {
		case SET_LOCATION:
			return {
				...state,
				location: action.payload
			};
		case SET_ENTRY_DATE:
			return {
				...state,
				entryDate: action.payload
			};
		case SET_EXIT_DATE:
			return {
				...state,
				exitDate: action.payload
			};
		case ADD_ROOM:
			return {
				...state,
				rooms: [...state.rooms, roomModel]
			};
		case REMOVE_ROOM:
			return {
				...state,
				rooms: removeRoom(state.rooms, action.payload)
			};
		case ADD_ADULT:
			return {
				...state,
				rooms: adultCounter(state.rooms, action.payload, 'add')
			};
		case REMOVE_ADULT:
			return {
				...state,
				rooms: adultCounter(state.rooms, action.payload, 'remove')
			};
		case ADD_YOUNGER:
			return {
				...state,
				rooms: youngerCounter(state.rooms, action.payload, 'add')
			};
		case REMOVE_YOUNGER:
			return {
				...state,
				rooms: youngerCounter(state.rooms, action.payload, 'remove')
			};
		case CHANGE_YOUNGER_AGE:
			return {
				...state,
				rooms: changeYoungerAge(state.rooms, action.payload)
			};
		case SET_NOTIFICATION:
			return {
				...state,
				notification: action.payload
			};
		case CLEAR_NOTIFICATION:
			return {
				...state,
				notification: null
			};
		default:
			return state;
	}
}

export default combineReducers({
	filter
});
