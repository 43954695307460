import styled from 'styled-components';
import Text from '../../text/Text';
import { Icon } from 'antd';
import Device from '../../../commons/Device';

export const StyledDiv = styled.div`
	border: 2px solid #b01a24;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 12px 16px;
	margin: ${props => props.margin};

	@media ${Device.xs} {
		width: 90%;
	}
`;

export const TitleContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	@media ${Device.xs} {
		justify-content: space-between;
	}
`;

export const TitleLeft = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;
export const TitleRight = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

export const StyleTitle = styled(Text)`
	font-size: 20px;
	margin: 0px;
	font-weight: bold;
	@media ${Device.xs} {
		font-size: 16px;
	}
`;

export const StyledInformation = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 18px;
`;

export const Content = styled.div`
	display: ${props => (props.visible ? 'block' : 'none')};
`;

export const TitleInfo = styled(Text)`
	font-size: 14px;
	font-weight: bold;
	margin: 0px;

	@media ${Device.xs} {
		font-size: 12px;
	}
`;

export const ContentInfo = styled(Text)`
	font-size: 14px;

	@media ${Device.xs} {
		font-size: 12px;
	}
`;

export const StyledIcon = styled.img`
	width: 24px;
	height: 24px;
	margin-right: 12px;

	@media ${Device.xs} {
		width: 21px;
		height: 21px;
	}
`;

export const StyledIconArrow = styled(Icon)`
	width: 7px;
	height: 4px;
	align-self: baseline;
	margin-right: 12px;
`;
