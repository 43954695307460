import styled from 'styled-components';
import Text from '../../text/Text';
import { Modal } from 'antd';

export const StyledModal = styled(Modal)`
	&& {
		max-width: calc(100vw);
		min-height: 100%;
		margin: 0;
		padding: 0;
		height: auto;
		top: 0;
	}
	height: 100vh;
	position: relative;
	display: flex;
	.ant-modal-content {
		width: 100% !important;
		border-radius: unset;
		padding: 12px;
	}
	.ant-modal-header {
		padding: 8px 24px;
	}
	.ant-modal-body {
		padding: 10px 24px;
	}
`;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px;
`;

export const Content = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: ${props => (props.justifyStart ? 'flex-start' : 'center')};
	flex-direction: column;
`;

export const StyledTitle = styled(Text)`
	font-size: 16px;
	font-weight: bold;
	color: black;
`;
