import styled from 'styled-components';
import GenericModal from '../../../components/modal/GenericModal';

export const StyledMain = styled.main`
	height: auto;
	min-height: 90vh;
	display: grid;
	justify-content: center;
	align-items: center;
	margin-bottom: 32px;
`;

export const StyledModal = styled(GenericModal)`
	.ant-modal-header {
		border: none;
		padding: 0px;
	}
	position: relative;
	justify-content: center;
	align-items: center;
`;

export const Styles = styled.div`
	position: relative;
`;
