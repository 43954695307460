import React from 'react';
import ErrorImage from '../../../assets/images/ErrorRegisterConfirmImage.svg';
import ResponseWithImage from '../ResponseWithImage';
import { useTranslation } from 'react-i18next';

const ErrorResponse = () => {
	const { t } = useTranslation();

	return (
		<ResponseWithImage
			image={ErrorImage}
			title={t('general.errorResponse.title')}
			subtitle={t('general.errorResponse.subtitle')}
		/>
	);
};

export default ErrorResponse;
