import React from 'react';

import Reservation from '../../../../commons/reservation';
import { cartSteps } from '../../../../../utilities/utils';

import EndReservation from '../../../../../assets/images/tranfers/transfer-end-reservation.svg';
import { reserveCartData } from '../../../selectors/reserveCartSelector';
import { connect } from 'react-redux';

const SuccessResponse = ({ data }) => (
	<Reservation
		code={data[0].code}
		dataSteps={cartSteps()}
		imageReservation={EndReservation}
		active={3}
	/>
);
const mapStateToProps = state => {
	return {
		data: reserveCartData(state)
	};
};

export default connect(mapStateToProps, null)(SuccessResponse);
