import styled from 'styled-components';
import Device from '../../../commons/Device';
import { Row, Col } from 'antd';

export const StyledRow = styled(Row)`
	padding: 30px 50px;
	background-color: white;

	@media ${Device.xs} {
		padding: 10px 0px;
	}
`;

export const StyledDetailCol = styled(Col)`
	&.ant-col {
		text-align: left;
		padding: 10px 50px;

		@media ${Device.xs} {
			padding: 10px 25px;
		}
	}
`;

export const StyledReservationCol = styled(Col)`
	z-index: 3;

	@media ${Device.md} {
		&.ant-col {
			position: sticky;
			top: 100px;
			height: 500px;
		}
	}

	@media ${Device.xs} {
		display: flex !important;
		justify-content: center;
		padding: 10px !important;
	}
`;
