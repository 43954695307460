/*----- Core -----*/
import React from 'react';
import { StyledItem, StyledText } from './styles';

const ReservationPanelItem = ({ label, children }) => (
	<StyledItem>
		<StyledText content={label} />
		{children}
	</StyledItem>
);

export default ReservationPanelItem;
