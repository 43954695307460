/*----- Core -----*/
import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

/*----- Assets -----*/
import cataratas_argentina from '../../../../assets/images/activities/cataratas_argentinas.jpg';

/*----- Ant design -----*/
import { Row, Col } from 'antd';

/*----- Components -----*/
import ConsultForm from '../../../../components/form/ConsultForm';
import SearchFormActivity from '../searchForm';
import HeroWithImage from '../../../../components/hero/HeroWithImage';
import ActivityCard from '../../../../components/card/ActivityCard';
import SearchFormCard from '../../../../components/card/SearchFormCard';

/*----- Styles -----*/
import Styles from './styles';

/*----- Actions -----*/
import { toggleModal } from '../../../app/AppActions';

/*----- Data -----*/
import { activiesData } from './data';

function Activities(props) {
	const { t } = useTranslation();
	const { toggleModal } = props;

	const renderActivities = () => {
		return activiesData.map((activity, i) => (
			<Col xs={24} sm={12} lg={6} className="card-container" key={i}>
				<ActivityCard
					title={t(activity.name)}
					imageUrl={activity.image}
					destination="/home"
					onClick={() =>
						toggleModal('Actividad', t(activity.name), t(activity.description))
					}
				/>
			</Col>
		));
	};

	return (
		<Styles>
			<ConsultForm></ConsultForm>
			<HeroWithImage
				title={t('activities.title')}
				subtitle={t('activities.subtitle')}
				imageUrl={cataratas_argentina}
			/>

			<SearchFormCard
				content={<SearchFormActivity header={t('activities.titleForm')} />}
			/>

			<Row type="flex" justify="center" className="card__list-container">
				{renderActivities()}
			</Row>
		</Styles>
	);
}

function mapStateToProps(state) {
	return {};
}

function mapDispatchToProps(dispatch) {
	return {
		toggleModal: (type, name, description) =>
			toggleModal(dispatch, type, name, description)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Activities);
