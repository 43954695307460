
import { TOGGLE_MODAL, TOGGLE_ALERT ,TOGGLE_FOOTER} from './AppConstants'

const initialState = {
  modalVisible: false,
  typeConsult: "",
  nameConsult: "",
  descriptionConsult: "",
  alertVisible: false,
  footerVisible: true,
}

export function appReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MODAL:
      return {
        ...state,
        typeConsult: state.modalVisible ? state.typeConsult : action.typeConsult,
        nameConsult: state.modalVisible ? state.nameConsult : action.nameConsult,
        descriptionConsult: state.modalVisible ? state.descriptionConsult : action.descriptionConsult,
        modalVisible: !state.modalVisible
      }
    case TOGGLE_ALERT:
      return {
        ...state,
        typeConsult:  state.alertVisible ? "" : state.typeConsult,
        nameConsult: state.alertVisible ? "" : state.nameConsult,
        descriptionConsult: state.alertVisible ? "" : state.descriptionConsult,
        alertVisible: action.state
      }
    case TOGGLE_FOOTER:
      return{
        ...state,
        footerVisible:!state.footerVisible
      }
    default:
      return state
  }
}

export default appReducer