import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

/*----- Components -----*/
import StepsContainer from '../../../../../components/steps/StepsContainer';
import CheckoutTransferData from './CheckoutTransferData';
import {
	ItemCheckoutContainer,
	CheckoutContainer
} from '../../../../../components/containers/CheckoutContainer';
import CheckoutTitle from '../../../../../components/titles/CheckoutTitle';
import TransferForm from './TransferForm';

/*----- Selectors -----*/
import { transferData } from '../../../selectors/showTransferSelector';

/*----- Utilities -----*/
import { transferSteps, capitalize } from '../../../../../utilities/utils';
import { getId } from '../../../../commons/checkout/CheckoutUtils';
import { getSearchQuery } from '../../../../../services/SearchService';

const SuccessResponse = ({ transfer, history }) => {
	const { t } = useTranslation();

	let id = getId(history);
	let details = transfer.rate.amount_details.occupancy;
	const searchQuery = getSearchQuery();
	const dates = () => {
		const kind = transfer.info.kind;
		if (kind === 'mix')
			return {
				date_in: searchQuery.date_in,
				date_out: searchQuery.date_out
			};
		else return { [`date_${kind}`]: searchQuery[`date_${kind}`] };
	};
	return (
		<CheckoutContainer>
			<StepsContainer active={1} rate_id={id} dataSteps={transferSteps(id)} />
			<ItemCheckoutContainer>
				<CheckoutTitle text={t('checkout.title')} />
				<CheckoutTransferData
					type={`${transfer.info.kind.toUpperCase()} | ${capitalize(
						transfer.info.category.name.toLowerCase()
					)}`}
					title={transfer.name}
					totalPrice={details.passenger.sale + details.passenger.iva}
					currency={transfer.rate.currency}
					{...dates()}
				/>
			</ItemCheckoutContainer>
			<TransferForm />
		</CheckoutContainer>
	);
};

const mapStateToProps = state => {
	return {
		transfer: transferData(state)
	};
};

export default connect(mapStateToProps, null)(withRouter(SuccessResponse));
