/*----- Imports -----*/
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/*----- Commons-----*/
import Color from '../../commons/Color';

/*----- Styles-----*/
const StyledButtonWithIcon = styled.button`
	background-color: ${Color.main};
	border: none;
	height: 25px;
	width: 25px;
	border-radius: 50%;
	cursor: pointer;
	transition: all 0.3s;
	margin: 12px;
	display: flex;
	align-items: center;
	img {
		width: 12px;
		height: 12px;
		padding-left: 1.3px;
		align-self: center;
	}
	&:hover {
		transform: scale(1.04);
		box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.2);
	}
	&:focus {
		outline: none;
	}
`;

/*----- Component -----*/
const ButtonWithIcon = ({ icon, onClick, className }) => {
	return (
		<StyledButtonWithIcon className={className} onClick={onClick}>
			<img src={icon} alt="" />
		</StyledButtonWithIcon>
	);
};
export default ButtonWithIcon;

/*----- PropTypes -----*/
ButtonWithIcon.propTypes = {
	icon: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired
};
