import React from 'react';
import { Pagination } from 'antd';
import { StyledPagination } from './styles';
import PropTypes from 'prop-types';
import { genericOnChange } from './SearchUtils';
import { withRouter } from 'react-router';

const CustomPagination = ({
	pagination,
	history,
	query,
	fetchData,
	service,
	moreRooms = false
}) => (
	<StyledPagination>
		<Pagination
			size="small"
			total={pagination.total_results}
			defaultPageSize={pagination.per_page}
			defaultCurrent={pagination.page}
			onChange={(page, pageSize) =>
				genericOnChange(
					page,
					pageSize,
					history,
					query,
					fetchData,
					service,
					moreRooms
				)
			}
		/>
	</StyledPagination>
);

export default withRouter(CustomPagination);

CustomPagination.propTypes = {
	pagination: PropTypes.shape({
		total_results: PropTypes.number.isRequired,
		per_page: PropTypes.number.isRequired,
		page: PropTypes.number.isRequired
	}),
	query: PropTypes.object.isRequired,
	fetchData: PropTypes.func.isRequired,
	service: PropTypes.string.isRequired
};
