import styled, { css } from 'styled-components';
import Device from '../../../../../commons/Device';
import DefaultButton from '../../../../../components/button/DefaultButton';

export const SearchBarContainer = styled.div`
	margin-bottom: 1rem;
	display: flex;
	width: 100%;
	align-items: center;
	flex-direction: column;
	@media ${Device.sm} {
		flex-wrap: wrap;
		flex-direction: row;
		align-items: flex-end;
		justify-content: space-evenly;
	}
	& > div {
		margin: 0;
		min-width: 75%;
		@media ${Device.sm} {
			margin: 0.5rem;
			min-width: min-content;
		}
	}
`;

export const StyledTableContainer = styled.div`
	width: 100%;
`;

export const StyledInlineContainer = styled.div`
	display: inline-flex;
`;

export const StyledTableWrapper = styled.div`
	overflow: auto;
	padding-top: 3rem;
`;

export const StyledSearchSelect = styled.div`
	margin: 8px 0px 0px;
	justify-content: flex-end;
	padding: 0px;
	.styled-status-select {
		margin: inherit;
		.ant-select-selection {
			border-radius: 10px;
		}
		label {
			font-weight: 400;
			color: #030f33;
			margin: 0;
			margin-bottom: 10px;
			display: block;
			text-transform: capitalize;
			font-size: 16px;
		}
	}
`;

export const StyledCancelReservationContainer = styled.div`
	position: relative;
	width: 70px;
`;

export const StyledCancelReservationIconWrapper = styled.div`
	&:hover {
		cursor: pointer;
	}
	svg path {
		stroke: #a4a4a4;
		${props =>
			props.cancelable &&
			css`
				stroke: ${props.active ? '#CC0000' : '#030f33'};
			`}
	}
`;

export const StyledCancelReservationTooltip = styled.div`
	position: relative;
	display: inline-block;
`;

export const StyledCancelReservationTooltipTxt = styled.p`
	width: 180px;
	color: #000033;
	text-align: center;
	padding: 5px 0;
	position: absolute;
	z-index: 999;
	bottom: 100%;
	margin-left: -78px;
	background-color: #ffffff;
	border: 1px solid #000033;
	box-sizing: border-box;
	border-radius: 10px;

	&::after {
		content: ' ';
		position: absolute;
		top: 100%;
		left: 50%;
		margin-left: -5px;
		border-width: 5px;
		border-style: solid;
		border-color: black transparent transparent transparent;
	}
`;

export const StyledConfirmTooltip = styled.div`
	position: absolute;
	width: 360px;
	height: 210px;
	background: #030f33d9;
	border-radius: 10px;
	top: -90px;
	z-index: 999;
	right: 140%;
	&::after {
		content: ' ';
		position: absolute;
		top: 80px;
		left: 100%;
		border-width: 25px;
		transform: rotate(-90deg);
		border-style: solid;
		border-color: #030f33d9 transparent transparent transparent;
	}
`;
export const StyledConfirmTooltipInner = styled.div`
	position: relative;
`;

export const StyledConfirmTooltipTitle = styled.h2`
	font-size: 20px;
	padding: 54px 1rem 0 1rem;
	color: white;
	font-weight: bolder;
	text-align: center;
`;
export const StyledConfirmTooltipMessage = styled.p`
	color: white;
	font-size: 1rem;
	text-align: center;
	font-style: italic;
`;
export const StyledConfirmTooltipButton = styled(DefaultButton)`
	button {
		background: transparent;
		border: 1px solid white;
		align-self: center;
		padding: 0 3.5rem;
	}
`;

export const StyledConfirmTooltipClose = styled.span`
	position: absolute;
	right: 24px;
	top: 24px;
	width: 24px;
	height: 24px;
	opacity: 0.3;
	&:hover {
		opacity: 1;
		cursor: pointer;
	}
	&:before,
	&:after {
		position: absolute;
		left: 12px;
		content: ' ';
		height: 25px;
		width: 2px;
		background-color: #fff;
	}
	&:before {
		transform: rotate(45deg);
	}
	&:after {
		transform: rotate(-45deg);
	}
`;
