import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

/*----- Components -----*/
import { Form } from 'antd';
import FilterForm from '../../../../components/form/FilterForms';
import FilterFormHotelRegimes from '../../../../components/form/FilterForms/FilterFormHotelRegimes';

/*----- Actions -----*/
import { getHotels } from '../../actions/searchFormActions';

/*----- Selectors -----*/
import { hotelsQuery, hotelFilterReseted } from '../../selectors/hotelsSelector';

/*----- utilities -----*/
import { activitiesDestinations } from '../../../../utilities/constants';
import { parse } from '../../../../utilities/queryString';
import {
	buildFetchQuery,
	addFilterToQuery
} from '../../../../utilities/buildSearchQuery';
import { replaceSearch } from '../../../../components/pagination/SearchUtils';

const FilterHotel = ({
	history,
	form,
	getHotels,
	query,
	filterReseted,
	onSubmit
}) => {
	const [initialMaxPrice, setInitialMaxPrice] = useState(null);
	const [initialMinPrice, setInitialMinPrice] = useState(null);
	const [initialRegimes, setInitialRegimes] = useState(['all']);

	useEffect(() => {
		let params = parse(history.location.search, activitiesDestinations);
		if (!params) {
			history.push('/hotels');
			return;
		}

		setInitialMaxPrice(params.max_price);
		setInitialMinPrice(params.min_price);
		setInitialRegimes(params.regime_codes || ['all']);
	}, [history]);

	useEffect(() => {
		if (filterReseted) {
			form.resetFields();
		}
	}, [filterReseted, form]);

	const fetchData = () => {
		let newQuery = addFilterToQuery(query, form.getFieldsValue());
		getHotels(buildFetchQuery(newQuery, true));
		replaceSearch(history, 'hotels', newQuery);

		if (onSubmit) onSubmit();
	};

	return (
		<FilterForm
			form={form}
			fetchData={fetchData}
			disabled={filterReseted}
			defaultValues={{
				min_price: initialMinPrice,
				max_price: initialMaxPrice
			}}
		>
			<FilterFormHotelRegimes
				form={form}
				fetchData={fetchData}
				defaultValues={initialRegimes}
				disabled={filterReseted}
			/>
		</FilterForm>
	);
};

const mapStateToProps = state => {
	return {
		query: hotelsQuery(state),
		filterReseted: hotelFilterReseted(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getHotels: payload => getHotels(dispatch, payload)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Form.create({ name: 'hotel_filter' })(withRouter(FilterHotel)));
