import React from 'react';
import { StyledLogoutButton, StyledLink, StyledDropdown } from './styles';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';

const DropdownProfile = ({ content }) => {
	const { t } = useTranslation();

	const dropDrown = (
		<Menu>
			<Menu.Item key="0">
				<StyledLogoutButton>{t('authentication.logout')}</StyledLogoutButton>
			</Menu.Item>
		</Menu>
	);

	return (
		<StyledDropdown overlay={dropDrown} trigger={['click']}>
			<StyledLink onClick={e => e.preventDefault()}>{content}</StyledLink>
		</StyledDropdown>
	);
};

export default DropdownProfile;
