import {
	REGISTER,
	REGISTER_SUCCESS,
	REGISTER_FAILED,
	DELETE_REGISTER
} from '../actions/registerActions';

const initialState = {
	email: '',
	success: false,
	isLoading: false,
	registerResponse: false
};

export function register(state = initialState, action) {
	switch (action.type) {
		case REGISTER:
			return {
				...state,
				isLoading: true
			};
		case REGISTER_SUCCESS:
			return {
				...state,
				email: action.email,
				success: true,
				registerResponse: true,
				isLoading: false
			};
		case REGISTER_FAILED:
			return {
				...state,
				success: false,
				registerResponse: true,
				isLoading: false
			};
		case DELETE_REGISTER:
			return {
				...state,
				...initialState
			};

		default:
			return state;
	}
}

export default register;
