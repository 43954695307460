/*----- Core -----*/
import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

/*----- Components -----*/
import TransferCard from '../../../components/card/TransferCard';

/*----- Assets -----*/

/*----- Ant design -----*/
import { Row, Col } from 'antd';
import Title from '../../../components/text/Title';

/*----- Actions -----*/
import { toggleModal } from '../../app/AppActions';

/*----- Data -----*/
import { transferData } from '../../tranfers/views/index/data';

/*----- Styles -----*/
import Styles from '../HomeStyles';

function FeaturedTransfers({ toggleModal }) {
	const { t } = useTranslation();

	const renderTransfer = () => {
		return transferData.map((transfer, i) => (
			<Col xs={24} sm={12} lg={6} className="card-container" key={i}>
				<TransferCard
					title={t(transfer.name)}
					imageUrl={transfer.image}
					destination="/home"
					onClick={() =>
						toggleModal('Traslado', t(transfer.name), t(transfer.description))
					}
				/>
			</Col>
		));
	};
	return (
		<Styles>
			<Row className="featured-card__title">
				<Title content={t('transfers.title')} size={2} color={'secondary'} />
			</Row>
			<Row type="flex" className="card__list-container">
				{renderTransfer()}
			</Row>
		</Styles>
	);
}
function mapStateToProps(state) {
	return {};
}

function mapDispatchToProps(dispatch) {
	return {
		toggleModal: (type, name, description) =>
			toggleModal(dispatch, type, name, description)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedTransfers);
