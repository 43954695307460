/*----- Core -----*/
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

/*----- Components -----*/
import { Form } from 'antd';
import { getPackages, resetPackagesFilter } from '../../actions/searchFormActions';
import SearchFormDestinations from '../../../../components/form/SearchForms/SearchFormDestinations';
import SearchFormNights from '../../../../components/form/SearchForms/SearchFormNights';
import { buildPackagesQuery } from '../../../../utilities/buildSearchQuery';
import { StyledSearchForm, StyledOption } from './styles';

//import SearchFormWithHotel from '../../../../components/form/SearchForms/SearchFormWithHotel';
//import { addWithHotel, getWithHotel } from '../../../../services/SearchService';

const SearchForm = ({
	history,
	form,
	header,
	getPackages,
	onSubmit,
	resetPackagesFilter
}) => {
	//const [withHotel, setWithHotel] = useState(getWithHotel());

	const fetchData = fields => {
		resetPackagesFilter();
		getPackages(buildPackagesQuery(fields));
	};

	/* 	const handleSwitchChange = value => {
		setWithHotel(value, true);
		addWithHotel(value);
	}; */

	return (
		<StyledSearchForm
			options={<StyledOption>Sin Hotel</StyledOption>}
			form={form}
			header={header}
			destinations={<SearchFormDestinations form={form} md={6} />}
			nights={<SearchFormNights form={form} />}
			fetchData={fetchData}
			//moreRooms={withHotel}
			moreRooms={false}
			onSubmit={onSubmit}
			service="packages"
			className="search-form-package"
		/>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		getPackages: payload => getPackages(dispatch, payload),
		resetPackagesFilter: payload => resetPackagesFilter(dispatch, payload)
	};
};

export default withRouter(
	connect(
		null,
		mapDispatchToProps
	)(Form.create({ name: 'packages_filter' })(SearchForm))
);
