import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Text from '../../../../../components/text/Text';
import { useTranslation } from 'react-i18next';
import DatePicker from '../../../../../components/date-picker/DatePicker';
import { handlerBetweenDates } from '../../../../../components/date-picker/Handlers';

import { getSearchQuery } from '../../../../../services/SearchService';
import { addCheckoutActivity } from '../../../../../services/ActivityCheckoutService';
import moment from 'moment';
import { getId } from '../../../../commons/checkout/CheckoutUtils';
import { withRouter } from 'react-router';
import Device from '../../../../../commons/Device';
import { connect } from 'react-redux';
import { selectDateActivity } from '../../../selectors/detailsSelector';
import { getUserCurrency } from '../../../../../utilities/utils'

const StyledDiv = styled.div`
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 20px #00000029;
	border-radius: 10px;
	padding: 47px 57px;
	margin: 32px 0px;

	@media ${Device.xs} {
		padding: 32px 24px;
	}
`;

const StyledTitle = styled(Text)`
	font-size: 20px;

	@media ${Device.xs} {
		font-size: 16px;
	}
`;

const StyledText = styled(Text)`
	font-size: 12px;
	color: #86858a;
`;

const StyledDatePicker = styled(DatePicker)`
	width: 30%;
	margin: 0px;
	padding: 0px;

	.ant-calendar-picker-input {
		border-radius: 10px;
	}

	@media ${Device.xs} {
		width: 100%;
	}
`;

const SelectDateActivity = ({
	history,
	reservationDate,
	setReservationDate,
	selectDate
}) => {
	const { t } = useTranslation();
	const searchQuery = getSearchQuery();
	const id = getId(history);
	const ref = useRef();

	useEffect(() => {
		if (selectDate) {
			ref.current.scrollIntoView({
				behavior: 'smooth',
				block: 'start'
			});
		}
	}, [ref, selectDate]);

	return (
		<StyledDiv ref={ref}>
			<StyledTitle bold content={t('activities.selectDate.title')} />
			<StyledText content={t('activities.selectDate.text')} />
			<StyledDatePicker
				value={reservationDate || moment(searchQuery.date_in)}
				onChange={setReservationDate}
				afterChange={date =>
					addCheckoutActivity(id, {
						reservation_date_in: moment(date).format('YYYY-MM-DD'),
						preset: getUserCurrency().name
					})
				}
				funcDisabledDate={current =>
					!handlerBetweenDates(
						current,
						moment(searchQuery.date_in),
						moment(searchQuery.date_out).add(1, 'days')
					)
				}
				defaultPickerValue={searchQuery.date_in}
			/>
		</StyledDiv>
	);
};

const mapStateToProps = state => {
	return {
		selectDate: selectDateActivity(state)
	};
};

export default connect(mapStateToProps, null)(withRouter(SelectDateActivity));
