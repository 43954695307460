import styled from 'styled-components';

/*----- Commons -----*/
import AppearElementAnimation from '../../../commons/animations/AppearElementAnimation';
import Color from '../../../commons/Color';
import Device from '../../../commons/Device';
import Title from '../../text/Title';
import DefaultButton from '../../button/DefaultButton';

export const Container = styled.div`
	justify-content: center;
	align-items: center;
	border: 1px solid lightgray;
	padding: 8px 0 0 0;
	position: absolute;
	background-color: white;
	z-index: 2;
	margin-left: 6px;
	border-radius: 5px;
	border: 1px solid ${Color.border};
	box-shadow: 0px 1px 3px 3px rgba(0, 0, 0, 0.1);
	max-height: 300px;
	overflow-y: auto;

	display: ${props => (props.visible ? 'block' : 'none')};

	@media ${Device.xs} {
		padding: 10px 20px;
	}
	${AppearElementAnimation}
`;

export const TitleContainer = styled.div`
	background-color: #f5f5f5;
	padding-top: 1px;
	padding-bottom: 1px;

	# Solo por compatibilidad hasta refactorizar todo. .items-title > .custom-title {
		font-size: 12px;
		margin: 8px;
		color: ${Color.footer};
	}
	.custom-title {
		margin: 8px;
	}
	@media ${Device.xs} {
		margin-bottom: 16px;
	}
`;

export const StyledDefaultButton = styled(DefaultButton)`
	@media ${Device.xs} {
		align-items: center;
	}
`;

export const RoomListContainer = styled.div`
	/* ${AppearElementAnimation} */
	width: 100%;
`;

export const ModalContent = styled.div`
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
`;

export const StyledTitle = styled(Title)`
	text-transform: uppercase;
`;
