import React from 'react';
import PropTypes from 'prop-types';
import { StyledReservationSuccess, ImageReservation } from './styles';
import ReservationData from '../ReservationData/index';

const ReservationSuccess = ({ code, imageReservation }) => (
	<StyledReservationSuccess>
		<ImageReservation style={{ backgroundImage: `url(${imageReservation})` }} />
		<ReservationData code={code} />
	</StyledReservationSuccess>
);

export default ReservationSuccess;

ReservationSuccess.propTypes = {
	imageReservation: PropTypes.string.isRequired,
	code: PropTypes.string.isRequired
};
