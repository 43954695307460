import styled from 'styled-components';
import Title from '../../../components/text/Title';
import Text from '../../../components/text/Text';
import Device from '../../../commons/Device';
import { Col } from 'antd';

export const StyledImg = styled.img`
	width: auto;
	height: 100%;

	@media ${Device.xs} {
		height: auto;
		width: 80%;
	}
`;

export const StyledTitle = styled(Title)`
	text-align: center;
	margin-bottom: 8px;
	@media ${Device.xs} {
		margin-bottom: 16px;
		font-size: 16px;
		padding: 0px 12px;
	}
`;

export const StyledSubtitle = styled(Text)`
	text-align: center;
	font-size: 16px;
	@media ${Device.xs} {
		font-size: 14px;
	}
`;

export const SyledColImage = styled(Col)`
	&& {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 30px;
		@media ${Device.xs} {
			margin-top: 70px;
		}
	}
`;

export const SyledColMessage = styled(Col)`
	margin-top: 15px;
	@media ${Device.xs} {
		margin-top: 36px;
	}
`;
