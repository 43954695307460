import { getSearchQuery } from '../../../services/SearchService';
import {
	SHOW_PACKAGE,
	SHOW_PACKAGE_FAILED,
	SHOW_PACKAGE_SUCCESS
} from '../actions/showPackageActions';

import PACKAGE_WITH_HOTEL_MOCK from '../PACKAGE_WITH_HOTEL_MOCK_Show.json';

const initialState = {
	data: [],
	isLoading: false,
	error: '',
	success: false
};

export default function showPackages(state = initialState, action) {
	switch (action.type) {
		case SHOW_PACKAGE:
			return {
				...state,
				data: [],
				isLoading: true,
				error: '',
				success: false
			};
		case SHOW_PACKAGE_SUCCESS:
			const WITH_HOTEL = getSearchQuery().with_hotel;
			return {
				...state,
				data: WITH_HOTEL ? PACKAGE_WITH_HOTEL_MOCK.data : action.payload,
				isLoading: false,
				success: true
			};
		case SHOW_PACKAGE_FAILED:
			return {
				...state,
				isLoading: false,
				error: action.payload,
				success: false
			};
		default:
			return state;
	}
}
