import React from 'react';
import styled from 'styled-components';
import Title from './Title';
import Color from '../../commons/Color';
import PropTypes from 'prop-types';

export const StyledTitle = styled(Title)`
	color: ${Color.footer};
	margin: 0px;
	font-weight: 700;
	display: inline-block !important;
	margin-right: 5px;
`;

export const Span = styled.span`
	color: ${Color.footer};
	font-weight: 400;
	font-size: 16px;
`;

const TitleWithLabel = ({ title, text }) => (
	<>
		<StyledTitle content={title} size={4} />
		<Span>{text}</Span>
	</>
);

export default TitleWithLabel;

TitleWithLabel.defaultProps = {
	title: '',
	text: ''
};

TitleWithLabel.propTypes = {
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired
};
