import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckoutTransferData from '../../../tranfers/views/checkout/section/CheckoutTransferData';
import CheckoutActivityData from '../../../activities/views/checkout/section/CheckoutActivityData';
import {
	getDetailCheckout,
	getHotelInformation
} from '../../../hotels/views/checkout/section/detailsItemsHotel.js';
import { capitalize } from '../../../../utilities/utils';
import CardCheckoutData from '../../../commons/checkout/CardCheckoutData';
import detailsItemsPackages from '../../../packages/views/detail/section/detailsItemsPackages';
import moment from 'moment';

const CartCheckoutItem = ({ item }) => {
	const { t } = useTranslation();
	switch (item.type) {
		case 'transfer':
			return (
				<CheckoutTransferData
					type={item.name}
					title={`${item.info.kind.toUpperCase()} | ${capitalize(
						item.info.category.name.toLowerCase()
					)}`}
					totalPrice={
						item.rate.amount_details.passenger.sale +
						item.rate.amount_details.passenger.iva
					}
					date_in={
						item.reservation_date_in &&
						moment(item.reservation_date_in, 'DD/MM/YYYY')
					}
					date_out={
						item.reservation_date_out &&
						moment(item.reservation_date_out, 'DD/MM/YYYY')
					}
					currency={item.rate.currency}
					fromCart
				/>
			);
		case 'excursion':
			return <CheckoutActivityData id={item.uuid} showActivity={item} fromCart />;
		case 'hotel':
			const hotelInformation = getHotelInformation(item, item.rate.item_id);
			return (
				<CardCheckoutData
					name={item.name}
					dateFrom={item.searchQuery.date_in}
					dateTo={item.searchQuery.date_out}
					totalPrice={item.rate.amount_with_taxes}
					currency={item.currency}
					items={getDetailCheckout(hotelInformation)}
					type={t('hotels.hotel')}
					fromCart
				/>
			);
		case 'package':
			const itemsList = item.searchQuery.with_hotel
				? item.itemsDetails
				: detailsItemsPackages(false, item.searchQuery);

			return (
				<CardCheckoutData
					name={item.name}
					dateFrom={item.searchQuery?.date_in}
					dateTo={item.searchQuery?.date_out}
					totalPrice={item.rate.amount_with_taxes}
					currency={item.currency}
					items={itemsList}
					type={t(
						`packages.package${
							item.searchQuery.with_hotel ? 'WithHotel' : 'WithoutHotel'
						}`
					)}
				/>
			);
		default:
			return null;
	}
};

export default CartCheckoutItem;
