import RegimeIcon from '../../../../../assets/icons/checkout/reception-bell.svg';
import RoomIcon from '../../../../../assets/icons/checkout/room.svg';
import PersonIcon from '../../../../../assets/icons/custom_icons/user.svg';
import MoonIcon from '../../../../../assets/icons/moon-clasic.svg';

import { getSearchQuery } from '../../../../../services/SearchService';
import moment from 'moment';
import { capitalize } from '../../../../../utilities/utils';

export const getHotelInformation = (hotel, rateId, fromPackage = false) => {
	let searchQuery = getSearchQuery();
	let rateInformation = {};
	if (rateId) {
		let rate = fromPackage
			? hotel
			: hotel.rate || hotel.rates.find(rate => rateId === rate.item_id);
		const roomsCountText =
			rate.rooms_details.length > 1 ? `x ${rate.rooms_details.length}` : '';
		rateInformation.regime = capitalize(rate.regime.name.toLowerCase());
		rateInformation.roomType = `${capitalize(
			rate.room_type.name
		)} ${roomsCountText}`;
		rateInformation.currency = rate.currency;
		rateInformation.amount = rate.rooms_details.reduce(
			(sum, room_detail) => sum + room_detail.sale,
			0
		);
		rateInformation.iva = rate.rooms_details.reduce(
			(sum, room_detail) => sum + room_detail.iva,
			0
		);
		rateInformation.hotel = 'Hotel';
	}

	const passengerData = getPassengerInformation(hotel.searchQuery);
	let nights = getNights(fromPackage ? hotel.nights : undefined);

	return {
		date_in: searchQuery.date_in,
		date_out: searchQuery.date_out,
		nights: nights,
		adults: passengerData.adults,
		rooms: passengerData.rooms,
		children: passengerData.children,
		passengers: passengerData.passengers,
		...rateInformation
	};
};

export const getDetailCheckout = (
	data,
	keys = ['regime', 'roomType', 'passengers']
) => {
	let icons = [];
	if (keys.includes('regime')) {
		icons.push({ icon: RegimeIcon, text: data.regime });
	}
	if (keys.includes('roomType')) {
		icons.push({ icon: RoomIcon, text: data.roomType });
	}
	if (keys.includes('passengers')) {
		icons.push({ icon: PersonIcon, text: data.passengers });
	}
	if (keys.includes('nights')) {
		icons.push({ icon: MoonIcon, text: data.nights });
	}

	return icons;
};

export const getNights = data => {
	let searchQuery = getSearchQuery();
	let fromRate = data ? parseInt(data) : null;
	let nights =
		fromRate ||
		moment(searchQuery.date_out).diff(moment(searchQuery.date_in), 'days');
	nights = `${nights} ${nights > 1 ? 'noches' : 'noche'}`;
	return nights;
};

export const getPassengerInformation = search => {
	let searchQuery = search || getSearchQuery();

	let rooms = searchQuery.rooms || [];
	let adults = searchQuery.adults_number || 0;
	let children = searchQuery.children_ages ? searchQuery.children_ages.length : 0;
	rooms.forEach(room => {
		adults += room.adults_number;
		children += room.children_ages.length;
	});
	const roomCount = rooms.length || 1;
	const childrenText = children ? `y ${children} menores` : '';
	const roomText = rooms.length > 1 ? 'habitaciones' : 'habitación';

	return {
		adults: adults,
		children: children,
		rooms: rooms,
		passengers: `${adults} adultos ${childrenText} en ${roomCount} ${roomText}`,
		passengersText: `${adults} adultos ${childrenText}`
	};
};
