/*----- Core -----*/
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

/*----- Commons -----*/
import OpenSans from '../../commons/mixins/fonts/OpenSans';
import Color from '../../commons/Color';
import OpenSansBold from '../../commons/mixins/fonts/OpenSansBold';

/*----- Styles -----*/
const StyledText = styled.p`
	${props =>
		props.bold
			? OpenSansBold(18, 600, Color.footer)
			: OpenSans(18, 400, Color.footer)};
	margin: 0px;
	margin-bottom: 5px;
`;

/*----- Component -----*/
const Text = ({ content, className, bold = false }) => {
	return (
		<StyledText className={className} bold={bold}>
			{content}
		</StyledText>
	);
};
export default Text;

Text.defaultProps = {
	className: 'custom-text'
};
/*----- PropTypes -----*/
Text.propTypes = {
	content: PropTypes.string.isRequired
};
