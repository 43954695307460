import styled from 'styled-components';

export const DateItem = styled.div`
	.ant-calendar-date {
		.today {
			font-size: 10px;
			color: black;
			margin-top: -4px;
		}
	}
`;
