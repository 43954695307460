import React from 'react';
import { useTranslation } from 'react-i18next';
import GenericFeedback from './GenericFeedback';
import { connect } from 'react-redux';
import { deleteRecovery } from '../../../authentication/actions/recoveryActions';
import { closeModal } from '../../../authentication/actions/loginModalActions';

const SuccessResponseRecovery = ({
	closeModal,
	deleteRecovery,
	closeModalMobile
}) => {
	const { t } = useTranslation();

	const closeFunction = () => {
		closeModal();
		deleteRecovery();
		if (closeModalMobile) closeModalMobile();
	};

	return (
		<GenericFeedback
			closeModal={closeFunction}
			title={t('authentication.recovery.feedback.title')}
			content={t('authentication.recovery.feedback.content')}
			footer={t('authentication.recovery.feedback.footer')}
		/>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		closeModal: payload => closeModal(dispatch, payload),
		deleteRecovery: payload => deleteRecovery(dispatch, payload)
	};
};

export default connect(null, mapDispatchToProps)(SuccessResponseRecovery);
