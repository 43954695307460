import React from 'react';
import { StyledModal, TitleContainer, StyledTitle, StyleKind } from './styles';
import MobileModal from '../MobileModal';
import { isMobile } from '../../../utilities/utils';
import SeeDetailsInfo from './SeeDetailsInfo';
import { useTranslation } from 'react-i18next';

const SeeDetailsModal = ({ kind, title, details, visible, onCancel }) => {
	const { t } = useTranslation();

	const Title = () => (
		<TitleContainer>
			<StyleKind content={kind} />
			<StyledTitle content={title} />
		</TitleContainer>
	);

	const ModalWeb = () => (
		<StyledModal
			visible={visible}
			onCancel={onCancel}
			closable={false}
			title={<Title />}
			footer={null}
			width="700px"
		>
			<SeeDetailsInfo details={details} />
		</StyledModal>
	);

	const ModalMobile = () => (
		<MobileModal
			visible={visible}
			onCancel={onCancel}
			title={t('general.details')}
			justifyStart
			content={
				<>
					<Title />
					<SeeDetailsInfo details={details} />
				</>
			}
		/>
	);

	return isMobile() ? <ModalMobile /> : <ModalWeb />;
};

export default SeeDetailsModal;
