import styled, { css } from 'styled-components';
import { Col } from 'antd';
import Device from '../../../../commons/Device';
import Text from '../../../../components/text/Text';
import { MainRow } from '../../../commons/checkout/CardCheckoutData/styles';

export const StyledTotalRow = styled(MainRow)`
	margin-top: 2rem;
	background: #030f33 !important;
	justify-content: flex-start !important;
	${props =>
		!props.roundedTop &&
		css`
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		`}
`;

export const StyledTotalPrice = styled(Text)`
	margin: 0px;
	font-size: 28px;
	font-weight: bolder;
	color: white;
	@media ${Device.xs} {
		font-size: 22px;
	}
	@media ${Device.xxl} {
		font-size: 35px;
	}
`;

export const StyledCol = styled(Col)`
	margin: 15px 0px;
	padding: 10px 30px !important;
	display: flex !important;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	@media ${Device.xs} {
		border-right: none;
		margin: 0px;
		padding: 12px 15px !important;
	}
`;

export const StyledPriceTaxes = styled(Text)`
	font-size: 12px;
	color: white;
	font-weight: bolder;
`;
