import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Span, NumberCode } from './styles';

const ReservationCode = ({ code }) => {
	const { t } = useTranslation();
	return (
		<Container>
			<Span>{t('reservation.code')}</Span>
			<NumberCode>{code}</NumberCode>
		</Container>
	);
};

export default ReservationCode;
