import React, { useState } from 'react';
import { isMobile } from '../../../../utilities/utils';

/*----- Components -----*/
import ImageGallery from './ImageGallery';
import GalleryModal from './GalleryModal';
import Mosaic from './Mosaic';

import DefaultImage from '../../../../assets/images/DefaultCardImage.svg';
import { StyledDetailHeader } from './styles';

const DetailHeader = ({ images }) => {
	const [galleryVisible, setGalleryVisible] = useState(false);
	if (!images || !images.length) {
		images = [DefaultImage];
	}

	const showGallery = () => {
		if (isMobile() || images.length >= 4) {
			setGalleryVisible(true);
		}
	};

	return (
		<>
			{isMobile() || images.length < 4 ? (
				<StyledDetailHeader onClick={showGallery}>
					<ImageGallery
						images={images}
						showThumbs={false}
						showIndicators={!isMobile() && images.length > 1}
						showArrows={!isMobile()}
						heigth={isMobile() ? 'auto' : '400px'}
						useImage={!isMobile()}
					/>
				</StyledDetailHeader>
			) : (
				<Mosaic showGallery={showGallery} images={images} />
			)}
			<GalleryModal
				onCancel={() => setGalleryVisible(false)}
				visible={galleryVisible}
				images={images}
			/>
		</>
	);
};

export default DetailHeader;
