import styled from 'styled-components';
import Color from '../../../commons/Color';
import Title from '../../../components/text/Title';
import Text from '../../../components/text/Text';

export const StyledContainer = styled.div`
	border-bottom: 1px solid #66666669;
	width: 100%;
	padding-bottom: 16px;
`;

export const StyledTitle = styled(Title)`
	margin-left: 0px;
	margin-right: 0px;
`;

export const StyledInformation = styled(Text)`
	color: ${Color.text};
	font-size: 12px;
	margin: 0px;
`;

export const TextPrice = styled(Text)`
	font-size: 28px;
	margin: 0px;
	line-height: 30px;
`;
