import React from 'react';
import SuccessRegisterConfirmImage from '../../../assets/images/SuccessRegisterConfirmImage.svg';
import SuccessRegisterConfirmImageMobile from '../../../assets/images/SuccessRegisterConfirmImageMobile.svg';
import { useTranslation } from 'react-i18next';
import GenericResponse from './GenericResponse';
import { isMobile } from '../../../utilities/utils';

const SuccessResponse = () => {
	const { t } = useTranslation();
	return (
		<GenericResponse
			image={
				isMobile() ? SuccessRegisterConfirmImageMobile : SuccessRegisterConfirmImage
			}
			title={t('authentication.register.feedback.confirm.success.title')}
			firstContent={t('authentication.register.feedback.confirm.success.content.1')}
			secondContent={t('authentication.register.feedback.confirm.success.content.2')}
			thirdContent={t('authentication.register.feedback.confirm.success.content.3')}
		/>
	);
};

export default SuccessResponse;
