import { getSearchQuery } from '../services/SearchService';

export const buildRoomsQuery = rooms => {
	let passengers = [];
	for (let key in rooms) {
		let minors = rooms[key].children_ages.join('-');
		let adults_number = rooms[key].adults_number;
		passengers.push(`${adults_number}${minors.length ? '-' : ''}${minors}`);
	}
	return passengers.join(',');
};

export const buildSearchUrl = () => {
	let query = getSearchQuery();

	query.page = parseInt(query.page);

	return `search?q=${encodeURIComponent(JSON.stringify(query))}`;
};

export const buildFetchQuery = (query, moreRooms = false) => {
	let fetchQuery = { ...query };
	if (!query.rooms) return;
	if (!moreRooms) {
		fetchQuery.adults_number = query.rooms[0].adults_number;
		fetchQuery.children_ages = query.rooms[0].children_ages;
		delete fetchQuery.rooms;
	} else {
		fetchQuery.passengers = buildRoomsQuery(query.rooms);
	}
	return fetchQuery;
};

export const addFilterToQuery = (query, filters) => {
	let newQuery = { ...query, page: 1 };

	Object.keys(filters).forEach(key => {
		let field = filters[key];
		if (key === 'prices') {
			newQuery = {
				...newQuery,
				min_price: field[0],
				max_price: field[1]
			};
		} else if (Array.isArray(field) && (!field.length || field.includes('all'))) {
			delete newQuery[key];
		} else if (!field) {
			delete newQuery[key];
		} else {
			newQuery = { ...newQuery, [key]: field };
		}
	});

	return newQuery;
};

export const buildPackagesQuery = query => {
	let fetchQuery = { ...buildFetchQuery(query, false) };
	fetchQuery.date = fetchQuery.date_in;
	//fetchQuery.with_hotel = fetchQuery.withHotel;
	delete fetchQuery['withHotel'];
	fetchQuery.with_hotel = false;
	return fetchQuery;
};
