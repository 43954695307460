import {
	RESERVE_PACKAGES,
	RESERVE_PACKAGES_FAILED,
	RESERVE_PACKAGES_SUCCESS
} from '../actions/reserveActions';

const initialState = {
	data: [],
	isLoading: false,
	error: '',
	success: false
};

export default function reservePackages(state = initialState, action) {
	switch (action.type) {
		case RESERVE_PACKAGES:
			return {
				...state,
				data: [],
				isLoading: true,
				error: '',
				success: false
			};
		case RESERVE_PACKAGES_SUCCESS:
			return {
				...state,
				data: action.payload,
				isLoading: false,
				success: true
			};
		case RESERVE_PACKAGES_FAILED:
			return {
				...state,
				isLoading: false,
				error: action.payload,
				success: false
			};
		default:
			return state;
	}
}
