import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

/*----- Components -----*/
import { Form } from 'antd';
import FilterForm from '../../../../components/form/FilterForms';
import FilterFormSearchName from '../../../../components/form/FilterForms/FilterFormSearchName';
import FilterFormTransferKind from '../../../../components/form/FilterForms/FilterFormTransferKind';
import FilterFormTransferCategory from '../../../../components/form/FilterForms/FilterFormTransferCategory';

/*----- Actions -----*/
import { getTransfers } from '../../actions/searchFormActions';

/*----- Selectors -----*/
import {
	transfersQuery,
	transferFilterReseted
} from '../../selectors/transfersSelector';

/*----- utilities -----*/
import { activitiesDestinations } from '../../../../utilities/constants';
import { replaceSearch } from '../../../../components/pagination/SearchUtils';
import { parse } from '../../../../utilities/queryString';
import {
	buildFetchQuery,
	addFilterToQuery
} from '../../../../utilities/buildSearchQuery';

const FilterTransfers = ({
	history,
	form,
	getTransfers,
	query,
	filterReseted,
	onSubmit
}) => {
	const [initialMaxPrice, setInitialMaxPrice] = useState(null);
	const [initialMinPrice, setInitialMinPrice] = useState(null);
	const [initialTransferName, setInitialTransferName] = useState('');
	const [initialKindCodes, setInitialKindCodes] = useState(['all']);
	const [initialCategoryCodes, setInitialCategoryCodes] = useState(['all']);

	useEffect(() => {
		let params = parse(history.location.search, activitiesDestinations);
		if (!params) {
			history.push('/transfers');
			return;
		}

		setInitialMaxPrice(params.max_price);
		setInitialMinPrice(params.min_price);
		setInitialTransferName(params.transfer_name || '');
		setInitialKindCodes(params.kind_codes || ['all']);
		setInitialCategoryCodes(params.category_codes || ['all']);
	}, [history]);

	useEffect(() => {
		if (filterReseted) {
			form.resetFields();
		}
	}, [filterReseted, form]);

	const fetchData = () => {
		let newQuery = addFilterToQuery(query, form.getFieldsValue());
		getTransfers(buildFetchQuery(newQuery));
		replaceSearch(history, 'transfers', newQuery);

		if (onSubmit) onSubmit();
	};

	return (
		<FilterForm
			form={form}
			fetchData={fetchData}
			disabled={filterReseted}
			defaultValues={{
				min_price: initialMinPrice,
				max_price: initialMaxPrice
			}}
			searchName={
				<FilterFormSearchName
					form={form}
					fieldName={'transfer_name'}
					fetchData={fetchData}
					defaultValue={initialTransferName}
					disabled={filterReseted}
				/>
			}
		>
			<FilterFormTransferKind
				form={form}
				fetchData={fetchData}
				defaultValues={initialKindCodes}
				disabled={filterReseted}
			/>
			<FilterFormTransferCategory
				form={form}
				fetchData={fetchData}
				defaultValues={initialCategoryCodes}
				disabled={filterReseted}
			/>
		</FilterForm>
	);
};

const mapStateToProps = state => {
	return {
		query: transfersQuery(state),
		filterReseted: transferFilterReseted(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getTransfers: payload => getTransfers(dispatch, payload)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Form.create({ name: 'transfer_filter' })(withRouter(FilterTransfers)));
