import styled from 'styled-components';

/*----- Assets -----*/
import OpenSans from '../../../../commons/mixins/fonts/OpenSans';
import Color from '../../../../commons/Color';
import Device from '../../../../commons/Device';

export const RoomResults = styled.div`
	margin: 16px 8px 16px 8px;
`;

export const Container = styled.div`
	height: 40px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid #c7c7c7;
	padding: 10px;
	max-width: 120px; /* to only passenger width: 70px;  */
	border-radius: 6px;
	cursor: pointer;

	&:hover {
		border-color: ${Color.main};
	}
	@media ${Device.xs} {
		max-width: unset;
	}
`;

export const Img = styled.img`
	width: 20px;
`;

export const PeopleNumberContainer = styled.div`
	${OpenSans(14, 500, 'black')};
`;
