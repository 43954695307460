import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Icon } from 'antd';
import { StyledRoundedInput } from '../styles';
import Color from '../../../commons/Color';

const { Item } = Form;

const FilterFormSearchName = ({
	form,
	fieldName,
	fetchData,
	defaultValue,
	disabled
}) => {
	const { t } = useTranslation();
	const [prevValue, setPrevValue] = useState(defaultValue || '');
	return (
		<Item>
			{form.getFieldDecorator(fieldName, {
				initialValue: defaultValue
			})(
				<StyledRoundedInput
					borderRadius={'23px'}
					type="text"
					name=""
					placeholder={t('general.filterByName')}
					suffix={
						<Icon
							type="search"
							style={{ color: disabled ? Color.text : Color.main }}
						/>
					}
					withoutLabel
					disabled={disabled}
					onPressEnter={e => {
						let value = e.target.value.trim();
						if (value !== prevValue) {
							fetchData();
							setPrevValue(value);
						}
					}}
				/>
			)}
		</Item>
	);
};

export default FilterFormSearchName;
