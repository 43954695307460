import styled from 'styled-components';
import LoginButton from '../button/LoginButton';
import LogoutButton from '../button/LogoutButton';
import { Dropdown } from 'antd';
import Text from '../text/Text';
import CenterContainer from '../containers/CenterContainer';
import Color from '../../commons/Color';

export const StyledDiv = styled.div`
	${CenterContainer}
`;

export const StyledLoginButton = styled(LoginButton)`
	&& {
		background-color: transparent;
		border: none;
	}
`;

export const StyledLogoutButton = styled(LogoutButton)`
	&& {
		background-color: transparent;
		border: none;
	}
`;

export const StyledLink = styled.a`
	font-size: 12px;
	font-weight: bold;
	${CenterContainer}
`;

export const StyledDropdown = styled(Dropdown)`
	${CenterContainer}
`;

export const StyledText = styled(Text)`
	color: ${Color.main};
	font-size: 14px;
	margin: 0px;
`;

export const StyledImg = styled.img`
	color: ${Color.main};
	width: 20px;
	height: 20px;
	margin-right: 8px;
`;
