/*----- Core -----*/
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import DefaultInput from './Input';

/*----- Assets -----*/
import MoonIcon from '../../assets/icons/fullmoon.svg';
import { useTranslation } from 'react-i18next';

/*----- Styles -----*/
const StyledInput = styled(DefaultInput)`
	width: 100%;
	min-width: unset;
	justify-content: flex-end;
	display: flex;
	padding: 0px 8px;
	.ant-input {
		text-align: center !important;
	}
`;

const StyledIcon = styled.img`
	width: 20px;
`;

const NightInput = ({ value, onAfterChange, onChange, disabled }, ref) => {
	const { t } = useTranslation();

	return (
		<StyledInput
			ref={ref}
			prefix={<StyledIcon src={MoonIcon} alt="Moon icon" />}
			name={t('general.nights')}
			onChange={currentValue => {
				onChange(currentValue);
				if (onAfterChange) onAfterChange(currentValue);
			}}
			type="number"
			max={400}
			min={1}
			value={value}
			disabled={disabled}
		/>
	);
};

export default forwardRef(NightInput);
