export const GET_MY_RESERVATIONS = 'GET_MY_RESERVATIONS';
export const GET_MY_RESERVATIONS_SUCCESS = 'GET_MY_RESERVATIONS_SUCCESS';
export const GET_MY_RESERVATIONS_FAILED = 'GET_MY_RESERVATIONS_FAILED';
export const CANCEL_RESERVATION = 'CANCEL_RESERVATION';
export const CANCEL_RESERVATION_SUCCESS = 'CANCEL_RESERVATION_SUCCESS';
export const CANCEL_RESERVATION_FAILED = 'CANCEL_RESERVATION_FAILED';
export const FILTER_RESERVATIONS = 'FILTER_RESERVATIONS';

export const getMyReservations = (dispatch, params) =>
	dispatch({ type: GET_MY_RESERVATIONS, payload: params });

export const cancelReservation = (dispatch, params) =>
	dispatch({ type: CANCEL_RESERVATION, payload: params });

export const filterReservations = (dispatch, params) =>
	dispatch({ type: FILTER_RESERVATIONS, payload: params });
