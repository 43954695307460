/*--- Core -- */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

/*--- Components -- */
import HeroText from '../../../../components/hero/HeroText';
import Pagination from '../../../../components/pagination';
import ReservationsTable from './ReservationsTable/myReservationsTable';
/*--- Selectors --- */
import {
	myReservationsData,
	myReservationsIsLoading,
	myReservationsPagination,
	myReservationsQuery
} from '../../selectors/myReservationsSelector';

/*--- Actions ---- */
import { getMyReservations } from '../../actions/myReservationsActions';

/*--- Styles -- */
import Styles, { StyledTableContainer } from './styles';
import { parseMyReservationsParams } from '../../../../utilities/queryString';

const MyReservations = ({
	isLoading,
	myReservations,
	getMyReservations,
	pagination,
	query,
	history
}) => {
	const { t } = useTranslation();
	useEffect(() => {
		let params = parseMyReservationsParams(history.location.search);
		getMyReservations(params);
	}, [getMyReservations, history.location.search]);
	return (
		<Styles>
			<Col
				xs={24}
				sm={{ span: 24 }}
				md={{ span: 20, offset: 2 }}
				lg={{ span: 22, offset: 1 }}
				xl={{ span: 22, offset: 1 }}
			>
				<Col span={24}>
					<Row type="flex" justify="start" align="top">
						{pagination && <HeroText title={t('myReservations.title')} />}
					</Row>
				</Col>
				<Col span={24}>
					<StyledTableContainer type="flex" justify="center" align="top">
						<ReservationsTable
							history={history}
							loading={isLoading}
							dataSource={myReservations}
						/>
						{pagination && myReservations.length && (
							<Pagination
								pagination={pagination}
								query={query}
								fetchData={getMyReservations}
								service="my_reservations"
							/>
						)}
					</StyledTableContainer>
				</Col>
			</Col>
		</Styles>
	);
};

const mapStateToProps = state => {
	return {
		myReservations: myReservationsData(state),
		isLoading: myReservationsIsLoading(state),
		pagination: myReservationsPagination(state),
		query: myReservationsQuery(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getMyReservations: payload => getMyReservations(dispatch, payload)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MyReservations);
