import styled from 'styled-components';
import Device from '../../../commons/Device';

import Text from '../../text/Text';

export const TitlesContainer = styled.div`
	margin-top: 20px;
	margin-bottom: 20px;

	@media ${Device.xs} {
		margin-bottom: 30px;
	}
`;

export const StyledText = styled(Text)`
	font-size: 20px;

	@media ${Device.xs} {
		font-size: 16px;
	}
`;
