import styled from 'styled-components';
import DefaultButton from '../../button/DefaultButton';
import { StyledServiceCard } from '../../carousel/styles';

export const StyledServiceRoomCard = styled(StyledServiceCard)`
	height: initial;
	width: initial;
	border-radius: 10px;
	margin: 15px 25px 25px 25px;
	padding: 25px 0px;
`;

export const TitleContainer = styled.div`
	padding: 0px 0px 20px 0px;
	border-bottom: 1px solid #66666669;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

export const StyledDefaultButton = styled(DefaultButton)`
	align-items: center;
	margin: 0.5rem;
	button {
		width: 100%;
	}
`;
