import React from 'react';
import {
	StyledDrawer,
	StyledLink,
	StyledTitle,
	StyledLogo,
	StyledIcon,
	StyledLogout,
	StyledLogoutContent,
	StyledLogoutButton,
	StyledTextLogout,
	StyledTextUserProfile,
	MenuDrawerItems
} from './styles';
import { getCurrentUser, isLogged } from '../../authentication/currentUser';
import logo from '../../assets/logo.svg';
import userIcon from '../../assets/icons/user_header_mobile.svg';
import logoutIcon from '../../assets/icons/logout.svg';
import { useTranslation } from 'react-i18next';

const Title = () => {
	return isLogged() ? (
		<StyledTitle>
			<StyledIcon src={userIcon} alt="user-icon" />
			<StyledTextUserProfile content={getCurrentUser().name} />
		</StyledTitle>
	) : (
		<StyledLogo src={logo} alt="logo" />
	);
};

const Logout = ({ t, onClose }) => (
	<StyledLogout>
		<StyledLogoutButton customOnClick={onClose}>
			<StyledLogoutContent>
				<StyledTextLogout content={t('general.logout')} />
				<StyledIcon src={logoutIcon} />
			</StyledLogoutContent>
		</StyledLogoutButton>
	</StyledLogout>
);

const MenuDrawer = ({ onClose, visible }) => {
	const { t } = useTranslation();

	return (
		<StyledDrawer
			title={<Title />}
			placement="left"
			closable={true}
			onClose={onClose}
			visible={visible}
		>
			<MenuDrawerItems>
				<StyledLink onClick={onClose} to="/home">
					{t('general.menu.init')}
				</StyledLink>
				<StyledLink onClick={onClose} to="/my_reservations/search">
					{t('general.menu.myReservations')}
				</StyledLink>
				<StyledLink onClick={onClose} to="/contact">
					{t('general.menu.contactUs')}
				</StyledLink>
				<StyledLink onClick={onClose} to="/about_us">
					{t('general.menu.aboutUs')}
				</StyledLink>
			</MenuDrawerItems>
			{isLogged() && <Logout t={t} onClose={onClose} />}
		</StyledDrawer>
	);
};

export default MenuDrawer;
