import styled from 'styled-components';
import { Row, Col } from 'antd';

export const StyledRow = styled(Row)`
	margin-top: 45px;
`;

export const StyledCol = styled(Col)`
	background-color: #ffffff;
`;
