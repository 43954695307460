import styled from 'styled-components';
import { Select } from 'antd';
import CenterContainer from '../../../containers/CenterContainer';
import Device from '../../../../commons/Device';
import { Link } from 'react-router-dom';

export const StyledHeader = styled.header`
	width: 100%;
	position: relative;
	z-index: 999;
	display: flex;
	flex-direction: column;
`;

export const StyledContent = styled.div`
	${CenterContainer}
	width: 100%;
	background: ${props => (props.logged ? '#F0EFEF' : 'white')};
	padding: 5px 24px 5px 0px;
`;

export const StyledLogo = styled.img`
	width: 209px;
	height: 45px;
	margin: 8px 0px;
	@media ${Device.xs} {
		display: none;
	}
`;

export const StyledLink = styled(Link)`
	position: ${props => (props.absolute ? 'absolute' : 'relative')};
	left: 0;
	margin-left: 24px;
	z-index: 3;
}
`;

export const StyledFlag = styled.img`
	width: 20px;
	height: 20px;
	margin-right: 8px;
	@media ${Device.xs} {
		margin-right: 4px;
	}
`;

export const StyledMenu = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
`;

export const StyledSelect = styled(Select)`
	min-width: 120px;
	.ant-select-selection {
		background: ${props => (props.logged ? '#F0EFEF' : 'white')};
		border: none;
	}
	.ant-select-selection-selected-value {
		display: flex !important;
		align-items: center;
	}
	@media ${Device.xs} {
		min-width: auto;
		.ant-select-selection__rendered {
			display: flex;
			align-items: center;
		}
	}
`;

export const StyledServicesNavBar = styled.div`
	${CenterContainer}
	width: 100%;
	background: white;
	padding: 0px 24px;

	@media ${Device.xs} {
		margin-top: 6px;
	}
`;

export const StyledNavBar = styled.div`
	${CenterContainer}
	position: relative;
	width: 100%;
`;

export const Pipe = styled.span`
	border: 1px solid;
	margin: 5px 15px;
	opacity: 0.1;
`;
