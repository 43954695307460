/*----- Core -----*/
import React from 'react';
import PropTypes from 'prop-types';

/*----- Components -----*/
import RoomResults from './results';
import { PeopleNumberContainer, Img } from './styles';

/*----- Assets -----*/
import BedIcon from '../../../../assets/icons/bed.svg';

export const PassengerResults = ({ people, onClick }) => (
	<RoomResults people={people} onClick={onClick} label="Pasajeros" />
);

export const MultipleRoomResults = ({ people, onClick, roomsCount }) => (
	<RoomResults people={people} onClick={onClick} label="Habitaciones">
		<Img src={BedIcon} alt="Bed icon" />

		<PeopleNumberContainer>{roomsCount}</PeopleNumberContainer>
	</RoomResults>
);

/*----- PropTypes -----*/
PassengerResults.propTypes = {
	people: PropTypes.number.isRequired,
	onClick: PropTypes.func.isRequired
};

MultipleRoomResults.propTypes = {
	people: PropTypes.number.isRequired,
	onClick: PropTypes.func.isRequired,
	roomsCount: PropTypes.number.isRequired
};
