import styled from 'styled-components';
import DefaultButton from '../../button/DefaultButton';
import Device from '../../../commons/Device';

export const Button = styled(DefaultButton)`
	align-items: center;
	button {
		width: 141px;
	}
`;

export const FilterContainer = styled.article`
	width: 100%;
	background-color: white;
	box-shadow: 0px 0px 32px -8px rgba(0, 0, 0, 0.2);
	border-radius: 8px;
	padding: 16px;

	@media ${Device.xs} {
		padding: 0px;
		border-radius: 0px;
		box-shadow: initial;
	}
`;
