/*----- Core -----*/
import React from 'react';
import PropTypes from 'prop-types';
import { StyledModal, StyledTitle, Content } from './styles';

const MobileModal = ({
	visible,
	onCancel,
	title,
	content,
	className,
	justifyStart
}) => {
	return (
		<StyledModal
			visible={visible}
			onCancel={onCancel}
			className={className}
			footer={null}
			title={<StyledTitle content={title} />}
		>
			<Content justifyStart={justifyStart}>{content}</Content>
		</StyledModal>
	);
};

MobileModal.propTypes = {
	onCancel: PropTypes.func,
	visible: PropTypes.bool
};

export default MobileModal;
