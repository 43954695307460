import styled from 'styled-components';

export const ContainerButton = styled.div`
	position: fixed;
	bottom: 0px;
	z-index: 100;
	width: 176px;
`;

export const StyledFilterMobile = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`;
