import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/*----- Components -----*/
import Reservation from '../../../../commons/reservation';
import { transferSteps } from '../../../../../utilities/utils';

/*----- Assets -----*/
import EndReservation from '../../../../../assets/images/tranfers/transfer-end-reservation.svg';

import { reserveTransferData } from '../../../selectors/reserveTransferSelector';
import { getId } from '../../../../commons/checkout/CheckoutUtils';

const SuccessResponse = ({ data, history }) => (
	<Reservation
		code={data.reservation_id}
		dataSteps={transferSteps(getId(history))}
		imageReservation={EndReservation}
		active={3}
	/>
);

const mapStateToProps = state => {
	return {
		data: reserveTransferData(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(SuccessResponse));
