import styled from 'styled-components';
import Device from '../../../../commons/Device';
import Color from '../../../../commons/Color';
import CenterContainer from '../../../containers/CenterContainer';
import Text from '../../../text/Text';

export const StyledPrice = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.input-container {
		width: 80%;
	}

	@media ${Device.xs} {
		.input-container {
			${CenterContainer}
			width: 100%;
			.default-button {
				min-width: 160px;
			}
		}
	}
`;

export const TextPrice = styled(Text)`
	font-size: 12px;
	margin-bottom: 0px;
	color: ${Color.text};

	@media ${Device.xs} {
		text-align: center;
	}
`;

export const AmountPrice = styled(Text)`
	font-size: 32px;
	margin-bottom: 0px;
	line-height: 1;

	@media ${Device.xs} {
		text-align: center;
	}
`;

export const PriceAmountContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 20px 0px 18px 15px;

	@media ${Device.xs} {
		margin: 0px;
	}
`;
