import styled from 'styled-components';
import { Row, Col } from 'antd';
import Text from '../../../../components/text/Text';
import Device from '../../../../commons/Device';

export const MainRow = styled(Row)`
	padding: 40px !important;

	@media ${Device.xs} {
		padding: 30px 30px 14px !important;
	}
`;

export const StyledCol = styled(Col)`
	display: flex !important;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	@media ${Device.xs} {
		margin-bottom: 16px !important;
	}
`;

export const StyledTitle = styled(Text)`
	font-size: 14px;
	margin-bottom: 22px;

	@media ${Device.xs} {
		font-size: 16px;
		margin-bottom: 10px;
	}
`;

export const StyledName = styled(Text)`
	font-size: 24px;
	width: 80%;
	@media ${Device.xs} {
		font-size: 18px;
	}
`;

export const StyledTextConfirm = styled(Text)`
	font-size: 16px;
	color: #86858a;
	margin: 0px;
`;
