import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT,
  RECOVERY_USER
} from '../actions/loginActions'
import { startSession, destroySession } from '../../sessions/loginSession'

/*----- Reducer -----*/
const initialState = {
  isLoading: false,
  recovery: false,
  error: false
}

export function login(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isLoading: true,
      }
    case LOGIN_SUCCESS:
      startSession(action.authData)
      return {
        ...state,
        isLoading: false,
      }
    case LOGIN_FAILED:
      return {
        ...state,
        isLoading: false,
        error: true
      }
    case RECOVERY_USER:
      return {
        ...state,
        recovery: true
      }
    case LOGOUT:
      destroySession()
      return {
        ...state,
        ...initialState
      }
    default:
      return state
  }
}

export default login
