import React from 'react';
import { connect } from 'react-redux';

/*----- Components -----*/
import GenericCheckout from '../../../commons/checkout/GenericCheckout';

/*----- Actions -----*/
import { showActivity } from '../../actions/showActivityActions';

/*----- Selectors -----*/
import {
	showActivityIsLoading,
	showActivitySuccess
} from '../../selectors/showActivitySelector';

const CheckoutActivity = ({ showActivity, success, isLoading, SuccessResponse }) => (
	<GenericCheckout
		showData={showActivity}
		success={success}
		isLoading={isLoading}
		service="activities"
		SuccessResponse={SuccessResponse}
	/>
);

const mapStateToProps = state => {
	return {
		isLoading: showActivityIsLoading(state),
		success: showActivitySuccess(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		showActivity: payload => showActivity(dispatch, payload)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutActivity);
