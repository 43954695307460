/*----- Core -----*/
import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

/*----- Assets -----*/
import tranfers_portrait from '../../../../assets/images/tranfers/young-family-traveling.jpg';

/*----- Components -----*/
import ConsultForm from '../../../../components/form/ConsultForm';
import { Row, Col } from 'antd';
import HeroWithImage from '../../../../components/hero/HeroWithImage';
import TransferCard from '../../../../components/card/TransferCard';
import SearchFormTransfers from '../searchForm';
import SearchFormCard from '../../../../components/card/SearchFormCard';

/*----- Styles -----*/
import Styles, { StyledText } from './styles';

/*----- Data -----*/
import { transferData } from './data';

import { toggleModal } from '../../../app/AppActions';

function Transfers(props) {
	const { t } = useTranslation();
	const { toggleModal } = props;

	const renderTransfers = () => {
		return transferData.map((transfer, i) => (
			<Col xs={22} sm={12} lg={6} xl={5} className="card-container" key={i}>
				<TransferCard
					title={t(transfer.name)}
					imageUrl={transfer.image}
					description={transfer.small_description}
					label={transfer.label}
					destination="/home"
					onClick={() =>
						toggleModal('Traslado', t(transfer.name), t(transfer.description))
					}
				/>
			</Col>
		));
	};

	return (
		<Styles>
			<ConsultForm />
			<HeroWithImage
				title={t('transfers.title')}
				subtitle={t('transfers.subtitle')}
				imageUrl={tranfers_portrait}
			/>

			<SearchFormCard
				content={<SearchFormTransfers header={t('transfers.titleForm')} />}
			/>

			<Row type="flex" justify="center" className="title-service-section">
				<StyledText content={t('transfers.titleIndex')} />
			</Row>

			<Row type="flex" justify="center" className="card__list-container">
				{renderTransfers()}
			</Row>
		</Styles>
	);
}

const mapDispatchToProps = dispatch => {
	return {
		toggleModal: (type, name, description) =>
			toggleModal(dispatch, type, name, description)
	};
};

export default connect(null, mapDispatchToProps)(Transfers);
