import React from 'react';
import { ItemCheckoutConfirmContainer } from '../../../../../components/containers/CheckoutContainer';
import CheckoutConfirmList from '../../../../../components/list/checkoutConfirmList/CheckoutConfirmList';
import { useTranslation } from 'react-i18next';
import { getConfirmData } from '../../../../commons/confirm/confirmUtils';

const TransferInformation = ({ dataCheckout, withHotel }) => {
	const { t } = useTranslation();

	const addLodging = data => {
		data.push({
			label: t('checkout.activities.meetingPoint.title'),
			value: dataCheckout.in.hotel_info
		});

		return data;
	};

	const listDeparture = addLodging(getConfirmData(dataCheckout, t, 'in'));
	const listArrival = addLodging(getConfirmData(dataCheckout, t, 'out'));

	return (
		<ItemCheckoutConfirmContainer>
			<CheckoutConfirmList
				title={t('checkout.arrival')}
				list={listDeparture}
				lastItem={false}
			/>
			<CheckoutConfirmList
				title={t('checkout.departure')}
				list={listArrival}
				lastItem={withHotel}
			/>
		</ItemCheckoutConfirmContainer>
	);
};

export default TransferInformation;
