import styled from 'styled-components';
import Text from '../../../../components/text/Text';
import Device from '../../../../commons/Device';

export const MainDiv = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	width: 100%;
`;

export const TitleContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 12px 0px;
`;

export const StyledTitleCheckout = styled(Text)`
	font-size: 28px;

	@media ${Device.xs} {
		font-size: 20px;
	}
`;

export const StyledText = styled(Text)`
	font-size: 18px;

	@media ${Device.xs} {
		font-size: 16px;
		margin-top: 20px;
	}
`;
