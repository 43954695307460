/*----- Core -----*/
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/*----- Components -----*/
import ReservationPanelDates from './ReservationPanelDates';
import ReservationPanelHeader from './ReservationPanelHeader';

/*----- Styles-----*/
import { CardContainer, ItemsContainer, Button, StyledWarningText } from './styles';
import { addToCartHandler } from '../../utilities/utils';
import { withRouter } from 'react-router';
import { getSearchQuery } from '../../services/SearchService';

const ReservationPanel = ({
	dateFrom,
	dateTo,
	textButton,
	amount,
	currency,
	textTotal,
	dateLabel,
	children,
	onClick,
	disabled = false,
	buttonLegend,
	panelTitle,
	addToCart,
	cartItem,
	history,
	setNotification,
	setCheckout
}) => {
	const { t } = useTranslation();
	return (
		<CardContainer>
			<ReservationPanelHeader
				panelTitle={panelTitle}
				currency={currency}
				amount={amount}
				textTotal={textTotal}
			/>

			<ItemsContainer>
				<ReservationPanelDates
					dateLabel={dateLabel}
					dateFrom={dateFrom}
					dateTo={dateTo}
				/>
				{children}
				<Button disabled={disabled} content={textButton} onClick={onClick} />
				{addToCart && (
					<Button
						secondary
						bold
						content={t('checkout.addToCart')}
						onClick={() =>
							addToCartHandler(
								{ searchQuery: getSearchQuery() },
								cartItem,
								addToCart,
								history,
								setCheckout,
								setNotification
							)
						}
					/>
				)}
				{disabled && <StyledWarningText bold content={buttonLegend} />}
			</ItemsContainer>
		</CardContainer>
	);
};

/*----- PropTypes -----*/
ReservationPanel.propTypes = {
	dateFrom: PropTypes.string,
	dateTo: PropTypes.string,
	textButton: PropTypes.string,
	amount: PropTypes.number,
	currency: PropTypes.string,
	textTotal: PropTypes.string,
	dateLabel: PropTypes.string,
	children: PropTypes.node,
	onClick: PropTypes.func,
	addToCart: PropTypes.func,
	cartItem: PropTypes.object,
	setNotification: PropTypes.func
};

export default withRouter(ReservationPanel);
