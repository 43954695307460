/*----- Imports -----*/
import React from 'react'
import styled from 'styled-components'
import { css } from 'styled-components'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

/*----- Commons-----*/
import Montserrat from '../../commons/mixins/fonts/Montserrat'
import Color from '../../commons/Color'
import Device from '../../commons/Device'

/*----- Styles-----*/
const StyledNavButton = styled(NavLink)`
	${ Montserrat(12, 400, '#606060') };
	display: flex;
	align-items: center;
	text-decoration: none;
	text-transform: capitalize;
	flex-direction: column;
	padding: 6px;
	height: 80px;
	min-width: 100px;
	justify-content: center;
	max-width: 200px;
	border: none;
	transition: all 0.3s ease-out;
	svg { 
		width: 40px;
		fill: #606060;
		transition: all 0.5s;
		transform: scale(0.7);
	}
	&:focus {
		outline: none;
	}
	&:hover {
		color: ${Color.main};
		box-shadow: inset 0px -4px 0px 0px ${Color.main};
		transition: all 0.5s;
		text-decoration: none;
		svg {
			fill: ${Color.main} !important;
			transform: scale(1);
		}
	}
	${props => props.selected && css`
		color: ${Color.main};
		box-shadow: inset 0px -2px 0px 0px ${Color.main};
		font-weight: 600;
		svg {
			fill: ${Color.main} !important;
			transform: scale(1);
		}
  	`}
	.nav-button__icon {
		width: 30px;
		padding-bottom: 8px;
	}

	@media ${Device.xs} {
		min-width: 80px;
	}

`

/*----- Component -----*/
const NavButton = ({ content, destination, selected, icon }) => {
	return(
		<StyledNavButton to={destination} selected={selected} className="nav-button">
			{ icon ? icon : null }
			{ content }
		</StyledNavButton>
	)
}
export default NavButton

/*----- PropTypes -----*/
NavButton.propTypes = {
  content:	 	PropTypes.string.isRequired,
  destination:	PropTypes.string.isRequired
}
