import moment from 'moment';

export const getId = history => {
	return history.location.pathname.split('/')[2];
};

export const getIdPackageHotel = history => {
	return history.location.pathname.split('/')[4];
};

export const loadPassengers = (data, countPassengers) => {
	let passengers = [];
	data &&
		countPassengers.forEach((pass, idx) => {
			let date_of_birthday = data[`date_of_birthday_${idx}`];

			passengers.push({
				name: data[`name_${idx}`].toUpperCase() || '',
				lastname: data[`lastname_${idx}`].toUpperCase() || '',
				nationality: data[`nationality_${idx}`] || '',
				dni_passport: data[`dni_passport_${idx}`] || '',
				age: date_of_birthday
					? moment().diff(moment(date_of_birthday), 'years')
					: '',
				date_of_birthday: date_of_birthday
					? date_of_birthday.format('DD/MM/YYYY')
					: '',
				...(data[`phone_${idx}`] && { phone: data[`phone_${idx}`] })
			});
		});
	return passengers;
};

export const countPassengers = passengersRequired => {
	let passengers = 0;
	if (!passengersRequired) return;
	if (Array.isArray(passengersRequired)) {
		passengersRequired.forEach(passengers => {
			passengers += passengers.adults_number + passengers.children_ages.length;
		});
	} else {
		passengers =
			passengersRequired.adults_number + passengersRequired.children_ages.length;
	}

	return [...Array(passengers).keys()];
};

export const hasErrors = fieldsError => {
	return Object.keys(fieldsError).some(field => fieldsError[field]);
};

export const checkoutHandleSubmit = (
	event,
	form,
	history,
	service,
	id,
	setPassengersParsed,
	addToCart
) => {
	event.preventDefault();
	form.validateFields();

	if (hasErrors(form.getFieldsError())) {
		console.log('Error de validación');
	} else if (setPassengersParsed) {
		setPassengersParsed(form.getFieldsValue());
		history.push(`/${service}/${id}/checkout/confirm`);
	} else {
		addToCart(form.getFieldsValue());
	}
};

export const cartHandleSubmit = (event, form, history, setPassengersParsed) => {
	event.preventDefault();
	form.validateFields();
	if (hasErrors(form.getFieldsError())) {
		console.log('Error de validación');
	} else {
		setPassengersParsed(form.getFieldsValue());
		history.push(`/cart/confirm`);
	}
};

export const passengersFromQuery = rooms => {
	let adults_number = 0;
	let children_ages = [];

	rooms.forEach(room => {
		adults_number += room.adults_number;
		room.children_ages.forEach(child => children_ages.push(child));
	});

	return { adults_number: adults_number, children_ages: children_ages };
};

export const parseCartHotelData = (hotel, id, isPackage = false, itemDetails) => {
	let cartData = { ...hotel };
	if (itemDetails) cartData.itemDetails = itemDetails;
	if (isPackage) {
		cartData.rate = hotel.rate;
		cartData.type = 'package';
		cartData.currency = hotel.rate.currency;
	} else {
		cartData.rate = cartData.rates.filter(r => r.item_id === id).pop();
		delete cartData.rates;
		cartData.type = 'hotel';
	}
	return cartData;
};

export const passengerSelector = (data = { type: null }) => {
	switch (data.type) {
		case 'transfer':
		case 'excursion':
			return data.rate.amount_details.occupancy.required;
		case 'hotel':
			return passengersFromQuery(data.searchQuery.rooms);
		case 'package':
			return {
				adults_number: data.searchQuery.adults_number,
				children_ages: data.searchQuery.children_ages
			};
		default:
			return null;
	}
};
export const parsePackageHotel = packageData => {
	let newData = { ...packageData };
	newData.name = packageData.rate.hotel[0].name;
	newData.info = {
		observations: [{ value: packageData.rate.hotel[0].description }],
		address: packageData.rate.hotel[0].address,
		geolocation: {
			latitude: null,
			longitude: null
		}
	};
	newData.city = {
		name: packageData.rate.hotel[0].location.nombre
	};
	newData.images = packageData.rate.hotel[0].images;
	newData.rate.amount = parseInt(packageData.rate.amount);
	newData.images = packageData.rate.hotel[0].images.map(i => i.url);
	newData.currency = packageData.rate.currency;
	return newData;
};

export const parsePackageHotelRoom = data => ({
	item_id: data.rate.item_id,
	id: data.rate.id,
	room_id: null,
	room_type: {
		id: data.rate.hotel[0].rooms.type.id,
		code: data.rate.hotel[0].rooms.type.code,
		name: data.rate.hotel[0].rooms.type.name
	},
	provider: {
		id: null
	},
	regime: {
		id: data.rate.hotel[0].regime.id,
		code: data.rate.hotel[0].regime.code,
		name: data.rate.hotel[0].regime.name
	},
	tariff_type: null,
	iva: null,
	currency: data.rate.currency,
	amount: data.rate.amount,
	amount_with_taxes: data.rate.amount_with_taxes,
	rooms_details: [
		{
			room_number: null,
			cost: data.rate.amount_details.passenger.sale,
			sale: data.rate.amount_with_taxes,
			iva: data.rate.amount_details.passenger.iva,
			notes: null,
			promotions: []
		}
	],
	nights: data.rate.hotel[0].nights
});

export const mixFields = (isMix, data, kind) => ({
	...(isMix
		? {
				in: {
					is_airport: data.in_is_airport.toString(),
					hotel_info: data.in_lodging,
					arrival_time: data.in_time_arrival.format('HH:mm'),
					...(data.in_is_airport
						? {
								fly_code: data.in_fly_code,
								airline: data.in_business,
								departure_time: data.in_time_departure.format('HH:mm')
						  }
						: {
								company: data.in_business
						  })
				},
				out: {
					is_airport: data.out_is_airport.toString(),
					arrival_time: data.out_time_arrival.format('HH:mm'),
					hotel_info: data.in_lodging,
					...(data.out_is_airport
						? {
								fly_code: data.out_fly_code,
								airline: data.out_business,
								departure_time: data.out_time_departure.format('HH:mm')
						  }
						: {
								company: data.out_business
						  })
				}
		  }
		: {
				[kind]: {
					is_airport: data.is_airport.toString(),
					hotel_info: data.lodging,
					arrival_time: data.time_arrival.format('HH:mm'),
					...(data.is_airport
						? {
								fly_code: data.fly_code,
								airline: data.business,
								departure_time: data.time_departure.format('HH:mm')
						  }
						: {
								company: data.business
						  })
				}
		  })
});
