import React from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { logout } from '../../authentication/actions/loginActions';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(Link)`
	box-shadow: unset !important;
	text-decoration: none !important;
`;

const LogoutButton = ({ children, logout, className, customOnClick }) => {
	return (
		<Button
			className={className}
			onClick={e => {
				e.preventDefault();
				logout();
				if (customOnClick) customOnClick();
			}}
		>
			<StyledLink to="/home">{children}</StyledLink>
		</Button>
	);
};

function mapDispatchToProps(dispatch) {
	return {
		logout: payload => logout(dispatch, payload)
	};
}

export default connect(null, mapDispatchToProps)(LogoutButton);
