import React from 'react';
import { Icon } from 'antd';
import {
	StyledDiv,
	IconRow,
	ContentRow,
	TitleText,
	SubTitleText,
	ContentText,
	FooterText,
	FooterRow
} from './styles';

const GenericFeedback = ({
	closeModal,
	title,
	subTitle,
	content,
	subContent,
	footer
}) => {
	return (
		<StyledDiv>
			<IconRow>
				<Icon
					type="close"
					onClick={e => {
						e.preventDefault();
						closeModal();
					}}
					style={{ color: '#8A9EAD', fontSize: '16px' }}
				/>
			</IconRow>
			<ContentRow>
				<TitleText content={title} />
				<SubTitleText content={subTitle} />
				<ContentText content={content} />
				<ContentText content={subContent} />
			</ContentRow>
			<FooterRow>
				<FooterText content={footer} />
			</FooterRow>
		</StyledDiv>
	);
};

export default GenericFeedback;
