export const GET_HOTELS = 'GET_HOTELS';
export const GET_HOTELS_SUCCESS = 'GET_HOTELS_SUCCESS';
export const GET_HOTELS_FAILED = 'GET_HOTELS_FAILED';
export const RESET_HOTEL_TRANSFER = 'RESET_HOTEL_TRANSFER';

export const getHotels = (dispatch, param) =>
	dispatch({ type: GET_HOTELS, payload: param });

export const resetHotelFilter = (dispatch, params) =>
	dispatch({ type: RESET_HOTEL_TRANSFER, payload: params });
