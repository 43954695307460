import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

/*----- Selectors -----*/
import Reservation from '../../../../commons/reservation';
import { hotelSteps } from '../../../../../utilities/utils';

/*----- Assets -----*/
import EndReservation from '../../../../../assets/images/hotel-end-reservation.svg';

import { getId } from '../../../../commons/checkout/CheckoutUtils';
import { reserveHotelData } from '../../../selectors/reserveHotelSelector';

const SuccessResponse = ({ data, history }) => (
	<Reservation
		code={data.reservation_id}
		dataSteps={hotelSteps(getId(history))}
		imageReservation={EndReservation}
		active={4}
	/>
);

const mapStateToProps = state => {
	return {
		data: reserveHotelData(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(SuccessResponse));
