import { call, put, takeLatest } from 'redux-saga/effects';
import {
	GET_PACKAGES,
	GET_PACKAGES_SUCCESS,
	GET_PACKAGES_FAILED
} from '../actions/searchFormActions';
import { getPackages } from '../../../api/endpoints/Packages';

const delay = time => new Promise(resolve => setTimeout(resolve, time));

function* shotFetchPackages(action) {
	function* onSuccess(body) {}

	function* onOk(body) {
		yield put({
			type: GET_PACKAGES_SUCCESS,
			payload: body,
			withHotel: action.payload.withHotel
		});
	}

	function* onError(error) {
		yield put({ type: GET_PACKAGES_FAILED, payload: error });
	}

	function* onPartialContent() {
		yield call(delay, 500);
		yield put({ type: GET_PACKAGES, payload: action.payload });
	}

	try {
		yield call(getPackages, action.payload, {
			onSuccess: onSuccess,
			onError: onError,
			onOk: onOk,
			onPartialContent: onPartialContent
		});
	} catch (error) {
		yield onError(error.message);
	}
}

export default function* PackagesSagas() {
	yield takeLatest(GET_PACKAGES, shotFetchPackages);
}
