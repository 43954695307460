import { createSelector } from 'reselect'

const modalVisible = state => state.app.modalVisible

export const modalVisibleSelector = createSelector(
	modalVisible,
	modalVisible => modalVisible
)
export const typeConsultSelector        = state => state.app.typeConsult
export const nameConsultSelector        = state => state.app.nameConsult
export const descriptionConsultSelector = state => state.app.descriptionConsult
export const alertVisibleSelector       = state => state.app.alertVisible
export const footerVisibleSelector      = state => state.app.footerVisible