import styled from 'styled-components';
import Text from '../text/Text';
import CenterContainer from '../containers/CenterContainer';
import { Carousel } from 'antd';
import DefaultButton from '../button/DefaultButton';
import Stars from '../commons/Stars';
import Color from '../../commons/Color';

export const StyledServicesCarousel = styled.div`
	flex-direction: column;
	display: flex;
	margin-bottom: 60px;
`;

export const StyledCarousel = styled(Carousel)`
	${CenterContainer}
`;

export const StyledServiceCard = styled.div`
	box-shadow: 0px 2px 20px #0000001f;
	${CenterContainer}
	flex-direction: column;
	width: 90%;
	margin: 10px 0px;
	border-radius: 10px 10px 0px 0px;
	height: 400px;
`;

export const StyledImage = styled.img`
	width: 100%;
	border-radius: 10px 10px 0px 0px;
	height: 198px;
`;

export const StyledTitle = styled(Text)`
	text-align: center;
	font-size: 25px;
	font-weight: bold;
	margin-bottom: 40px;
`;

export const StyledName = styled(Text)`
	text-align: center;
	font-size: 20px;
	margin: 16px 0px 0px;
`;

export const StyledDescription = styled(Text)``;

export const ContentCard = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: space-around;
	flex-direction: column;
	align-items: center;
`;

export const StyledDefaultButton = styled(DefaultButton)`
	align-items: center;
	margin: 12px 0px 24px;
`;

export const ContentImages = styled.div`
	position: relative;
	display: flex;
	justify-content: flex-end;
`;

export const StyledStars = styled(Stars)`
	margin: 8px;
	position: absolute;
	background: ${props => props.stars && 'white'};
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px 0px 8px 8px;
`;

export const StyledPackagesServices = styled.div`
	flex-wrap: wrap;
	display: flex;
	justify-content: center;
`;

export const StyledCountText = styled(Text)`
	color: ${Color.text};
	font-size: 14px;
	text-align: center;
	padding-top: 10px;
`;
