import styled, { css } from 'styled-components';
import { Row, Col } from 'antd';
import WaterMark from '../../../assets/images/watermark.svg';
import Device from '../../../commons/Device';
import Text from '../../text/Text';

export const ItemContainer = styled(Row)`
	.custom-text {
		margin-top: 50px;
		margin-bottom: 20px;
		@media ${Device.xs} {
			font-size: 16px;
		}
	}
`;

export const ButtonContainer = styled(Row)`
	padding: 35px 0px;
	.btn-row {
		flex-wrap: nowrap;
		width: 50%;
	}
`;
export const StyledCheckoutContainer = styled.main`
	width: 100%;
	background-image: url(${WaterMark});
	background-position: left bottom;
	background-repeat: no-repeat;
`;

export const StyledBoxContainer = styled.article`
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 20px #00000029;
	margin: 0 6%;
	border-radius: 10px;
	margin-bottom: 35px;
	padding-top: 19px;
	padding-bottom: 10px;
`;

export const ItemConfirmContainer = styled(Row)`
	padding: 40px;
	@media ${Device.xs} {
		padding: 30px;
	}
`;

export const StyledBoxContainerConfirm = styled(StyledBoxContainer)`
	padding-top: 0px;
	padding-bottom: 0px;
	${props =>
		props.fromCart &&
		css`
			margin: 1rem !important;
		`}
`;

export const StyledCol = styled(Col)`
	display: flex !important;
	align-items: flex-start;

	@media ${Device.xs} {
		flex-direction: column;
	}
`;

export const StyledTitleHeader = styled(Text)`
	font-size: 26px;

	@media ${Device.xs} {
		font-size: 18px;
	}
`;

export const StyledSubtitleHeader = styled(Text)`
	font-size: 20px;

	@media ${Device.xs} {
		font-size: 16px;
	}
`;
