import React from 'react';

const ResponseContent = ({
	Loading,
	Success,
	Error,
	isLoading,
	successResponse
}) => {
	if (isLoading) {
		return <Loading />;
	} else if (successResponse) {
		return <Success />;
	} else {
		return <>{Error}</>;
	}
};

export default ResponseContent;
