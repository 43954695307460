import React from "react";

export default function Suitcase() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -24 480 480" className="suitcase-icon">
      <path d="M456 72H352V40c-.027-22.082-17.918-39.973-40-40H168c-22.082.027-39.973 17.918-40 40v32H24C10.746 72 0 82.746 0 96v178.078a24.105 24.105 0 0016 22.586V408c0 13.254 10.746 24 24 24h400c13.254 0 24-10.746 24-24V296.672a24.113 24.113 0 0016-22.594V96c0-13.254-10.746-24-24-24zM144 40c0-13.254 10.746-24 24-24h144c13.254 0 24 10.746 24 24v32h-16V40a8 8 0 00-8-8H168a8 8 0 00-8 8v32h-16zm160 32H176V48h128zm144 336a8 8 0 01-8 8H40a8 8 0 01-8-8V299.414l176 24.274V344c0 13.254 10.746 24 24 24h16c13.254 0 24-10.746 24-24v-20.313l176-24.273zm-192-64a8 8 0 01-8 8h-16a8 8 0 01-8-8v-48a8 8 0 018-8h16a8 8 0 018 8zm208-69.922a8 8 0 01-6.879 7.93l-2.219.305L272 307.534V296c0-13.254-10.746-24-24-24h-16c-13.254 0-24 10.746-24 24v11.535L22.887 282.008a8.001 8.001 0 01-6.887-7.93V96a8 8 0 018-8h432a8 8 0 018 8zm0 0" />
    </svg>
  );
}

