import React from 'react';

/*----- Components -----*/
import SuccessResponse from './SuccessResponse';
import PackageFinder from '../../commons/PackageFinder';

const DetailsPackageHotel = () => (
	<PackageFinder SuccessResponse={SuccessResponse} />
);

export default DetailsPackageHotel;
