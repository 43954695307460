import React, { useState } from 'react';
import { StyledMain } from './styles';
import LoginTabs from '../LoginTabs';
import ContentModal from './ContentModal';
import FeedbackModal from './FeedbackModal';
import RecoveryPassword from '../feedback/RecoveryPassword';
import SuccessResponseRecovery from '../feedback/SuccessResponseRecovery';
import SuccessResponseRegister from '../feedback/SuccessResponseRegister';
import FailedResponseRegister from '../feedback/FailedResponseRegister';
import { connect } from 'react-redux';
import { deleteRecovery } from '../../../authentication/actions/recoveryActions';
import { successRegister } from '../../../authentication/selectors/registerSelector';
import { recovery } from '../../../authentication/selectors/recoverySelector';

const LoginMobile = ({
	history,
	recoveryPassword,
	successRegister,
	deleteRecovery
}) => {
	const [visible, setVisible] = useState(false);

	const closeModalFunction = () => {
		if (recoveryPassword) deleteRecovery();
		if (successRegister) history.push('/home');
		setVisible(false);
	};

	return (
		<StyledMain>
			<LoginTabs
				openModal={() => {
					setVisible(true);
				}}
				stayRegister={true}
			/>
			<FeedbackModal
				visible={visible}
				closeModal={closeModalFunction}
				content={
					<ContentModal
						history={history}
						closeModal={closeModalFunction}
						SuccessRegister={SuccessResponseRegister}
						FailedRegister={FailedResponseRegister}
						RecoveryPasswordForm={RecoveryPassword}
						SuccessRecoveryPassword={SuccessResponseRecovery}
					/>
				}
			/>
		</StyledMain>
	);
};

const mapStateToProps = state => {
	return {
		successRegister: successRegister(state),
		recoveryPassword: recovery(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		deleteRecovery: payload => deleteRecovery(dispatch, payload)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginMobile);
