import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

/*----- Ant design -----*/
import { Checkbox } from 'antd';

/*----- Components -----*/
import Label from './Label';
import { InputContainer } from './styles';

const CheckboxGroup = (
	{ name, options, onChange, value, afterChange, className, disabled = false },
	ref
) => {
	const getOptions = () => {
		return options.map((opt, key) => (
			<Checkbox value={opt.value} key={key}>
				{opt.label}
			</Checkbox>
		));
	};

	return (
		<InputContainer className={className}>
			<Label name={name} />
			<Checkbox.Group
				ref={ref}
				disabled={disabled}
				onChange={value => {
					onChange(value);
					if (afterChange) {
						afterChange(value);
					}
				}}
				value={value}
			>
				{getOptions()}
			</Checkbox.Group>
		</InputContainer>
	);
};

export default forwardRef(CheckboxGroup);

/*----- PropTypes -----*/
CheckboxGroup.propTypes = {
	name: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired,
	onChange: PropTypes.func,
	value: PropTypes.array,
	afterChange: PropTypes.func,
	className: PropTypes.string,
	disabled: PropTypes.func
};
