import React from 'react';
import { InfoContainer, StyledInfoTitle, StyledInfoContent } from './styles';

const SplitByTwoDots = fields => {
	let data = fields.split('\n');
	return data.map((item, i) => {
		let splitItem = item.split(':');
		return {
			title: `${splitItem[0]}:`,
			content: [splitItem.slice(1).join(':')]
		};
	});
};

const SplitByMiddleDash = (fields, data) => {
	let splitData = fields.split(';\n');
	if (splitData.length <= 2) {
		return splitData.map((item, i) => {
			let splitItem = item.split('-');
			return {
				title: splitItem[0],
				content: splitItem.slice(1)
			};
		});
	} else {
		return [
			{
				title: data[0],
				content: data.slice(1)
			}
		];
	}
};

const SeeDetailsInfo = details => {
	try {
		return details.details.map((fields, key) => {
			let items = [];
			let splitData = fields.split('-');
			if (splitData.length > 1) {
				items = SplitByMiddleDash(fields, splitData, key);
			} else {
				items = SplitByTwoDots(fields);
			}

			return items.map((item, i) => {
				if (item.title.length > 1) {
					return (
						<InfoContainer key={i}>
							<StyledInfoTitle content={item.title} />
							{item.content.map((child, j) => (
								<StyledInfoContent key={j} content={child} />
							))}
						</InfoContainer>
					);
				} else {
					return null;
				}
			});
		});
	} catch {
		return 'No se encuentran detalles';
	}
};

export default SeeDetailsInfo;
