import styled from 'styled-components';
import Device from '../../../../commons/Device';
import { Row, Col } from 'antd';
import Text from '../../../../components/text/Text';

export const MainRow = styled(Row)`
	border-radius: 10px;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 8px 20px #0000001f;
	width: 100%;
	min-height: ${props => (props.withimage ? '184px' : 'unset')};

	@media ${Device.xs} {
		padding-bottom: 18px !important;
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 0px 20px 5px #0000001f;
		min-height: unset;
	}
`;

export const StyledCol = styled(Col)`
	border-right: 1.5px solid #0000001f;
	margin: 15px 0px;
	padding: 10px 30px !important;
	display: flex !important;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	@media ${Device.xs} {
		border-right: none;
		margin: 0px;
		padding: 12px 15px !important;
	}
`;

export const StyledFirstCol = styled(StyledCol)`
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;

	@media ${Device.xs} {
		padding-top: 30px !important;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		border-bottom-left-radius: 0px;
	}
`;

export const StyledLastCol = styled(StyledCol)`
	border-right: none;
`;

export const CheckoutDataImage = styled(StyledFirstCol)`
	background-position: center;
	background-size: ${props => props.position || 'cover'};
	background-repeat: no-repeat;
	background-image: ${props => `url(${props.src})`};
	margin: 0px;

	@media ${Device.xs} {
		height: 180px;
	}
`;

export const StyledTitle = styled(Text)`
	font-size: 14px;
	margin-bottom: 22px;

	@media ${Device.xs} {
		font-size: 16px;
		margin-bottom: 10px;
	}
`;

export const StyledName = styled(Text)`
	font-size: 1rem;

	@media ${Device.xs} {
		font-size: 18px;
	}
	@media ${Device.xxl} {
		font-size: 20px;
	}
`;

export const StyledTextCheckout = styled(Text)`
	font-size: 16px;
	color: #707070;
	margin: 0px;
`;

export const StyledPriceText = styled(Text)`
	margin: 0px;
	font-size: 28px;

	@media ${Device.xs} {
		font-size: 22px;
	}
	@media ${Device.xxl} {
		font-size: 35px;
	}
`;

export const StyledPriceTaxes = styled(Text)`
	font-size: 12px;
	color: #707070;
`;
