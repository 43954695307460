import TransfersIcon from '../../../../../assets/icons/checkout/transfer.svg';
import PersonIcon from '../../../../../assets/icons/custom_icons/user.svg';

const detailsItemsActivity = (adults = '2', children = '') => {
	const childText = children > 1 ? 'menores' : 'menor';
	const childrenText = children ? `y ${children} ${childText}` : '';

	return [
		{ icon: PersonIcon, text: `${adults} adultos ${childrenText}` },
		{ icon: TransfersIcon, text: 'Traslados' }
	];
};

export default detailsItemsActivity;
