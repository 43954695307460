import styled from 'styled-components';
import Device from '../../../commons/Device';
import Stars from '../../commons/Stars';
import Text from '../../text/Text';

export const StyledCard = styled.div`
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;

	@media ${Device.xs} {
		align-items: center;
	}
`;

export const TitleContainer = styled.div`
	padding-bottom: 18px;
	margin: 0px;
	display: flex;
	flex-direction: column;

	@media ${Device.xs} {
		padding: 10px 0px;
		align-items: center;
	}
`;

export const DescriptionContainer = styled.div`
	display: flex;
	padding-top: 14px;
	line-height: 18px;

	@media ${Device.xs} {
		padding-bottom: 16px;
		justify-content: center;
	}
`;

export const StyledStars = styled(Stars)`
	margin-right: 8px;
	display: flex;
	margin-bottom: 12px;

	@media ${Device.xs} {
		padding-bottom: 3px;
		justify-content: center;
	}
`;

export const StyledText = styled(Text)`
	font-size: 14px;
	margin-right: 12px;
	color: #707070;

	@media ${Device.xs} {
		font-size: 13px;
	}
`;

export const StyledIcon = styled.img`
	width: 19px;
	height: 16px;
	margin-right: 12px;
`;

export const StyledTitle = styled(Text)`
	font-size: 20px;

	@media ${Device.xs} {
		text-align: center;
	}
`;
