import styled from 'styled-components';
import Device from '../../../commons/Device';
import Title from '../../text/Title';
import Color from '../../../commons/Color';
import SeeDetails from '../../modal/SeeDetails';

export const StyledCard = styled.div`
	width: 100%;

	.data-container {
		padding-top: 3%;
	}

	@media ${Device.xs} {
		.destination-item {
			padding-bottom: 15px;
		}
	}
`;

export const CardTitle = styled(Title)`
	padding: 16px 0px 16px 0px;
	text-align: left;
	margin: 0px;
	font-size: 20px;
	font-weight: 700;
	order: 2;

	@media ${Device.xs} {
		padding: 16px 0px 8px 0px;
		text-align: center;
		font-size: 16px;
	}
`;

export const TypeTitle = styled(CardTitle)`
	padding: 0px;
	order: 1;
	@media ${Device.xs} {
		padding: 0px;
	}
`;

export const ValidityText = styled(Title)`
	text-align: left;
	margin: 0px;
	font-size: 14px;
	font-weight: 700;
	color: ${Color.text};
	margin-bottom: ${props => props.marginBottom};
	order: 6;
	@media ${Device.xs} {
		text-align: center;
		font-size: 12px;
		margin-bottom: 16px;
		margin-top: 12px;
	}
`;

export const ContentInformation = styled.div`
	padding-right: 30px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	@media ${Device.xs} {
		align-items: center;
		padding-right: 0px;
	}
`;

export const StyledSeeDetails = styled(SeeDetails)`
	margin-bottom: 12px;
	order: 5;

	@media ${Device.xs} {
		padding-right: 0px;
		order: 3;
	}
`;
