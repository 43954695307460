import React from 'react';
import ReservationPanelItemIcon from '../../../components/panel/ReservationPanalItemIcon';

const DetailsItems = detailsContent => {
	return detailsContent.map((item, id) => (
		<ReservationPanelItemIcon key={id} icon={item.icon} description={item.text} />
	));
};

export default DetailsItems;
